import React, { memo } from 'react';
import { Issue } from 'entities/Issue';
import { Badge } from '@mantine/core';

interface CvssBadgeProps {
    issue: Issue;
}

const CvssBadge = (props: CvssBadgeProps) => {
    const { issue } = props;
    const { severity } = issue;
    if (!severity?.score) return null;
    return (
        <Badge variant="light">CVSS score: {severity.score.toFixed(1)}</Badge>
    );
};

export default memo(CvssBadge);
