import { useMemo } from 'react';
import { useGetJiraSettingsQuery } from 'entities/Integrations';
import { JiraSettings } from '../types/jira';

interface JiraSettingsHook {
    jiraSettings: JiraSettings | undefined;
    hasJiraSettings: boolean;
    connectToJiraProjectAvailable: boolean;
}

export const useJiraSettings = () => {
    const { data: jiraSettings } = useGetJiraSettingsQuery();

    const hasJiraSettings = useMemo(
        () =>
            jiraSettings?.url &&
            jiraSettings?.username &&
            jiraSettings?.apiToken &&
            jiraSettings?.projectKey &&
            jiraSettings?.issueType,
        [jiraSettings]
    );

    const connectToJiraProjectAvailable = useMemo(
        () =>
            jiraSettings?.url &&
            jiraSettings?.username &&
            jiraSettings?.apiToken,
        [jiraSettings]
    );

    return {
        jiraSettings,
        hasJiraSettings,
        connectToJiraProjectAvailable
    };
};
