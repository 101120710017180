import {
    Asset,
    PortDeviceType,
    PortExtraInfo,
    PortOs,
    PortProduct,
    RootAsset,
    RootAssetCandidate
} from 'entities/Asset';
import { Group } from '@mantine/core';
import React from 'react';
import HeaderedCard from 'shared/ui/HeaderedCard/HeaderedCard';

interface PortOverviewProps {
    asset: Asset | RootAsset | RootAssetCandidate;
}

const PortOverview = (props: PortOverviewProps) => {
    const { asset } = props;

    return (
        <Group gap="lg" grow>
            <HeaderedCard header="Server">
                <PortProduct port={asset.metadata?.port} />
            </HeaderedCard>
            <HeaderedCard header="Device type">
                <PortDeviceType port={asset.metadata?.port} />
            </HeaderedCard>
            <HeaderedCard header="Server OS">
                <PortOs port={asset.metadata?.port} />
            </HeaderedCard>
            <HeaderedCard header="Extra info">
                <PortExtraInfo port={asset.metadata?.port} />
            </HeaderedCard>
        </Group>
    );
};

export default PortOverview;
