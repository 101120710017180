import { rtkApi } from 'shared/api/rtkApi';
import { ApiResponse } from 'shared/types/pagination';
import { AssetType, PaginationQueryParams } from 'entities/Asset';
import { IssueUnique, Rating } from 'entities/Issue';
import { Misconfiguration } from '../model/types/misconfiguration';

export interface MisconfigurationQueryParams extends PaginationQueryParams {
    assetType?: AssetType[];
    tag?: string[];
    assetTag?: string[];
    assetID?: string[];
    rootAssetID?: string[];
    orderBy?: string;
    nameExact?: string;
    nameStartsWith?: string;
    nameIncludes?: string;
    lastSeenAtGte?: number;
    lastSeenAtLte?: number;
    createdAtGte?: number;
    createdAtLte?: number;
    rating?: Rating[];
    alertId?: string;
}

const misconfigurationApi = rtkApi.injectEndpoints({
    endpoints: builder => ({
        getMisconfiguration: builder.query<Misconfiguration, string>({
            query: id => `/misconfigurations/${id}`
        }),
        getMisconfigurations: builder.query<
            ApiResponse<Misconfiguration>,
            MisconfigurationQueryParams
        >({
            query: params => ({
                url: '/misconfigurations',
                params
            })
        }),
        getMisconfigurationsUnique: builder.query<
            ApiResponse<IssueUnique>,
            MisconfigurationQueryParams
        >({
            query: params => ({
                url: '/misconfigurations-unique',
                params
            })
        })
    })
});

export const useGetMisconfigurations =
    misconfigurationApi.useGetMisconfigurationsQuery;
export const useGetMisconfiguration =
    misconfigurationApi.useGetMisconfigurationQuery;
export const useGetMisconfigurationsUnique =
    misconfigurationApi.useGetMisconfigurationsUniqueQuery;
