import { StateSchema } from 'app/providers/StoreProvider';

export const getUser = (state: StateSchema) => state.user;
export const getUserAuthData = (state: StateSchema) => state.user?.authData;
export const getUserInitialized = (state: StateSchema) =>
    state.user?.initialized;
export const getEmail = (state: StateSchema) => state.user?.authData?.email;
export const getUserApiToken = (state: StateSchema) =>
    state.user?.authData?.api_key;
export const getUserAuthToken = (state: StateSchema) =>
    state.user?.authData?.auth_token;
export const getUserStripeToken = (state: StateSchema) =>
    state.user?.authData?.stripe_key;
