import { memo } from 'react';
import { Asset, RootAsset, useGetAssetDependencyGraph } from 'entities/Asset';
import { useGraph } from 'entities/Graph';
import AssetGraph from '../AssetGraph/AssetGraph';

interface AssetDependencyGraphProps {
    className?: string;
    asset: Asset | RootAsset;
}

const AssetDependencyGraph = (props: AssetDependencyGraphProps) => {
    const { asset } = props;
    const { graph } = useGetAssetDependencyGraph(asset);
    const { links, nodes } = useGraph(graph ? [graph] : undefined);

    function renderGraph() {
        if (!links || !nodes) return null;
        return (
            <AssetGraph
                links={links}
                nodes={nodes}
                sourceAssetID={asset.id}
                withArrows
            />
        );
    }

    return renderGraph();
};

export default memo(AssetDependencyGraph);
