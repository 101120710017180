import { memo, useState } from 'react';
import {
    Anchor,
    Button,
    Container,
    Divider,
    FileButton,
    Flex,
    Grid,
    Image,
    JsonInput,
    List,
    Mark,
    Paper,
    Space,
    Text,
    Title
} from '@mantine/core';
import { GcpAssetCreateBody, useAddGcpAsset } from 'entities/Asset';
import { useForm } from '@mantine/form';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { addNewRootAssetActions } from 'features/asset/actions/AddNewRootAsset';
import ZoomedImage from 'shared/ui/ZoomedImage/ZoomedImage';
import Image1 from 'shared/assets/images/integrations/gcp/Screenshot 2023-12-04 at 16.38.07.png';
import Image2 from 'shared/assets/images/integrations/gcp/Screenshot 2023-12-04 at 16.38.21.png';
import Image3 from 'shared/assets/images/integrations/gcp/Screenshot 2023-12-04 at 16.38.56.png';
import Image4 from 'shared/assets/images/integrations/gcp/Screenshot 2023-12-04 at 16.39.26.png';
import Image5 from 'shared/assets/images/integrations/gcp/Screenshot 2023-12-04 at 16.39.48.png';
import Image6 from 'shared/assets/images/integrations/gcp/Screenshot 2023-12-04 at 16.39.55.png';
import Image7 from 'shared/assets/images/integrations/gcp/Screenshot 2023-12-04 at 17.05.08.png';
import Image8 from 'shared/assets/images/integrations/gcp/Screenshot 2023-12-04 at 17.05.57.png';
import MailToServiceDesk from 'shared/ui/MailTo/MailToServiceDesk';
import { contactUsToVerifyAssetMessage } from '../../model/service/contactUsToVerifyAssetMessage';
import ConfirmFormFooter from '../common/ConfirmFormFooter/ConfirmFormFooter';

const JSON_PLACEHOLDER = `{
    "type": "",
    "project_id": "",
    "private_key_id": "",
    "private_key": "",
    "client_email": "",
    "client_id": "",
    "auth_uri": "",
    "token_uri": "",
    "auth_provider_x509_cert_url": "",
    "client_x509_cert_url": ""
}`;

const GcpRootAsset = () => {
    const dispatch = useAppDispatch();
    const [dragging, setDragging] = useState(false);
    const [addAsset, { isLoading }] = useAddGcpAsset();
    const form = useForm<{ json: string }>({
        initialValues: {
            json: ''
        }
    });

    const handleFileChange = (file: File | null) => {
        if (!file) return;

        const reader = new FileReader();
        reader.onload = function (e) {
            const contents = e.target?.result;
            if (contents) {
                if (typeof contents === 'string')
                    form.setFieldValue('json', contents);
            }
        };
        reader.readAsText(file);
    };

    const onSubmit = (values: { json: string }) => {
        const body = JSON.parse(values.json) as GcpAssetCreateBody;
        return addAsset(body)
            .unwrap()
            .then(() => {
                dispatch(
                    addNewRootAssetActions.setSuccess(
                        contactUsToVerifyAssetMessage(
                            `Google Cloud Platform account`
                        )
                    )
                );
            })
            .catch(err => {
                dispatch(addNewRootAssetActions.setError(err));
            });
    };

    return (
        <Container size="md">
            <Text c="dimmed" ta="center" size="md">
                Enter a Google Cloud Platform account you want to keep safe and
                secure. Follow the guide below or contact our support team (
                <MailToServiceDesk />) for more information.
            </Text>
            <Container size="sm">
                <Paper withBorder shadow="md" p="xl" radius="md" mt="xl">
                    <form onSubmit={form.onSubmit(onSubmit)}>
                        <Flex direction="column" gap="lg">
                            <JsonInput
                                className={dragging ? 'dragging' : ''}
                                label="Service account JSON"
                                description={
                                    <Flex
                                        align="center"
                                        justify="space-between"
                                    >
                                        <Text
                                            size="sm"
                                            c="dimmed"
                                            inline
                                            mt={7}
                                        >
                                            Read more about{' '}
                                            <Anchor
                                                href="https://cloud.google.com/iam/docs/keys-create-delete"
                                                target="_blank"
                                            >
                                                how to create service account
                                                keys
                                            </Anchor>
                                            .
                                        </Text>
                                        <FileButton
                                            onChange={handleFileChange}
                                            accept="application/json"
                                        >
                                            {props => (
                                                <Button
                                                    {...props}
                                                    variant="outline"
                                                    size="compact-xs"
                                                >
                                                    Drag&Drop supported
                                                </Button>
                                            )}
                                        </FileButton>
                                    </Flex>
                                }
                                validationError="Invalid JSON provided"
                                placeholder={JSON_PLACEHOLDER}
                                rows={12}
                                withAsterisk
                                required
                                formatOnBlur
                                data-gramm="false"
                                onDragEnter={() => setDragging(true)}
                                onDragLeave={() => setDragging(false)}
                                onDrop={e => {
                                    e.preventDefault();
                                    setDragging(false);
                                    handleFileChange(e.dataTransfer.files[0]);
                                }}
                                {...form.getInputProps('json')}
                            />
                            <Divider />
                            <ConfirmFormFooter loading={isLoading} />
                        </Flex>
                    </form>
                </Paper>
            </Container>

            <Space h="xl" />
            <Space h="xl" />
            <Space h="xl" />

            <Grid align="center">
                <Grid.Col span={12}>
                    <Title order={2} ta="center" fw="600">
                        How to connect your Google Cloud Platform account to
                        Attaxion?
                    </Title>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Space h="md" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        1. To connect your Google Cloud Platform account to
                        Attaxion, you have to create a service account key. To
                        do so, go to the the <Mark>Service accounts</Mark>{' '}
                        screen under IAM & Admin Product.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image1} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        2. Click <Mark>Create service account</Mark> button.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image2} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        3. Enter the service account name, and click{' '}
                        <Mark>Create and Continue</Mark> button.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image3} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        4. Add the following permissions (presented on the
                        screenshot) to the service account, click{' '}
                        <Mark>Continue</Mark> button. In the third step, leave
                        the fields blank and click the <Mark>DONE</Mark> button.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image4} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        5. Open service account details and click on the{' '}
                        <Mark>Keys</Mark> tab. Then click <Mark>Add key</Mark>{' '}
                        button.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image5} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        6. Select <Mark>Create new key</Mark> option and{' '}
                        <Mark>JSON</Mark> key type. Then click{' '}
                        <Mark>Create</Mark> button.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image6} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        7. Download the JSON file and upload it to the Attaxion
                        form at the top of this page. Drag and drop is
                        supported.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image6} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        8. To allow Attaxion to scan your infrastructure, you
                        also need to enable some GCP APIs that aren’t enabled by
                        default. To do so, go to the{' '}
                        <Mark>APIs & Services</Mark> screen.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image7} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Text size="xl">
                        9. Check the list on the page, and ensure that at least
                        the following APIs are enabled:
                    </Text>
                    <List size="xl" withPadding listStyleType="disc" mt="md">
                        <List.Item>App Engine</List.Item>
                        <List.Item>Cloud Asset API</List.Item>
                        <List.Item>Cloud DNS API</List.Item>
                        <List.Item>Cloud SQL Admin API</List.Item>
                        <List.Item>Compute Engine API</List.Item>
                    </List>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        10. If something is missing at the previous step, click
                        on the <Mark>Enable APIs and Services</Mark> button.
                        Find the required API there and enable it.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image8} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Text size="xl">
                        11. Now you can go back to Attaxion and click the{' '}
                        <Mark>Add</Mark> button.
                    </Text>
                </Grid.Col>
            </Grid>

            <Space h="xl" />
            <Space h="xl" />
            <Space h="xl" />
        </Container>
    );
};

export default memo(GcpRootAsset);
