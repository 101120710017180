import { DataTableColumn } from 'mantine-datatable';
import { Asset, AssetQueryParams, RootAsset } from 'entities/Asset';
import { BaseColumnProps, updateQuery } from 'widgets/Columns/baseColumn';
import React from 'react';
import { ScanMode, ScannerModeBadge } from 'entities/Scanner';

type RecordType = Asset | RootAsset;
type QueryParams = AssetQueryParams;

export default ({
    query = {},
    setQuery,
    column
}: BaseColumnProps<RecordType, QueryParams>): DataTableColumn<RecordType> => {
    const { scanMode = [] } = query;
    return {
        width: 130,
        accessor: 'scanMode',
        title: 'Scan mode',
        render: record => {
            const asset = 'asset' in record ? record.asset : record;
            return <ScannerModeBadge asset={asset as RecordType} fullWidth />;
        },
        ...column
    };
};
