import { useContext, useEffect } from 'react';
import { AssetDetailsContext } from './AssetDetailsContext';

interface UseTabsProps {
    activeTab: string;
    setActiveTab: (value: string) => void;
}

function useTabs(defaultTab: string = '') {
    const { activeTab, setActiveTab } = useContext(AssetDetailsContext);

    useEffect(() => {
        if (defaultTab) {
            setActiveTab(defaultTab);
        }
    }, []);

    return {
        activeTab,
        setActiveTab
    };
}

export default useTabs;
