import { AssetType, RootAsset, RootVerificationStatus } from 'entities/Asset';

export const isRootAssetVerificationRequired = (asset?: RootAsset) =>
    asset &&
    [
        RootVerificationStatus.PENDING,
        RootVerificationStatus.FAILED,
        RootVerificationStatus.ERROR
    ].includes(asset.rootVerificationStatus);

export const isRootAssetVerificationAvailable = (asset?: RootAsset) =>
    asset && [AssetType.DOMAIN].includes(asset.type);

export const isRootAssetVerificationCompleted = (asset?: RootAsset) =>
    asset?.rootVerificationStatus === RootVerificationStatus.VERIFIED;

export const isRootAssetVerificationRunning = (asset?: RootAsset) =>
    asset?.rootVerificationStatus === RootVerificationStatus.RUNNING;

export const isRootAssetVerificationFailed = (asset?: RootAsset) =>
    asset?.rootVerificationStatus === RootVerificationStatus.FAILED;

export const isRootAssetVerificationError = (asset?: RootAsset) =>
    asset?.rootVerificationStatus === RootVerificationStatus.ERROR;
