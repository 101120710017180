import React, { memo, useEffect, useRef } from 'react';
import classNames from 'classnames';
import maplibregl, { LngLatLike } from 'maplibre-gl';
import { Loader } from 'shared/ui/Loader';
import styles from './AssetGeolocation.module.scss';
import { Asset, RootAsset, RootAssetCandidate } from '../../model/types/asset';

interface AssetGeolocationProps {
    className?: string;
    asset?: Asset | RootAsset | RootAssetCandidate;
}

const MAX_ZOOM = 10;
const MIN_ZOOM = 1;
const DEFAULT_ZOOM = 1.5;
const DEFAULT_CENTER: LngLatLike = {
    lat: 20,
    lng: 0
};

const AssetGeolocationDetailsRow = (props: {
    label: string;
    value: string | number | null;
}) => {
    const { label, value } = props;

    return (
        <div className={styles.AssetGeolocationDetails__Row}>
            <div className={styles.AssetGeolocationDetails__Label}>{label}</div>
            <div className={styles.AssetGeolocationDetails__Value}>
                {value || '-'}
            </div>
        </div>
    );
};

const AssetGeolocation = (props: AssetGeolocationProps) => {
    const { className, asset } = props;
    const classes = classNames(styles.AssetGeolocation, className);

    const { geoip } = asset?.metadata || {};
    const mapContainer = useRef(null);
    const map = useRef<maplibregl.Map | null>(null);

    useEffect(() => {
        if (mapContainer.current && !map.current) {
            map.current = new maplibregl.Map({
                container: mapContainer.current,
                style: 'https://api.maptiler.com/maps/basic-v2/style.json?key=gpBPerLiRLjnpXmTKx3P',
                center: [
                    geoip?.lng || DEFAULT_CENTER.lng,
                    geoip?.lat || DEFAULT_CENTER.lat
                ],
                zoom: DEFAULT_ZOOM,
                maxZoom: MAX_ZOOM,
                minZoom: MIN_ZOOM,
                attributionControl: false
            });

            map.current.scrollZoom.disable();

            map.current.addControl(
                new maplibregl.NavigationControl({
                    showCompass: false
                }),
                'top-left'
            );

            map.current.on('load', () => {
                if (map.current) {
                    if (geoip?.lat && geoip?.lng) {
                        new maplibregl.Marker({
                            color: '#3254b5'
                        })
                            .setLngLat([geoip?.lng, geoip?.lat])
                            .addTo(map.current);
                    }
                }
            });
        }
    }, [geoip]);

    if (!asset || !geoip) return null;

    return (
        <div className={classes}>
            <div ref={mapContainer} className={styles.Map} />
            <div className={styles.AssetGeolocationDetails}>
                <div className={styles.AssetGeolocationDetails__Header}>
                    <div className={styles.AssetGeolocationDetails__Title}>
                        {asset.value}
                    </div>
                </div>
                <div className={styles.AssetGeolocationDetails__Body}>
                    <AssetGeolocationDetailsRow
                        label="Country"
                        value={geoip.country}
                    />
                    <AssetGeolocationDetailsRow
                        label="Region"
                        value={geoip.region}
                    />
                    <AssetGeolocationDetailsRow
                        label="City"
                        value={geoip.city}
                    />
                    <AssetGeolocationDetailsRow
                        label="Postal code"
                        value={geoip.postalCode}
                    />
                    <AssetGeolocationDetailsRow
                        label="Latitude"
                        value={geoip.lat}
                    />
                    <AssetGeolocationDetailsRow
                        label="Longitude"
                        value={geoip.lng}
                    />
                    <AssetGeolocationDetailsRow
                        label="Timezone offset (UTC)"
                        value={geoip.timezone}
                    />
                    <AssetGeolocationDetailsRow
                        label="Geoname ID"
                        value={geoip.geonameID}
                    />
                    <div className={styles.AssetGeolocationDetails__Divider} />
                    <AssetGeolocationDetailsRow label="ASN" value={geoip.asn} />
                    <AssetGeolocationDetailsRow
                        label="AS name"
                        value={geoip.asName}
                    />
                    <AssetGeolocationDetailsRow
                        label="AS route"
                        value={geoip.asRoute}
                    />
                    <AssetGeolocationDetailsRow
                        label="AS domain"
                        value={geoip.asDomain}
                    />
                    <AssetGeolocationDetailsRow
                        label="AS type"
                        value={geoip.asType}
                    />
                    <div className={styles.AssetGeolocationDetails__Divider} />
                    <AssetGeolocationDetailsRow label="ISP" value={geoip.isp} />
                    <AssetGeolocationDetailsRow
                        label="Connection type"
                        value={geoip.connectionType}
                    />
                </div>
            </div>
        </div>
    );
};

export default memo(AssetGeolocation);
