import { memo } from 'react';
import { Asset, useGetAssetBackwardGraph } from 'entities/Asset';
import { useGraph } from 'entities/Graph';
import AssetGraph from '../AssetGraph/AssetGraph';

interface AssetDiscoveryGraphProps {
    className?: string;
    asset: Asset;
}

const AssetDiscoveryGraph = (props: AssetDiscoveryGraphProps) => {
    const { asset } = props;
    const { graph } = useGetAssetBackwardGraph(asset);
    const { links, nodes } = useGraph(graph ? [graph] : undefined);

    function renderGraph() {
        if (!links || !nodes) return null;
        return (
            <AssetGraph
                links={links}
                nodes={nodes}
                sourceAssetID={asset.id}
                withArrows
            />
        );
    }

    return renderGraph();
};

export default memo(AssetDiscoveryGraph);
