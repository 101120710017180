import React, { memo } from 'react';
import { Button, ButtonProps } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useDownloadInvoiceMutation } from 'entities/Billing';

interface DownloadInvoiceButtonProps extends ButtonProps {
    id: number;
}

const DownloadInvoiceButton = (props: DownloadInvoiceButtonProps) => {
    const { id, ...buttonProps } = props;
    const [download, { isLoading }] = useDownloadInvoiceMutation();
    const onDownload = () => {
        download(id)
            .unwrap()
            .then(_ => {
                showNotification({
                    title: 'Downloading...',
                    message: 'Downloading file has started.',
                    color: 'green'
                });
            })
            .catch(_ => {
                showNotification({
                    title: 'Error',
                    message: 'Error downloading file.',
                    color: 'red'
                });
            });
    };

    if (!id) return null;

    return (
        <Button
            color="asm"
            variant="light"
            onClick={onDownload}
            loading={isLoading}
            {...buttonProps}
        >
            Download invoice
        </Button>
    );
};

export default memo(DownloadInvoiceButton);
