import React, { memo } from 'react';
import { Icon, Icons, IconSize } from 'shared/ui/Icon';
import {
    CopyButton as MCopyButton,
    CopyButtonProps as MCopyButtonProps,
    Button,
    ButtonProps
} from '@mantine/core';

interface CopyButtonProps extends Omit<MCopyButtonProps, 'children'> {
    className?: string;
    label?: string;
    labelCopied?: string;
    value: any;
    buttonProps?: ButtonProps;
}

const CopyButton = (props: CopyButtonProps) => {
    const {
        className,
        label = 'Copy',
        labelCopied = 'Copied',
        value = 'Provide text for copying.',
        buttonProps
    } = props;
    return (
        <MCopyButton value={value}>
            {({ copied, copy }) => (
                <Button
                    className={className}
                    variant="light"
                    leftSection={
                        <Icon
                            icon={Icons.CONTENT_COPY}
                            size={IconSize.MEDIUM}
                        />
                    }
                    color={copied ? 'teal' : 'asm'}
                    onClick={copy}
                    {...buttonProps}
                >
                    {copied ? labelCopied : label}
                </Button>
            )}
        </MCopyButton>
    );
};

export default memo(CopyButton);
