import React, { memo, useEffect } from 'react';
import { TechnologiesTable } from 'widgets/Technology';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { useParams } from 'react-router-dom';
import { navigationActions, NavigationPageType } from 'entities/Navigation';
import { Seo } from 'shared/ui/SEO/SEO';
import TechnologiesBaseLayout from '../../ui/TechnologiesBaseLayout/TechnologiesBaseLayout';

function FoundTechnologiesPage() {
    const dispatch = useAppDispatch();
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (!id) return;
        dispatch(
            navigationActions.addToDrawerStack({
                pageLink: window.location.href,
                pageType: NavigationPageType.TECHNOLOGY_DETAILS,
                pageID: id
            })
        );
    }, [id]);

    return (
        <TechnologiesBaseLayout>
            <Seo title="Found | Technologies" />
            <TechnologiesTable />
        </TechnologiesBaseLayout>
    );
}

export default memo(FoundTechnologiesPage);
