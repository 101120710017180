import { ACCOUNT_API_TAGS, accountApi } from 'shared/api/rtkApi';
import { PaypalCallbackPayload } from 'entities/Billing/model/types/paypal';

const paypalApi = accountApi.injectEndpoints({
    endpoints: builder => ({
        chargePaypal: builder.mutation<
            { message: string } | { error: string },
            {
                planId: number;
                priceListKey: number;
                returnUrl: string;
            }
        >({
            query: ({ planId, priceListKey, returnUrl }) => ({
                url: '/charge_paypal',
                method: 'POST',
                body: {
                    plan_id: planId,
                    key: priceListKey,
                    paypal_return_url: returnUrl.includes('http')
                        ? returnUrl
                        : `${window.location.origin}${returnUrl}`
                }
            }),
            invalidatesTags: [
                ACCOUNT_API_TAGS.PAYMENT,
                ACCOUNT_API_TAGS.SUBSCRIPTION
            ]
        }),
        checkPaymentStatus: builder.mutation<
            { message: string } | { error: string },
            PaypalCallbackPayload
        >({
            query: payload => ({
                url: '/payment/status',
                method: 'POST',
                body: payload
            }),
            invalidatesTags: [
                ACCOUNT_API_TAGS.PAYMENT,
                ACCOUNT_API_TAGS.SUBSCRIPTION
            ]
        })
    })
});

export const { useChargePaypalMutation, useCheckPaymentStatusMutation } =
    paypalApi;
