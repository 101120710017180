import { DataTableColumn } from 'mantine-datatable';
import { SearchFilter } from 'shared/ui/DataTable';
import React from 'react';
import { BaseColumnProps } from 'widgets/Columns/baseColumn';
import { Flex } from '@mantine/core';
import {
    Vulnerability,
    VulnerabilityLink,
    VulnerabilityNameLink,
    VulnerabilityQueryParams
} from 'entities/Vulnerability';
import { IssueUnique, NewIssueBadge } from 'entities/Issue';

type RecordType = Vulnerability | IssueUnique;

export default ({
    query = {},
    setQuery,
    column
}: BaseColumnProps<
    RecordType,
    VulnerabilityQueryParams
>): DataTableColumn<RecordType> => ({
    accessor: 'name',
    title: 'Name',
    sortable: true,
    filter: (
        <SearchFilter
            label="Vulnerability"
            description="Show vulnerabilities whose names contain the specified text."
            defaultQuery={query.nameIncludes}
            onFilter={nameIncludes => {
                if (setQuery) {
                    setQuery({ ...query, nameIncludes });
                }
            }}
        />
    ),
    filtering: Boolean(query?.nameIncludes),
    render: vulnerability => (
        <Flex wrap="nowrap" gap="xs">
            {'id' in vulnerability ? (
                <VulnerabilityLink vulnerability={vulnerability} />
            ) : (
                // @ts-ignore
                <VulnerabilityNameLink vulnerability={vulnerability} />
            )}
            <NewIssueBadge issue={vulnerability} />
        </Flex>
    ),
    ...column
});
