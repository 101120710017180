import { memo } from 'react';
import classNames from 'classnames';
import { Loader } from 'shared/ui/Loader';
import styles from './RefetchableComponent.module.scss';

interface RefetchableComponentProps {
    className?: string;
    children?: React.ReactNode;
    isFetching?: boolean;
}

const RefetchableComponent = (props: RefetchableComponentProps) => {
    const { className, children, isFetching } = props;
    const classes = classNames(styles.RefetchableComponent, className);

    return (
        <div className={classes}>
            {isFetching && (
                <div className={styles.loader}>
                    <Loader />
                </div>
            )}
            {children}
        </div>
    );
};

export default memo(RefetchableComponent);
