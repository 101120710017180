import React, { memo } from 'react';
import {
    Box,
    Button,
    ButtonProps,
    Card,
    Divider,
    Group,
    Modal,
    NumberFormatter,
    SimpleGrid,
    Text
} from '@mantine/core';
import {
    Plan,
    useChargePaypalMutation,
    useChargeStripeMutation
} from 'entities/Billing';
import { useDisclosure } from '@mantine/hooks';
import { useSelector } from 'react-redux';
import { getUserAuthData } from 'entities/User';
import { useLoadStripeCheckout } from 'shared/hooks/useLoadStripeCheckout/useLoadStripeCheckout';
import { showNotification } from '@mantine/notifications';
import { RoutePath } from 'shared/config/routeConfig';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { analyticsActions, AnalyticsEvent } from 'entities/Analytics';

interface SelectPlanButtonProps extends ButtonProps {
    plan?: Plan;
    email?: string;
    name?: string;
    description?: string;
    amount?: number;
    entitiesAmount?: number;
    disabled?: boolean;
}

const SelectPlanButton = (props: SelectPlanButtonProps) => {
    const {
        plan,
        email,
        name,
        description,
        amount,
        entitiesAmount,
        disabled,
        ...buttonProps
    } = props;
    const [opened, { open, close }] = useDisclosure(false);
    const user = useSelector(getUserAuthData);
    const dispatch = useAppDispatch();

    const stripeCheckout = useLoadStripeCheckout(opened);
    const [
        chargeWithCreditCard,
        { isLoading: isCreditCardCharging, isSuccess: chargeCreditCardSuccess }
    ] = useChargeStripeMutation();
    const [chargeWithPaypal, { isLoading: isPaypalCharging }] =
        useChargePaypalMutation();

    const onCreditCardSubmit = () => {
        if (!user?.stripe_key) return;
        if (!plan || !amount) return;

        const stripeAmount = (amount ?? 0) * 100;
        stripeCheckout
            .configure({
                key: user?.stripe_key,
                image: '/assets/lettermark-logo-b.png',
                token: (token: any) => {
                    chargeWithCreditCard({
                        token: token.id,
                        planId: plan.id,
                        priceListKey: entitiesAmount ?? 0
                    })
                        .unwrap()
                        .then(() => {
                            close();
                            showNotification({
                                title: 'Success',
                                message: 'Payment was successful',
                                color: 'green'
                            });
                            dispatch(
                                analyticsActions.setEvent(
                                    AnalyticsEvent.SUCCESSFUL_PURCHASE
                                )
                            );
                        })
                        .catch(() => {
                            showNotification({
                                title: 'Error',
                                message:
                                    'Payment failed. Please try again later. If the problem persists, contact support.',
                                color: 'red'
                            });
                        });
                }
            })
            .open({
                name,
                description,
                email: email ?? user?.email,
                amount: stripeAmount
            });
    };

    const onPaypalSubmit = () => {
        if (!plan || !amount) return;

        chargeWithPaypal({
            planId: plan.id,
            priceListKey: entitiesAmount ?? 0,
            returnUrl: `${RoutePath.account}/billing`
        })
            .unwrap()
            .then(result => {
                if ('message' in result) {
                    window.location.href = result.message;
                }
                if ('error' in result) {
                    showNotification({
                        title: 'Error',
                        message:
                            'Something went wrong. Please try again later. If the problem persists, contact support.',
                        color: 'red'
                    });
                }
            })
            .catch(() => {
                showNotification({
                    title: 'Error',
                    message:
                        'Payment failed. Please try again later. If the problem persists, contact support.',
                    color: 'red'
                });
            });
    };

    return (
        <>
            <Modal
                size="lg"
                title="Order summary"
                opened={opened}
                onClose={close}
            >
                <Card pt="md" pb="xl" px="0">
                    <Group justify="space-between">
                        <Text>Email</Text>
                        <Text>{email ?? user?.email}</Text>
                    </Group>
                    <Divider my="sm" />
                    <Group justify="space-between">
                        <Text>Shipping</Text>
                        <Text>{name}</Text>
                    </Group>
                    <Divider my="sm" />
                    <Group justify="space-between">
                        <Text>Description</Text>
                        <Text>{description}</Text>
                    </Group>
                    <Divider my="sm" />
                    <Group justify="space-between">
                        <Text fw={700}>Total</Text>
                        <Text fw={700}>
                            <NumberFormatter
                                prefix="$"
                                suffix=".00"
                                value={amount}
                                thousandSeparator
                            />
                        </Text>
                    </Group>
                </Card>
                <SimpleGrid cols={2} spacing="lg">
                    <Button
                        type="submit"
                        color="asm"
                        loading={isCreditCardCharging}
                        onClick={onCreditCardSubmit}
                        {...buttonProps}
                    >
                        Buy with Credit Card
                    </Button>
                    <Button
                        type="submit"
                        color="asm"
                        loading={isPaypalCharging}
                        onClick={onPaypalSubmit}
                        {...buttonProps}
                    >
                        Buy with PayPal
                    </Button>
                </SimpleGrid>
            </Modal>
            <Button
                fullWidth
                variant="filled"
                color="asm"
                size="md"
                disabled={disabled}
                {...buttonProps}
                onClick={open}
            >
                {disabled ? (
                    'Select plan'
                ) : (
                    <>
                        Buy now for{' '}
                        <Box ml={5}>
                            <NumberFormatter
                                prefix="$"
                                suffix=".00"
                                value={amount}
                                thousandSeparator
                            />
                        </Box>
                    </>
                )}
            </Button>
        </>
    );
};

export default memo(SelectPlanButton);
