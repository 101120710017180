import { memo } from 'react';
import { Card, SimpleGrid, Text, Flex, Center, Badge } from '@mantine/core';
import OrganizationIcon from 'shared/assets/icons/apartment.svg';
import DomainIcon from 'shared/assets/icons/dns.svg';
import IpAddressIcon from 'shared/assets/icons/location_on.svg';
import HubIcon from 'shared/assets/icons/hub.svg';
import AwsLogo from 'shared/assets/logos/aws.svg';
import GoogleCloudLogo from 'shared/assets/logos/google-cloud.svg';
import AzureLogo from 'shared/assets/logos/azure.svg';
import DigitalOceanLogo from 'shared/assets/logos/digital-ocean.svg';
import { Link } from 'react-router-dom';

const SelectRootAssetType = () => (
    <>
        <Text c="dimmed" ta="center" size="lg">
            Please choose the type of the root asset you want to add
        </Text>

        <Center mt="xl">
            <Card
                shadow="md"
                padding="xl"
                variant="clickable"
                component={Link}
                to="domain"
                w={{ base: '100%', md: 250 }}
            >
                <Flex direction="column" align="center" ml="auto" mr="auto">
                    <DomainIcon fill="black" width="auto" height={40} />
                    <Text fw={500} size="lg" ta="center" mt="md">
                        Domain Name
                    </Text>
                    <Badge variant="primary-gradient" mt="sm" mb="-xs">
                        Recommended
                    </Badge>
                </Flex>
            </Card>
        </Center>
        <SimpleGrid cols={{ base: 1, md: 3 }} spacing="xl" mt={50}>
            <Card
                shadow="md"
                padding="xl"
                variant="clickable"
                component={Link}
                to="organization"
            >
                <Flex direction="column" align="center" ml="auto" mr="auto">
                    <OrganizationIcon fill="black" width="auto" height={40} />
                    <Text fw={500} size="lg" ta="center" mt="md">
                        Organization
                    </Text>
                </Flex>
            </Card>
            <Card
                shadow="md"
                padding="xl"
                variant="clickable"
                component={Link}
                to="ipAddress"
            >
                <Flex direction="column" align="center" ml="auto" mr="auto">
                    <IpAddressIcon fill="black" width="auto" height={40} />
                    <Text fw={500} size="lg" ta="center" mt="md">
                        IP Address
                    </Text>
                </Flex>
            </Card>
            <Card
                shadow="md"
                padding="xl"
                variant="clickable"
                component={Link}
                to="cidr"
            >
                <Flex direction="column" align="center" ml="auto" mr="auto">
                    <HubIcon fill="black" width="auto" height={40} />
                    <Text fw={500} size="lg" ta="center" mt="md">
                        CIDR
                    </Text>
                </Flex>
            </Card>
        </SimpleGrid>
        <SimpleGrid cols={{ base: 1, md: 4 }} spacing="xl" mt={50}>
            <Card
                shadow="md"
                padding="xl"
                variant="clickable"
                component={Link}
                to="aws"
            >
                <Flex direction="column" align="center" ml="auto" mr="auto">
                    <AwsLogo width="auto" height={40} />
                    <Text fw={500} size="lg" ta="center" mt="md">
                        AWS
                    </Text>
                </Flex>
            </Card>
            <Card
                shadow="md"
                padding="xl"
                variant="clickable"
                component={Link}
                to="gcp"
            >
                <Flex direction="column" align="center" ml="auto" mr="auto">
                    <GoogleCloudLogo width="auto" height={40} />
                    <Text fw={500} size="lg" ta="center" mt="md">
                        Google Cloud Platform
                    </Text>
                </Flex>
            </Card>
            <Card
                shadow="md"
                padding="xl"
                variant="clickable"
                component={Link}
                to="azure"
            >
                <Flex direction="column" align="center" ml="auto" mr="auto">
                    <AzureLogo width="auto" height={40} />
                    <Text fw={500} size="lg" ta="center" mt="md">
                        Microsoft Azure
                    </Text>
                </Flex>
            </Card>
            <Card
                shadow="md"
                padding="xl"
                variant="clickable"
                component={Link}
                to="digitalOcean"
            >
                <Flex direction="column" align="center" ml="auto" mr="auto">
                    <DigitalOceanLogo width="auto" height={40} />
                    <Text fw={500} size="lg" ta="center" mt="md">
                        Digital Ocean
                    </Text>
                </Flex>
            </Card>
        </SimpleGrid>
    </>
);

export default memo(SelectRootAssetType);
