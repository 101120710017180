import React, { memo, Suspense, useMemo, useState } from 'react';
import { DataTableColumn } from 'mantine-datatable';
import { GenericTable } from 'shared/ui/DataTable';
import {
    AssetQueryParams,
    RootAssetCandidate,
    useGetRootAssetCandidates
} from 'entities/Asset';
import { Button, Group } from '@mantine/core';
import {
    declineAssetCandidateActions,
    DeclineAssetCandidateModal
} from 'features/asset/actions/DeclineAssetCandidate';
import {
    approveAssetCandidateActions,
    ApproveAssetCandidateModal
} from 'features/asset/actions/ApproveAssetCandidate';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import rootAssetCandidateNameColumn from 'widgets/Columns/rootAssetCandidateNameColumn';
import assetTypeColumn from 'widgets/Columns/assetTypeColumn';
import rootAssetSelectColumn from 'widgets/Columns/rootAssetSelectColumn';
import tagsColumn from 'widgets/Columns/tagsColumn';
import lastSeenColumn from 'widgets/Columns/lastSeenColumn';
import createdAtColumn from 'widgets/Columns/createdAtColumn';
import { useDemo } from 'shared/hooks/useDemo/useDemo';

const RootAssetCandidatesTable = () => {
    const dispatch = useAppDispatch();
    const { isDemo } = useDemo();
    const [selectedRecords, setSelectedRecords] = useState<
        RootAssetCandidate[]
    >([]);
    const [query, setQuery] = useState<AssetQueryParams>({});
    const columns = useMemo<DataTableColumn<RootAssetCandidate>[]>(
        () => [
            rootAssetCandidateNameColumn({
                query,
                setQuery
            }),
            assetTypeColumn({
                query,
                setQuery
            }),
            rootAssetSelectColumn({
                query,
                setQuery
            }),
            tagsColumn({
                query,
                setQuery
            }),
            lastSeenColumn({
                query,
                setQuery
            }),
            createdAtColumn({
                query,
                setQuery
            }),
            {
                hidden: isDemo,
                width: 200,
                accessor: 'actions',
                title: 'Actions',
                render: asset => (
                    <Suspense fallback={<div>Loading...</div>}>
                        <Group gap="xs" wrap="nowrap">
                            <Button
                                size="compact-sm"
                                color="green.9"
                                fullWidth
                                onClick={() =>
                                    dispatch(
                                        approveAssetCandidateActions.setAssets([
                                            asset
                                        ])
                                    )
                                }
                            >
                                Approve
                            </Button>
                            {!asset.declined && (
                                <Button
                                    size="compact-sm"
                                    fullWidth
                                    color="red.9"
                                    onClick={() =>
                                        dispatch(
                                            declineAssetCandidateActions.setAssets(
                                                [asset]
                                            )
                                        )
                                    }
                                >
                                    Decline
                                </Button>
                            )}
                        </Group>
                    </Suspense>
                )
            }
        ],
        [dispatch, query, isDemo]
    );

    return (
        <>
            <GenericTable<RootAssetCandidate>
                // @ts-ignore
                useData={useGetRootAssetCandidates}
                columns={columns}
                queryParams={query}
                defaultSortStatus={{
                    columnAccessor: 'lastSeenAt',
                    direction: 'desc'
                }}
                pinLastColumn={!isDemo}
                hasSelection={!isDemo}
                hasToolbar
                hasDownload
                onSelectionChange={setSelectedRecords}
                selectionActions={
                    <>
                        <Button
                            size="compact-sm"
                            color="green"
                            onClick={() =>
                                dispatch(
                                    approveAssetCandidateActions.setAssets(
                                        selectedRecords
                                    )
                                )
                            }
                        >
                            Approve
                        </Button>
                        <Button
                            size="compact-sm"
                            color="red"
                            onClick={() =>
                                dispatch(
                                    declineAssetCandidateActions.setAssets(
                                        selectedRecords
                                    )
                                )
                            }
                        >
                            Decline
                        </Button>
                    </>
                }
            />
            <Suspense fallback="">
                <ApproveAssetCandidateModal />
                <DeclineAssetCandidateModal />
            </Suspense>
        </>
    );
};

export default memo(RootAssetCandidatesTable);
