import React, { memo, useEffect, useState } from 'react';
import { Icon, Icons } from 'shared/ui/Icon';
import { ActionIcon, TextInput, TextInputProps } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';

interface SearchFilterProps extends TextInputProps {
    debounceWait?: number;
    defaultQuery?: string;
    onFilter: (query: string) => void;
}

const SearchFilter = (props: SearchFilterProps) => {
    const { debounceWait = 200, defaultQuery, onFilter, ...rest } = props;
    const [query, setQuery] = useState(defaultQuery ?? '');
    const [debouncedQuery] = useDebouncedValue(query, debounceWait);

    useEffect(() => {
        onFilter?.(debouncedQuery);
    }, [debouncedQuery, onFilter]);

    return (
        <TextInput
            leftSection={<Icon icon={Icons.SEARCH} />}
            rightSection={
                <ActionIcon variant="transparent" onClick={() => setQuery('')}>
                    <Icon icon={Icons.CLOSE} />
                </ActionIcon>
            }
            value={query}
            onChange={e => setQuery(e.currentTarget.value)}
            {...rest}
        />
    );
};

export default memo(SearchFilter);
