import axios, { AxiosRequestHeaders } from 'axios';
import { getCookie } from 'shared/lib/cookie/cookie';
import { USER_AUTH_COOKIE } from 'entities/User';

// @ts-ignore
const headers: AxiosRequestHeaders = {
    'Content-Type': 'application/json',
    Authorization: getCookie(USER_AUTH_COOKIE)
};

export const $api = axios.create({
    baseURL: __AUTH_API_URL__,
    headers
});
