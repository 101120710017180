import { useContext, useEffect } from 'react';
import { InitContext } from '../InitContext';
import useInitSubscriptionStatus from './useInitSubscriptionStatus';
import useInitUser from './useInitUser';
import useSubscription from './useSubscription';
import useGlobalDateRange from './useGlobalDateRange';

interface UseInit {
    appLoading: boolean;
}

function useInit(): UseInit {
    const { appLoading, setAppLoading } = useContext(InitContext);
    const { loading: userLoading } = useInitUser();
    const { loading: subscriptionLoading } = useInitSubscriptionStatus();
    useSubscription();
    useGlobalDateRange();

    useEffect(() => {
        if (!userLoading && !subscriptionLoading) {
            setAppLoading(false);
        }
    }, [userLoading, subscriptionLoading, setAppLoading]);

    return {
        appLoading
    };
}

export default useInit;
