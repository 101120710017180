import { memo } from 'react';
import { GeoIP, Subnetwork } from 'entities/Asset/model/types/metadata';

interface ASNProps {
    geolocation?: GeoIP;
    subnetworks?: Subnetwork[];
}

const ASN = (props: ASNProps) => {
    const { geolocation, subnetworks } = props;
    if (
        (!geolocation || !geolocation.asn) &&
        (!subnetworks || !subnetworks.length || !subnetworks[0]?.as?.asn)
    )
        return 'N/A';

    const asNumber = geolocation?.asn || subnetworks?.[0]?.as?.asn;
    const asName = geolocation?.asName || subnetworks?.[0]?.as?.name;
    const asDomain = geolocation?.asDomain || subnetworks?.[0]?.as?.domain;

    return [asNumber, asName, asDomain].filter(Boolean).join(', ') || 'N/A';
};

export default memo(ASN);
