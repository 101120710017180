import React, { memo } from 'react';
import { Box, SimpleGrid, Stack } from '@mantine/core';
import { AccountInformationCard } from 'entities/User';
import { NotificationSettings } from 'entities/Notifications';
import { Seo } from 'shared/ui/SEO/SEO';
import { GlobalScannerSettings } from 'entities/Scanner';
import { Icons } from 'shared/ui/Icon';
import AccountBaseLayout from '../../../ui/AccountBaseLayout/AccountBaseLayout';

function GeneralPage() {
    return (
        <AccountBaseLayout>
            <Seo title="General | Account" />
            <Box style={{ maxHeight: 'calc(100% - 120px)' }}>
                <Stack gap="lg" maw={1366}>
                    <AccountInformationCard />
                    <NotificationSettings
                        channel="email"
                        title="Email notification settings"
                        icon={Icons.ALTERNATE_EMAIL}
                    />
                    <NotificationSettings
                        channel="slack"
                        title="Slack notification settings"
                        icon={Icons.SLACK}
                    />
                    <GlobalScannerSettings
                        title="Global scanner settings"
                        icon={Icons.WIFI_FIND}
                    />
                </Stack>
            </Box>
        </AccountBaseLayout>
    );
}

export default memo(GeneralPage);
