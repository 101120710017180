import { Card, Group, List, Stack, Text, ThemeIcon } from '@mantine/core';
import { ScanMode, ScanModeLabel } from 'entities/Scanner/model/types/config';
import { Icon, Icons } from 'shared/ui/Icon';
import React from 'react';
import KnowledgeBasePost from '../KnowledgeBasePost/KnowledgeBasePost';

const Content = () => (
    <Stack>
        <Text>
            The Attaxion scanner supports the following scan modes:{' '}
            <b>
                {Object.keys(ScanMode)
                    .filter(value => value !== 'INHERIT')
                    .join(', ')}
            </b>
            . The scan mode determines how the Attaxion scanner will process the
            target asset, which analysis utilities it will use. The default scan
            mode is <b>{ScanModeLabel.ACTIVE}</b>. All newly created root assets
            will inherit the scan mode from the global configuration by default.
        </Text>
        <Text>
            The following shows what utilities a particular scan mode includes.
        </Text>
        <Group align="flex-start" grow>
            <Card py="xl" px="lg" radius="md" withBorder>
                <Text fz="lg" fw={700} ta="center" tt="uppercase">
                    {ScanMode.PASSIVE}
                </Text>
                <Text c="dimmed" ta="center" mb="md">
                    Includes utilities:
                </Text>
                <List
                    icon={
                        <ThemeIcon color="teal" size={20} radius="xl">
                            <Icon icon={Icons.DONE} style={{ fill: 'white' }} />
                        </ThemeIcon>
                    }
                >
                    <List.Item>WHOIS Lookup</List.Item>
                    <List.Item>Passive Subdomains Lookup</List.Item>
                    <List.Item>Reverse DNS Lookup</List.Item>
                    <List.Item>Reverse WHOIS Lookup</List.Item>
                    <List.Item>IP Geolocation Lookup</List.Item>
                    <List.Item>IP Netblocks Lookup</List.Item>
                    <List.Item>Cloud Providers Scanner</List.Item>
                    <List.Item>DNS Lookup</List.Item>
                </List>
            </Card>
            <Card py="xl" px="lg" radius="md" withBorder>
                <Text fz="lg" fw={700} ta="center" tt="uppercase">
                    {ScanMode.ACTIVE}
                </Text>
                <Text c="dimmed" ta="center" mb="md">
                    <b>Passive</b> utilities plus:
                </Text>
                <List
                    icon={
                        <ThemeIcon color="teal" size={20} radius="xl">
                            <Icon icon={Icons.DONE} style={{ fill: 'white' }} />
                        </ThemeIcon>
                    }
                >
                    <List.Item>Vulnerability Scanner</List.Item>
                    <List.Item>Web Crawler</List.Item>
                    <List.Item>Active Subdomains Scanner</List.Item>
                    <List.Item>SSL Lookup</List.Item>
                    <List.Item>Port Scanner</List.Item>
                    <List.Item>Screenshot Lookup</List.Item>
                </List>
            </Card>
        </Group>
    </Stack>
);

const GlobalScanModePost = () => (
    <KnowledgeBasePost
        title="Global scanner settings"
        content={<Content />}
        modalSize="800px"
    />
);

export default GlobalScanModePost;
