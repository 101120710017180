import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    RootAsset,
    RootAssetVerificationMethod,
    useGetRootAsset,
    useVerifyRootAsset
} from 'entities/Asset';
import {
    Alert,
    Box,
    Button,
    Flex,
    Paper,
    SegmentedControl,
    Space,
    Stack,
    Text,
    TextInput,
    Timeline
} from '@mantine/core';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { CopyButton, DownloadButton } from 'shared/ui/Button';
import { Icon, Icons, IconSize } from 'shared/ui/Icon';
import { ExternalLink } from 'shared/ui/ExternalLink';
import {
    isRootAssetVerificationCompleted,
    isRootAssetVerificationError,
    isRootAssetVerificationFailed,
    isRootAssetVerificationRunning
} from 'features/asset/actions/VerifyRootAsset/model/service/helpers';
import { API_TAGS, rtkApi } from 'shared/api/rtkApi';
import { analyticsActions, AnalyticsEvent } from 'entities/Analytics';

export interface VerifyRootAssetFormProps {
    asset: RootAsset;
    hasDescription?: boolean;
}

const VerifyRootAssetForm = (props: VerifyRootAssetFormProps) => {
    const dispatch = useAppDispatch();
    const { asset, hasDescription = true } = props;

    const [method, setMethod] = useState<RootAssetVerificationMethod>('file');
    const [verificationRequested, setVerificationRequested] = useState(false);
    const [verifyRootAsset, { isLoading: isVerifying }] = useVerifyRootAsset();
    const { data: rootAsset, isFetching: assetLoading } = useGetRootAsset(
        asset.id,
        {
            skip: !verificationRequested,
            pollingInterval: 5000
        }
    );

    const isVerificationCompleted = useMemo(
        () => isRootAssetVerificationCompleted(rootAsset),
        [rootAsset]
    );

    const isVerificationRunning = useMemo(
        () => isRootAssetVerificationRunning(rootAsset),
        [rootAsset]
    );

    const isVerificationFailed = useMemo(
        () => isRootAssetVerificationFailed(rootAsset),
        [rootAsset]
    );

    const isVerificationError = useMemo(
        () => isRootAssetVerificationError(rootAsset),
        [rootAsset]
    );

    useEffect(() => {
        if (
            isVerificationFailed ||
            isVerificationCompleted ||
            isVerificationError
        ) {
            setVerificationRequested(false);
            dispatch(
                rtkApi.util?.invalidateTags([
                    { type: API_TAGS.ASSET, id: asset.id },
                    API_TAGS.ASSET
                ])
            );
        }
    }, [
        isVerificationFailed,
        isVerificationCompleted,
        isVerificationError,
        asset.id
    ]);

    useEffect(() => {
        if (isVerificationRunning) {
            setVerificationRequested(true);
        }
    }, [isVerificationRunning]);

    useEffect(() => {
        if (isVerificationCompleted && !isVerifying) {
            dispatch(
                analyticsActions.setEvent({
                    event: AnalyticsEvent.ROOT_ASSET_VERIFIED,
                    payload: {
                        id: asset.id,
                        name: asset.value
                    }
                })
            );
        }
    }, [isVerificationCompleted, isVerifying]);

    const onVerify = useCallback(async () => {
        if (asset && method) {
            await verifyRootAsset({
                id: asset.id,
                method
            }).then(verification => {
                setVerificationRequested(true);
            });
        }
    }, [asset, method, verifyRootAsset]);

    const verifyButton = useCallback(
        () =>
            !isVerificationCompleted && (
                <Button
                    color="asm"
                    loading={
                        isVerifying || assetLoading || isVerificationRunning
                    }
                    leftSection={
                        <Icon
                            icon={Icons.ROCKET_LAUNCH}
                            size={IconSize.MEDIUM}
                        />
                    }
                    onClick={onVerify}
                >
                    {assetLoading || isVerificationRunning
                        ? 'Verification in progress'
                        : 'Verify'}
                </Button>
            ),
        [
            assetLoading,
            isVerifying,
            isVerificationRunning,
            onVerify,
            isVerificationCompleted
        ]
    );

    return (
        <Box>
            {hasDescription && (
                <Text size="sm" mb="lg">
                    This asset is not verified. Verification is required to
                    ensure only authorized people can view sensitive data. You
                    can verify your domain ownership in two different ways: with
                    DNS records (TXT) or by uploading a txt file into the root
                    of your asset. If none of the verification methods work for
                    you, you can request assisted verification via our support
                    team.
                </Text>
            )}
            <Stack gap="sm">
                <SegmentedControl
                    data={[
                        { label: 'Upload TXT-file', value: 'file' },
                        { label: 'Add DNS TXT record', value: 'dns' }
                    ]}
                    value={method}
                    fullWidth
                    onChange={value =>
                        setMethod(value as RootAssetVerificationMethod)
                    }
                />
                <Paper p="lg" withBorder>
                    {method === 'file' && (
                        <Timeline bulletSize={24}>
                            <Timeline.Item
                                title="Download the following file"
                                bullet={1}
                            >
                                <DownloadButton
                                    label="Download verification file"
                                    filename={asset?.rootVerification.file.name}
                                    content={
                                        asset?.rootVerification.file.content
                                    }
                                />
                            </Timeline.Item>
                            <Timeline.Item
                                title="Upload it to the root"
                                bullet={2}
                            >
                                <Text size="sm" c="dimmed">
                                    Place the downloaded file in your website’s
                                    root directory.
                                </Text>
                            </Timeline.Item>
                            <Timeline.Item
                                title="Verify uploaded file"
                                bullet={3}
                            >
                                <Text size="sm" c="dimmed">
                                    Attaxion will try to access the file at:
                                </Text>
                                <Flex w="100%" gap="xs">
                                    <TextInput
                                        value={`${asset?.value}/${asset?.rootVerification.file.name}`}
                                        readOnly
                                        style={{ flex: 1 }}
                                    />
                                    {verifyButton()}
                                </Flex>
                            </Timeline.Item>
                        </Timeline>
                    )}
                    {method === 'dns' && (
                        <Timeline bulletSize={24}>
                            <Timeline.Item
                                title="Go to your DNS provider settings"
                                bullet={1}
                            >
                                <Text size="sm" c="dimmed">
                                    Could be for example AWS Route53, Cloudflare
                                    or DigitalOcean.
                                </Text>
                            </Timeline.Item>
                            <Timeline.Item
                                title="Create a TXT record"
                                bullet={2}
                            >
                                <Stack gap="sm">
                                    <Text size="sm" c="dimmed">
                                        Copy the following text and paste it as
                                        a TXT record in your DNS provider
                                        settings.
                                    </Text>
                                    <Flex align="flex-end" gap="xs">
                                        <TextInput
                                            label="Record name"
                                            value="@"
                                            readOnly
                                            style={{ flex: 1 }}
                                        />
                                        <CopyButton value="@" />
                                    </Flex>
                                    <Flex w="100%" align="flex-end" gap="xs">
                                        <TextInput
                                            label="Value"
                                            value={
                                                asset?.rootVerification
                                                    .dnsRecord
                                            }
                                            readOnly
                                            style={{ flex: 1 }}
                                        />
                                        <CopyButton
                                            value={
                                                asset?.rootVerification
                                                    .dnsRecord
                                            }
                                        />
                                    </Flex>
                                </Stack>
                            </Timeline.Item>
                            <Timeline.Item title="Verify DNS record" bullet={3}>
                                <Stack gap="sm">
                                    <Text size="sm" c="dimmed">
                                        DNS records need time to get updated
                                        across all DNS servers. In most cases it
                                        takes less than 5 minutes, but sometimes
                                        it could take up to 48 hours. You can
                                        check if your DNS record is updated
                                        using the following service:
                                        <ExternalLink href="https://toolbox.googleapps.com/apps/dig/#TXT/">
                                            https://toolbox.googleapps.com/apps/dig/#TXT/
                                        </ExternalLink>
                                    </Text>
                                    {verifyButton()}
                                </Stack>
                            </Timeline.Item>
                        </Timeline>
                    )}

                    {isVerificationFailed && !isVerifying && !assetLoading && (
                        <>
                            <Space h="lg" />
                            <Alert
                                variant="light"
                                color="red"
                                title="Verification failed"
                                icon={
                                    <Icon
                                        icon={Icons.WARNING}
                                        size={IconSize.LARGE}
                                    />
                                }
                            >
                                The last verification attempt failed. Please try
                                again or contact our support team.
                            </Alert>
                        </>
                    )}

                    {isVerificationError && !isVerifying && !assetLoading && (
                        <>
                            <Space h="lg" />
                            <Alert
                                variant="light"
                                color="red"
                                title="Verification error"
                                icon={
                                    <Icon
                                        icon={Icons.WARNING}
                                        size={IconSize.LARGE}
                                    />
                                }
                            >
                                An error occurred during the last verification
                                attempt. Please contact our support team.
                            </Alert>
                        </>
                    )}

                    {isVerificationCompleted && !isVerifying && (
                        <>
                            <Space h="lg" />
                            <Alert
                                variant="light"
                                color="green"
                                title="Verification completed"
                                icon={
                                    <Icon
                                        icon={Icons.VERIFIED}
                                        size={IconSize.LARGE}
                                    />
                                }
                            >
                                Your asset is verified. Scanning will start
                                shortly.
                            </Alert>
                        </>
                    )}
                </Paper>
            </Stack>
        </Box>
    );
};

export default VerifyRootAssetForm;
