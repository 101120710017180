import React, { FC, useMemo, useState } from 'react';
import { Subscription } from 'entities/Billing';
import {
    GlobalDateRange,
    InitContext,
    SubscriptionStatus
} from '../model/InitContext';

interface InitProviderProps {
    children: React.ReactNode;
}

const InitProvider: FC<InitProviderProps> = props => {
    const { children } = props;
    const { Provider } = InitContext;

    const [appLoading, setAppLoading] = useState(true);
    const [hasUser, setHasUser] = useState(false);
    const [subscription, setSubscription] = useState<
        Subscription | undefined
    >();
    const [subscriptionStatus, setSubscriptionStatus] =
        useState<SubscriptionStatus[]>();
    const [subscriptionDaysLeft, setSubscriptionDaysLeft] = useState<number>();
    const [subscriptionExpiredDaysAgo, setSubscriptionExpiredDaysAgo] =
        useState<number>();
    const [scanningStoppedAt, setScanningStoppedAt] = useState<string>();
    const [globalDateRange, setGlobalDateRange] = useState<GlobalDateRange>();

    const defaultProps = useMemo(
        () => ({
            appLoading,
            setAppLoading,
            hasUser,
            setHasUser,
            subscription,
            setSubscription,
            subscriptionStatus,
            setSubscriptionStatus,
            subscriptionDaysLeft,
            setSubscriptionDaysLeft,
            subscriptionExpiredDaysAgo,
            setSubscriptionExpiredDaysAgo,
            scanningStoppedAt,
            setScanningStoppedAt,
            globalDateRange,
            setGlobalDateRange,
            resetSubscriptionStatus: () => setSubscriptionStatus(undefined)
        }),
        [
            appLoading,
            hasUser,
            subscription,
            subscriptionStatus,
            subscriptionDaysLeft,
            subscriptionExpiredDaysAgo,
            scanningStoppedAt,
            globalDateRange
        ]
    );

    return <Provider value={defaultProps}>{children}</Provider>;
};

export default InitProvider;
