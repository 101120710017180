import React, { memo } from 'react';
import classNames from 'classnames';
import { Logo } from 'shared/ui/Logo';
import styles from './AppInitialLoader.module.scss';

const AppInitialLoader = () => {
    const classes = classNames(styles.AppInitialLoader);

    return (
        <div className={classes}>
            <Logo className={styles.Logo} />
        </div>
    );
};

export default memo(AppInitialLoader);
