import { memo } from 'react';
import './ZoomedImage.module.scss';
import Zoom from 'react-medium-image-zoom';

interface ZoomedImageProps {
    children: React.ReactNode;
}

const ZoomedImage = (props: ZoomedImageProps) => {
    const { children } = props;
    return <Zoom>{children}</Zoom>;
};

export default memo(ZoomedImage);
