import { Node, Edge, Graph, GraphLink, GraphNode } from 'entities/Asset';

interface UseGraph {
    nodes: GraphNode[] | undefined;
    links: GraphLink[] | undefined;
}

function useGraphLinks(graph: Graph[] | undefined): GraphLink[] | undefined {
    if (!graph) return undefined;
    return Object.values<Edge>(
        Object.assign({}, ...graph.map(graph => graph.edges).flat())
    ).map(edge => ({
        id: `${edge.from}:${edge.to}`,
        source: edge.from,
        target: edge.to
    }));
}

function useGraphNodes(graph: Graph[] | undefined): GraphNode[] | undefined {
    if (!graph) return undefined;
    return Object.values<Node>(
        Object.assign({}, ...graph.map(graph => graph.nodes))
    ).map(node => ({
        id: node.id,
        type: node.type,
        isRoot: node.isRoot,
        hasVulnerabilities: node.directVulnerabilitiesCount > 0,
        name: node.value
    }));
}

export function useGraph(graphs?: Graph[]): UseGraph {
    const links = useGraphLinks(graphs);
    const nodes = useGraphNodes(graphs);

    return {
        nodes,
        links
    };
}
