import { RoutePath } from 'shared/config/routeConfig';
import { Icons } from 'shared/ui/Icon';
import { SidebarItem } from '../../types/sidebar-item';

function useSidebarItems(): SidebarItem[] {
    return [
        {
            path: RoutePath.dashboard,
            label: 'Dashboard',
            icon: Icons.DASHBOARD
        },
        {
            path: RoutePath.graph,
            label: 'Graph',
            icon: Icons.HUB
        },
        {
            path: RoutePath.assets,
            label: 'Assets',
            icon: Icons.LAYERS
        },
        {
            path: RoutePath.issues,
            label: 'Issues',
            icon: Icons.WARNING
        },
        {
            path: RoutePath.technologies,
            label: 'Technologies',
            icon: Icons.FINGERPRINT
        },
        {
            path: RoutePath.management,
            label: 'Management',
            icon: Icons.SETTINGS
        },
        {
            path: RoutePath.reports,
            label: 'Reports',
            icon: Icons.INVENTORY
        },
        {
            path: RoutePath.events,
            label: 'Events',
            icon: Icons.HISTORY
        }
    ];
}

export default useSidebarItems;
