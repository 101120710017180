import Cookies from 'js-cookie';

export function getCookie<T>(key: string): T | string | undefined {
    const cookie = Cookies.get(key);

    if (cookie) {
        return JSON.parse(cookie);
    }

    return undefined;
}

export function setCookie<T>(
    key: string,
    value: T,
    options?: Cookies.CookieAttributes
): void {
    Cookies.set(key, JSON.stringify(value), options);
}

export function removeCookie(
    key: string,
    options?: Cookies.CookieAttributes
): void {
    Cookies.remove(key, options);
}
