import { Asset, AssetType } from './asset';

export interface Adjacency {
    [key: string]: string[];
}

export enum AdjacencyType {
    DNS = 'dns',
    REVERSEIP = 'reverseip',
    REVERSENETBLOCKS = 'reversenetblocks',
    SSL = 'ssl',
    SSL2 = 'ssl2',
    WWW_PREFIX = 'www_prefix',
    SUBDOMAINS = 'subdomains',
    SUBDOMAINS2 = 'subdomains2',
    SUBDOMAINS3 = 'subdomains3',
    SUBDOMAINS4 = 'subdomains4',
    WHOIS = 'whois',
    EMAIL_PARSING = 'email_parsing',
    PORT_SCAN = 'port_scan',
    REVERSECNAME = 'reversecname',
    REVERSEMX = 'reversemx',
    REVERSENS = 'reversens',
    IP_ENRICHMENT = 'ip_enrichment',
    WEB_CRAWLING = 'web_crawling',
    REVERSEWHOIS = 'reversewhois',
    CIDR = 'cidr',
    AWS_SCAN = 'aws_scan',
    DO_SCAN = 'do_scan',
    GCP_SCAN = 'gcp_scan',
    AZURE_SCAN = 'azure_scan'
}

export enum AdjacencyTypeLabel {
    DNS = 'DNS',
    REVERSEIP = 'Reverse IP',
    REVERSENETBLOCKS = 'Reverse Netblocks',
    SSL = 'SSL',
    SSL2 = 'SSL 2',
    WWW_PREFIX = 'WWW Prefix',
    SUBDOMAINS = 'Subdomains',
    SUBDOMAINS2 = 'Subdomains 2',
    SUBDOMAINS3 = 'Subdomains 3',
    SUBDOMAINS4 = 'Subdomains 4',
    WHOIS = 'WHOIS',
    EMAIL_PARSING = 'Email Parsing',
    PORT_SCAN = 'Port Scan',
    REVERSECNAME = 'Reverse CNAME',
    REVERSEMX = 'Reverse MX',
    REVERSENS = 'Reverse NS',
    IP_ENRICHMENT = 'IP Enrichment',
    WEB_CRAWLING = 'Web Crawling',
    REVERSEWHOIS = 'Reverse Whois',
    CIDR = 'CIDR',
    AWS_SCAN = 'AWS Scan',
    DO_SCAN = 'DigitalOcean Scan',
    GCP_SCAN = 'GCP Scan',
    AZURE_SCAN = 'Azure Scan'
}

export interface AdjacencyObject {
    from: string;
    to: string;
    adjacencyType: AdjacencyType;
}

export interface Node extends Asset {
    cluster?: string;
}
export interface Nodes {
    [key: string]: Node;
}
export interface GraphNode {
    id: string;
    cluster?: string;
    type: AssetType;
    isRoot?: boolean;
    hasVulnerabilities: boolean;
    name: string;
}

export interface Edge {
    id?: string;
    from: string;
    to: string;
    name: AdjacencyType;
    comment: string;
}
export interface Edges {
    [key: string]: Edge;
}
export interface GraphLink {
    id: string;
    source: string;
    target: string;
}

export interface Graph {
    clusterId: string;
    nodes: Nodes;
    edges: Edges;
    adjacency: Adjacency;
}

export enum GraphType {
    discoveryPathGraph = 'discoveryPathGraph'
    // relationsGraph = 'relationsGraph'
}

export enum GraphTypeLabel {
    discoveryPathGraph = 'Discovery Path'
    // relationsGraph = 'Relations Graph'
}
