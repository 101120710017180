import { createContext } from 'react';
import { Subscription } from 'entities/Billing';

export enum SubscriptionStatus {
    IS_TRIAL = 'IS_TRIAL',
    IS_PAID = 'IS_PAID',
    IS_ADDED_BY_ADMIN = 'IS_ADDED_BY_ADMIN',
    SUBSCRIPTION_ACTIVE = 'SUBSCRIPTION_ACTIVE',
    SUBSCRIPTION_EXPIRED = 'SUBSCRIPTION_EXPIRED',
    SUBSCRIPTION_CANCELED = 'SUBSCRIPTION_CANCELED',
    SUBSCRIPTION_LIMIT_REACHED = 'SUBSCRIPTION_LIMIT_REACHED',
    UNKNOWN = 'UNKNOWN'
}

export enum DataStatus {
    HAS_ASSETS_FOUND = 'HAS_ASSETS_FOUND',
    NO_ASSETS_FOUND = 'NO_ASSETS_FOUND',
    ROOT_ASSET_VERIFICATION_REQUIRED = 'ROOT_ASSET_VERIFICATION_REQUIRED',
    ROOT_ASSET_VERIFICATION_FAILED = 'ROOT_ASSET_VERIFICATION_FAILED'
}

export interface GlobalDateRange {
    from: number;
    to: number;
}

export interface InitContextProps {
    appLoading: boolean;
    setAppLoading: (loading: boolean) => void;
    hasUser: boolean;
    setHasUser: (hasUser: boolean) => void;
    subscription?: Subscription;
    setSubscription: (subscription: Subscription) => void;
    subscriptionStatus?: SubscriptionStatus[];
    setSubscriptionStatus: (status: SubscriptionStatus[]) => void;
    subscriptionDaysLeft?: number;
    setSubscriptionDaysLeft: (days: number) => void;
    subscriptionExpiredDaysAgo?: number;
    setSubscriptionExpiredDaysAgo: (days: number) => void;
    scanningStoppedAt?: string;
    setScanningStoppedAt: (date: string) => void;
    resetSubscriptionStatus: () => void;
    globalDateRange?: GlobalDateRange;
    setGlobalDateRange: (range: GlobalDateRange) => void;
}

export const InitContext = createContext<InitContextProps>({
    appLoading: true,
    setAppLoading: () => {},
    hasUser: false,
    subscription: undefined,
    setSubscription: () => {},
    setHasUser: () => {},
    subscriptionStatus: undefined,
    setSubscriptionStatus: () => {},
    resetSubscriptionStatus: () => {},
    subscriptionDaysLeft: undefined,
    setSubscriptionDaysLeft: () => {},
    subscriptionExpiredDaysAgo: undefined,
    setSubscriptionExpiredDaysAgo: () => {},
    scanningStoppedAt: undefined,
    setScanningStoppedAt: () => {},
    globalDateRange: undefined,
    setGlobalDateRange: () => {}
});
