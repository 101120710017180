import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { parseApiError } from 'shared/lib/api';
import { AddNewRootAssetSchema } from '../../types/addNewRootAssetSchema';

const initialState: AddNewRootAssetSchema = {
    success: false,
    successMessage: '',
    error: false,
    errorMessage: ''
};

const CUSTOM_API_ERRORS = {
    422: 'The provided root asset has invalid format.'
};

export const addNewRootAssetSlice = createSlice({
    name: 'addNewRootAsset',
    initialState,
    reducers: {
        reset: () => initialState,
        setSuccess: (state, action: PayloadAction<string>) => {
            state.error = false;
            state.errorMessage = '';
            state.success = true;
            state.successMessage = action.payload;
        },
        setError: (state, action: PayloadAction<object>) => {
            state.error = true;

            const parsedError = parseApiError(
                action.payload,
                CUSTOM_API_ERRORS
            );
            state.errorMessage = parsedError.message;
        }
    }
});

export const { actions: addNewRootAssetActions } = addNewRootAssetSlice;
export const { reducer: addNewRootAssetReducer } = addNewRootAssetSlice;
