import { DataTableColumn } from 'mantine-datatable';
import React from 'react';
import { BaseColumnProps, updateQuery } from 'widgets/Columns/baseColumn';
import {
    Vulnerability,
    VulnerabilityQueryParams
} from 'entities/Vulnerability';
import { EpssScoreBadge } from 'entities/EPSS';
import { KevCheckBadge } from 'entities/KEV';
import { FilterByKevCheckStatusSelect } from 'features/issue/filters/FilterByKevCheckStatus';

type RecordType = Vulnerability;
type QueryParams = VulnerabilityQueryParams;

export default ({
    query = {},
    setQuery,
    column
}: BaseColumnProps<RecordType, QueryParams>): DataTableColumn<RecordType> => {
    const { isKev } = query;

    return {
        width: 170,
        accessor: 'kev',
        title: 'CISA KEV status',
        filtering: isKev !== undefined,
        filter: (
            <FilterByKevCheckStatusSelect
                isKev={isKev}
                onChange={isKev => {
                    updateQuery({ isKev }, query, setQuery);
                }}
            />
        ),
        render: vulnerability => (
            <KevCheckBadge
                fullWidth
                issue={vulnerability}
                failedText="Detected"
                passedText="Passed"
                hasFaq={false}
            />
        ),
        ...column
    };
};
