import { theme } from 'shared/config/theme/theme';
import { Rating } from '../../types/issue';

export function severityRatingColor(rating: Rating | undefined) {
    switch (rating) {
        case 'critical':
            return theme.colors.red[9];
        case 'high':
            return theme.colors.orange[7];
        case 'medium':
            return theme.colors.yellow[7];
        case 'low':
            return theme.colors.green[7];
        case 'info':
            return theme.colors.blue[7];
        default:
            return theme.colors.gray[7];
    }
}

export function severityRatingThemeColorName(rating: Rating | undefined) {
    switch (rating) {
        case 'critical':
            return 'red.9';
        case 'high':
            return 'orange.7';
        case 'medium':
            return 'yellow.7';
        case 'low':
            return 'green.7';
        case 'info':
            return 'blue.7';
        default:
            return 'gray.7';
    }
}
