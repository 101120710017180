import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useRouteProgress = () => {
    const [progress, setProgress] = useState(false);
    const [prevLoc, setPrevLoc] = useState('');
    const location = useLocation();

    useEffect(() => {
        setPrevLoc(location.pathname);
        setProgress(true);
        if (location.pathname === prevLoc) {
            setPrevLoc('');
        }
    }, [location]);

    useEffect(() => {
        setProgress(false);
    }, [prevLoc]);

    return {
        progress
    };
};
