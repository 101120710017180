import { memo } from 'react';
import * as echarts from 'echarts/core';
import {
    TooltipComponent,
    GridComponent,
    LegendComponent
} from 'echarts/components';
import { LineChart as ELineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { EChartsOption } from 'echarts';
import ReactECharts from 'echarts-for-react';
import { RefetchableComponent } from 'widgets/RefetchableComponent';
import styles from './LineChart.module.scss';

echarts.use([
    TooltipComponent,
    GridComponent,
    LegendComponent,
    ELineChart,
    CanvasRenderer,
    UniversalTransition
]);

interface LineChartProps {
    options: EChartsOption;
    isLoading?: boolean;
    noData?: boolean;
}

function LineChart(props: LineChartProps) {
    const { options, isLoading, noData } = props;

    return (
        <RefetchableComponent isFetching={isLoading}>
            {noData && <div className={styles['no-data']}>No data</div>}
            {!noData && (
                <ReactECharts className={styles.chart} option={options} />
            )}
        </RefetchableComponent>
    );
}

export default memo(LineChart);
