import React, { memo } from 'react';
import { useLayout } from 'app/providers/LayoutProvider';
import classNames from 'classnames';
import { Sidebar } from 'widgets/Sidebar';
import { Content } from 'widgets/Content';
import { Outlet } from 'react-router-dom';
import { PageBarLoader } from 'widgets/PageBarLoader';
import { StackedDrawer } from 'entities/Navigation';
import { AlertsBar } from 'entities/Alerts';
import styles from './BaseLayout.module.scss';

interface LayoutProps {
    className?: string;
    children?: React.ReactNode;
}

const BaseLayout = (props: LayoutProps) => {
    const { className, children } = props;
    const { layout } = useLayout();
    const classes = classNames(styles.BaseLayout, className, styles[layout]);

    return (
        <main className={classes}>
            <PageBarLoader />
            <Sidebar />
            <Content>
                <AlertsBar mb="lg" />
                {children ?? <Outlet />}
            </Content>
            <StackedDrawer />
        </main>
    );
};

export default memo(BaseLayout);
