import React, { memo } from 'react';
import {
    Badge,
    Group,
    NumberFormatter,
    Paper,
    Skeleton,
    Text,
    Title
} from '@mantine/core';
import { Icon, Icons, IconSize } from 'shared/ui/Icon';
import { NavLink } from 'react-router-dom';

interface StatisticsCardProps {
    link?: string;
    title: string;
    icon?: Icons;
    primaryValue?: number;
    secondaryValue?: number;
    note?: string;
    isLoading?: boolean;
    additionalContent?: React.ReactNode;
}

const StatisticsCard = (props: StatisticsCardProps) => {
    const {
        link,
        title,
        icon,
        primaryValue,
        secondaryValue,
        note,
        isLoading,
        additionalContent
    } = props;

    const titleRender = () => (
        <Title order={2}>
            <NumberFormatter value={primaryValue} thousandSeparator />
        </Title>
    );

    return (
        <Paper withBorder p="md" radius="md" shadow="md">
            <Group
                gap={0}
                wrap="nowrap"
                justify="space-between"
                mt="-6"
                mr="-6"
            >
                <Text size="sm" c="dimmed" fw={700}>
                    {title}
                </Text>
                <Icon icon={icon as unknown as Icons} size={IconSize.LARGE} />
            </Group>

            <Group gap="xs" mt="lg">
                {isLoading ? (
                    <Skeleton height={35} width={80} />
                ) : (
                    <>
                        {link ? (
                            <NavLink color="black" to={link}>
                                {titleRender()}
                            </NavLink>
                        ) : (
                            titleRender()
                        )}
                        {secondaryValue ? (
                            <Badge
                                leftSection={
                                    secondaryValue > 0 ? (
                                        <Icon icon={Icons.TRENDING_UP} />
                                    ) : (
                                        <Icon icon={Icons.TRENDING_DOWN} />
                                    )
                                }
                                color={secondaryValue > 0 ? 'green' : 'red'}
                                variant="light"
                                size="md"
                                radius="sm"
                            >
                                <NumberFormatter
                                    value={secondaryValue}
                                    thousandSeparator
                                />
                            </Badge>
                        ) : (
                            <Badge
                                leftSection={
                                    <Icon icon={Icons.TRENDING_FLAT} />
                                }
                                color="gray"
                                variant="light"
                                size="md"
                                radius="sm"
                            >
                                0
                            </Badge>
                        )}
                    </>
                )}
            </Group>

            {note && (
                <Text fz="xs" c="dimmed">
                    {note}
                </Text>
            )}

            {additionalContent}
        </Paper>
    );
};

export default memo(StatisticsCard);
