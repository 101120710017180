import React, { memo } from 'react';
import { PageHeader, PageChildrenLink } from 'widgets/PageHeader';
import { RoutePath } from 'shared/config/routeConfig';
import { Button } from '@mantine/core';
import { NavLink } from 'react-router-dom';
import { Icon, Icons, IconSize } from 'shared/ui/Icon';

const AccountPageHeader = () => (
    <PageHeader
        title="Account"
        links={
            <>
                <PageChildrenLink
                    title="General"
                    to={`${RoutePath.account}/general`}
                    hasPreviewNumber={false}
                />
                <PageChildrenLink
                    title="Billing"
                    to={`${RoutePath.account}/billing`}
                    hasPreviewNumber={false}
                />
            </>
        }
        actions={
            <Button
                component={NavLink}
                to={`${RoutePath.logout}`}
                color="red"
                variant="filled"
                size="sm"
                leftSection={<Icon icon={Icons.LOGOUT} />}
            >
                Logout
            </Button>
        }
    />
);

export default memo(AccountPageHeader);
