import {
    Alert,
    Badge,
    Button,
    Card,
    createTheme,
    DEFAULT_THEME,
    JsonInput,
    MantineColorsTuple,
    Menu,
    mergeMantineTheme,
    Modal,
    Text,
    Tooltip
} from '@mantine/core';

import classes from './extends.scss';

const asm: MantineColorsTuple = [
    '#ecf2ff',
    '#d9e1f8',
    '#b1c0e9',
    '#879ddd',
    '#637fd1',
    '#4c6ccb',
    '#3f63c9',
    '#3153b2',
    '#2949a0',
    '#1d3f8e'
];

const themeOverride = createTheme({
    fontFamily: 'Noto Sans, sans-serif',
    fontFamilyMonospace: 'Fira Code, monospace',
    defaultRadius: 'sm',
    primaryColor: 'asm',
    primaryShade: 7,
    colors: {
        asm
    },
    components: {
        Button: Button.extend({
            classNames: classes
        }),
        Badge: Badge.extend({
            classNames: classes
        }),
        Card: Card.extend({
            classNames: classes
        }),
        Text: Text.extend({
            classNames: classes
        }),
        JsonInput: JsonInput.extend({
            classNames: classes
        }),
        Tooltip: Tooltip.extend({
            classNames: classes
        }),
        Modal: Modal.extend({
            classNames: classes
        }),
        Alert: Alert.extend({
            classNames: classes
        })
    }
});

export const theme = mergeMantineTheme(DEFAULT_THEME, themeOverride);
