import { memo } from 'react';
import { assetDetailsRoute } from 'app/providers/RouteProvider';
import { Asset, RootAsset } from 'entities/Asset';
import { NavigationLink, NavigationPageType } from 'entities/Navigation';

interface AssetLinkProps {
    asset: Asset | RootAsset;
    children?: React.ReactNode;
}

const AssetLink = (props: AssetLinkProps) => {
    const { asset, children } = props;
    if (!asset) return '-';

    return (
        <NavigationLink
            pageLink={assetDetailsRoute(asset.id, asset.type)}
            pageTitle={asset.value}
            pageType={NavigationPageType.ASSET_DETAILS}
            pageID={asset.id}
        >
            {children || asset.value}
        </NavigationLink>
    );
};

export default memo(AssetLink);
