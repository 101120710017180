import { memo } from 'react';
import { NavigationLink, NavigationPageType } from 'entities/Navigation';
import { rootAssetDetailsRoute } from 'app/providers/RouteProvider';
import { RootAsset } from '../../../model/types/asset';

interface RootAssetLinkProps {
    asset: RootAsset;
    children?: React.ReactNode;
}

const RootAssetLink = (props: RootAssetLinkProps) => {
    const { asset, children } = props;
    const { id, value } = asset;

    return (
        <NavigationLink
            pageTitle={value}
            pageLink={rootAssetDetailsRoute(id)}
            pageType={NavigationPageType.ROOT_ASSET_DETAILS}
            pageID={id}
        >
            {children || value}
        </NavigationLink>
    );
};

export default memo(RootAssetLink);
