import { memo, useState } from 'react';
import { Box, Container, Flex, Paper, Text, TextInput } from '@mantine/core';
import { AssetCreateBody, AssetType, useAddAsset } from 'entities/Asset';
import { useForm } from '@mantine/form';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { addNewRootAssetActions } from 'features/asset/actions/AddNewRootAsset';
import { VerifyRootAssetForm } from 'features/asset/actions/VerifyRootAsset';
import ConfirmFormFooter from '../common/ConfirmFormFooter/ConfirmFormFooter';

const DomainRootAsset = () => {
    const dispatch = useAppDispatch();
    const [addAsset, { isLoading, data: addedRootAsset }] = useAddAsset();
    const [verificationRequired, setVerificationRequired] = useState(false);
    const form = useForm<AssetCreateBody>({
        initialValues: {
            value: '',
            type: AssetType.DOMAIN
        }
    });

    const onSubmit = (values: AssetCreateBody) =>
        addAsset(values)
            .unwrap()
            .then(_ => {
                setVerificationRequired(true);
            })
            .catch(err => {
                dispatch(addNewRootAssetActions.setError(err));
            });

    if (addedRootAsset) {
        return (
            <Container size="md">
                <Text c="dimmed" ta="center" size="lg">
                    The domain has been added successfully. Now you need to
                    verify that you own this domain. Verification is required to
                    ensure only authorized people can view sensitive data. You
                    can verify your domain ownership in two different ways: with
                    DNS records (TXT) or by uploading a txt file into the root
                    of your asset. If none of the verification methods work for
                    you, you can request assisted verification via our support
                    team.
                </Text>
                <Paper withBorder shadow="md" p="xl" radius="md" mt="xl">
                    <VerifyRootAssetForm
                        asset={addedRootAsset}
                        hasDescription={false}
                    />
                </Paper>
            </Container>
        );
    }
    return (
        <Container size="xs">
            <Text c="dimmed" ta="center" size="md">
                Enter a domain name you want to keep secure. Next, you&apos;ll
                need to verify that you own this domain by adding a TXT record
                to your DNS settings or uploading a file to your website.
            </Text>
            <Paper withBorder shadow="md" p="xl" radius="md" mt="xl">
                <form onSubmit={form.onSubmit(onSubmit)}>
                    <Flex gap="lg" direction="column">
                        <TextInput
                            label="Domain name"
                            placeholder="example.com"
                            withAsterisk
                            required
                            autoFocus
                            pattern="^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$"
                            {...form.getInputProps('value')}
                        />
                        <ConfirmFormFooter loading={isLoading} />
                    </Flex>
                </form>
            </Paper>
        </Container>
    );
};

export default memo(DomainRootAsset);
