import useHubspot from '../useHubspot/useHubspot';

export const useAnalytics = () => {
    const { setDoNotTrack } = useHubspot();

    const enableAnalytics = () => {
        console.log('enableAnalytics');
        // @ts-ignore
        window['ga-disable-G-B68WSPVLQ3'] = false;
        setDoNotTrack(true);
    };

    const disableAnalytics = () => {
        console.log('disableAnalytics');
        // @ts-ignore
        window['ga-disable-G-B68WSPVLQ3'] = true;
        setDoNotTrack(false);
    };

    return {
        enableAnalytics,
        disableAnalytics
    };
};
