import { memo, useMemo } from 'react';
import { useGetStatisticsByVulnerabilityRating } from 'entities/Statistics';
import { BarChart } from 'shared/ui/Chart';
import { EChartsOption } from 'echarts';
import { severityRatingColor } from 'entities/Issue';
import dayjs from 'dayjs';
import { dateFormatLib } from 'shared/lib/formatter/formatter';

const IssueStatisticsChart = () => {
    const startDate = dayjs().subtract(14, 'days').startOf('D').unix();
    const endDate = dayjs().startOf('m').unix();
    const { data, isLoading, isFetching } =
        useGetStatisticsByVulnerabilityRating({
            startDate,
            endDate
        });

    const options: EChartsOption = useMemo(
        () => ({
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {
                data: ['Critical', 'High', 'Medium', 'Low', 'Info'],
                selected: {
                    Critical: true,
                    High: true,
                    Medium: true,
                    Low: false,
                    Info: false
                },
                bottom: 12
            },
            grid: {
                top: 24,
                right: 16,
                bottom: 46,
                left: 12,
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    boundaryGap: false,
                    axisTick: { show: false },
                    data: data?.map(({ ts }) => dateFormatLib(ts)) ?? []
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    scale: true,
                    minInterval: 1,
                    axisLabel: {
                        formatter(value, index) {
                            if (value < 1) {
                                return '';
                            }

                            return `${value} issues`;
                        }
                    }
                }
            ],
            series: [
                {
                    name: 'Critical',
                    type: 'line',
                    emphasis: {
                        focus: 'series'
                    },
                    itemStyle: {
                        color: severityRatingColor('critical'),
                        borderRadius: [4, 4, 0, 0]
                    },
                    data: data?.reduce((acc: number[], dateData) => {
                        const { critical } = dateData.distribution;
                        return [...acc, critical.count];
                    }, [])
                },
                {
                    name: 'High',
                    type: 'line',
                    emphasis: {
                        focus: 'series'
                    },
                    itemStyle: {
                        color: severityRatingColor('high'),
                        borderRadius: [4, 4, 0, 0]
                    },
                    data: data?.reduce((acc: number[], dateData) => {
                        const { high } = dateData.distribution;
                        return [...acc, high.count];
                    }, [])
                },
                {
                    name: 'Medium',
                    type: 'line',
                    emphasis: {
                        focus: 'series'
                    },
                    itemStyle: {
                        color: severityRatingColor('medium'),
                        borderRadius: [4, 4, 0, 0]
                    },
                    data: data?.reduce((acc: number[], dateData) => {
                        const { medium } = dateData.distribution;
                        return [...acc, medium.count];
                    }, [])
                },
                {
                    name: 'Low',
                    type: 'line',
                    emphasis: {
                        focus: 'series'
                    },
                    itemStyle: {
                        color: severityRatingColor('low'),
                        borderRadius: [4, 4, 0, 0]
                    },
                    data: data?.reduce((acc: number[], dateData) => {
                        const { low } = dateData.distribution;
                        return [...acc, low.count];
                    }, [])
                },
                {
                    name: 'Info',
                    type: 'line',
                    emphasis: {
                        focus: 'series'
                    },
                    itemStyle: {
                        color: severityRatingColor('info'),
                        borderRadius: [4, 4, 0, 0]
                    },
                    data: data?.reduce((acc: number[], dateData) => {
                        const { info } = dateData.distribution;
                        return [...acc, info.count];
                    }, [])
                }
            ]
        }),
        [data]
    );

    return (
        <BarChart
            defaultOptions={options}
            isLoading={isLoading || isFetching}
            /* onClick={params => {
                const { dataIndex } = params;

                if (data) {
                    const { ts } = data[dataIndex];
                    console.log(data[dataIndex]);
                    const createdAtGte = ts;
                    const createdAtLte = ts + 86400;
                    navigate(
                        `/issues/all?createdAtGte=${createdAtGte}&createdAtLte=${createdAtLte}`
                    );
                }
            }} */
        />
    );
};

export default memo(IssueStatisticsChart);
