import { memo } from 'react';
import { PageHeader, PageChildrenLink } from 'widgets/PageHeader';
import { RoutePath } from 'shared/config/routeConfig';
import { useGetLastStatisticsByAssetType } from 'entities/Statistics/api/statisticsApi/statisticsApi';
import { AssetType } from 'entities/Asset';

const AssetsPageHeader = () => {
    const { data: allStatistics, isLoading: allLoading } =
        useGetLastStatisticsByAssetType({});
    const { data: domainStatistics, isLoading: domainLoading } =
        useGetLastStatisticsByAssetType({
            assetType: AssetType.DOMAIN
        });
    const { data: ipv4Statistics, isLoading: ipv4Loading } =
        useGetLastStatisticsByAssetType({
            assetType: AssetType.IPV4
        });
    const { data: ipv6Statistics, isLoading: ipv6Loading } =
        useGetLastStatisticsByAssetType({
            assetType: AssetType.IPV6
        });
    const { data: sslStatistics, isLoading: sslLoading } =
        useGetLastStatisticsByAssetType({
            assetType: AssetType.SSL
        });
    const { data: portStatistics, isLoading: portLoading } =
        useGetLastStatisticsByAssetType({
            assetType: AssetType.PORT
        });
    const { data: cidrStatistics, isLoading: cidrLoading } =
        useGetLastStatisticsByAssetType({
            assetType: AssetType.CIDR
        });
    const { data: emailStatistics, isLoading: emailLoading } =
        useGetLastStatisticsByAssetType({
            assetType: AssetType.EMAIL
        });
    const { data: cloudStatistics, isLoading: cloudLoading } =
        useGetLastStatisticsByAssetType({
            assetType: AssetType.CLOUD
        });
    const { data: orgStatistics, isLoading: orgLoading } =
        useGetLastStatisticsByAssetType({
            assetType: AssetType.ORGANIZATION
        });

    return (
        <PageHeader
            title="Assets"
            links={
                <>
                    <PageChildrenLink
                        title="All Assets"
                        to={`${RoutePath.assets}/all`}
                        previewNumber={allStatistics?.total}
                        previewNumberLoading={allLoading}
                    />
                    <PageChildrenLink
                        title="Domain"
                        to={`${RoutePath.assets}/domain`}
                        previewNumber={domainStatistics?.total}
                        previewNumberLoading={domainLoading}
                    />
                    <PageChildrenLink
                        title="IPv4"
                        to={`${RoutePath.assets}/ipv4`}
                        previewNumber={ipv4Statistics?.total}
                        previewNumberLoading={ipv4Loading}
                    />
                    <PageChildrenLink
                        title="IPv6"
                        to={`${RoutePath.assets}/ipv6`}
                        previewNumber={ipv6Statistics?.total}
                        previewNumberLoading={ipv6Loading}
                    />
                    <PageChildrenLink
                        title="SSL"
                        to={`${RoutePath.assets}/ssl`}
                        previewNumber={sslStatistics?.total}
                        previewNumberLoading={sslLoading}
                    />
                    <PageChildrenLink
                        title="Port"
                        to={`${RoutePath.assets}/port`}
                        previewNumber={portStatistics?.total}
                        previewNumberLoading={portLoading}
                    />
                    <PageChildrenLink
                        title="CIDR"
                        to={`${RoutePath.assets}/cidr`}
                        previewNumber={cidrStatistics?.total}
                        previewNumberLoading={cidrLoading}
                    />
                    <PageChildrenLink
                        title="Email"
                        to={`${RoutePath.assets}/email`}
                        previewNumber={emailStatistics?.total}
                        previewNumberLoading={emailLoading}
                    />
                    <PageChildrenLink
                        title="Org"
                        to={`${RoutePath.assets}/org`}
                        previewNumber={orgStatistics?.total}
                        previewNumberLoading={orgLoading}
                    />
                    <PageChildrenLink
                        title="Cloud"
                        to={`${RoutePath.assets}/cloud`}
                        previewNumber={cloudStatistics?.total}
                        previewNumberLoading={cloudLoading}
                    />
                </>
            }
        />
    );
};

export default memo(AssetsPageHeader);
