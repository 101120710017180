import React, { memo } from 'react';
import { useGetLastTotalStatisticsByVulnerabilityScore } from 'entities/Statistics';
import {
    NumberFormatter,
    RingProgress,
    Stack,
    Title,
    Text,
    Skeleton,
    Group,
    Mark
} from '@mantine/core';
import { severityRatingColor, severityScoreColor } from 'entities/Issue';
import HelpHoverCard from 'shared/ui/HelpHoverCard/HelpHoverCard';

const TotalScoreBarChart = () => {
    const { data: distribution, isLoading } =
        useGetLastTotalStatisticsByVulnerabilityScore({});
    const score = distribution?.total ?? 0;
    const roundedScore = Math.floor(score * 10) / 10;

    return (
        <RingProgress
            size={300}
            thickness={16}
            roundCaps
            label={
                <Stack gap={0}>
                    <Group justify="center" gap={4} mr="-lg">
                        <Text ta="center" fw={600} fz="md">
                            Total score
                        </Text>
                        <HelpHoverCard
                            withIcon
                            position="right"
                            title="What is the total score?"
                            description={
                                <Text>
                                    The total score is the average of all the
                                    scores of the vulnerabilities found in the
                                    project. It is a number between{' '}
                                    <Mark
                                        fw={700}
                                        c="white"
                                        pl="xs"
                                        pr="xs"
                                        color={severityRatingColor('low')}
                                    >
                                        0
                                    </Mark>{' '}
                                    and{' '}
                                    <Mark
                                        fw={700}
                                        c="white"
                                        pl="xs"
                                        pr="xs"
                                        color={severityRatingColor('critical')}
                                    >
                                        10
                                    </Mark>
                                    . The higher the score, the more vulnerable
                                    your infrastructure is.
                                </Text>
                            }
                        />
                    </Group>
                    {isLoading ? (
                        <Skeleton width={80} height={32} mt="sm" mx="auto" />
                    ) : (
                        <Title order={1} ta="center" fw={900}>
                            <NumberFormatter value={roundedScore} />
                        </Title>
                    )}
                </Stack>
            }
            sections={
                roundedScore
                    ? [
                          {
                              value: roundedScore * 10,
                              color: severityScoreColor(roundedScore)
                          }
                      ]
                    : []
            }
        />
    );
};

export default memo(TotalScoreBarChart);
