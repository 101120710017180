import { useEffect, useState } from 'react';

interface StripeCheckout {
    configure: (config: {
        key: string;
        image: string;
        token: (token: { id: string }) => void;
    }) => {
        open: (options: {
            email: string;
            name?: string;
            description?: string;
            amount: number;
        }) => void;
    };
    open: (options: {
        name: string;
        description: string;
        amount: number;
        currency: string;
        token: (token: { id: string }) => void;
    }) => void;
}

export const useLoadStripeCheckout = (allow = false) => {
    const [stripeCheckout, setStripeCheckout] = useState<StripeCheckout>(
        (window as any).StripeCheckout
    );

    useEffect(() => {
        function loadStripe() {
            const stripe = document.createElement('script');
            stripe.src = 'https://checkout.stripe.com/checkout.js';
            stripe.async = true;
            document.body.appendChild(stripe);
            stripe.onload = () => {
                setStripeCheckout((window as any).StripeCheckout);
            };
        }

        if (allow && !stripeCheckout) {
            loadStripe();
        }
    }, [allow, stripeCheckout]);

    return stripeCheckout;
};
