import { memo } from 'react';
import { Port } from 'entities/Asset/model/types/metadata';

interface PortDeviceTypeProps {
    port?: Port;
}

const PortDeviceType = (props: PortDeviceTypeProps) => {
    const { port } = props;
    return port?.deviceType || 'N/A';
};

export default memo(PortDeviceType);
