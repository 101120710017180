import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootAsset } from 'entities/Asset';
import { RemoveRootAssetSchema } from '../../types/removeRootAssetSchema';

const initialState: RemoveRootAssetSchema = {
    asset: null
};

export const removeRootAssetSlice = createSlice({
    name: 'removeRootAsset',
    initialState,
    reducers: {
        reset: () => initialState,
        setAsset: (state, action: PayloadAction<RootAsset>) => {
            state.asset = action.payload;
        }
    }
});

export const { actions: removeRootAssetActions } = removeRootAssetSlice;
export const { reducer: removeRootAssetReducer } = removeRootAssetSlice;
