import { AssetQueryParams, RootAsset } from 'entities/Asset';
import { DataTableColumn } from 'mantine-datatable';
import React from 'react';
import { BaseColumnProps } from 'widgets/Columns/baseColumn';
import { NumberFormatter } from '@mantine/core';

export default ({
    query,
    setQuery,
    column
}: BaseColumnProps<
    RootAsset,
    AssetQueryParams
>): DataTableColumn<RootAsset> => ({
    width: 150,
    accessor: 'descendantsCount',
    title: 'Assets',
    sortable: true,
    render: ({ descendantsCount }) => (
        <NumberFormatter value={descendantsCount} thousandSeparator />
    ),
    ...column
});
