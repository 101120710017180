import { DataTableColumn } from 'mantine-datatable';
import React from 'react';
import { BaseColumnProps, updateQuery } from 'widgets/Columns/baseColumn';
import { IssueUnique, RiskBadge } from 'entities/Issue';
import {
    Vulnerability,
    VulnerabilityQueryParams
} from 'entities/Vulnerability';
import { FilterBySeverityRatingForm } from 'features/issue/filters/FilterBySeverityRating';

type RecordType = Vulnerability | IssueUnique;
type QueryParams = VulnerabilityQueryParams;

export default ({
    query = {},
    setQuery,
    column
}: BaseColumnProps<RecordType, QueryParams>): DataTableColumn<RecordType> => {
    const { rating = [] } = query;
    return {
        width: 140,
        accessor: 'severity',
        title: 'Severity',
        sortable: true,
        filtering: rating.length > 0,
        filter: (
            <FilterBySeverityRatingForm
                selectedSeverityRatings={rating}
                onChange={rating => {
                    updateQuery({ rating }, query, setQuery);
                }}
            />
        ),
        render: vulnerability => (
            <RiskBadge
                issue={vulnerability}
                fullWidth
                selectedSeverityRatings={rating}
                setSelectedSeverityRatings={rating => {
                    updateQuery({ rating }, query, setQuery);
                }}
            />
        ),
        ...column
    };
};
