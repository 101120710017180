export interface GeoIP {
    ip: string;
    country: string;
    region: string;
    city: string;
    lat: number;
    lng: number;
    postalCode: string;
    timezone: string;
    geonameID: number;
    isp: string;
    connectionType: string;
    asn: number;
    asName: string;
    asRoute: string;
    asDomain: string;
    asType: string;
}

export interface SubnetworkContact {
    id: string;
    person: string;
    role: string;
    phone: string;
    email: string;
    country: string;
    city: string;
    address: string;
}

export interface SubnetworkMaintainer {
    mntner: string;
    email: string;
}

export interface Subnetwork {
    as: {
        asn: number;
        name: string;
        type: string;
        route: string;
        domain: string;
    };
    org: {
        id: string;
        org: string;
        city: string;
        name: string;
        email: string;
        phone: string;
        address: string[];
        country: string;
        postalCode: string;
    };
    city: string;
    mntBy: SubnetworkMaintainer[];
    parent: string;
    source: string;
    address: string[];
    country: string;
    inetnum: string;
    netname: string;
    remarks: string[];
    mntLower: SubnetworkMaintainer[];
    modified: string;
    mntRoutes: SubnetworkMaintainer[];
    nethandle: string;
    mntDomains: SubnetworkMaintainer[];
    description: string[];
    inetnumLast: number;
    techContact: SubnetworkContact[];
    abuseContact: SubnetworkContact[];
    adminContact: SubnetworkContact[];
    inetnumFirst: number;
    inetnumLastString: string;
    inetnumFirstString: string;
}

export interface WhoisContact {
    city: string;
    country: string;
    countryCode: string;
    email: string;
    fax: string;
    faxExt: string;
    name: string;
    organization: string;
    postalCode: string;
    rawText: string;
    state: string;
    street1: string;
    street2: string;
    street3: string;
    street4: string;
    telephone: string;
    telephoneExt: string;
    unparsable: string;
}

export interface WhoisRecord {
    administrativeContact: WhoisContact;
    audit: {
        createdDate: string;
        updatedDate: string;
    };
    billingContact: WhoisContact;
    contactEmail: string;
    createdDate: string;
    createdDateNormalized: string;
    custom1FieldName: string;
    custom1FieldValue: string;
    custom2FieldName: string;
    custom2FieldValue: string;
    custom3FieldName: string;
    custom3FieldValue: string;
    dataError: string;
    domainAvailability: string;
    domainName: string;
    domainNameExt: string;
    estimatedDomainAge: number;
    expiresDate: string;
    expiresDateNormalized: string;
    footer: string;
    header: string;
    ips: null;
    nameServers: {
        ips: string[];
        rawText: string;
    };
    parseCode: number;
    rawText: string;
    registrant: WhoisContact;
    registrarIANAID: string;
    registrarName: string;
    registryData: any;
    status: string;
    strippedText: string;
    subRecords: any;
    technicalContact: WhoisContact;
    updatedDate: string;
    updatedDateNormalized: string;
    whoisServer?: string;
}

export interface PortComponentCategories {
    categories: string[];
}

export interface Port {
    port: number;
    deviceType: string;
    extraInfo?: {
        components: Record<string, PortComponentCategories>;
        data?: string;
        waf?: string;
        redirects?: string[];
        title?: string;
        status?: string;
        server?: string;
        html?: string;
        host?: string;
    };
    isOpen: boolean;
    os: string;
    product: string;
    protocol: string;
    service: string;
    version: string;
}

export type SslCertificateStatus =
    | 'VALID'
    | 'EXPIRED'
    | 'NOT VALID YET'
    | 'EXPIRES'
    | 'REVOKED';
export interface SslCertificate {
    positionInChain: number;
    subject: string;
    serialNumber: string;
    commonNames: string[];
    alternativeNames: string[];
    notValidBefore: string;
    notValidAfter: string;
    issuer: string;
    signatureAlgorithm: string;
    crlURIs: string[];
    ocspURIs: string[];
    revocationStatus: string;
    crlRevocationStatus: string;
    ocspRevocationStatus: string;
    dnsCaa: boolean;
    caaPolicy: string;
    mustStaple: boolean;
    sgc: string[];
    validationType: string;
    sct: boolean;
    sha1Hash: string;
    pinSha256: string;
    keyAlg: string;
    keySize: number;
    keyStrength: number;
    PEM: string;
    status: SslCertificateStatus;
}

export interface SslCertificateMetadata extends SslCertificate {
    chain: SslCertificate[];
}

export interface SslConnection {
    protocols: string[];
    cipherSuites: Record<string, string[]>;
    renegotiationSupport: string[];
    sessionResumption: string;
    compression: string[];
    namedGroups: string[];
    supportsNpn: boolean;
    npnProtocols: string[];
    supportsAlpn: boolean;
    alpnProtocols: string[];
    sessionTickets: string[];
    ocspStaplingEnabled: boolean;
    ocspStaplingRevocationStatus: string;
    ocspStaplingErrorMessage: string;
    supportsRc4: boolean;
    supportsRc4WithModernClients: boolean;
    supportsAead: boolean;
    supportsCbc: boolean;
    sniRequired: boolean;
    zeroRTTEnabled: boolean;
}

export interface Dns {
    name: string;
    type: string;
    content: string;
    ttl: number;
    lastSeenAt: string;
}

export interface Metadata {
    geoip: GeoIP;
    subnetwork: Subnetwork[];
    screenshot: string;
    whoisRecord: WhoisRecord;
    port: Port;
    sslCertificate: SslCertificateMetadata;
    sslConnection: SslConnection;
    dns: Dns[];
}

export enum MetadataTypeLabel {
    'geoip' = 'Geolocation',
    'subnetwork' = 'Subnetworks',
    'screenshot' = 'Web Screenshot',
    'whoisRecord' = 'WHOIS Record',
    'port' = 'Port Meta'
}
