import { DependencyList, useEffect } from 'react';

export const useInitialEffect = (
    callback: () => void,
    deps: DependencyList = []
) => {
    const isStorybook = __PROJECT__ === 'storybook';

    useEffect(() => {
        if (!isStorybook) {
            callback();
        }
    }, [
        isStorybook,
        callback,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ...deps
    ]);
};
