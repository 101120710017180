import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { getCookie } from 'shared/lib/cookie/cookie';
import { userActions } from '../../slices/userSlice';
import { User } from '../../types/user';
import { USER_AUTH_COOKIE } from '../../const';

const initUser = createAsyncThunk<User | undefined, void, ThunkConfig<string>>(
    'user/initUser',
    async (_, thunkAPI) => {
        const { rejectWithValue, extra, dispatch } = thunkAPI;

        try {
            const user = getCookie<User>(USER_AUTH_COOKIE);

            if (user && typeof user === 'object') {
                dispatch(userActions.setAuthData(user));
                dispatch(userActions.setInitialized(true));

                return user;
            }

            return undefined;
        } catch (error) {
            return rejectWithValue("Couldn't initialize user.");
        }
    }
);

export default initUser;
