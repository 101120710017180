import React from 'react';
import { AppInitialLoader } from 'widgets/AppInitialLoader';
import { AppRouter } from 'app/providers/RouteProvider';
import { useInit } from 'app/providers/InitProvider';

function App() {
    const { appLoading } = useInit();

    if (appLoading) {
        return <AppInitialLoader />;
    }

    return <AppRouter />;
}

export default App;
