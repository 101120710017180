import { API_TAGS, rtkApi } from 'shared/api/rtkApi';
import {
    SlackSettings,
    SlackSettingsCreateBody,
    TestSlackMessage
} from '../../model/types/slack';

const jiraApi = rtkApi.injectEndpoints({
    endpoints: builder => ({
        getSlackSettings: builder.query<SlackSettings, void>({
            queryFn: async (_, api, extraOptions, baseQuery) => {
                try {
                    const result = await baseQuery('/slack-settings');

                    if ('error' in result) {
                        return {
                            data: null
                        };
                    }

                    return result;
                } catch (error) {
                    return {
                        data: null
                    };
                }
            },
            providesTags: [API_TAGS.SLACK_SETTINGS]
        }),
        createSlackSettings: builder.mutation<
            SlackSettings,
            SlackSettingsCreateBody
        >({
            query: body => ({
                url: '/slack-settings',
                method: 'POST',
                body
            }),
            invalidatesTags: [API_TAGS.SLACK_SETTINGS]
        }),
        deleteSlackSettings: builder.mutation<void, void>({
            query: () => ({
                url: '/slack-settings',
                method: 'DELETE'
            }),
            invalidatesTags: [API_TAGS.SLACK_SETTINGS]
        }),
        postTestMessage: builder.mutation<TestSlackMessage, void>({
            query: () => ({
                url: '/slack-settings/test-message',
                method: 'POST'
            })
        })
    })
});

export const {
    useGetSlackSettingsQuery,
    useCreateSlackSettingsMutation,
    useDeleteSlackSettingsMutation,
    usePostTestMessageMutation
} = jiraApi;
