import { ACCOUNT_API_TAGS, accountApi } from 'shared/api/rtkApi';

const stripeApi = accountApi.injectEndpoints({
    endpoints: builder => ({
        chargeStripe: builder.mutation<
            void,
            {
                token: string;
                planId: number;
                priceListKey: number;
            }
        >({
            query: ({ token, planId, priceListKey }) => ({
                url: '/charge_stripe',
                method: 'POST',
                body: {
                    stripe_token: token,
                    plan_id: planId,
                    key: priceListKey
                }
            }),
            invalidatesTags: [
                ACCOUNT_API_TAGS.PAYMENT,
                ACCOUNT_API_TAGS.SUBSCRIPTION
            ]
        })
    })
});

export const { useChargeStripeMutation } = stripeApi;
