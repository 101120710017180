import { useSearchParams } from 'react-router-dom';
import { AssetSearchParams } from '../types/asset';

export function useAssetSearchParams(): AssetSearchParams {
    const [params] = useSearchParams();

    return {
        createdAtGte: Number(params.get('createdAtGte')) || undefined,
        createdAtLte: Number(params.get('createdAtLte')) || undefined
    };
}
