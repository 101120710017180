import { memo, useCallback, useEffect } from 'react';
import { useApproveAssetCandidates } from 'entities/Asset';
import { Button, Group, Modal, Space, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { useSelector } from 'react-redux';
import { useInjectReducer } from 'shared/hooks/useInjectReducer';
import {
    approveAssetCandidateActions,
    approveAssetCandidateReducer
} from 'features/asset/actions/ApproveAssetCandidate';
import { showNotification } from '@mantine/notifications';
import { getAssets } from '../../model/selectors/getAssets/getAssets';

const ApproveAssetCandidateModal = () => {
    useInjectReducer('approveAssetCandidate', approveAssetCandidateReducer);

    const [opened, { open, close }] = useDisclosure(false);

    const dispatch = useAppDispatch();
    const assets = useSelector(getAssets);
    const [approveAssetCandidate, { isLoading }] = useApproveAssetCandidates();

    useEffect(() => {
        if (assets === undefined) return;
        if (assets.length === 0) close();
        if (assets.length > 0) open();
    }, [assets, close, open]);

    const onApprove = useCallback(() => {
        if (assets === undefined) return;
        const ids = assets.map(asset => asset.id);
        approveAssetCandidate(ids).then(() => {
            dispatch(approveAssetCandidateActions.reset());
            showNotification({
                title: 'Successfully approved',
                message: `${assets.length} asset candidates have been approved.`,
                color: 'green'
            });
        });
    }, [approveAssetCandidate, assets, dispatch]);

    const onClose = useCallback(() => {
        dispatch(approveAssetCandidateActions.reset());
    }, [dispatch]);

    return (
        <Modal
            size="lg"
            opened={opened}
            title="Approve asset candidates"
            onClose={close}
        >
            {assets?.length === 1 && (
                <Text size="sm">
                    Are you sure you want to approve asset candidate{' '}
                    <b>{assets[0].value}</b>? Approving this asset candidate
                    will make it the root asset. By doing so, you agree to scan
                    the infrastructures of this asset.
                </Text>
            )}
            {assets && assets?.length > 1 && (
                <Text size="sm">
                    Are you sure you want to approve selected{' '}
                    <b>{assets.length} asset candidates</b>? Approving these
                    asset candidates will make them the root assets. By doing
                    so, you agree to scan the infrastructures of these assets.
                </Text>
            )}
            <Space h="md" />
            <Group justify="flex-end">
                <Button onClick={onClose} variant="outline" color="asm">
                    Cancel
                </Button>
                <Button color="green.9" loading={isLoading} onClick={onApprove}>
                    I&apos;m sure, approve
                </Button>
            </Group>
        </Modal>
    );
};

export default memo(ApproveAssetCandidateModal);
