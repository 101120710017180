import React, { memo, useMemo } from 'react';
import { MultiSelect } from '@mantine/core';
import { Icon, Icons } from 'shared/ui/Icon';
import { useGetTags } from 'entities/Tag';

export interface FilterByAssetTagFormProps {
    selectedTags?: string[];
    onChange?: (value: string[]) => void;
}

const EXCLUDED_TAGS = ['deleting'];

const FilterByAssetTagForm = (props: FilterByAssetTagFormProps) => {
    const { selectedTags, onChange } = props;
    const { data, isLoading } = useGetTags();

    const { data: tags } = data ?? {};

    const systemTagOptions = useMemo(
        () =>
            tags
                ?.filter(tag => tag.isSystem)
                .filter(tag => !EXCLUDED_TAGS.includes(tag.name))
                .map(tag => ({
                    value: tag.id,
                    label: tag.name
                })) ?? [],
        [tags]
    );
    const userTagOptions = useMemo(
        () =>
            tags
                ?.filter(tag => !tag.isSystem)
                .map(tag => ({
                    value: tag.id,
                    label: tag.name
                })) ?? [],
        [tags]
    );

    const options = useMemo(() => {
        const _options = [];

        if (systemTagOptions.length > 0) {
            _options.push({
                group: 'System tags',
                items: systemTagOptions
            });
        }

        if (userTagOptions.length > 0) {
            _options.push({
                group: 'User tags',
                items: userTagOptions
            });
        }

        return _options;
    }, [systemTagOptions, userTagOptions]);

    return (
        <MultiSelect
            clearable
            searchable
            label="Tags"
            description="Show all tags whose names contain the specified text."
            data={options}
            value={!isLoading ? selectedTags : ['Loading...']}
            disabled={isLoading}
            leftSection={<Icon icon={Icons.SEARCH} />}
            comboboxProps={{ withinPortal: !options?.length }}
            onChange={onChange}
            style={{ maxWidth: 600 }}
        />
    );
};

export default memo(FilterByAssetTagForm);
