import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { RoutePath } from 'shared/config/routeConfig';
import { AssetType } from 'entities/Asset';

export function assetDetailsRoute(assetId: string, assetType?: AssetType) {
    return `${RoutePath.assets}/${assetType && `${assetType}/`}${assetId}`;
}

export function useNavigateToAssetDetails() {
    const navigate = useNavigate();

    return useCallback(
        (assetId: string) => {
            navigate(assetDetailsRoute(assetId));
        },
        [navigate]
    );
}
