import { memo } from 'react';
import { Port } from 'entities/Asset/model/types/metadata';

interface PortOsProps {
    port?: Port;
}

const PortOs = (props: PortOsProps) => {
    const { port } = props;
    return port?.os || 'N/A';
};

export default memo(PortOs);
