import { DataTableColumn } from 'mantine-datatable';
import { BaseColumnProps } from 'widgets/Columns/baseColumn';
import { Asset, AssetQueryParams } from 'entities/Asset';
import { IssueUnique } from 'entities/Issue';
import { VulnerabilityQueryParams } from 'entities/Vulnerability';
import { MisconfigurationQueryParams } from 'entities/Misconfiguration';
import { NumberFormatter } from '@mantine/core';
import React from 'react';

type RecordType = IssueUnique;
type QueryParams = VulnerabilityQueryParams | MisconfigurationQueryParams;

export default ({
    column
}: BaseColumnProps<RecordType, QueryParams>): DataTableColumn<RecordType> => ({
    accessor: 'count',
    title: '# of Affected Assets',
    render: ({ count }) => <NumberFormatter value={count} thousandSeparator />,
    ...column
});
