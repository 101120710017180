import { DataTableColumn } from 'mantine-datatable';
import { Asset, AssetQueryParams } from 'entities/Asset';
import { BaseColumnProps } from 'widgets/Columns/baseColumn';
import React from 'react';
import { DateColumn } from 'shared/ui/DataTable';

type RecordType = Asset;
type QueryParams = AssetQueryParams;

export default ({
    column
}: BaseColumnProps<RecordType, QueryParams>): DataTableColumn<RecordType> => ({
    width: 170,
    accessor: 'metadata.sslCertificate.notValidBefore',
    title: 'Valid From',
    render: ({ metadata }) => (
        <DateColumn date={metadata?.sslCertificate.notValidBefore} />
    ),
    ...column
});
