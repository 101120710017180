import React, { memo, Suspense } from 'react';
import classNames from 'classnames';
import { Box } from '@mantine/core';
import styles from './Icon.module.scss';

/*
 * Icons
 * Source: https://fonts.google.com/icons
 * Save to: src/shared/assets/icons as SVG
 * Usage: <Icon icon={Icons.HOME} size={IconSize.MEDIUM} />
 * Params:
 * - Fill: 0
 * - Weight: 300
 * - Grade: 0
 * - Optical Size: 48px
 */

export enum Icons {
    HOME = 'home.svg',
    DASHBOARD = 'dashboard.svg',
    ACCOUNT_TREE = 'account_tree.svg',
    HISTORY = 'history.svg',
    HUB = 'hub.svg',
    LANGUAGE = 'language.svg',
    LAYERS = 'layers.svg',
    SCHEMA = 'schema.svg',
    WARNING = 'warning.svg',
    WIFI_FIND = 'wifi_find.svg',
    CHEVRON_LEFT = 'chevron_left.svg',
    CHEVRON_RIGHT = 'chevron_right.svg',
    LOCATION_ON = 'location_on.svg',
    SHIELD_LOCK = 'shield_lock.svg',
    ALTERNATE_EMAIL = 'alternate_email.svg',
    SETTINGS_INPUT_SVIDEO = 'settings_input_svideo.svg',
    DNS = 'dns.svg',
    FIRST_PAGE = 'first_page.svg',
    LAST_PAGE = 'last_page.svg',
    BLOCK = 'block.svg',
    PENDING = 'pending.svg',
    VERIFIED = 'verified.svg',
    DONE = 'done.svg',
    KEYBOARD_ARROW_DOWN = 'keyboard_arrow_down.svg',
    KEYBOARD_ARROW_UP = 'keyboard_arrow_up.svg',
    UNFOLD_MORE = 'unfold_more.svg',
    ADD = 'add.svg',
    REMOVE = 'remove.svg',
    CLOSE = 'close.svg',
    OPEN_IN_FULL = 'open_in_full.svg',
    LIGHT_BULB = 'lightbulb.svg',
    PHOTO_CAMERA = 'photo_camera.svg',
    VERIFIED_USER = 'verified_user.svg',
    FULLSCREEN = 'fullscreen.svg',
    FULLSCREEN_EXIT = 'fullscreen_exit.svg',
    OPEN_IN_NEW = 'open_in_new.svg',
    SETTINGS = 'settings.svg',
    LOGOUT = 'logout.svg',
    CONTENT_COPY = 'content_copy.svg',
    SHARE = 'share.svg',
    LOCK = 'lock.svg',
    LOCK_OPEN = 'lock_open.svg',
    APARTMENT = 'apartment.svg',
    CHAT = 'chat.svg',
    FINGERPRINT = 'fingerprint.svg',
    EXPAND_MORE = 'expand_more.svg',
    CLOUD = 'cloud.svg',
    TAG = 'tag.svg',
    SEARCH = 'search.svg',
    MANUFACTURING = 'manufacturing.svg',
    TRENDING_UP = 'trending_up.svg',
    TRENDING_DOWN = 'trending_down.svg',
    TRENDING_FLAT = 'trending_flat.svg',
    HELP = 'help.svg',
    INFO = 'info.svg',
    DOWNLOAD = 'download.svg',
    ROCKET_LAUNCH = 'rocket_launch.svg',
    MORE_VERT = 'more_vert.svg',
    DELETE = 'delete.svg',
    EXTENSION = 'extension.svg',
    PERSON = 'person.svg',
    INVENTORY = 'inventory.svg',
    COMMENT = 'comment.svg',
    BUILD = 'build.svg',
    VITAL_SIGNS = 'vital_signs.svg',
    POLICY = 'policy.svg',
    RULE = 'rule.svg',
    SLACK = 'slack.svg',
    EDIT = 'edit.svg',
    CHECK_BOX = 'check_box.svg',
    CHECK_BOX_OUTLINE_BLANK = 'check_box_outline_blank.svg'
}
export enum IconSize {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large'
}

const IconSizeMap = {
    [IconSize.SMALL]: {
        width: 18,
        height: 18
    },
    [IconSize.MEDIUM]: {
        width: 24,
        height: 24
    },
    [IconSize.LARGE]: {
        width: 32,
        height: 32
    }
};

interface IconComponentProps {
    className?: string;
    icon: Icons;
    size?: IconSize;
    clickable?: boolean;
    hoverable?: boolean;
    disabled?: boolean;
    style?: React.CSSProperties;
}

const IconMap = Object.values(Icons).reduce((acc, icon) => {
    acc[icon] = React.lazy(() => import(`shared/assets/icons/${icon}`));
    return acc;
}, {} as Record<Icons, React.FC<React.SVGProps<SVGSVGElement>>>);

const Icon = (props: IconComponentProps) => {
    const {
        className,
        icon,
        size = IconSize.SMALL,
        clickable = false,
        hoverable = false,
        disabled = false,
        style
    } = props;
    const classes = classNames(styles.Icon, className, {
        [styles.clickable]: clickable,
        [styles.hoverable]: hoverable,
        [styles.disabled]: disabled
    });
    const { width, height } = IconSizeMap[size];
    const IconComponent = IconMap[icon];
    return (
        <Suspense fallback="">
            <Box miw={width} mih={height}>
                <IconComponent
                    className={classes}
                    width={width}
                    height={height}
                    style={style}
                />
            </Box>
        </Suspense>
    );
};

export default memo(Icon);
