import { ACCOUNT_API_TAGS, accountApi } from 'shared/api/rtkApi';
import { ApiResponse } from 'entities/NAF';
import { Subscription } from '../../model/types/subscription';

const subscriptionApi = accountApi.injectEndpoints({
    endpoints: builder => ({
        getSubscriptions: builder.query<Subscription[] | undefined, void>({
            query: () => ({
                url: '/v2/subscription',
                method: 'GET',
                params: {
                    page: 1,
                    per_page: 100,
                    product_id: __NAF_PRODUCT_ID__
                }
            }),
            transformResponse: (response: ApiResponse<Subscription>) =>
                response.data,
            providesTags: (subscriptions, error, arg) =>
                subscriptions
                    ? [
                          ...subscriptions.map(({ subscription_id }) => ({
                              type: ACCOUNT_API_TAGS.SUBSCRIPTION,
                              id: subscription_id
                          })),
                          ACCOUNT_API_TAGS.SUBSCRIPTION
                      ]
                    : [ACCOUNT_API_TAGS.SUBSCRIPTION]
        }),
        getTrialSubscription: builder.query<Subscription | undefined, void>({
            query: () => ({
                url: '/v2/subscription',
                method: 'GET',
                params: {
                    page: 1,
                    per_page: 100,
                    product_id: __NAF_PRODUCT_ID__
                }
            }),
            transformResponse: (response: ApiResponse<Subscription>) =>
                response.data.find(subscription => subscription.trial > 0),
            providesTags: [ACCOUNT_API_TAGS.SUBSCRIPTION]
        }),
        getActiveSubscription: builder.query<Subscription | undefined, void>({
            query: () => ({
                url: '/v2/subscription',
                method: 'GET',
                params: {
                    page: 1,
                    per_page: 100,
                    product_id: __NAF_PRODUCT_ID__
                }
            }),
            transformResponse: (response: ApiResponse<Subscription>) =>
                response.data.find(
                    subscription => subscription.state.color === 'green'
                ),
            providesTags: [ACCOUNT_API_TAGS.SUBSCRIPTION]
        }),
        getBeingVerifiedSubscription: builder.query<
            Subscription | undefined,
            void
        >({
            query: () => ({
                url: '/v2/subscription',
                method: 'GET',
                params: {
                    page: 1,
                    per_page: 100,
                    product_id: __NAF_PRODUCT_ID__
                }
            }),
            transformResponse: (response: ApiResponse<Subscription>) =>
                response.data.find(
                    subscription => subscription.state.color === 'yellow'
                ),
            providesTags: [ACCOUNT_API_TAGS.SUBSCRIPTION]
        })
    })
});

export const {
    useGetSubscriptionsQuery,
    useGetActiveSubscriptionQuery,
    useGetTrialSubscriptionQuery,
    useGetBeingVerifiedSubscriptionQuery
} = subscriptionApi;
