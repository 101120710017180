import React, { useMemo } from 'react';
import { Button, Modal, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useJiraSettings } from 'entities/Integrations';
import AuthAppForm from '../AuthAppForm/AuthAppForm';
import ConnectToJiraProjectForm from '../ConnectToJiraProjectForm/ConnectToJiraProjectForm';

const ControlJiraSettings = () => {
    const [opened, { open, close }] = useDisclosure(false);
    const { jiraSettings, hasJiraSettings, connectToJiraProjectAvailable } =
        useJiraSettings();

    return (
        <>
            <Modal
                size="lg"
                title="Configure JIRA integration"
                opened={opened}
                onClose={close}
            >
                <Stack gap="lg">
                    <AuthAppForm
                        jiraSettings={jiraSettings}
                        onCancelled={close}
                    />
                    {connectToJiraProjectAvailable && (
                        <ConnectToJiraProjectForm
                            jiraSettings={jiraSettings}
                            onConnected={close}
                        />
                    )}
                </Stack>
            </Modal>

            {hasJiraSettings ? (
                <Button variant="light" color="green" onClick={open}>
                    Configured
                </Button>
            ) : (
                <Button variant="light" color="blue" onClick={open}>
                    Configure
                </Button>
            )}
        </>
    );
};

export default ControlJiraSettings;
