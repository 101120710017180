import React, { memo } from 'react';
import { ExternalLink } from 'shared/ui/ExternalLink';
import HeaderedCard from 'shared/ui/HeaderedCard/HeaderedCard';
import { Text } from '@mantine/core';
import { Misconfiguration } from 'entities/Misconfiguration';
import { Vulnerability } from 'entities/Vulnerability';
import { Issue } from '../../model/types/issue';

interface IssueDescriptionProps {
    issue: Issue | Vulnerability | Misconfiguration;
}

const IssueDescription = (props: IssueDescriptionProps) => {
    const { issue } = props;
    const { description, references } = issue;

    function renderDescription() {
        if (description) {
            return (
                <HeaderedCard header="Summary">
                    <Text size="sm">{description}</Text>
                </HeaderedCard>
            );
        }

        if (references) {
            return (
                <HeaderedCard header="Summary">
                    Read more at{' '}
                    <ExternalLink href={references[0]}>
                        {references[0]}
                    </ExternalLink>
                </HeaderedCard>
            );
        }

        return null;
    }

    return renderDescription();
};

export default memo(IssueDescription);
