import React, { memo } from 'react';
import { Page } from 'shared/ui/Page';
import classNames from 'classnames';
import { useGetRootAsset } from 'entities/Asset';
import { AssetDetailsCard, AssetTabs } from 'widgets/Asset';
import AssetDetailsProvider from 'pages/Assets/children/AssetDetails/ui/AssetDetailsProvider';
import styles from './RootAssetDetailsPage.module.scss';

interface RootAssetDetailsPageProps {
    className?: string;
    id: string;
}

function RootAssetDetailsPage(props: RootAssetDetailsPageProps) {
    const { className, id } = props;
    const classes = classNames(styles.RootAssetDetailsPage, className);
    const { data: asset, isLoading } = useGetRootAsset(id);

    if (isLoading) {
        return (
            <Page className={classes}>
                <div>Loading...</div>
            </Page>
        );
    }

    if (!asset) {
        return (
            <Page className={classes}>
                <div>Asset not found</div>
            </Page>
        );
    }

    return (
        <AssetDetailsProvider>
            <Page className={classes}>
                <AssetDetailsCard asset={asset} />
                <AssetTabs asset={asset} />
            </Page>
        </AssetDetailsProvider>
    );
}

export default memo(RootAssetDetailsPage);
