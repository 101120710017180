import { useCallback, useContext, useEffect } from 'react';
import { useGetSubscriptionsQuery, Subscription } from 'entities/Billing';
import { SubscriptionStatus, InitContext } from '../InitContext';

interface UseInitSubscriptionStatus {
    loading: boolean;
}

function isAddedByAdmin(subscriptions?: Subscription[]) {
    return subscriptions?.some(
        subscription =>
            subscription.price === 0 && subscription.price_desc === 'Custom'
    );
}

function isTrial(subscriptions?: Subscription[]) {
    return subscriptions?.some(subscription => subscription.trial > 0);
}

function isPaid(subscriptions?: Subscription[]) {
    return subscriptions?.some(subscription => subscription.price > 0);
}

function isSubscriptionActive(subscriptions?: Subscription[]) {
    return subscriptions?.some(
        subscription =>
            !subscription.archived && subscription.state.state === 'Active'
    );
}

function isSubscriptionExpired(subscriptions?: Subscription[]) {
    return subscriptions?.some(subscription => subscription.archived);
}

function isSubscriptionCanceled(subscriptions?: Subscription[]) {
    return subscriptions?.some(subscription => subscription.cancelled);
}

function isSubscriptionLimitReached(subscriptions?: Subscription[]) {
    return subscriptions?.some(
        subscription =>
            !subscription.archived && subscription.state.state === 'Active'
    );
}

function useInitSubscriptionStatus(): UseInitSubscriptionStatus {
    const {
        hasUser,
        subscriptionStatus,
        setSubscriptionStatus,
        setSubscription
    } = useContext(InitContext);
    const { data: subscriptions, isLoading: subscriptionsLoading } =
        useGetSubscriptionsQuery(undefined, {
            pollingInterval: 60 * 1000,
            skip: !hasUser
        });

    const newSubscriptionStatus = useCallback(
        (status: SubscriptionStatus) => {
            if (subscriptionStatus?.includes(status)) return;
            if (subscriptionStatus?.includes(SubscriptionStatus.UNKNOWN)) {
                setSubscriptionStatus([status]);
                return;
            }

            setSubscriptionStatus([...(subscriptionStatus ?? []), status]);
        },
        [setSubscriptionStatus, subscriptionStatus]
    );

    useEffect(() => {
        if (subscriptions) {
            setSubscription(subscriptions[0]);
        }
    }, [subscriptions, setSubscription]);

    useEffect(() => {
        switch (true) {
            case isAddedByAdmin(subscriptions):
                newSubscriptionStatus(SubscriptionStatus.IS_ADDED_BY_ADMIN);
                break;
            case isPaid(subscriptions):
                newSubscriptionStatus(SubscriptionStatus.IS_PAID);
                break;
            case isTrial(subscriptions):
                newSubscriptionStatus(SubscriptionStatus.IS_TRIAL);
                break;
            case subscriptionsLoading:
            case subscriptions === undefined:
            default:
                newSubscriptionStatus(SubscriptionStatus.UNKNOWN);
                break;
        }
    }, [newSubscriptionStatus, subscriptions, subscriptionsLoading]);

    useEffect(() => {
        switch (true) {
            case isSubscriptionActive(subscriptions):
                newSubscriptionStatus(SubscriptionStatus.SUBSCRIPTION_ACTIVE);
                break;
            case isSubscriptionExpired(subscriptions):
                newSubscriptionStatus(SubscriptionStatus.SUBSCRIPTION_EXPIRED);
                break;
            case isSubscriptionCanceled(subscriptions):
                newSubscriptionStatus(SubscriptionStatus.SUBSCRIPTION_CANCELED);
                break;
            case isSubscriptionLimitReached(subscriptions):
                newSubscriptionStatus(
                    SubscriptionStatus.SUBSCRIPTION_LIMIT_REACHED
                );
                break;
            case subscriptionsLoading:
            case subscriptions === undefined:
            default:
                newSubscriptionStatus(SubscriptionStatus.UNKNOWN);
                break;
        }
    }, [newSubscriptionStatus, subscriptions, subscriptionsLoading]);

    return {
        loading: subscriptionsLoading
    };
}

export default useInitSubscriptionStatus;
