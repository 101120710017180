import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootAssetCandidate } from 'entities/Asset';
import { DeclineAssetCandidateSchema } from '../../types/declineAssetCandidateSchema';

const initialState: DeclineAssetCandidateSchema = {
    assets: []
};

export const declineAssetCandidateSlice = createSlice({
    name: 'declineAssetCandidate',
    initialState,
    reducers: {
        reset: () => initialState,
        setAssets: (state, action: PayloadAction<RootAssetCandidate[]>) => {
            state.assets = action.payload;
        }
    }
});

export const { actions: declineAssetCandidateActions } =
    declineAssetCandidateSlice;
export const { reducer: declineAssetCandidateReducer } =
    declineAssetCandidateSlice;
