import React, { memo, useMemo } from 'react';
import { MultiSelect, MultiSelectProps } from '@mantine/core';
import { Icon, Icons } from 'shared/ui/Icon';
import { useGetRootAssetNames } from 'entities/Dictionary';

export interface FilterByRootAssetFormProps extends MultiSelectProps {
    selectedAssets?: string[];
    onChange?: (value: string[]) => void;
}

const FilterByRootAssetForm = (props: FilterByRootAssetFormProps) => {
    const { selectedAssets, onChange, ...selectProps } = props;
    const { rootAssets, loading } = useGetRootAssetNames();

    const options = useMemo(
        () =>
            rootAssets
                ?.map(asset => ({
                    value: asset.id,
                    label: asset.value
                }))
                .filter(
                    (option, index, self) =>
                        index === self.findIndex(t => t.value === option.value)
                ) ?? [],
        [rootAssets]
    );

    return (
        <MultiSelect
            clearable
            searchable
            label="Root Assets"
            description="Show all assets belonging to the selected root assets."
            data={options}
            value={!loading ? selectedAssets : ['Loading...']}
            disabled={loading}
            leftSection={<Icon icon={Icons.SEARCH} />}
            comboboxProps={{ withinPortal: !options?.length }}
            onChange={onChange}
            style={{ maxWidth: 600 }}
            {...selectProps}
        />
    );
};

export default memo(FilterByRootAssetForm);
