import { API_TAGS, rtkApi } from 'shared/api/rtkApi';
import {
    JiraSettings,
    JiraProject,
    TestJiraIssue,
    JiraSettingsCreateBody,
    JiraSettingsUpdateBody
} from '../../model/types/jira';

const jiraApi = rtkApi.injectEndpoints({
    endpoints: builder => ({
        getJiraSettings: builder.query<JiraSettings, void>({
            queryFn: async (_, api, extraOptions, baseQuery) => {
                try {
                    const result = await baseQuery('/jira-settings');

                    if ('error' in result) {
                        return {
                            data: null
                        };
                    }

                    return result;
                } catch (error) {
                    return {
                        data: null
                    };
                }
            },
            providesTags: [API_TAGS.JIRA_SETTINGS]
        }),
        getJiraProjects: builder.query<JiraProject[], void>({
            query: () => '/jira-settings/projects',
            transformResponse: (response: DataResponse<JiraProject[]>) =>
                response.data,
            providesTags: [API_TAGS.JIRA_PROJECTS]
        }),
        createJiraSettings: builder.mutation<
            JiraSettings,
            JiraSettingsCreateBody
        >({
            query: body => ({
                url: '/jira-settings',
                method: 'POST',
                body
            }),
            invalidatesTags: [API_TAGS.JIRA_SETTINGS, API_TAGS.JIRA_PROJECTS]
        }),
        updateJiraSettings: builder.mutation<
            JiraSettings,
            JiraSettingsUpdateBody
        >({
            query: body => ({
                url: `/jira-settings`,
                method: 'PATCH',
                body
            }),
            invalidatesTags: [API_TAGS.JIRA_SETTINGS, API_TAGS.JIRA_PROJECTS]
        }),
        deleteJiraSettings: builder.mutation<void, void>({
            query: () => ({
                url: '/jira-settings',
                method: 'DELETE'
            }),
            invalidatesTags: [API_TAGS.JIRA_SETTINGS, API_TAGS.JIRA_PROJECTS]
        }),
        postTestIssue: builder.mutation<TestJiraIssue, void>({
            query: () => ({
                url: '/jira-settings/test-issue',
                method: 'POST'
            })
        })
    })
});

export const {
    useGetJiraSettingsQuery,
    useGetJiraProjectsQuery,
    useCreateJiraSettingsMutation,
    useUpdateJiraSettingsMutation,
    useDeleteJiraSettingsMutation,
    usePostTestIssueMutation
} = jiraApi;
