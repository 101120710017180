import { RootAssetName } from 'entities/Asset';
import { useGetRootAssetNamesQuery } from 'entities/Dictionary';

interface UseGetAllRootAssetNames {
    rootAssets?: RootAssetName[];
    loading: boolean;
}

export function useGetRootAssetNames(): UseGetAllRootAssetNames {
    const { data, isLoading } = useGetRootAssetNamesQuery({
        size: 1000
    });

    return {
        rootAssets: data?.data,
        loading: isLoading
    };
}
