import React, { memo, useEffect } from 'react';
import {
    NotificationChannel,
    NotificationConfigUpdateBody
} from 'entities/Notifications/model/types/notification';
import {
    useGetNotificationConfigQuery,
    useUpdateNotificationConfigMutation
} from 'entities/Notifications/api/notificationsApi/notificationsApi';
import {
    Box,
    Group,
    SegmentedControl,
    Stack,
    Switch,
    Text
} from '@mantine/core';
import HeaderedCard from 'shared/ui/HeaderedCard/HeaderedCard';
import { useForm } from '@mantine/form';
import { showNotification, notifications } from '@mantine/notifications';
import dayjs from 'dayjs';
import { severityRatingColor } from 'entities/Issue';
import { Icon, Icons, IconSize } from 'shared/ui/Icon';

interface NotificationSettingsProps {
    channel: NotificationChannel;
    title: string;
    icon?: Icons;
}

const NotificationSettings = (props: NotificationSettingsProps) => {
    const { channel, title, icon } = props;
    const { data: config, isLoading } = useGetNotificationConfigQuery(channel);
    const [updateConfig] = useUpdateNotificationConfigMutation();
    const form = useForm<NotificationConfigUpdateBody>();

    useEffect(() => {
        if (config && !form.isDirty()) {
            form.setValues(config);
        }
    }, [config, form.isDirty]);

    useEffect(() => {
        if (form.isTouched()) {
            notifications.clean();
            updateConfig({ channel, ...form.values })
                .unwrap()
                .then(() => {
                    showNotification({
                        message: 'Notification settings updated',
                        color: 'green'
                    });
                })
                .catch(() => {
                    showNotification({
                        message: 'Failed to update notification settings',
                        color: 'red'
                    });
                });
        }
    }, [channel, form.values]);

    return (
        <HeaderedCard
            header={title}
            headerIcon={icon && <Icon icon={icon} size={IconSize.MEDIUM} />}
            actions={
                <Group justify="space-between">
                    <Switch
                        size="md"
                        color="teal"
                        onLabel="ON"
                        offLabel="OFF"
                        {...form.getInputProps('enabled', { type: 'checkbox' })}
                    />
                </Group>
            }
        >
            <Stack>
                <Group justify="space-between">
                    <Box>
                        <Text>Issues notifications</Text>
                        <Text size="xs" c="dimmed">
                            Receive notifications about discovered issues
                        </Text>
                    </Box>
                    <Switch
                        size="md"
                        color="teal"
                        onLabel="ON"
                        offLabel="OFF"
                        disabled={!form?.values.enabled}
                        {...form.getInputProps('vulns', {
                            type: 'checkbox'
                        })}
                    />
                </Group>
                <Group justify="space-between">
                    <Box>
                        <Text>Minimum severity to notify</Text>
                        <Text size="xs" c="dimmed">
                            Receive notifications only for issues with severity
                            rating equal or higher than selected
                        </Text>
                    </Box>
                    <SegmentedControl
                        transitionDuration={0}
                        size="sm"
                        data={[
                            { value: 'info', label: 'Info' },
                            { value: 'low', label: 'Low' },
                            { value: 'medium', label: 'Medium' },
                            { value: 'high', label: 'High' },
                            { value: 'critical', label: 'Critical' }
                        ]}
                        color={severityRatingColor(form.values.minVulnRating)}
                        {...form.getInputProps('minVulnRating')}
                    />
                </Group>
            </Stack>
        </HeaderedCard>
    );
};

export default memo(NotificationSettings);
