import { AdjacencyType, AdjacencyTypeLabel } from 'entities/Asset';

export function parseDiscoveryMethods(discoveryMethods: AdjacencyType[]) {
    return discoveryMethods.map(method => ({
        id: method,
        name:
            AdjacencyTypeLabel[
                method.toUpperCase() as keyof typeof AdjacencyTypeLabel
            ] || method
    }));
}
