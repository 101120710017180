import React, { memo } from 'react';
import { ExternalLink } from 'shared/ui/ExternalLink';
import HeaderedCard from 'shared/ui/HeaderedCard/HeaderedCard';
import { List, Text } from '@mantine/core';
import { Vulnerability } from 'entities/Vulnerability';
import { Misconfiguration } from 'entities/Misconfiguration';
import { Issue } from '../../model/types/issue';

interface IssueReferencesProps {
    issue: Issue | Vulnerability | Misconfiguration;
}

const IssueReferences = (props: IssueReferencesProps) => {
    const { issue } = props;
    const { references } = issue;

    if (!references) return null;

    return (
        <HeaderedCard header="References">
            <List>
                {references.map(ref => (
                    <List.Item key={ref}>
                        <ExternalLink href={ref} hasIcon={false}>
                            <Text size="sm">{ref}</Text>
                        </ExternalLink>
                    </List.Item>
                ))}
            </List>
        </HeaderedCard>
    );
};

export default memo(IssueReferences);
