import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Asset, RootAsset, RootAssetCandidate } from 'entities/Asset';
import { EntityTagSchema } from '../../types/entityTagSchema';

const initialState: EntityTagSchema = {
    entity: null
};

type Entity = Asset | RootAsset | RootAssetCandidate;

export const entityTagSlice = createSlice({
    name: 'entityTag',
    initialState,
    reducers: {
        reset: () => initialState,
        setEntity: (state, action: PayloadAction<Entity>) => {
            state.entity = action.payload;
        }
    }
});

export const { actions: entityTagActions } = entityTagSlice;
export const { reducer: entityTagReducer } = entityTagSlice;
