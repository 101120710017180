import React, { memo } from 'react';
import { Button, ButtonProps } from '@mantine/core';
import { useDownloadQueryAsFileMutation } from 'entities/Download';
import { showNotification } from '@mantine/notifications';

interface DownloadQueryButtonProps extends ButtonProps {
    path: string;
}

const DownloadQueryButton = (props: DownloadQueryButtonProps) => {
    const { path, ...buttonProps } = props;
    const [download, { isLoading }] = useDownloadQueryAsFileMutation();
    const onDownload = () => {
        download({
            // @ts-ignore
            path,
            name: 'test'
        })
            .then(_ => {
                showNotification({
                    title: 'Downloading...',
                    message: 'Downloading file has started.',
                    color: 'green'
                });
            })
            .catch(_ => {
                showNotification({
                    title: 'Error',
                    message: 'Error downloading file.',
                    color: 'red'
                });
            });
    };

    return (
        <Button
            color="asm"
            onClick={onDownload}
            loading={isLoading}
            {...buttonProps}
        >
            Export CSV
        </Button>
    );
};

export default memo(DownloadQueryButton);
