import React, { memo } from 'react';
import HeaderedCard from 'shared/ui/HeaderedCard/HeaderedCard';
import ViewAllLink from 'shared/ui/Links/ViewAllLink/ViewAllLink';
import { RoutePath } from 'shared/config/routeConfig';
import TechnologiesFeedTable from '../../Tables/TechnologiesFeedTable/TechnologiesFeedTable';

const NewTechnologiesCard = () => (
    <HeaderedCard
        header="New technologies"
        bodyPadding="0"
        actions={<ViewAllLink to={RoutePath.technologies} />}
    >
        <TechnologiesFeedTable />
    </HeaderedCard>
);

export default memo(NewTechnologiesCard);
