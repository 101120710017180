import { useEffect, useState } from 'react';

export const useIpAddress = () => {
    const [ipAddress, setIpAddress] = useState<string>();

    useEffect(() => {
        const getIpAddress = async () => {
            const response = await fetch('https://api.ipify.org?format=json');
            const { ip } = await response.json();
            return ip;
        };

        getIpAddress().then(setIpAddress);
    }, []);

    return ipAddress;
};
