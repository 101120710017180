import React, { memo } from 'react';
import { VulnerabilitiesTable } from 'widgets/Issue';
import { Box } from '@mantine/core';
import { Seo } from 'shared/ui/SEO/SEO';
import { useIssueSearchParams } from 'entities/Issue';
import IssuesBaseLayout from '../../../ui/IssuesBaseLayout/IssuesBaseLayout';

function FalsePositiveIssuesPage() {
    const { rating, lastSeenPreset, nameExact, createdAtGte, createdAtLte } =
        useIssueSearchParams();

    return (
        <IssuesBaseLayout>
            <Seo title="False positive | Issues" />
            <Box style={{ maxHeight: 'calc(100% - 120px)' }}>
                <VulnerabilitiesTable
                    defaultStatus="fp"
                    defaultRating={rating}
                    defaultVulnerabilityNameIncludes={nameExact}
                    defaultLastSeenPreset={lastSeenPreset}
                    defaultCreatedAtGte={createdAtGte}
                    defaultCreatedAtLte={createdAtLte}
                />
            </Box>
        </IssuesBaseLayout>
    );
}

export default memo(FalsePositiveIssuesPage);
