import React, { memo, useMemo } from 'react';
import {
    Rating,
    Issue,
    IssueUnique,
    severityRatingColor
} from 'entities/Issue';
import { Badge, BadgeProps, Button } from '@mantine/core';
import { Misconfiguration } from 'entities/Misconfiguration';
import { Vulnerability } from 'entities/Vulnerability';

interface RiskBadgeProps extends BadgeProps {
    issue: Issue | IssueUnique | Vulnerability | Misconfiguration;
    selectedSeverityRatings?: Rating[];
    setSelectedSeverityRatings?: (severityRatings: Rating[]) => void;
}

const RiskBadge = (props: RiskBadgeProps) => {
    const {
        issue,
        fullWidth = false,
        selectedSeverityRatings = [],
        setSelectedSeverityRatings
    } = props;
    const { severity } = issue;
    const clickable = setSelectedSeverityRatings !== undefined;

    const color = useMemo(
        () => severityRatingColor(severity?.rating),
        [severity?.rating]
    );

    if (!severity) return null;

    if (clickable) {
        return (
            <Button
                size="compact-xs"
                color={color}
                fw={600}
                fullWidth
                onClick={() => {
                    if (severity?.rating === undefined) return;
                    const assetTypeSelected = selectedSeverityRatings.includes(
                        severity?.rating
                    );
                    if (!assetTypeSelected)
                        setSelectedSeverityRatings([
                            ...selectedSeverityRatings,
                            severity?.rating
                        ]);
                }}
            >
                {severity?.rating.toUpperCase() || 'Uncertain'}
                {severity?.score && ` (${severity.score.toFixed(1)})`}
            </Button>
        );
    }

    return (
        <Badge fullWidth={fullWidth} color={color}>
            {severity?.rating || 'Uncertain'}
            {severity?.score && ` (${severity.score.toFixed(1)})`}
        </Badge>
    );
};

export default memo(RiskBadge);
