import React, { memo, useMemo } from 'react';
import { Dns } from 'entities/Asset';
import { DataTable, DateColumn } from 'shared/ui/DataTable';
import { DataTableColumn } from 'mantine-datatable';

interface DnsTableProps {
    dns: Dns[];
}

const DnsTable = (props: DnsTableProps) => {
    const { dns } = props;
    const columns = useMemo<DataTableColumn<Dns>[]>(
        () => [
            {
                accessor: 'name',
                header: 'Name'
            },
            {
                accessor: 'type',
                header: 'Type'
            },
            {
                accessor: 'content',
                header: 'Content'
            },
            {
                accessor: 'ttl',
                header: 'TTL'
            },
            {
                accessor: 'lastSeenAt',
                header: 'Last Seen',
                render: ({ lastSeenAt }) => (
                    <DateColumn date={lastSeenAt} timeAgo />
                )
            }
        ],
        []
    );

    return (
        <DataTable
            idAccessor="name"
            defaultColumnProps={{
                ellipsis: true
            }}
            records={dns ?? []}
            columns={columns}
        />
    );
};

export default memo(DnsTable);
