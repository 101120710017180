import { useContext, useState } from 'react';
import { useInitialEffect } from 'shared/hooks/useInitialEffect/useInitialEffect';
import { initUser } from 'entities/User';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { InitContext } from '../InitContext';

interface UseInitUser {
    loading: boolean;
}

function useInitUser(): UseInitUser {
    const { setHasUser } = useContext(InitContext);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(true);

    useInitialEffect(() => {
        dispatch(initUser())
            .unwrap()
            .then(result => {
                setTimeout(() => {
                    setLoading(false);
                    setHasUser(Boolean(result));
                }, 1500);
            });
    }, []);

    return {
        loading
    };
}

export default useInitUser;
