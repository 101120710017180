import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { RoutePath } from 'shared/config/routeConfig';

export function archivedAssetDetailsRoute(assetId: string) {
    return `${RoutePath.management}/archived-assets/${assetId}`;
}

export function useNavigateToArchivedAssetDetails() {
    const navigate = useNavigate();

    return useCallback(
        (assetId: string) => {
            navigate(archivedAssetDetailsRoute(assetId));
        },
        [navigate]
    );
}
