import React, { memo, useMemo } from 'react';
import { AssetType, AssetTypeLabel } from 'entities/Asset';
import { MultiSelect } from '@mantine/core';
import { Icon, Icons } from 'shared/ui/Icon';

export interface FilterByAssetTypeFormProps {
    selectedAssetTypes?: AssetType[];
    onChange?: (value: AssetType[]) => void;
}

const FilterByAssetTypeForm = (props: FilterByAssetTypeFormProps) => {
    const { selectedAssetTypes, onChange } = props;
    const ASSET_TYPE_OPTIONS = useMemo(
        () =>
            Object.entries(AssetType).map(([key, type]) => ({
                value: type,
                label: AssetTypeLabel[key as keyof typeof AssetType]
            })),
        []
    );

    return (
        <MultiSelect
            label="Asset type"
            description="Show all assets belonging to the selected types."
            data={ASSET_TYPE_OPTIONS}
            value={selectedAssetTypes}
            onChange={(value: string[]) => onChange?.(value as AssetType[])}
            leftSection={<Icon icon={Icons.SEARCH} />}
            clearable
            searchable
            comboboxProps={{ withinPortal: !ASSET_TYPE_OPTIONS?.length }}
            style={{ maxWidth: 600 }}
        />
    );
};

export default memo(FilterByAssetTypeForm);
