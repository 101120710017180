import React, { memo } from 'react';
import classNames from 'classnames';
import { Text } from 'shared/ui/Text';
import { Card } from 'shared/ui/Card';
import { dateFormat } from 'shared/lib/formatter/formatter';
import {
    SubnetworkContact,
    SubnetworkMaintainer
} from 'entities/Asset/model/types/metadata';
import styles from './AssetSubnetworks.module.scss';
import { Asset, RootAsset, RootAssetCandidate } from '../../model/types/asset';

interface AssetSubnetworksProps {
    className?: string;
    asset?: Asset | RootAsset | RootAssetCandidate;
}

const AssetSubnetworksRow = ({
    label,
    value
}: {
    label: string;
    value: string | string[] | number | number[];
}) => {
    if (!value) return null;

    const normalizedValue = Array.isArray(value) ? value.join('\n') : value;
    return (
        <div className={styles.AssetSubnetworks__Item__Content__Row}>
            <Text color="secondary">{label}</Text>
            <div title={normalizedValue.toString()}>{normalizedValue}</div>
        </div>
    );
};

const AssetSubnetworkContactsColumn = ({
    contact,
    title
}: {
    contact: SubnetworkContact;
    title: string;
}) => (
    <div className={styles.AssetSubnetworks__Item__Column}>
        <Text size="m">{title}</Text>
        <div className={styles.AssetSubnetworks__Item__Content}>
            <AssetSubnetworksRow label="ID" value={contact.id} />
            <AssetSubnetworksRow label="Person" value={contact.person} />
            <AssetSubnetworksRow label="Role" value={contact.role} />
            <AssetSubnetworksRow label="Phone" value={contact.phone} />
            <AssetSubnetworksRow label="Email" value={contact.email} />
            <AssetSubnetworksRow label="Country" value={contact.country} />
            <AssetSubnetworksRow label="City" value={contact.city} />
            <AssetSubnetworksRow label="Address" value={contact.address} />
        </div>
    </div>
);

const AssetSubnetworkMaintainerColumn = ({
    maintainer,
    title
}: {
    maintainer: SubnetworkMaintainer;
    title: string;
}) => (
    <div className={styles.AssetSubnetworks__Item__Column}>
        <Text size="m">{title}</Text>
        <div className={styles.AssetSubnetworks__Item__Content}>
            <AssetSubnetworksRow label="ID" value={maintainer.mntner} />
            <AssetSubnetworksRow label="Email" value={maintainer.email} />
        </div>
    </div>
);

const AssetSubnetworks = (props: AssetSubnetworksProps) => {
    const { className, asset } = props;
    const classes = classNames(styles.AssetSubnetworks, className);

    if (!asset?.metadata?.subnetwork) return null;

    return (
        <div className={classes}>
            {asset.metadata.subnetwork.map((subnetwork, index) => (
                <Card
                    contentClassName={styles.AssetSubnetworks__Item}
                    header={`IP Range #${index + 1}`}
                    key={subnetwork.inetnum}
                >
                    <div className={styles.AssetSubnetworks__Item__Column}>
                        <Text size="m">Overview</Text>
                        <div className={styles.AssetSubnetworks__Item__Content}>
                            <AssetSubnetworksRow
                                label="Inetnum"
                                value={subnetwork.inetnum}
                            />
                            <AssetSubnetworksRow
                                label="Inetnum first"
                                value={subnetwork.inetnumFirstString}
                            />
                            <AssetSubnetworksRow
                                label="Inetnum last"
                                value={subnetwork.inetnumLastString}
                            />
                            <AssetSubnetworksRow
                                label="Parent"
                                value={subnetwork.parent}
                            />
                            <AssetSubnetworksRow
                                label="Source"
                                value={subnetwork.source}
                            />
                            <AssetSubnetworksRow
                                label="Netname"
                                value={subnetwork.netname}
                            />
                            <AssetSubnetworksRow
                                label="ARIN ID"
                                value={subnetwork.nethandle}
                            />
                            <AssetSubnetworksRow
                                label="Modified"
                                value={dateFormat(
                                    subnetwork.modified,
                                    'en-GB',
                                    {
                                        dateStyle: 'long'
                                    },
                                    false
                                )}
                            />
                            <AssetSubnetworksRow
                                label="Country"
                                value={subnetwork.country}
                            />
                            <AssetSubnetworksRow
                                label="City"
                                value={subnetwork.city}
                            />
                            <AssetSubnetworksRow
                                label="Address"
                                value={subnetwork.address}
                            />
                        </div>
                    </div>
                    {subnetwork.org && (
                        <div className={styles.AssetSubnetworks__Item__Column}>
                            <Text size="m">Organization</Text>
                            <div
                                className={
                                    styles.AssetSubnetworks__Item__Content
                                }
                            >
                                <AssetSubnetworksRow
                                    label="ID"
                                    value={subnetwork.org.org}
                                />
                                <AssetSubnetworksRow
                                    label="Name"
                                    value={subnetwork.org.name}
                                />
                                <AssetSubnetworksRow
                                    label="Email"
                                    value={subnetwork.org.email}
                                />
                                <AssetSubnetworksRow
                                    label="Phone"
                                    value={subnetwork.org.phone}
                                />
                                <AssetSubnetworksRow
                                    label="Country"
                                    value={subnetwork.org.country}
                                />
                                <AssetSubnetworksRow
                                    label="City"
                                    value={subnetwork.org.city}
                                />
                                <AssetSubnetworksRow
                                    label="Address"
                                    value={subnetwork.org.address}
                                />
                                <AssetSubnetworksRow
                                    label="Postal Code"
                                    value={subnetwork.org.postalCode}
                                />
                            </div>
                        </div>
                    )}
                    {subnetwork.as && (
                        <div className={styles.AssetSubnetworks__Item__Column}>
                            <Text size="m">Autonomous System</Text>
                            <div
                                className={
                                    styles.AssetSubnetworks__Item__Content
                                }
                            >
                                <AssetSubnetworksRow
                                    label="ASN"
                                    value={subnetwork.as.asn}
                                />
                                <AssetSubnetworksRow
                                    label="Name"
                                    value={subnetwork.as.name}
                                />
                                <AssetSubnetworksRow
                                    label="Route"
                                    value={subnetwork.as.route}
                                />
                                <AssetSubnetworksRow
                                    label="Domain"
                                    value={subnetwork.as.domain}
                                />
                                <AssetSubnetworksRow
                                    label="Type"
                                    value={subnetwork.as.type}
                                />
                            </div>
                        </div>
                    )}
                    {subnetwork?.abuseContact?.map(contact => (
                        <AssetSubnetworkContactsColumn
                            contact={contact}
                            key={contact.id}
                            title="Abuse Contact"
                        />
                    ))}
                    {subnetwork?.adminContact?.map(contact => (
                        <AssetSubnetworkContactsColumn
                            contact={contact}
                            key={contact.id}
                            title="Administrative Contacts"
                        />
                    ))}
                    {subnetwork?.techContact?.map(contact => (
                        <AssetSubnetworkContactsColumn
                            contact={contact}
                            key={contact.id}
                            title="Technical Contacts"
                        />
                    ))}
                    {subnetwork?.mntBy?.map(maintainer => (
                        <AssetSubnetworkMaintainerColumn
                            maintainer={maintainer}
                            key={maintainer.mntner}
                            title="General maintainer"
                        />
                    ))}
                    {subnetwork?.mntLower?.map(maintainer => (
                        <AssetSubnetworkMaintainerColumn
                            maintainer={maintainer}
                            key={maintainer.mntner}
                            title="Lower Maintainer"
                        />
                    ))}
                    {subnetwork?.mntDomains?.map(maintainer => (
                        <AssetSubnetworkMaintainerColumn
                            maintainer={maintainer}
                            key={maintainer.mntner}
                            title="Domains' Maintainer"
                        />
                    ))}
                    {subnetwork?.mntRoutes?.map(maintainer => (
                        <AssetSubnetworkMaintainerColumn
                            maintainer={maintainer}
                            key={maintainer.mntner}
                            title="Routes' Maintainer"
                        />
                    ))}
                </Card>
            ))}
        </div>
    );
};

export default memo(AssetSubnetworks);
