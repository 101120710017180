import React from 'react';
import { Navigate } from 'react-router';
import { RoutePath } from 'shared/config/routeConfig';
import { useLocation } from 'react-router-dom';
import { useUser } from 'entities/User';

interface AuthGuardProps {
    children: React.ReactNode;
}

function AuthGuard(props: AuthGuardProps) {
    const { children } = props;
    const { isAuthenticated } = useUser();
    const location = useLocation();

    if (!isAuthenticated) {
        return <Navigate to={RoutePath.login} state={{ from: location }} />;
    }

    return children;
}

export default AuthGuard;
