import React, { memo } from 'react';
import {
    useGetLastStatisticsByAssetType,
    useGetStatisticsByAssetType
} from 'entities/Statistics';
import { AssetType, AssetTypeIcon } from 'entities/Asset';
import { Icons } from 'shared/ui/Icon';
import dayjs from 'dayjs';
import StatisticsCard from 'shared/ui/StatisticsCard/StatisticsCard';

interface StatisticsByAssetTypeCardProps {
    title: string;
    assetType?: AssetType;
    icon: Icons | AssetTypeIcon;
    link?: string;
}

const StatisticsByAssetTypeCard = (props: StatisticsByAssetTypeCardProps) => {
    const { assetType, title, icon, link } = props;
    const startDate = dayjs().endOf('day').subtract(1, 'week').unix();
    const endDate = dayjs().unix();

    const { data: actualStatistics, isLoading: actualStatisticsLoading } =
        useGetLastStatisticsByAssetType({
            assetType
        });
    const { data: oldStatistics, isLoading: oldStatisticsLoading } =
        useGetStatisticsByAssetType({
            assetType,
            startDate,
            endDate
        });

    const actualTotal = actualStatistics ? actualStatistics.total : undefined;
    const oldTotal = oldStatistics ? oldStatistics[0].total : undefined;
    const diff = actualTotal && oldTotal && actualTotal - oldTotal;

    return (
        <StatisticsCard
            link={link}
            title={title}
            icon={icon as unknown as Icons}
            primaryValue={actualTotal}
            secondaryValue={diff}
            note="Compared to previous week"
            isLoading={actualStatisticsLoading || oldStatisticsLoading}
        />
    );
};

export default memo(StatisticsByAssetTypeCard);
