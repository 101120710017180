import { API_TAGS, rtkApi } from 'shared/api/rtkApi';
import { Asset, RootAsset } from 'entities/Asset';
import {
    AssetScannerConfig,
    GlobalScannerConfig,
    ScanMode
} from '../../model/types/config';

const scannerConfigApi = rtkApi.injectEndpoints({
    endpoints: builder => ({
        getScannerConfig: builder.query<GlobalScannerConfig, void>({
            query: () => ({
                url: '/scan-cfg/global'
            }),
            providesTags: [API_TAGS.SCANNER_CONFIG]
        }),
        updateScannerConfig: builder.mutation<
            GlobalScannerConfig,
            GlobalScannerConfig
        >({
            query: body => ({
                url: `/scan-cfg/global`,
                method: 'PUT',
                body
            }),
            invalidatesTags: [API_TAGS.SCANNER_CONFIG]
        }),
        updateAssetScannerConfig: builder.mutation<
            Asset | RootAsset,
            AssetScannerConfig
        >({
            query: ({ id, scanMode }) => ({
                url: `/assets/${id}`,
                method: 'PUT',
                body: {
                    scanMode
                }
            }),
            invalidatesTags: (_, __, { id }) => [
                { type: API_TAGS.ASSET, id },
                { type: API_TAGS.TAG }
            ]
        }),
        startRootAssetScanning: builder.mutation<Asset, string>({
            query: id => ({
                url: `/assets/${id}`,
                method: 'PUT',
                body: {
                    scanMode: ScanMode.ACTIVE
                }
            }),
            invalidatesTags: (_, __, id) => [
                { type: API_TAGS.ASSET, id },
                { type: API_TAGS.TAG }
            ]
        })
    })
});

export const {
    useGetScannerConfigQuery,
    useUpdateScannerConfigMutation,
    useUpdateAssetScannerConfigMutation,
    useStartRootAssetScanningMutation
} = scannerConfigApi;
