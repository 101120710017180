import React, { memo } from 'react';
import { Card, Center, Stack } from '@mantine/core';
import TotalScoreBarChart from '../../../Issue/Charts/TotalScoreBarChart/TotalScoreBarChart';
import BreakdownsByLastPeriod from '../../BreakdownsByLastPeriod/BreakdownsByLastPeriod';

const FindingsLastWeekCard = () => (
    <Card
        withBorder
        shadow="md"
        radius="md"
        p={{ lg: 'md', xl: 'lg' }}
        miw={{ lg: 310, xl: 360 }}
        maw={{ lg: 310, xl: 360 }}
    >
        <Stack>
            <Center>
                <TotalScoreBarChart />
            </Center>
            <BreakdownsByLastPeriod />
        </Stack>
    </Card>
);

export default memo(FindingsLastWeekCard);
