import React, { memo } from 'react';
import { Outlet } from 'react-router-dom';

interface AuthLayoutProps {
    children?: React.ReactNode;
}

const AuthLayout = (props: AuthLayoutProps) => {
    const { children } = props;

    return <main className="app">{children ?? <Outlet />}</main>;
};

export default memo(AuthLayout);
