import { useCallback } from 'react';
import {
    Asset,
    AssetType,
    RootAsset,
    RootAssetCandidate
} from 'entities/Asset';
import { Stack } from '@mantine/core';
import { DistributionBySeverityCard } from 'widgets/Issue';
import useIssueFilters from 'pages/Assets/children/AssetDetails/lib/useIssueFilters';
import { Rating } from 'entities/Issue';
import useTabs from 'pages/Assets/children/AssetDetails/lib/useTabs';
import EmailOverview from './EmailOverview';
import DomainOverview from './DomainOverview';
import IpAddressOverview from './IpAddressOverview';
import PortOverview from './PortOverview';
import SslOverview from './SslOverview';

interface AssetOverviewProps {
    asset: Asset | RootAsset | RootAssetCandidate;
}

const AssetOverview = (props: AssetOverviewProps) => {
    const { asset } = props;

    const { setActiveTab } = useTabs();
    const { setSelectedIssueRating } = useIssueFilters();

    const overviewTab = useCallback(() => {
        switch (asset.type) {
            case AssetType.DOMAIN:
                return <DomainOverview asset={asset} />;
            case AssetType.IPV4:
            case AssetType.IPV6:
                return <IpAddressOverview asset={asset} />;
            case AssetType.SSL:
                return <SslOverview asset={asset} />;
            case AssetType.EMAIL:
                return <EmailOverview asset={asset} />;
            case AssetType.PORT:
                return <PortOverview asset={asset} />;
            case AssetType.CLOUD:
                return null;
            case AssetType.CIDR:
                return null;
            case AssetType.ORGANIZATION:
            default:
                return null;
        }
    }, [asset]);

    const handleSeverityCardClick = (rating: string) => {
        if (setSelectedIssueRating) {
            setSelectedIssueRating(rating as unknown as Rating);
        }

        if (setActiveTab) {
            setActiveTab('Issues');
        }
    };

    return (
        <Stack h="100%" gap="lg">
            {!asset.isRootCandidate && !asset.isFalsePositive && (
                <DistributionBySeverityCard
                    asset={asset}
                    size={100}
                    hasLink={false}
                    onClick={handleSeverityCardClick}
                />
            )}
            {overviewTab()}
        </Stack>
    );
};

export default AssetOverview;
