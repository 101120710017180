import React, { memo, useEffect } from 'react';
import { ArchivedAssetsTable } from 'widgets/Asset';
import { Seo } from 'shared/ui/SEO/SEO';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { useParams } from 'react-router-dom';
import { navigationActions, NavigationPageType } from 'entities/Navigation';
import ManagementBaseLayout from '../../../ui/ManagementBaseLayout/ManagementBaseLayout';

function ArchivedAssetsPage() {
    const dispatch = useAppDispatch();
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (!id) return;
        dispatch(
            navigationActions.addToDrawerStack({
                pageLink: window.location.href,
                pageType: NavigationPageType.ASSET_DETAILS,
                pageID: id
            })
        );
    }, [dispatch, id]);

    return (
        <ManagementBaseLayout>
            <Seo title="Archived Assets" />
            <ArchivedAssetsTable />
        </ManagementBaseLayout>
    );
}

export default memo(ArchivedAssetsPage);
