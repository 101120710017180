import { memo } from 'react';
import {
    useGetArchivedAssets,
    useGetAssets,
    useGetRootAssetCandidates,
    useGetRootAssets
} from 'entities/Asset';
import { PageHeader, PageChildrenLink } from 'widgets/PageHeader';
import { RoutePath } from 'shared/config/routeConfig';
import { Button } from '@mantine/core';
import { Link } from 'react-router-dom';
import DemoGuard from 'shared/guards/DemoGuard/DemoGuard';

const ManagementPageHeader = () => {
    const { data: rootAssets, isLoading: rooAssetsLoading } = useGetRootAssets(
        {}
    );
    const { data: rootAssetCandidates, isLoading: rootAssetCandidatesLoading } =
        useGetRootAssetCandidates({});
    const { data: archivedAssets, isLoading: archivedAssetsLoading } =
        useGetArchivedAssets({
            size: 1
        });
    const rootAssetsCount = rootAssets?.pagination?.totalItems;
    const rootAssetCandidatesCount =
        rootAssetCandidates?.pagination?.totalItems;
    const archivedAssetsCount = archivedAssets?.pagination?.totalItems;

    return (
        <PageHeader
            title="Management"
            links={
                <>
                    <PageChildrenLink
                        title="Root Assets"
                        to={`${RoutePath.management}/root-assets`}
                        previewNumber={rootAssetsCount}
                        previewNumberLoading={rooAssetsLoading}
                    />
                    <PageChildrenLink
                        title="Root Asset Candidates"
                        to={`${RoutePath.management}/root-asset-candidates`}
                        previewNumber={rootAssetCandidatesCount}
                        previewNumberLoading={rootAssetCandidatesLoading}
                    />
                    <PageChildrenLink
                        title="Archived Assets"
                        to={`${RoutePath.management}/archived-assets`}
                        previewNumber={archivedAssetsCount}
                        previewNumberLoading={archivedAssetsLoading}
                    />
                </>
            }
            actions={
                <DemoGuard>
                    <Button
                        component={Link}
                        to={`${RoutePath.management}/root-assets/new`}
                        variant="primary-gradient"
                        size="md"
                    >
                        Add new Root Asset
                    </Button>
                </DemoGuard>
            }
        />
    );
};

export default memo(ManagementPageHeader);
