import {
    Asset,
    AssetSslCertificate,
    RootAsset,
    RootAssetCandidate
} from 'entities/Asset';
import React from 'react';

interface SslOverviewProps {
    asset: Asset | RootAsset | RootAssetCandidate;
}

const SslOverview = (props: SslOverviewProps) => {
    const { asset } = props;
    return <AssetSslCertificate asset={asset as Asset} />;
};

export default SslOverview;
