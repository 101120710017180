import { memo } from 'react';
import { useGetLastStatisticsByVulnerabilityRating } from 'entities/Statistics';
import { Badge, Group, NumberFormatter, rem, Skeleton } from '@mantine/core';
import { Rating, severityRatingColor } from 'entities/Issue';

type SeverityDistributionByAssetIDProps =
    | {
          assetId: string;
          rootAssetId?: never;
      }
    | {
          assetId?: never;
          rootAssetId: string;
      };

const SeverityDistributionByAssetID = (
    props: SeverityDistributionByAssetIDProps
) => {
    const { assetId, rootAssetId } = props;
    const { data, isLoading, isFetching } =
        useGetLastStatisticsByVulnerabilityRating({
            assetId,
            rootAssetId
        });

    if (isLoading || isFetching) {
        return <Skeleton width="100%" mih={rem(20)} />;
    }

    return (
        <Group gap="xs" wrap="nowrap" w="100%" grow>
            {Object.entries(data?.distribution ?? {}).map(([rating, value]) => (
                <Badge
                    key={rating}
                    px="xs"
                    c="#fff"
                    radius="sm"
                    color={
                        value.count
                            ? severityRatingColor(rating as Rating)
                            : 'gray.4'
                    }
                    styles={{
                        label: {
                            overflow: 'visible'
                        }
                    }}
                >
                    <NumberFormatter value={value.count} thousandSeparator />
                </Badge>
            ))}
        </Group>
    );
};

export default memo(SeverityDistributionByAssetID);
