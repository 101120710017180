import { memo } from 'react';
import { archivedAssetDetailsRoute } from 'app/providers/RouteProvider';
import { Asset, RootAsset } from 'entities/Asset';
import { NavigationLink, NavigationPageType } from 'entities/Navigation';

interface ArchivedAssetLinkProps {
    asset: Asset | RootAsset;
    children?: React.ReactNode;
}

const ArchivedAssetLink = (props: ArchivedAssetLinkProps) => {
    const { asset, children } = props;
    if (!asset) return '-';

    return (
        <NavigationLink
            pageLink={archivedAssetDetailsRoute(asset.id)}
            pageTitle={asset.value}
            pageType={NavigationPageType.ASSET_DETAILS}
            pageID={asset.id}
        >
            {children || asset.value}
        </NavigationLink>
    );
};

export default memo(ArchivedAssetLink);
