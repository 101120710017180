import React, { memo } from 'react';
import { ExternalLink } from 'shared/ui/ExternalLink';
import HeaderedCard from 'shared/ui/HeaderedCard/HeaderedCard';
import { Technology } from '../../model/types/technology';

interface TechnologyNistDetailsProps {
    inCard?: boolean;
    technology: Technology;
}

const TechnologyNistDetails = (props: TechnologyNistDetailsProps) => {
    const { inCard = false, technology } = props;
    const { nvdLink, nvdData } = technology;

    if (!nvdLink) return null;

    return (
        <HeaderedCard header="National Vulnerability Database (NVD)">
            <ExternalLink href={nvdLink}>
                {nvdData?._id || nvdLink}
            </ExternalLink>
        </HeaderedCard>
    );
};

export default memo(TechnologyNistDetails);
