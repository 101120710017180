import { memo } from 'react';
import {
    Container,
    Paper,
    Text,
    TextInput,
    Flex,
    PasswordInput,
    Space,
    Divider,
    Grid,
    Title,
    Mark,
    Image
} from '@mantine/core';
import { AzureAssetCreateBody, useAddAzureAsset } from 'entities/Asset';
import { useForm } from '@mantine/form';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { addNewRootAssetActions } from 'features/asset/actions/AddNewRootAsset';
import ZoomedImage from 'shared/ui/ZoomedImage/ZoomedImage';
import Image1 from 'shared/assets/images/integrations/azure/Screenshot 2023-12-05 at 9.22.21.png';
import Image2 from 'shared/assets/images/integrations/azure/Screenshot 2023-12-05 at 9.22.47.png';
import Image3 from 'shared/assets/images/integrations/azure/Screenshot 2023-12-05 at 9.23.12.png';
import Image4 from 'shared/assets/images/integrations/azure/Screenshot 2023-12-05 at 9.23.49.png';
import Image5 from 'shared/assets/images/integrations/azure/Screenshot 2023-12-05 at 9.23.56.png';
import Image6 from 'shared/assets/images/integrations/azure/Screenshot 2023-12-05 at 9.24.10.png';
import Image7 from 'shared/assets/images/integrations/azure/Screenshot 2023-12-05 at 9.24.22.png';
import Image8 from 'shared/assets/images/integrations/azure/Screenshot 2023-12-05 at 9.24.35.png';
import Image9 from 'shared/assets/images/integrations/azure/Screenshot 2023-12-05 at 9.25.02.png';
import Image10 from 'shared/assets/images/integrations/azure/Screenshot 2023-12-05 at 9.26.21.png';
import Image11 from 'shared/assets/images/integrations/azure/Screenshot 2023-12-05 at 9.27.18.png';
import Image12 from 'shared/assets/images/integrations/azure/Screenshot 2023-12-05 at 9.28.32.png';
import Image13 from 'shared/assets/images/integrations/azure/Screenshot 2023-12-05 at 9.28.46.png';
import Image14 from 'shared/assets/images/integrations/azure/Screenshot 2023-12-05 at 9.29.26.png';
import Image15 from 'shared/assets/images/integrations/azure/Screenshot 2023-12-05 at 9.35.46.png';
import Image16 from 'shared/assets/images/integrations/azure/Screenshot 2023-12-05 at 9.36.26.png';
import Image17 from 'shared/assets/images/integrations/azure/Screenshot 2023-12-05 at 9.37.06.png';
import Image18 from 'shared/assets/images/integrations/azure/Screenshot 2023-12-05 at 9.37.31.png';
import Image19 from 'shared/assets/images/integrations/azure/Screenshot 2023-12-05 at 9.38.21.png';
import MailToServiceDesk from 'shared/ui/MailTo/MailToServiceDesk';
import { contactUsToVerifyAssetMessage } from '../../model/service/contactUsToVerifyAssetMessage';
import ConfirmFormFooter from '../common/ConfirmFormFooter/ConfirmFormFooter';

const AzureRootAsset = () => {
    const dispatch = useAppDispatch();
    const [addAsset, { isLoading }] = useAddAzureAsset();
    const form = useForm<AzureAssetCreateBody>({
        initialValues: {
            tenantID: '',
            subscriptionID: '',
            clientID: '',
            clientSecret: ''
        }
    });

    const onSubmit = (values: AzureAssetCreateBody) =>
        addAsset(values)
            .unwrap()
            .then(() => {
                dispatch(
                    addNewRootAssetActions.setSuccess(
                        contactUsToVerifyAssetMessage(`Microsoft Azure account`)
                    )
                );
            })
            .catch(err => {
                dispatch(addNewRootAssetActions.setError(err));
            });

    return (
        <Container size="md">
            <Text c="dimmed" ta="center" size="md">
                Enter a Microsoft Azure account details you want to keep safe
                and secure. Follow the guide below or contact our support team (
                <MailToServiceDesk />) for more information.
            </Text>
            <Container size="sm">
                <Paper withBorder shadow="md" p="xl" radius="md" mt="xl">
                    <form onSubmit={form.onSubmit(onSubmit)}>
                        <Flex gap="lg" direction="column">
                            <TextInput
                                label="Tenant ID"
                                placeholder="tenant-id"
                                withAsterisk
                                required
                                {...form.getInputProps('tenantID')}
                            />
                            <TextInput
                                label="Subscription ID"
                                placeholder="subscription-id"
                                withAsterisk
                                required
                                {...form.getInputProps('subscriptionID')}
                            />
                            <TextInput
                                label="Client ID"
                                placeholder="client-id"
                                withAsterisk
                                required
                                {...form.getInputProps('clientID')}
                            />
                            <PasswordInput
                                label="Client Secret"
                                placeholder="client-secret"
                                withAsterisk
                                required
                                {...form.getInputProps('clientSecret')}
                            />
                            <ConfirmFormFooter loading={isLoading} />
                        </Flex>
                    </form>
                </Paper>
            </Container>

            <Space h="xl" />
            <Space h="xl" />
            <Space h="xl" />

            <Grid align="center">
                <Grid.Col span={12}>
                    <Title order={2} ta="center" fw="600">
                        How to connect your Microsoft Azure account to Attaxion?
                    </Title>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Space h="md" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        1. To connect your Microsoft Azure account to Attaxion,
                        you need to create a new application in your Azure
                        Active Directory. To do this, go to the{' '}
                        <Mark>App registrations</Mark> section.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image1} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        2. Click the <Mark>New registration</Mark> button.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image2} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        3. Enter the application name and select other options,
                        as shown in the screenshot. Then click the{' '}
                        <Mark>Register</Mark> button.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image3} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        4. After creating the application, you will be
                        redirected to the application page. Copy the{' '}
                        <Mark>Application (client) ID</Mark> and{' '}
                        <Mark>Directory (tenant) ID</Mark> values and paste them
                        into the corresponding fields at the top of this page.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image4} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        5. Then you need to create a new client secret. To do
                        this, click the <Mark>Add a certificate or secret</Mark>{' '}
                        link.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image5} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        6. Select the <Mark>Client secrets</Mark> tab and click
                        the <Mark>New client secret</Mark> button.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image6} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        7. Enter the secret description and select the
                        expiration period. Then click the <Mark>Add</Mark>{' '}
                        button.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image7} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        8. Copy the <Mark>Value</Mark> value and paste it into
                        the <Mark>Client Secret</Mark> field at the top of this
                        page.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image8} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        9. Each Azure integration on Attaxion is associated with
                        a single subscription. To get a{' '}
                        <Mark>Subscription ID</Mark>, go to the{' '}
                        <Mark>Subscriptions</Mark> section.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image9} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        10. Find the subscription you want to connect to
                        Attaxion and copy the <Mark>Subscription ID</Mark>{' '}
                        value. Then paste it into the corresponding field at the
                        top of this page.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image10} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        11. To allow Attaxion to scan required Azure
                        infrastructure, you have to assign a Reader role to the
                        created app. On the Subscription screen, go to{' '}
                        <Mark>Access control (IAM)</Mark> section.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image11} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        11. Click the <Mark>Role assignment</Mark> tab.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image12} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        12. Click the <Mark>Add</Mark> button. Then select the{' '}
                        <Mark>Add role assignment</Mark> option from the drop
                        down list.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image13} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        13. Select the <Mark>Reader</Mark> role and click the{' '}
                        <Mark>Next</Mark> button.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image14} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        14. Ensure that the <Mark>Assign access to</Mark> option
                        is set to{' '}
                        <Mark>User, group, and service principal</Mark>. Then
                        click the <Mark>Select members</Mark> button.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image15} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        15. In the sidebar, enter your application name, select
                        it, then click <Mark>Select</Mark>.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image17} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        16. Click the <Mark>Review + assign</Mark>, and check
                        everything is OK, then again click{' '}
                        <Mark>Review + assign</Mark> to confirm the assignment.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image18} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        17. Check that your application has been assigned the{' '}
                        <Mark>Reader</Mark> role on the{' '}
                        <Mark>Role assignments</Mark> tab.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image19} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Text size="xl">
                        18. Now you can go back to Attaxion and click the{' '}
                        <Mark>Add</Mark> button.
                    </Text>
                </Grid.Col>
            </Grid>
        </Container>
    );
};

export default memo(AzureRootAsset);
