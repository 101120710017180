import { memo } from 'react';
import { Page } from 'shared/ui/Page';
import GraphPageHeader from 'pages/Graph/ui/GraphPageHeader/GraphPageHeader';

interface GraphBaseLayoutProps {
    children?: React.ReactNode;
}

const GraphBaseLayout = (props: GraphBaseLayoutProps) => {
    const { children } = props;

    return (
        <Page>
            <GraphPageHeader />
            {children}
        </Page>
    );
};

export default memo(GraphBaseLayout);
