import React, { memo } from 'react';
import { formatDate, formatDateToUTC } from 'shared/lib/date';
import { Tooltip } from '@mantine/core';
import { WhoisRecord } from '../../../model/types/metadata';

interface WhoisExpiresDateProps {
    whoisRecord?: WhoisRecord;
}

const WhoisExpiresDate = (props: WhoisExpiresDateProps) => {
    const { whoisRecord } = props;
    const expiresDate =
        whoisRecord?.expiresDateNormalized ||
        whoisRecord?.registryData?.expiresDateNormalized;

    if (!expiresDate) {
        return 'N/A';
    }

    return (
        <Tooltip label={formatDateToUTC(expiresDate)} withArrow>
            <span>{formatDate(expiresDate)}</span>
        </Tooltip>
    );
};

export default memo(WhoisExpiresDate);
