import React, { memo, useMemo, useState } from 'react';
import {
    useGetVulnerabilities,
    Vulnerability,
    VulnerabilityLink
} from 'entities/Vulnerability';
import { DataTableColumn } from 'mantine-datatable';
import { DataTable, DateColumn } from 'shared/ui/DataTable';
import { RiskBadge } from 'entities/Issue';

const VulnerabilitiesFeedTable = () => {
    const [page, setPage] = useState(1);
    const { data, isLoading, isFetching } = useGetVulnerabilities(
        {
            page,
            size: 5,
            orderBy: '-lastSeenAt'
        },
        {
            pollingInterval: 60 * 1000
        }
    );
    const { data: vulnerabilities, pagination } = data ?? {};

    const columns = useMemo<DataTableColumn<Vulnerability>[]>(
        () => [
            {
                accessor: 'lastSeenAt',
                title: 'Last Seen',
                render: ({ lastSeenAt }) => (
                    <DateColumn date={lastSeenAt} timeAgo />
                )
            },
            {
                accessor: 'name',
                title: 'Name',
                render: vulnerability => (
                    <VulnerabilityLink vulnerability={vulnerability} />
                )
            },
            {
                accessor: 'risk',
                title: 'Severity',
                render: vulnerability => (
                    <RiskBadge issue={vulnerability} fullWidth />
                )
            }
        ],
        []
    );

    return (
        <DataTable
            defaultColumnProps={{
                width: 'fit-content',
                noWrap: true
            }}
            records={vulnerabilities}
            columns={columns}
            fetching={isLoading || isFetching}
            totalRecords={pagination?.totalItems ?? 0}
            recordsPerPage={pagination?.size ?? 0}
            page={page}
            onPageChange={setPage}
        />
    );
};

export default memo(VulnerabilitiesFeedTable);
