import { ACCOUNT_API_TAGS, accountApi } from 'shared/api/rtkApi';
import { Subscription } from '../../model/types/subscription';

const paymentsApi = accountApi.injectEndpoints({
    endpoints: builder => ({
        getPayments: builder.query<Subscription | undefined, void>({
            query: () => ({
                url: '/v2/payment',
                method: 'GET',
                params: {
                    page: 1,
                    per_page: 100,
                    product_id: __NAF_PRODUCT_ID__,
                    frontend: 1
                }
            }),
            providesTags: [ACCOUNT_API_TAGS.PAYMENT]
        }),
        downloadInvoice: builder.mutation({
            queryFn: async (id, api, extraOptions, baseQuery) => {
                const result = await baseQuery({
                    url: `/invoice/download/${id}`,
                    headers: {
                        Accept: 'application/pdf'
                    },
                    // @ts-ignore
                    responseHandler: async response => response.arrayBuffer()
                });

                if (result.error) {
                    throw new Error('Failed to download invoice');
                }

                const hiddenElement = document.createElement('a');
                const url = window.URL || window.webkitURL;

                // @ts-ignore
                const blob = new Blob([result.data], {
                    type: 'application/pdf;charset=utf-8'
                });
                const csvUrl = url.createObjectURL(blob);

                hiddenElement.href = csvUrl;
                hiddenElement.target = '_blank';
                hiddenElement.download = `attaxion_invoice_${id}.pdf`;
                hiddenElement.click();
                url.revokeObjectURL(csvUrl);

                return { data: null };
            }
        })
    })
});

export const { useGetPaymentsQuery, useDownloadInvoiceMutation } = paymentsApi;
