import React, { memo, useEffect } from 'react';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { useParams } from 'react-router-dom';
import { navigationActions, NavigationPageType } from 'entities/Navigation';
import { RootAssetsTable } from 'widgets/Asset';
import { Box } from '@mantine/core';
import { Seo } from 'shared/ui/SEO/SEO';
import { StartRootAssetScanningBanner } from 'entities/Banner';
import ManagementBaseLayout from '../../../ui/ManagementBaseLayout/ManagementBaseLayout';

function RootAssetsPage() {
    const dispatch = useAppDispatch();
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (!id) return;
        dispatch(
            navigationActions.addToDrawerStack({
                pageLink: window.location.href,
                pageType: NavigationPageType.ROOT_ASSET_DETAILS,
                pageID: id
            })
        );
    }, [dispatch, id]);

    return (
        <ManagementBaseLayout>
            <Seo title="Root Assets | Management" />
            <StartRootAssetScanningBanner />
            <Box style={{ maxHeight: 'calc(100% - 120px)' }}>
                <RootAssetsTable />
            </Box>
        </ManagementBaseLayout>
    );
}

export default memo(RootAssetsPage);
