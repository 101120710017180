import React, { memo } from 'react';
import { Page } from 'shared/ui/Page';
import classNames from 'classnames';
import {
    parseDiscoveryMethods,
    TechnologyDescription,
    TechnologyNistDetails,
    TechnologyRelatedAssets,
    TechnologyRelatedIssues,
    useGetTechnology
} from 'entities/Technology';
import { MetadataBar, MetadataItem } from 'shared/ui/Metadata';
import { BadgeBar } from 'shared/ui/Badge';
import { ExternalLink } from 'shared/ui/ExternalLink';
import TimeAgo from 'timeago-react';
import { Tooltip } from '@mantine/core';
import { Seo } from 'shared/ui/SEO/SEO';
import { IssuesCountBadge } from 'entities/Issue';
import styles from './TechnologyDetailsPage.module.scss';

interface TechnologyDetailsPageProps {
    className?: string;
    id: string;
}

function TechnologyDetailsPage(props: TechnologyDetailsPageProps) {
    const { className, id } = props;
    const classes = classNames(styles.TechnologyDetailsPage, className);
    const { data: technology, isLoading } = useGetTechnology(id);

    if (isLoading) {
        return (
            <Page className={classes}>
                <div>Loading...</div>
            </Page>
        );
    }

    if (!technology) {
        return (
            <Page className={classes}>
                <div>Technology not found</div>
            </Page>
        );
    }

    return (
        <Page className={classes}>
            <Seo title="Details | Technologies" />
            <div className={styles.TechnologyDetails}>
                <MetadataBar>
                    <BadgeBar options={technology.categories}>
                        <IssuesCountBadge
                            vulnerabilitiesCount={
                                technology.vulnerabilitiesCount
                            }
                        />
                    </BadgeBar>
                </MetadataBar>
                <h1 className={styles.Header__Title}>{technology.name}</h1>
                <MetadataBar>
                    <MetadataItem
                        label="Last seen"
                        value={
                            <Tooltip label={technology.lastSeenAtParsed}>
                                <TimeAgo datetime={technology.lastSeenAt} />
                            </Tooltip>
                        }
                    />
                    <MetadataItem
                        label="First seen"
                        value={
                            <Tooltip label={technology.createdAtParsed}>
                                <TimeAgo datetime={technology.createdAt} />
                            </Tooltip>
                        }
                    />
                    <MetadataItem label="Version" value={technology.version} />
                    {technology.website && (
                        <MetadataItem
                            label="Website"
                            value={
                                <ExternalLink href={technology.website}>
                                    {technology.website}
                                </ExternalLink>
                            }
                        />
                    )}
                    {technology.discoveryMethod.length > 0 && (
                        <MetadataItem
                            label="Discovery Methods"
                            value={parseDiscoveryMethods(
                                technology.discoveryMethod
                            )
                                .map(method => method.name)
                                .join(', ')}
                        />
                    )}
                </MetadataBar>
            </div>
            <TechnologyDescription technology={technology} />
            <TechnologyNistDetails technology={technology} />
            <TechnologyRelatedAssets technology={technology} />
            <TechnologyRelatedIssues technology={technology} />
        </Page>
    );
}

export default memo(TechnologyDetailsPage);
