export enum NavigationPageType {
    ROOT_ASSET_DETAILS = 'root_asset_details',
    ROOT_ASSET_CANDIDATE_DETAILS = 'root_asset_candidate_details',
    ASSET_DETAILS = 'asset_details',
    ISSUE_DETAILS = 'issue_details',
    MISCONFIGURATION_DETAILS = 'misconfiguration_details',
    TECHNOLOGY_DETAILS = 'technology_details'
}

export interface NavigationPage {
    pageType: NavigationPageType;
    pageID: string;
    pageLink?: string;
    pageTitle?: string;
}
