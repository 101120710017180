import {
    Anchor,
    Container,
    Paper,
    Text,
    TextInput,
    Flex,
    Divider,
    Title,
    List,
    Image,
    Stack,
    Mark,
    Grid,
    Space
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useAddAwsAsset, AwsAssetCreateBody } from 'entities/Asset';
import { addNewRootAssetActions } from 'features/asset/actions/AddNewRootAsset';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { memo } from 'react';

import Image1 from 'shared/assets/images/integrations/aws/Screenshot 2023-12-04 at 15.27.23.png';
import Image2 from 'shared/assets/images/integrations/aws/Screenshot 2023-12-04 at 15.28.15.png';
import Image3 from 'shared/assets/images/integrations/aws/Screenshot 2023-12-04 at 15.29.11.png';
import Image4 from 'shared/assets/images/integrations/aws/Screenshot 2023-12-04 at 15.31.58.png';
import Image5 from 'shared/assets/images/integrations/aws/Screenshot 2023-12-04 at 15.34.16.png';
import Image6 from 'shared/assets/images/integrations/aws/Screenshot 2023-12-04 at 15.34.38.png';
import Image7 from 'shared/assets/images/integrations/aws/Screenshot 2023-12-04 at 15.36.32.png';
import Image8 from 'shared/assets/images/integrations/aws/Screenshot 2023-12-04 at 15.37.26.png';
import Image9 from 'shared/assets/images/integrations/aws/Screenshot 2023-12-04 at 15.37.32.png';
import Image10 from 'shared/assets/images/integrations/aws/Screenshot 2023-12-04 at 15.38.45.png';
import Image11 from 'shared/assets/images/integrations/aws/Screenshot 2023-12-04 at 15.39.39.png';
import ZoomedImage from 'shared/ui/ZoomedImage/ZoomedImage';
import MailToServiceDesk from 'shared/ui/MailTo/MailToServiceDesk';
import ConfirmFormFooter from '../common/ConfirmFormFooter/ConfirmFormFooter';
import { contactUsToVerifyAssetMessage } from '../../model/service/contactUsToVerifyAssetMessage';

const AwsRootAsset = () => {
    const dispatch = useAppDispatch();
    const [addAsset, { isLoading }] = useAddAwsAsset();
    const form = useForm<AwsAssetCreateBody>({
        initialValues: {
            roleArn: '',
            externalID: ''
        }
    });

    const onSubmit = (values: AwsAssetCreateBody) =>
        addAsset(values)
            .unwrap()
            .then(() => {
                dispatch(
                    addNewRootAssetActions.setSuccess(
                        contactUsToVerifyAssetMessage(`AWS account`)
                    )
                );
            })
            .catch(err => {
                dispatch(addNewRootAssetActions.setError(err));
            });

    return (
        <Container size="md">
            <Text c="dimmed" ta="center" size="md">
                Enter a AWS account you want to keep safe and secure. Follow the
                guide below or contact our support team (<MailToServiceDesk />)
                for more information.
            </Text>
            <Container size="sm">
                <Paper withBorder shadow="md" p="xl" radius="md" mt="xl">
                    <form onSubmit={form.onSubmit(onSubmit)}>
                        <Flex gap="lg" direction="column">
                            <TextInput
                                label="Role ARN"
                                description={
                                    <Text size="sm">
                                        Read more about{' '}
                                        <Anchor
                                            href="https://docs.aws.amazon.com/IAM/latest/UserGuide/id_roles_create_for-user_externalid.html"
                                            target="_blank"
                                        >
                                            AWS IAM identifiers
                                        </Anchor>
                                    </Text>
                                }
                                placeholder="arn:aws:iam::123456789012:role/role-name"
                                pattern="^arn:aws:iam::\d+:role\/.*$"
                                withAsterisk
                                required
                                {...form.getInputProps('roleArn')}
                            />
                            <TextInput
                                label="External ID"
                                description={
                                    <Text size="sm">
                                        Read more about{' '}
                                        <Anchor
                                            href="https://docs.aws.amazon.com/IAM/latest/UserGuide/id_roles_create_for-user_externalid.html"
                                            target="_blank"
                                        >
                                            how to use an external ID
                                        </Anchor>
                                    </Text>
                                }
                                placeholder="123456789012"
                                withAsterisk
                                required
                                {...form.getInputProps('externalID')}
                            />
                            <ConfirmFormFooter loading={isLoading} />
                        </Flex>
                    </form>
                </Paper>
            </Container>

            <Space h="xl" />
            <Space h="xl" />
            <Space h="xl" />

            <Grid align="center">
                <Grid.Col span={12}>
                    <Title order={2} ta="center" fw="600">
                        How to connect your Amazon AWS account to Attaxion?
                    </Title>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Space h="md" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        1. To connect your AWS account to Attaxion, you have to
                        create a new role in the AWS IAM service. This role will
                        allow Attaxion to scan your AWS infrastructure. First,
                        click your account name in the top right corner, then
                        click <Mark>Security credentials</Mark>.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image1} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        2. On the <Mark>Security credentials page,</Mark> select{' '}
                        <Mark>Roles</Mark> in the sidebar.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper radius="md" withBorder>
                        <ZoomedImage>
                            <Image radius="md" src={Image2} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        3. Click the <Mark>Create role</Mark> button to create a
                        new role.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper radius="md" withBorder>
                        <ZoomedImage>
                            <Image radius="md" src={Image3} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        4. Select <Mark>AWS account</Mark> as the type of
                        trusted entity. Then select option{' '}
                        <Mark>Another AWS account</Mark>, and type in the
                        Account ID field Attaxion account ID:{' '}
                        <Mark>886169467523</Mark>. Then select option{' '}
                        <Mark>Require External ID</Mark> and type any random
                        string in the <Mark>External ID</Mark> field below (for
                        example, <Mark>123456789012</Mark>). Save the External
                        ID - you will need it in step #9. Then click{' '}
                        <Mark>Next</Mark> button.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper radius="md" withBorder>
                        <ZoomedImage>
                            <Image radius="md" src={Image4} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Text size="xl">
                        7. Then you have to select permissions. To allow
                        Attaxion to scan AWS infrastructure, you have to add the
                        following default permissions to this role:
                    </Text>
                    <List size="xl" withPadding listStyleType="disc" my="md">
                        <List.Item>AmazonEC2ReadOnlyAccess</List.Item>
                        <List.Item>
                            AmazonRoute53DomainsReadOnlyAccess
                        </List.Item>
                        <List.Item>AmazonRoute53ReadOnlyAccess</List.Item>
                        <List.Item>AmazonS3ReadOnlyAccess</List.Item>
                        <List.Item>CloudFrontReadOnlyAccess</List.Item>
                        <List.Item>ElasticLoadBalancingReadOnly</List.Item>
                    </List>
                    <Text size="xl">
                        Then click <Mark>Next</Mark> button.
                    </Text>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        7. Enter the role’s name in the <Mark>Role name</Mark>{' '}
                        field (for example, <Mark>attaxion-role</Mark>).
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper radius="md" withBorder>
                        <ZoomedImage>
                            <Image radius="md" src={Image5} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        8. On the same screen, check if the permissions are set
                        correctly.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper radius="md" withBorder>
                        <ZoomedImage>
                            <Image radius="md" src={Image6} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Text size="xl">
                        9. Click <Mark>Create role</Mark> button to create the
                        role. Now you have to copy the role’s ARN and External
                        ID to the Attaxion form at the top of this page. Сlick
                        the <Mark>Add</Mark> button to add your AWS account to
                        Attaxion. Follow the instructions below to extract
                        Lightsail domains (if you have any).
                    </Text>
                </Grid.Col>
            </Grid>

            <Space h="xl" />
            <Space h="xl" />
            <Space h="xl" />
            <Space h="xl" />

            <Grid align="center">
                <Grid.Col span={12}>
                    <Title order={2} ta="center" fw="600">
                        How to extract LightSail domains? (if you have any)
                    </Title>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Space h="md" />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Text size="xl">
                        1. If you have Lightsail domains, you have to create a
                        custom policy to enable <Mark>Lightsail</Mark> domain
                        extraction. Once a role is created, open its details.
                    </Text>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        2. Click <Mark>Add permissions</Mark> and select the{' '}
                        <Mark>Create inline policy</Mark> option.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper radius="md" withBorder>
                        <ZoomedImage>
                            <Image radius="md" src={Image7} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        3. Select <Mark>Lightsail</Mark> service. Then select{' '}
                        <Mark>All read actions</Mark>.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper radius="md" withBorder>
                        <ZoomedImage>
                            <Image radius="md" src={Image8} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        4. Select <Mark>All</Mark> resources and click{' '}
                        <Mark>Next</Mark> button.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper radius="md" withBorder>
                        <ZoomedImage>
                            <Image radius="md" src={Image9} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        5. Enter a name for the policy (for example,{' '}
                        <Mark>attaxion-lightsail-read-all</Mark>) and click{' '}
                        <Mark>Create policy</Mark> button.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper radius="md" withBorder>
                        <ZoomedImage>
                            <Image radius="md" src={Image10} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        5. Copy the role ARN and paste it to the Attaxion form
                        at the beginning of this page.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper radius="md" withBorder>
                        <ZoomedImage>
                            <Image radius="md" src={Image11} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Text size="xl">
                        6. Now you can go back to Attaxion and click the{' '}
                        <Mark>Add</Mark> button.
                    </Text>
                </Grid.Col>
            </Grid>

            <Space h="xl" />
            <Space h="xl" />
            <Space h="xl" />
        </Container>
    );
};

export default memo(AwsRootAsset);
