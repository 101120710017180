import { Outlet, useLocation } from 'react-router-dom';
import React, { Suspense, useEffect } from 'react';
import useHubspot from 'shared/hooks/useHubspot/useHubspot';
import { EventsListener } from 'entities/Analytics';
import { VerifyRootAssetModal } from 'features/asset/actions/VerifyRootAsset';
import { RemoveRootAssetModal } from 'features/asset/actions/RemoveRootAsset';
import { useDynamicViewport } from 'shared/hooks/useDynamicViewport/useDynamicViewport';
import { useDemoStyleSheet } from 'shared/hooks/useDemo/useDemoStyleSheet';
import { useAnalytics } from 'shared/hooks/useAnalytics/useAnalytics';
import { userActions, useUser } from 'entities/User';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { EntityTagModal } from 'features/asset/actions/AddTag';
import { EntityCommentModal } from 'features/asset/actions/AddComment';
import { UpdateAssetScannerSettingsModal } from 'features/asset/actions/UpdateAssetScannerSettings';
import { SetAssetFalsePositiveModal } from 'features/asset/actions/SetAssetFalsePositive';

const RootElement = () => {
    useDynamicViewport();
    useDemoStyleSheet();

    const dispatch = useAppDispatch();
    const location = useLocation();
    const { user } = useUser();
    const { setPathPageView } = useHubspot();
    const { enableAnalytics, disableAnalytics } = useAnalytics();

    useEffect(() => {
        setPathPageView(location.pathname);
    }, [location, setPathPageView]);

    useEffect(() => {
        if (user && !user.analytics_enabled) {
            disableAnalytics();
        } else {
            enableAnalytics();
        }
    }, [user, disableAnalytics, enableAnalytics]);

    useEffect(() => {
        if (user) {
            dispatch(userActions.updateAuthCookie());
        }
    }, [user, dispatch, location]);

    return (
        <>
            <Outlet />
            <EventsListener />
            <Suspense fallback="">
                <VerifyRootAssetModal />
                <RemoveRootAssetModal />
                <EntityTagModal />
                <EntityCommentModal />
                <UpdateAssetScannerSettingsModal />
                <SetAssetFalsePositiveModal />
            </Suspense>
        </>
    );
};

export default RootElement;
