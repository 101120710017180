import React, { memo } from 'react';
import classNames from 'classnames';
import { Text } from 'shared/ui/Text';
import { Card } from 'shared/ui/Card';
import { WhoisContact, WhoisRecord } from 'entities/Asset/model/types/metadata';
import { CopyButton } from 'shared/ui/Button';
import styles from './AssetWhoisRecord.module.scss';
import { Asset } from '../../model/types/asset';

interface AssetWhoisRecordProps {
    className?: string;
    asset?: Asset;
}

const AssetWhoisRecordRow = ({
    label,
    value
}: {
    label: string;
    value?: string | string[] | number | number[];
}) =>
    value && (
        <div className={styles.AssetWhoisRecord__Item__Content__Row}>
            <Text color="secondary">{label}</Text>
            <div>{Array.isArray(value) ? value.join('\n') : value}</div>
        </div>
    );

const AssetWhoisContactsColumn = ({
    contact,
    title
}: {
    contact: WhoisContact;
    title: string;
}) => (
    <div className={styles.AssetWhoisRecord__Item__Column}>
        <Text size="m">{title}</Text>
        <div className={styles.AssetWhoisRecord__Item__Content}>
            <AssetWhoisRecordRow label="Name" value={contact.name} />
            <AssetWhoisRecordRow
                label="Organization"
                value={contact.organization}
            />
            <AssetWhoisRecordRow
                label="Street"
                value={
                    contact.street1 ||
                    contact.street2 ||
                    contact.street3 ||
                    contact.street4
                }
            />
            <AssetWhoisRecordRow label="City" value={contact.city} />
            <AssetWhoisRecordRow label="State" value={contact.state} />
            <AssetWhoisRecordRow
                label="Postal code"
                value={contact.postalCode}
            />
            <AssetWhoisRecordRow label="Country" value={contact.country} />
            <AssetWhoisRecordRow
                label="Country code"
                value={contact.countryCode}
            />
            <AssetWhoisRecordRow label="Phone" value={contact.telephone} />
            <AssetWhoisRecordRow label="Fax" value={contact.fax} />
            <AssetWhoisRecordRow label="Email" value={contact.email} />
        </div>
    </div>
);

const WhoisRecordCard = ({ whoisRecord }: { whoisRecord: WhoisRecord }) => (
    <>
        <div className={styles.AssetWhoisRecord__Item__Column}>
            <Text size="m">Overview</Text>
            <div className={styles.AssetWhoisRecord__Item__Content}>
                <AssetWhoisRecordRow
                    label="WHOIS server"
                    value={whoisRecord.whoisServer}
                />
                <AssetWhoisRecordRow
                    label="Created date"
                    value={whoisRecord.createdDate}
                />
                <AssetWhoisRecordRow
                    label="Updated date"
                    value={whoisRecord.updatedDate}
                />
                <AssetWhoisRecordRow
                    label="Expires date"
                    value={whoisRecord.expiresDate}
                />
                {Boolean(whoisRecord.estimatedDomainAge) && (
                    <AssetWhoisRecordRow
                        label="Domain age"
                        value={`${whoisRecord.estimatedDomainAge} days`}
                    />
                )}
                <AssetWhoisRecordRow
                    label="Contact email"
                    value={whoisRecord.contactEmail}
                />
                <AssetWhoisRecordRow
                    label="Registrar name"
                    value={whoisRecord.registrarName}
                />
                <AssetWhoisRecordRow
                    label="Registrar IANA ID"
                    value={whoisRecord.registrarIANAID}
                />
                <AssetWhoisRecordRow
                    label="EPP status codes"
                    value={whoisRecord.status}
                />
                <AssetWhoisRecordRow
                    label="Name servers"
                    value={whoisRecord.nameServers.rawText}
                />
            </div>
        </div>
        {whoisRecord.registrant && (
            <AssetWhoisContactsColumn
                contact={whoisRecord.registrant}
                title="Registrant contact"
            />
        )}
        {whoisRecord.administrativeContact && (
            <AssetWhoisContactsColumn
                contact={whoisRecord.administrativeContact}
                title="Administrative contact"
            />
        )}
        {whoisRecord.technicalContact && (
            <AssetWhoisContactsColumn
                contact={whoisRecord.technicalContact}
                title="Technical contact"
            />
        )}
        {whoisRecord.billingContact && (
            <AssetWhoisContactsColumn
                contact={whoisRecord.billingContact}
                title="Billing contact"
            />
        )}
    </>
);

const AssetWhoisRecord = (props: AssetWhoisRecordProps) => {
    const { className, asset } = props;
    const classes = classNames(styles.AssetWhoisRecord, className);

    if (!asset?.metadata?.whoisRecord) return null;
    const { whoisRecord } = asset.metadata;
    const hasWhoisData =
        whoisRecord && whoisRecord.dataError !== 'MISSING_WHOIS_DATA';

    return (
        <div className={classes}>
            <Card
                contentClassName={styles.AssetWhoisRecord__Item}
                header="WHOIS record"
                actions={
                    hasWhoisData && (
                        <CopyButton value={JSON.stringify(whoisRecord)} />
                    )
                }
            >
                {hasWhoisData ? (
                    <WhoisRecordCard whoisRecord={whoisRecord} />
                ) : (
                    <Text color="secondary">
                        No WHOIS data available for this asset.
                    </Text>
                )}
            </Card>
            <Card
                header="Registry record"
                contentClassName={styles.AssetWhoisRecord__Item}
                actions={
                    <CopyButton
                        value={JSON.stringify(whoisRecord.registryData)}
                    />
                }
            >
                <WhoisRecordCard whoisRecord={whoisRecord.registryData} />
            </Card>
        </div>
    );
};

export default memo(AssetWhoisRecord);
