import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Asset, RootAsset } from 'entities/Asset';
import { UpdateAssetScannerSettingsSchema } from '../../types/updateAssetScannerSettingsSchema';

const initialState: UpdateAssetScannerSettingsSchema = {
    asset: null
};

export const updateAssetScannerSettingsSlice = createSlice({
    name: 'updateAssetScannerSettingsSlice',
    initialState,
    reducers: {
        reset: () => initialState,
        setAsset: (state, action: PayloadAction<Asset | RootAsset>) => {
            state.asset = action.payload;
        }
    }
});

export const { actions: updateAssetScannerSettingsActions } =
    updateAssetScannerSettingsSlice;
export const { reducer: updateAssetScannerSettingsReducer } =
    updateAssetScannerSettingsSlice;
