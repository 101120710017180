import { DataTableColumn } from 'mantine-datatable';
import { Asset, AssetQueryParams, AssetType } from 'entities/Asset';
import { BaseColumnProps } from 'widgets/Columns/baseColumn';
import React from 'react';
import { DateColumn } from 'shared/ui/DataTable';

type RecordType = Asset;
type QueryParams = AssetQueryParams;

export default ({
    column
}: BaseColumnProps<RecordType, QueryParams>): DataTableColumn<RecordType> => ({
    width: 'fit-content',
    accessor: 'metadata.sslCertificate.subject',
    title: 'Subject',
    render: ({ metadata }) => {
        if (!metadata?.sslCertificate?.subject) {
            return '-';
        }

        return metadata?.sslCertificate.subject;
    },
    ...column
});
