import { memo } from 'react';
import classNames from 'classnames';
import styles from './Loader.module.scss';

interface LoaderProps {
    fullContainer?: boolean;
    absolute?: boolean;
}

const Loader = (props: LoaderProps) => {
    const { fullContainer, absolute = false } = props;
    const classes = classNames(styles.LoaderFullContainerWrapper, {
        [styles.LoaderAbsoluteWrapper]: absolute
    });

    if (fullContainer) {
        return (
            <div className={classes}>
                <div className={styles.Loader} />
            </div>
        );
    }

    return <div className={styles.Loader} />;
};

export default memo(Loader);
