import { AssetQueryParams, RootAsset } from 'entities/Asset';
import { DataTableColumn } from 'mantine-datatable';
import React from 'react';
import { BaseColumnProps } from 'widgets/Columns/baseColumn';
import RootAssetActionsMenu from '../Asset/RootAssetActionsMenu/RootAssetActionsMenu';

export default ({
    query,
    setQuery,
    column
}: BaseColumnProps<
    RootAsset,
    AssetQueryParams
>): DataTableColumn<RootAsset> => ({
    width: '50px',
    accessor: 'actions',
    title: '',
    render: asset => <RootAssetActionsMenu asset={asset} />,
    ...column
});
