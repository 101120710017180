import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootAssetCandidate } from 'entities/Asset';
import { ApproveAssetCandidateSchema } from '../../types/approveAssetCandidateSchema';

const initialState: ApproveAssetCandidateSchema = {
    assets: []
};

export const approveAssetCandidateSlice = createSlice({
    name: 'approveAssetCandidate',
    initialState,
    reducers: {
        reset: () => initialState,
        setAssets: (state, action: PayloadAction<RootAssetCandidate[]>) => {
            state.assets = action.payload;
        }
    }
});

export const { actions: approveAssetCandidateActions } =
    approveAssetCandidateSlice;
export const { reducer: approveAssetCandidateReducer } =
    approveAssetCandidateSlice;
