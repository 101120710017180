import { InvisibleForm } from 'shared/ui/Analytics';

interface RootAssetAddedInvisibleFormProps {
    email: string;
    payload: object;
}

const RootAssetAddedInvisibleForm = (
    props: RootAssetAddedInvisibleFormProps
) => {
    const { email, payload } = props;

    return (
        <InvisibleForm id="root_asset_added">
            <input type="email" name="email" value={email} />
            {'id' in payload && (
                <input name="root_asset_id" value={payload.id as string} />
            )}
            {'name' in payload && (
                <input name="root_asset_name" value={payload.name as string} />
            )}
        </InvisibleForm>
    );
};

export default RootAssetAddedInvisibleForm;
