import React from 'react';
import { Navigate } from 'react-router';
import { RoutePath } from 'shared/config/routeConfig';
import { useLocation } from 'react-router-dom';
import { useDemo } from 'shared/hooks/useDemo/useDemo';

interface DemoGuardProps {
    routeAvailableForDemo?: boolean;
    children: React.ReactNode;
}

function DemoGuard(props: DemoGuardProps) {
    const { routeAvailableForDemo = true, children } = props;
    const { isDemo } = useDemo();
    const location = useLocation();

    if (isDemo && !routeAvailableForDemo) {
        return <Navigate to={RoutePath.dashboard} state={{ from: location }} />;
    }

    return children;
}

export default DemoGuard;
