import React, { memo } from 'react';
import PriceCard from './PriceCard';
import { PlanSchema } from '../../model/types/pricing';

interface TrialPriceCardProps {
    isActive: boolean;
    assets?: number | string;
}

const TrialPriceCard = (props: TrialPriceCardProps) => {
    const { isActive, assets } = props;

    return (
        <PriceCard
            schema={PlanSchema.TRIAL}
            price={0}
            period="mo"
            assets={assets === 'Unlimited' ? 0 : (assets as number)}
            isActive={isActive}
            isCustom
        />
    );
};

export default memo(TrialPriceCard);
