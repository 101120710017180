import { memo } from 'react';
import {
    Anchor,
    Container,
    Paper,
    Text,
    Flex,
    PasswordInput,
    Space,
    Divider,
    Grid,
    Title,
    Mark,
    Image
} from '@mantine/core';
import {
    DigitalOceanAssetCreateBody,
    useAddDigitalOceanAsset
} from 'entities/Asset';
import { useForm } from '@mantine/form';
import { addNewRootAssetActions } from 'features/asset/actions/AddNewRootAsset';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import ZoomedImage from 'shared/ui/ZoomedImage/ZoomedImage';
import Image1 from 'shared/assets/images/integrations/do/Screenshot 2023-12-04 at 17.09.13.png';
import Image2 from 'shared/assets/images/integrations/do/Screenshot 2023-12-04 at 17.09.25.png';
import Image3 from 'shared/assets/images/integrations/do/Screenshot 2023-12-04 at 17.09.42.png';
import Image4 from 'shared/assets/images/integrations/do/Screenshot 2023-12-04 at 17.09.54.png';
import MailToServiceDesk from 'shared/ui/MailTo/MailToServiceDesk';
import { contactUsToVerifyAssetMessage } from '../../model/service/contactUsToVerifyAssetMessage';
import ConfirmFormFooter from '../common/ConfirmFormFooter/ConfirmFormFooter';

const DigitalOceanRootAsset = () => {
    const dispatch = useAppDispatch();
    const [addAsset, { isLoading }] = useAddDigitalOceanAsset();
    const form = useForm<DigitalOceanAssetCreateBody>({
        initialValues: {
            apiKey: ''
        }
    });

    const onSubmit = (values: DigitalOceanAssetCreateBody) =>
        addAsset(values)
            .unwrap()
            .then(() => {
                dispatch(
                    addNewRootAssetActions.setSuccess(
                        contactUsToVerifyAssetMessage(`DigitalOcean account`)
                    )
                );
            })
            .catch(err => {
                dispatch(addNewRootAssetActions.setError(err));
            });

    return (
        <Container size="md">
            <Text c="dimmed" ta="center" size="md">
                Enter a DigitalOcean account you want to keep safe and secure.
                Follow the guide below or contact our support team (
                <MailToServiceDesk />) for more information.
            </Text>
            <Container size="sm">
                <Paper withBorder shadow="md" p="xl" radius="md" mt="xl">
                    <form onSubmit={form.onSubmit(onSubmit)}>
                        <Flex gap="lg" direction="column">
                            <PasswordInput
                                label="Personal Access Token"
                                placeholder="dop_v1_..."
                                withAsterisk
                                required
                                autoFocus
                                {...form.getInputProps('apiKey')}
                            />
                            <ConfirmFormFooter loading={isLoading} />
                        </Flex>
                    </form>
                </Paper>
            </Container>

            <Space h="xl" />
            <Space h="xl" />
            <Space h="xl" />

            <Grid align="center">
                <Grid.Col span={12}>
                    <Title order={2} ta="center" fw="600">
                        How to connect your DigitalOcean account to Attaxion?
                    </Title>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Space h="md" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        1. To connect your DigitalOcean account to Attaxion, you
                        need to create a Personal Access Token in your
                        DigitalOcean account. To do this, go to the{' '}
                        <Mark>API</Mark> section in the <Mark>Account</Mark>{' '}
                        menu.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image1} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        2. Click the <Mark>Generate New Token</Mark> button.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image2} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        3. Enter a name for the token and click the{' '}
                        <Mark>Generate Token</Mark> button.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image3} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Text size="xl">
                        4. Copy the generated token and paste it into the{' '}
                        <Mark>Personal Access Token</Mark> field in the form on
                        the top of this page.
                    </Text>
                </Grid.Col>
                <Grid.Col span={5}>
                    <Paper withBorder radius="md">
                        <ZoomedImage>
                            <Image radius="md" src={Image4} />
                        </ZoomedImage>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider my="xl" />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Text size="xl">
                        5. Click the <Mark>Add</Mark> button to add your
                        DigitalOcean account to Attaxion.
                    </Text>
                </Grid.Col>
            </Grid>

            <Space h="xl" />
            <Space h="xl" />
            <Space h="xl" />
        </Container>
    );
};

export default memo(DigitalOceanRootAsset);
