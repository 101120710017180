import React from 'react';
import { Loader } from 'shared/ui/Loader';
import { Seo } from 'shared/ui/SEO/SEO';
import { useGraph } from 'entities/Graph';
import { AssetGraph, useGetAllRootAssetGraphs } from 'entities/Asset';
import GraphBaseLayout from '../GraphBaseLayout/GraphBaseLayout';
import styles from './GraphPage.module.scss';

function GraphPage() {
    const { graphs, loading } = useGetAllRootAssetGraphs();
    const { links, nodes } = useGraph(graphs);

    function renderGraph() {
        if (loading) {
            return <Loader fullContainer />;
        }

        if (nodes && links) {
            return (
                <AssetGraph
                    className={styles.GraphPage}
                    links={links}
                    nodes={nodes}
                />
            );
        }

        return null;
    }

    return (
        <GraphBaseLayout>
            <Seo title="Graph" />
            {renderGraph()}
        </GraphBaseLayout>
    );
}

export default GraphPage;
