import { API_TAGS, rtkApi } from 'shared/api/rtkApi';
import { ApiResponse } from 'shared/types/pagination';
import { AssetName, RootAssetName } from 'entities/Asset';

export interface VulnerableAssetQueryParams {
    valueIncludes?: string;
    size?: number;
    page?: number;
}

export interface RootAssetQueryParams
    extends Omit<VulnerableAssetQueryParams, 'valueIncludes'> {}

const dictionariesApi = rtkApi.injectEndpoints({
    endpoints: builder => ({
        getRootAssetNames: builder.query<
            ApiResponse<RootAssetName>,
            RootAssetQueryParams
        >({
            query: params => ({
                url: '/dictionaries/root-asset-names',
                params
            }),
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({
                              type: API_TAGS.ROOT_ASSET_NAMES,
                              id
                          })),
                          API_TAGS.ROOT_ASSET_NAMES
                      ]
                    : [API_TAGS.ROOT_ASSET_NAMES]
        }),
        getVulnerableAssetNames: builder.query<
            ApiResponse<AssetName>,
            VulnerableAssetQueryParams
        >({
            query: params => ({
                url: '/dictionaries/vulnerable-asset-names',
                params
            }),
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({
                              type: API_TAGS.VULNERABLE_ASSET_NAMES,
                              id
                          })),
                          API_TAGS.VULNERABLE_ASSET_NAMES
                      ]
                    : [API_TAGS.VULNERABLE_ASSET_NAMES]
        })
    })
});

export const { useGetRootAssetNamesQuery, useGetVulnerableAssetNamesQuery } =
    dictionariesApi;
