import React, { memo, useMemo, useState } from 'react';
import {
    Asset,
    useGetRelatedAssets,
    RelatedAssetsQueryParams,
    AssetQueryParams,
    AssetType,
    WithMetaType
} from 'entities/Asset';
import { GenericTable } from 'shared/ui/DataTable';
import { DataTableColumn } from 'mantine-datatable';
import lastSeenColumn from 'widgets/Columns/lastSeenColumn';
import createdAtColumn from 'widgets/Columns/createdAtColumn';
import tagsColumn from 'widgets/Columns/tagsColumn';
import directVulnerabilitiesCountColumn from 'widgets/Columns/directVulnerabilitiesCountColumn';
import sslCertificateIssuerColumn from 'widgets/Columns/sslCertificateIssuerColumn';
import sslCertificateNotValidAfterColumn from 'widgets/Columns/sslCertificateNotValidAfterColumn';
import sslCertificateNotValidBeforeColumn from 'widgets/Columns/sslCertificateNotValidBeforeColumn';
import assetNameColumn from 'widgets/Columns/assetNameColumn';
import sslCertificateSubjectColumn from 'widgets/Columns/sslCertificateSubjectColumn';
import sslCertificateStatusColumn from 'widgets/Columns/sslCertificateStatusColumn';

interface RelatedAssetsTableProps {
    assetID: string;
    relationType?: string | string[];
    title?: string;
}

const RelatedAssetsTable = (props: RelatedAssetsTableProps) => {
    const { title, assetID, relationType } = props;
    const [query, setQuery] = useState<AssetQueryParams>({
        withMeta: [
            WithMetaType.GEOIP,
            WithMetaType.WHOIS_RECORD,
            WithMetaType.SSL_CERTIFICATE,
            WithMetaType.PORT,
            WithMetaType.SUBNETWORK
        ]
    });
    const relatedAssetsQuery: RelatedAssetsQueryParams = useMemo(
        () => ({
            ...query,
            assetID,
            relationType
        }),
        [query, assetID, relationType]
    );

    const isSSL = relationType === AssetType.SSL;

    const columns = useMemo<DataTableColumn<Asset>[]>(
        () => [
            assetNameColumn({
                query,
                setQuery
            }),
            sslCertificateSubjectColumn({
                column: {
                    hidden: !isSSL
                }
            }),
            sslCertificateStatusColumn({
                column: {
                    hidden: !isSSL
                }
            }),
            sslCertificateNotValidBeforeColumn({
                column: {
                    hidden: !isSSL
                }
            }),
            sslCertificateNotValidAfterColumn({
                column: {
                    hidden: !isSSL
                }
            }),
            sslCertificateIssuerColumn({
                column: {
                    hidden: !isSSL
                }
            }),
            tagsColumn({
                query,
                setQuery
            }),
            directVulnerabilitiesCountColumn({
                query,
                setQuery
            }),
            lastSeenColumn({
                query,
                setQuery
            }),
            createdAtColumn({
                query,
                setQuery
            })
        ],
        [isSSL, query]
    );

    return (
        <GenericTable<Asset>
            // @ts-ignore
            useData={useGetRelatedAssets}
            columns={columns}
            queryParams={relatedAssetsQuery}
            defaultSortStatus={{
                columnAccessor: 'lastSeenAt',
                direction: 'desc'
            }}
            hasToolbar
            hasDownload
            title={title}
        />
    );
};

export default memo(RelatedAssetsTable);
