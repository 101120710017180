import { useSelector } from 'react-redux';
import { getUserAuthData } from '../selectors/getUser/getUser';

export const useUser = () => {
    const user = useSelector(getUserAuthData);
    const isAuthenticated = !!user;

    return {
        user,
        isAuthenticated
    };
};
