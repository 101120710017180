import { memo } from 'react';
import classNames from 'classnames';
import styles from './Card.module.scss';

interface CardThemes {
    [key: string]: string;
}

export interface CardProps {
    className?: string;
    headerClassName?: string;
    contentClassName?: string;
    theme?: keyof CardThemes;
    header?: React.ReactNode;
    actions?: React.ReactNode;
    children?: React.ReactNode;
}

const Card = (props: CardProps) => {
    const {
        className,
        headerClassName,
        contentClassName,
        theme,
        header,
        actions,
        children
    } = props;
    const classes = classNames(
        styles.Card,
        {
            [styles[theme as keyof CardThemes]]: theme
        },
        className
    );
    const headerClasses = classNames(styles.Card__Header, headerClassName);
    const contentClasses = classNames(styles.Card__Content, contentClassName);

    return (
        <div className={classes}>
            {header && (
                <div className={headerClasses}>
                    <div className={styles.Card__Header__Title}>{header}</div>
                    {actions && (
                        <div className={styles.Card__Header__Actions}>
                            {actions}
                        </div>
                    )}
                </div>
            )}
            <div className={contentClasses}>{children}</div>
        </div>
    );
};

export default memo(Card);
