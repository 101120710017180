import React, { useMemo, useState } from 'react';
import { Layout, LayoutContext } from '../lib/LayoutContext';

const defaultLayout = Layout.Collapsed;
const defaultIsCollapsed = defaultLayout === Layout.Collapsed;

interface LayoutProviderProps {
    children: React.ReactNode;
}

const LayoutProvider = (props: LayoutProviderProps) => {
    const { children } = props;
    const [layout, setLayout] = useState<Layout>(defaultLayout);
    const [isCollapsed, setIsCollapsed] = useState<boolean>(defaultIsCollapsed);

    const defaultProps = useMemo(
        () => ({
            layout,
            setLayout,
            isCollapsed,
            setIsCollapsed
        }),
        [layout, isCollapsed, setLayout, setIsCollapsed]
    );

    return (
        <LayoutContext.Provider value={defaultProps}>
            {children}
        </LayoutContext.Provider>
    );
};

export default LayoutProvider;
