import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import classNames from 'classnames';
import ForceGraph2D, {
    ForceGraphMethods,
    ForceGraphProps,
    LinkObject,
    NodeObject
} from 'react-force-graph-2d';
import * as d3Force from 'd3-force';
import { SizeMeProps, withSize } from 'react-sizeme';
import { AssetTypeIcon, GraphLink, GraphNode } from 'entities/Asset';
import {
    assetDetailsRoute,
    rootAssetDetailsRoute
} from 'app/providers/RouteProvider';
import { Icon, Icons, IconSize } from 'shared/ui/Icon';
import { useEscapeKey } from 'shared/hooks/useEscapeKey/useEscapeKey';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { navigationActions, NavigationPageType } from 'entities/Navigation';
import { Text } from 'shared/ui/Text';
import { ActionIcon, Tooltip } from '@mantine/core';
import { Loader } from 'shared/ui/Loader';
import styles from './AssetGraph.module.scss';

const withSizeHOC = withSize({
    monitorWidth: true,
    monitorHeight: true,
    noPlaceholder: true
});

type NodeType = NodeObject<GraphNode>;
type EdgeType = LinkObject<GraphLink>;
type GraphType = ForceGraphProps<GraphNode, GraphLink>;
type GraphMethodsType = ForceGraphMethods<GraphNode, GraphLink>;

interface GraphProps extends SizeMeProps {
    className?: string;
    nodes: GraphNode[];
    links: GraphLink[];
    sourceAssetID?: string;
    nodeLabel?: string;
    withArrows?: boolean;
}

enum COLORS {
    PRIMARY_1 = '#3254b5',
    PRIMARY_2 = '#7878ff',
    SECONDARY_1 = '#ff0181',
    SECONDARY_2 = '#fda800'
}

const ZOOM_THRESHOLD = 0.375;
const MAX_ZOOM = 1.3;
const MIN_ZOOM = 0.05;
const WARMUP_TICKS = 15;
const COOLDOWN_TICKS = 200;
const LINK_WIDTH = 1;
const LINK_DIRECTIONAL_PARTICLES = 4;
const LINK_DIRECTIONAL_PARTICLE_WIDTH = 3;
const LINK_DIRECTIONAL_ARROW_LENGTH = 12;
const NODE_ICONS_PATH = '/assets/icons';

const NODE_RADIUS = 16;
const NODE_BACKGROUND_RADIUS = NODE_RADIUS * 0.95;
const NODE_ICON_SIZE = NODE_RADIUS * 1.55;

const NODE_HEALTHY_BACKGROUND_COLOR = '#5d9c59';
const NODE_VULNERABLE_BACKGROUND_COLOR = '#df2e38';
const NODE_ROOT_BACKGROUND_COLOR = '#7878ff';

const ROOT_DEFAULT_ICON = new Image();
ROOT_DEFAULT_ICON.src = `${NODE_ICONS_PATH}/language.png?v2`;

const ROOT_IP_ICON = new Image();
ROOT_IP_ICON.src = `${NODE_ICONS_PATH}/location_on.png?v2`;

const ROOT_CLOUD_ICON = new Image();
ROOT_CLOUD_ICON.src = `${NODE_ICONS_PATH}/cloud.png?v2`;

const ROOT_ORGANIZATION_ICON = new Image();
ROOT_ORGANIZATION_ICON.src = `${NODE_ICONS_PATH}/apartment.png?v2`;

const AssetGraph = (props: GraphProps) => {
    const {
        className,
        nodes,
        links,
        size,
        sourceAssetID,
        nodeLabel = 'name',
        withArrows
    } = props;

    const NODE_ICONS = useMemo(
        () =>
            Object.keys(AssetTypeIcon).reduce(
                (acc, assetType) => {
                    const img = new Image();
                    // @ts-ignore
                    const imgSource = `${NODE_ICONS_PATH}/${AssetTypeIcon[assetType]}`;
                    img.src = imgSource.replace('.svg', '.png?v2');
                    return {
                        ...acc,
                        [assetType]: img
                    };
                },
                {
                    ROOT_ORGANIZATION: ROOT_ORGANIZATION_ICON,
                    ROOT_DOMAIN: ROOT_DEFAULT_ICON,
                    ROOT_IPV4: ROOT_IP_ICON,
                    ROOT_IPV6: ROOT_IP_ICON,
                    ROOt_CIDR: ROOT_IP_ICON,
                    ROOT_CLOUD: ROOT_CLOUD_ICON
                }
            ),
        []
    );

    const [fullscreen, setFullscreen] = useState(false);
    const classes = classNames(styles.GraphWrapper, className, {
        [styles.FullScreen]: fullscreen
    });

    const graphWrapper = useRef<HTMLDivElement>(null);
    const graph = useRef<GraphMethodsType>();
    const zoom = useRef<number>(1);

    const [initialFittedToCanvas, setInitialFittedToCanvas] = useState(false);
    const graphData: GraphType['graphData'] = useMemo(
        () => ({
            nodes,
            links
        }),
        [nodes, links]
    );

    const dispatch = useAppDispatch();
    const [highlightNodes, setHighlightNodes] = useState(new Set());
    const [highlightLinks, setHighlightLinks] = useState(new Set());
    const [hoveredNode, setHoveredNode] = useState<NodeType>();
    const [clickedNode, setClickedNode] = useState<NodeType>();

    const nodeCanvasObject = useCallback(
        (node: NodeType, ctx: CanvasRenderingContext2D) => {
            if (
                graph &&
                graph.current &&
                node &&
                node.x !== undefined &&
                node.y !== undefined
            ) {
                const img =
                    // @ts-ignore
                    NODE_ICONS[
                        `${
                            node.isRoot ? 'ROOT_' : ''
                        }${node.type.toUpperCase()}`
                    ];
                const RADIUS_MULTIPLIER = node.isRoot ? 2 : 1;
                const isSource = node.id === sourceAssetID;

                if (
                    (hoveredNode || clickedNode) &&
                    highlightNodes.has(node.id)
                ) {
                    ctx.fillStyle =
                        hoveredNode?.id === node.id ||
                        clickedNode?.id === node.id
                            ? COLORS.SECONDARY_2
                            : COLORS.PRIMARY_2;
                    ctx.beginPath();
                    ctx.arc(
                        node.x,
                        node.y,
                        NODE_RADIUS * RADIUS_MULTIPLIER * 1.05,
                        0,
                        2 * Math.PI,
                        false
                    );
                    ctx.fill();
                }

                // draw background circle border
                switch (true) {
                    case isSource:
                        ctx.fillStyle = NODE_ROOT_BACKGROUND_COLOR;
                        break;
                    case node.hasVulnerabilities:
                        ctx.fillStyle = NODE_VULNERABLE_BACKGROUND_COLOR;
                        break;
                    default:
                        ctx.fillStyle = NODE_HEALTHY_BACKGROUND_COLOR;
                }

                ctx.beginPath();
                ctx.arc(
                    node.x,
                    node.y,
                    NODE_BACKGROUND_RADIUS * RADIUS_MULTIPLIER,
                    0,
                    2 * Math.PI,
                    false
                );
                ctx.fill();

                if (zoom.current > ZOOM_THRESHOLD) {
                    ctx.drawImage(
                        img ?? ROOT_DEFAULT_ICON,
                        node.x - (NODE_ICON_SIZE * RADIUS_MULTIPLIER) / 2,
                        node.y - (NODE_ICON_SIZE * RADIUS_MULTIPLIER) / 2,
                        NODE_ICON_SIZE * RADIUS_MULTIPLIER,
                        NODE_ICON_SIZE * RADIUS_MULTIPLIER
                    );
                }
            }
        },
        [NODE_ICONS, clickedNode, highlightNodes, hoveredNode, sourceAssetID]
    );

    const highlightNeighbors = (
        node: NodeType | null = null,
        reset: Boolean = true,
        callback: () => void = () => {}
    ) => {
        if (reset) {
            highlightNodes.clear();
            highlightLinks.clear();
        }

        if (node) {
            const targetEdge = links.filter(
                // @ts-ignore
                edge => edge.target?.id === node.id
            );
            const sourceEdges = links.filter(
                // @ts-ignore
                edge => edge.source?.id === node.id
            );

            highlightNodes.add(node.id);
            // @ts-ignore
            targetEdge.forEach(edge => highlightNodes.add(edge.source.id));
            // @ts-ignore
            sourceEdges.forEach(edge => highlightNodes.add(edge.target.id));
            targetEdge.forEach(edge => highlightLinks.add(edge.id));
            sourceEdges.forEach(edge => highlightLinks.add(edge.id));
        }

        setHighlightNodes(highlightNodes);
        setHighlightLinks(highlightLinks);
        callback();
    };

    const resetHighlight = () => {
        setHoveredNode(undefined);
        setClickedNode(undefined);
        setHighlightNodes(new Set());
        setHighlightLinks(new Set());
    };

    const handleNodeHover = (node: NodeType | null) => {
        if (clickedNode) {
            return;
        }

        if (!node) {
            resetHighlight();
        }

        highlightNeighbors(node, true, () => {
            setHoveredNode(node || undefined);
        });
    };

    const handleNodeClick = (node: NodeType | null) => {
        if (clickedNode && clickedNode.id === node?.id) {
            resetHighlight();
            return;
        }

        if (node) {
            highlightNeighbors(node, true, () => {
                if (node.isRoot) {
                    dispatch(
                        navigationActions.addToDrawerStack({
                            pageLink: rootAssetDetailsRoute(node.id),
                            pageType: NavigationPageType.ROOT_ASSET_DETAILS,
                            pageID: node.id,
                            pageTitle: node.name
                        })
                    );
                } else {
                    dispatch(
                        navigationActions.addToDrawerStack({
                            pageLink: assetDetailsRoute(node.id, node.type),
                            pageType: NavigationPageType.ASSET_DETAILS,
                            pageID: node.id,
                            pageTitle: node.name
                        })
                    );
                }
            });
        }
    };

    const onEngineStop = useCallback(() => {
        if (graph.current && !initialFittedToCanvas) {
            graph.current?.zoomToFit(300, 50);
            setInitialFittedToCanvas(true);
        }
    }, [initialFittedToCanvas]);

    const onZoom = useCallback((zoomLevel: number) => {
        zoom.current = zoomLevel;
    }, []);

    const linkDirectionalArrowLength = withArrows
        ? LINK_DIRECTIONAL_ARROW_LENGTH
        : 0;

    const linkWidth = (link: EdgeType) => {
        if (highlightLinks.has(link.id)) {
            return LINK_WIDTH * 2;
        }

        return LINK_WIDTH;
    };

    const linkDirectionalParticles = (link: EdgeType) => {
        if (highlightLinks.has(link.id)) {
            return LINK_DIRECTIONAL_PARTICLES;
        }
        return 0;
    };

    const linkDirectionalParticleWidth = (link: EdgeType) => {
        if (highlightLinks.has(link.id)) {
            return LINK_DIRECTIONAL_PARTICLE_WIDTH;
        }
        return 0;
    };

    useEffect(() => {
        if (graph.current) {
            const width = graphWrapper.current?.clientWidth || 0;
            const height = graphWrapper.current?.clientHeight || 0;

            graph.current
                ?.d3Force('link', d3Force.forceLink().distance(100))
                .d3Force('center', d3Force.forceCenter(width / 2, height / 2))
                .d3Force(
                    'charge',
                    d3Force.forceManyBody().strength(-100).distanceMin(100)
                )
                .d3Force(
                    'collide',
                    d3Force
                        .forceCollide()
                        .radius(d => {
                            // @ts-ignore
                            if (d.isRoot) {
                                return NODE_RADIUS * 10;
                            }
                            return NODE_RADIUS * 1.25;
                        })
                        .iterations(3)
                );
        }
    }, [graph]);

    const handleCenter = useCallback(() => {
        graph.current?.zoomToFit(300, 0);
    }, [graph]);

    const handleScreenshot = useCallback(() => {
        const graphCanvas = graphWrapper.current?.querySelector('canvas');
        const saveCanvas = document.createElement('canvas');
        const context = saveCanvas.getContext('2d');

        if (!graphCanvas || !context) {
            return;
        }

        saveCanvas.width = graphCanvas.width;
        saveCanvas.height = graphCanvas.height;
        context.fillStyle = '#fff';
        context.fillRect(0, 0, saveCanvas.width, saveCanvas.height);
        context.drawImage(graphCanvas, 0, 0);

        const image = saveCanvas?.toDataURL('image/png');
        const link = document.createElement('a');
        link.download = sourceAssetID ? `${sourceAssetID}.png` : 'graph.png';
        link.href = image || '';
        link.click();
    }, [sourceAssetID]);

    const handleFullScreenClick = useCallback(() => {
        setFullscreen(prev => !prev);
        setTimeout(() => handleCenter(), 100);
    }, [handleCenter]);

    useEscapeKey({
        handleClose: handleFullScreenClick,
        enabled: fullscreen
    });

    return (
        <div className={classes} ref={graphWrapper}>
            <div
                className={classNames(
                    styles.GraphWrapper__Graph,
                    !initialFittedToCanvas &&
                        styles.GraphWrapper__Graph__Loading
                )}
            >
                <ForceGraph2D
                    ref={graph}
                    maxZoom={MAX_ZOOM}
                    minZoom={MIN_ZOOM}
                    width={size.width || 0}
                    height={size.height || 0}
                    nodeLabel={nodeLabel}
                    enableNodeDrag={false}
                    graphData={graphData}
                    warmupTicks={WARMUP_TICKS}
                    cooldownTicks={COOLDOWN_TICKS}
                    nodeRelSize={NODE_RADIUS}
                    linkWidth={linkWidth}
                    linkDirectionalArrowLength={linkDirectionalArrowLength}
                    linkDirectionalParticles={linkDirectionalParticles}
                    linkDirectionalParticleWidth={linkDirectionalParticleWidth}
                    nodeCanvasObject={nodeCanvasObject}
                    onEngineStop={onEngineStop}
                    onZoom={({ k }) => onZoom(k)}
                    onNodeHover={node => handleNodeHover(node)}
                    onNodeClick={node => handleNodeClick(node)}
                    onBackgroundClick={() => resetHighlight()}
                />
            </div>
            {nodes.length > 0 && (
                <>
                    {!initialFittedToCanvas && (
                        <Loader fullContainer absolute />
                    )}
                    <div className={styles.GraphWrapper__Controls}>
                        <Tooltip label="Re-center" position="left" withArrow>
                            <ActionIcon
                                size="xl"
                                color="black"
                                variant="outline"
                                aria-label="Re-center"
                                onClick={handleCenter}
                            >
                                <Icon
                                    icon={Icons.LOCATION_ON}
                                    size={IconSize.MEDIUM}
                                />
                            </ActionIcon>
                        </Tooltip>
                        <Tooltip
                            label="Take Screenshot"
                            position="left"
                            withArrow
                        >
                            <ActionIcon
                                size="xl"
                                color="black"
                                variant="outline"
                                aria-label="Take Screenshot"
                                onClick={handleScreenshot}
                            >
                                <Icon
                                    icon={Icons.PHOTO_CAMERA}
                                    size={IconSize.MEDIUM}
                                />
                            </ActionIcon>
                        </Tooltip>
                    </div>
                </>
            )}
            {nodes.length === 0 && (
                <div className={styles.GraphWrapper__Empty}>
                    <Text size="l">No assets to display</Text>
                </div>
            )}
        </div>
    );
};

export default withSizeHOC(AssetGraph);
