import { memo } from 'react';
import classNames from 'classnames';
import styles from './MetadataBar.module.scss';

interface MetadataBarProps {
    className?: string;
    children?: React.ReactNode;
}

const MetadataBar = (props: MetadataBarProps) => {
    const { className, children } = props;
    const classes = classNames(styles.MetadataBar, className);

    return <div className={classes}>{children}</div>;
};

export default memo(MetadataBar);
