import { Stack, Text } from '@mantine/core';
import React from 'react';
import { ExternalLink } from 'shared/ui/ExternalLink';
import { IconSize } from 'shared/ui/Icon';
import KnowledgeBasePost from '../KnowledgeBasePost/KnowledgeBasePost';

const Content = () => (
    <Stack>
        <Text>
            The Exploit Prediction Scoring System (EPSS) is a data-driven effort
            for estimating the likelihood (probability) that a software
            vulnerability will be exploited in the wild. Our goal is to assist
            network defenders to better prioritize vulnerability remediation
            efforts. While other industry standards have been useful for
            capturing innate characteristics of a vulnerability and provide
            measures of severity, they are limited in their ability to assess
            threat. EPSS fills that gap because it uses current threat
            information from CVE and real-world exploit data. The EPSS model
            produces a probability score between 0 and 1 (0 and 100%). The
            higher the score, the greater the probability that a vulnerability
            will be exploited.
        </Text>
        <Text>
            Reference:{' '}
            <ExternalLink href="https://www.first.org/epss/">
                https://www.first.org/epss/
            </ExternalLink>
        </Text>
    </Stack>
);

const EpssPost = () => (
    <KnowledgeBasePost
        title="Exploit Prediction Scoring System (EPSS)"
        content={<Content />}
        modalSize="800px"
        actionIconSize={IconSize.SMALL}
    />
);

export default EpssPost;
