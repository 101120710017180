import React, { useState } from 'react';
import { Group, SegmentedControl, SegmentedControlProps } from '@mantine/core';
import { Status, StatusIcon, StatusId } from 'entities/Issue';
import { Icon, Icons, IconSize } from 'shared/ui/Icon';
import { statusColor } from 'entities/Issue/model/lib/statusColor/statusColor';

export interface FilterBySeverityRatingFormProps
    extends Omit<SegmentedControlProps, 'data'> {
    status?: Status;
    onChangeStatus?: (status: StatusId) => void;
}

const FilterByStatusControl = (props: FilterBySeverityRatingFormProps) => {
    const { status, onChangeStatus, ...segmentedControlProps } = props;
    const [id, setId] = useState<StatusId>(status?.id ?? 'open');

    return (
        <SegmentedControl
            size="md"
            color={statusColor(id)}
            data={[
                {
                    value: 'open',
                    label: (
                        <Group justify="center" gap="4">
                            <Icon
                                icon={StatusIcon.open as unknown as Icons}
                                size={IconSize.MEDIUM}
                            />
                            <span>Open</span>
                        </Group>
                    )
                },
                {
                    value: 'fixed',
                    label: (
                        <Group justify="center" gap="4">
                            <Icon
                                icon={StatusIcon.fixed as unknown as Icons}
                                size={IconSize.MEDIUM}
                            />
                            <span>Fixed</span>
                        </Group>
                    )
                },
                {
                    value: 'accepted',
                    label: (
                        <Group justify="center" gap="4">
                            <Icon
                                icon={StatusIcon.accepted as unknown as Icons}
                                size={IconSize.MEDIUM}
                            />
                            <span>Accepted risk</span>
                        </Group>
                    )
                },
                {
                    value: 'fp',
                    label: (
                        <Group justify="center" gap="4">
                            <Icon
                                icon={StatusIcon.fp as unknown as Icons}
                                size={IconSize.MEDIUM}
                            />
                            <span>False positive</span>
                        </Group>
                    )
                }
            ]}
            defaultValue={status?.id ?? 'open'}
            onChange={(value: string) => {
                setId(value as StatusId);
                onChangeStatus?.(value as StatusId);
            }}
            {...segmentedControlProps}
        />
    );
};

export default FilterByStatusControl;
