import React, { memo, useEffect } from 'react';
import { Box, Group, SegmentedControl, Stack, Text } from '@mantine/core';
import HeaderedCard from 'shared/ui/HeaderedCard/HeaderedCard';
import { useForm } from '@mantine/form';
import { showNotification, notifications } from '@mantine/notifications';
import { GlobalScanModePost } from 'entities/KnowledgeBase';
import { Icon, Icons, IconSize } from 'shared/ui/Icon';
import {
    useGetScannerConfigQuery,
    useUpdateScannerConfigMutation
} from '../../api/scannerConfigApi/scannerConfigApi';
import { ScanMode, GlobalScannerConfig } from '../../model/types/config';
import ScannerModeControl from '../ScannerModeControl/ScannerModeControl';

interface ScannerSettingsProps {
    title: string;
    icon?: Icons;
}

const GlobalScannerSettings = (props: ScannerSettingsProps) => {
    const { title, icon } = props;
    const { data: config, isLoading } = useGetScannerConfigQuery();
    const [updateConfig] = useUpdateScannerConfigMutation();
    const form = useForm<GlobalScannerConfig>();

    useEffect(() => {
        if (config && !form.isDirty()) {
            form.setValues(config);
        }
    }, [config, form.isDirty]);

    useEffect(() => {
        if (form.isTouched()) {
            notifications.clean();
            updateConfig(form.values)
                .unwrap()
                .then(() => {
                    showNotification({
                        message: 'Scanner settings updated',
                        color: 'green'
                    });
                })
                .catch(() => {
                    showNotification({
                        message: 'Failed to update notification settings',
                        color: 'red'
                    });
                });
        }
    }, [form.values]);

    return (
        <HeaderedCard
            header={title}
            headerIcon={icon && <Icon icon={icon} size={IconSize.MEDIUM} />}
        >
            <Stack>
                <Group justify="space-between">
                    <Box>
                        <Group gap={4}>
                            <Text>Scan mode</Text>
                            <GlobalScanModePost />
                        </Group>
                        <Text size="xs" c="dimmed">
                            Different scan modes can be used to optimize the
                            scanner for your needs
                        </Text>
                    </Box>
                    <ScannerModeControl
                        value={config?.scanMode || ScanMode.ACTIVE}
                        onChange={value =>
                            form.setFieldValue('scanMode', value)
                        }
                    />
                </Group>
            </Stack>
        </HeaderedCard>
    );
};

export default memo(GlobalScannerSettings);
