import React, { memo } from 'react';
import PriceCard from './PriceCard';
import { PlanSchema } from '../../model/types/pricing';

interface TrialPriceCardProps {
    isActive: boolean;
}

const TrialPriceCard = (props: TrialPriceCardProps) => {
    const { isActive } = props;

    return (
        <PriceCard
            schema={PlanSchema.TRIAL}
            price={0}
            period="mo"
            assets={0}
            isActive={isActive}
            isTrial
        />
    );
};

export default memo(TrialPriceCard);
