import React, { memo } from 'react';
import { Button, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import ChangePasswordForm from '../ChangePasswordForm/ChangePasswordForm';

const ChangePasswordModal = () => {
    const [opened, { open, close }] = useDisclosure(false);

    return (
        <>
            <Modal
                size="lg"
                title="Change password"
                opened={opened}
                onClose={close}
            >
                <ChangePasswordForm onClose={close} />
            </Modal>
            <Button color="asm" variant="outline" onClick={open}>
                Change password
            </Button>
        </>
    );
};

export default memo(ChangePasswordModal);
