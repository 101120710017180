import React, { memo } from 'react';
import { NavigationLink, NavigationPageType } from 'entities/Navigation';
import { Vulnerability } from '../../model/types/vulnerability';
import { useVulnerabilityDetailsLink } from '../../hooks/useVulnerabilityDetailsLink/useVulnerabilityDetailsLink';

interface VulnerabilityLinkProps {
    vulnerability: Vulnerability;
    children?: React.ReactNode;
}

const VulnerabilityLink = (props: VulnerabilityLinkProps) => {
    const { vulnerability, children } = props;
    const { link } = useVulnerabilityDetailsLink(vulnerability.id);

    return (
        <NavigationLink
            pageLink={link}
            pageType={NavigationPageType.ISSUE_DETAILS}
            pageID={vulnerability.id}
        >
            {children || vulnerability.name}
        </NavigationLink>
    );
};

export default memo(VulnerabilityLink);
