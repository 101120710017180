import React, { memo } from 'react';
import { AssetsTable } from 'widgets/Asset';
import AssetsBaseLayout from 'pages/Assets/ui/AssetsBaseLayout/AssetsBaseLayout';
import { Seo } from 'shared/ui/SEO/SEO';
import { useAssetSearchParams } from 'entities/Asset';

function AllAssetsPage() {
    const { createdAtGte, createdAtLte } = useAssetSearchParams();

    return (
        <AssetsBaseLayout>
            <Seo title="Assets" />
            <AssetsTable
                defaultCreatedAtGte={createdAtGte}
                defaultCreatedAtLte={createdAtLte}
            />
        </AssetsBaseLayout>
    );
}

export default memo(AllAssetsPage);
