import React, { memo } from 'react';
import classNames from 'classnames';
import { Icon, Icons, IconSize } from 'shared/ui/Icon';
import { Button, ButtonTheme } from 'shared/ui/Button';
import { ButtonProps } from '../../ui/Button';

interface CloseButtonProps extends ButtonProps {
    className?: string;
    label?: string;
    backPageTitle?: string;
    onBack: () => void;
}

const BackButton = (props: CloseButtonProps) => {
    const { className, label = 'Back', backPageTitle, onBack } = props;
    const classes = classNames(className);

    return (
        <Button theme={ButtonTheme.CLEAR} className={classes} onClick={onBack}>
            <Icon icon={Icons.CHEVRON_LEFT} size={IconSize.MEDIUM} />
            {label}
            {backPageTitle && ` to ${backPageTitle}`}
        </Button>
    );
};

export default memo(BackButton);
