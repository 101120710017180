export interface Invoice {
    id: number;
    created_at: {
        date: string;
        timezone_type: number;
        timezone: string;
    };
    due_at: string;
}

export interface PaymentState {
    name: string;
    color: string;
    reason: string | null;
}

export enum PaymentMethod {
    CREDIT_CARD = 'credit_card',
    PAYPAL = 'paypal',
    BANK_TRANSFER = 'bank_transfer'
}

export interface Payment {
    created_at: string;
    subscription_id: number;
    payment_id: number;
    product: {
        id: number;
        name: string;
        version: number;
    };
    plan_desc: string;
    credits: number;
    credits_desc: string;
    amount: number;
    payment_method: PaymentMethod;
    invoice: Invoice;
    state: PaymentState;
}
