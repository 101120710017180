import {
    Asset,
    RootAsset,
    RootAssetCandidate,
    WhoisCreatedDate,
    WhoisExpiresDate,
    WhoisRegistrarName
} from 'entities/Asset';
import { Group } from '@mantine/core';
import React from 'react';
import HeaderedCard from 'shared/ui/HeaderedCard/HeaderedCard';
import { FoundOnPagesTable, RelatedAssetsTable } from 'widgets/Asset';

interface DomainOverviewProps {
    asset: Asset | RootAsset | RootAssetCandidate;
}

const DomainOverview = (props: DomainOverviewProps) => {
    const { asset } = props;

    return (
        <>
            <Group gap="lg" grow>
                <HeaderedCard header="Registrar name">
                    <WhoisRegistrarName
                        whoisRecord={asset.metadata?.whoisRecord}
                    />
                </HeaderedCard>
                <HeaderedCard header="Domain registered date">
                    <WhoisCreatedDate
                        whoisRecord={asset.metadata?.whoisRecord}
                    />
                </HeaderedCard>
                <HeaderedCard header="Domain expires date">
                    <WhoisExpiresDate
                        whoisRecord={asset.metadata?.whoisRecord}
                    />
                </HeaderedCard>
            </Group>
            <RelatedAssetsTable
                assetID={asset.id}
                relationType="ssl"
                title="SSL certificates"
            />
            {asset.foundOnPages && asset.foundOnPages.length > 0 && (
                <HeaderedCard
                    header="Discovered on the following pages"
                    bodyPadding="0"
                >
                    <FoundOnPagesTable pages={asset.foundOnPages} />
                </HeaderedCard>
            )}
        </>
    );
};

export default DomainOverview;
