import React, { memo, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Box, Portal } from '@mantine/core';
import { useSelector } from 'react-redux';
import {
    getNavigationDrawerOpen,
    getNavigationDrawerStack,
    getNavigationScrollToTop,
    navigationActions,
    NavigationPageType
} from 'entities/Navigation';
import { useEscapeKey } from 'shared/hooks/useEscapeKey/useEscapeKey';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { useLocation } from 'react-router-dom';
import {
    AssetCandidatesDetailsPage,
    RootAssetDetailsPage
} from 'pages/Management';
import { AssetDetailsPage } from 'pages/Assets';
import { IssueDetailsPage } from 'pages/Issues';
import { TechnologyDetailsPage } from 'pages/Technologies';
import styles from './StackedDrawer.module.scss';
import StackedDrawerHeader from '../StackedDrawerHeader/StackedDrawerHeader';

const CLOSE_TIMEOUT = 350;

const StackedDrawer = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();

    const drawerScrollToTop = useSelector(getNavigationScrollToTop);
    const drawerStack = useSelector(getNavigationDrawerStack);
    const drawerOpened = useSelector(getNavigationDrawerOpen);

    const classes = classNames(styles.StackedDrawer);

    const [isClosing, setIsClosing] = useState(false);
    const isClosingRef = React.useRef<ReturnType<typeof setTimeout>>();
    const pageRefs = React.useRef<HTMLDivElement[]>();

    const onCloseHandler = useCallback(() => {
        setIsClosing(true);
        isClosingRef.current = setTimeout(() => {
            dispatch(navigationActions.resetDrawerStack());
            pageRefs.current = [];
            setIsClosing(false);
        }, CLOSE_TIMEOUT);
    }, [dispatch]);

    const onBackHandler = useCallback(
        (pageIndex: number) => {
            if (pageRefs && pageRefs.current && pageRefs.current.length > 0) {
                const page = pageRefs.current[pageIndex];
                page.classList.add(styles.StackedDrawer__Content__Closing);
                setTimeout(() => {
                    dispatch(navigationActions.removeFromDrawerStack());
                    page.classList.remove(
                        styles.StackedDrawer__Content__Closing
                    );
                }, CLOSE_TIMEOUT);
            }
        },
        [dispatch]
    );

    useEffect(() => {
        if (drawerScrollToTop) {
            dispatch(navigationActions.setScrollToTop(false));
            if (pageRefs.current && pageRefs.current.length > 0) {
                const lastPage = pageRefs.current[pageRefs.current.length - 1];
                const lastPageScrollPosition = lastPage.scrollTop;
                if (lastPageScrollPosition > 0) {
                    lastPage.scroll({ top: 0, behavior: 'smooth' });
                } else {
                    lastPage.animate(
                        [
                            { transform: 'translateX(0)' },
                            { transform: 'translateX(-10px)' },
                            { transform: 'translateX(0)' }
                        ],
                        {
                            duration: CLOSE_TIMEOUT,
                            easing: 'ease-in-out'
                        }
                    );
                }
            }
        }
    }, [dispatch, drawerScrollToTop]);

    useEffect(() => {
        if (drawerStack.length) {
            onCloseHandler();
        }
    }, [location]);

    useEscapeKey({
        handleClose: onCloseHandler,
        enabled: true
    });

    const renderPage = useCallback(
        (pageType: NavigationPageType, pageID: string) => {
            switch (pageType) {
                case NavigationPageType.ROOT_ASSET_DETAILS:
                    return <RootAssetDetailsPage id={pageID} />;
                case NavigationPageType.ASSET_DETAILS:
                    return <AssetDetailsPage id={pageID} />;
                case NavigationPageType.ROOT_ASSET_CANDIDATE_DETAILS:
                    return <AssetCandidatesDetailsPage id={pageID} />;
                case NavigationPageType.ISSUE_DETAILS:
                    return <IssueDetailsPage id={pageID} />;
                case NavigationPageType.TECHNOLOGY_DETAILS:
                    return <TechnologyDetailsPage id={pageID} />;
                default:
                    return null;
            }
        },
        []
    );

    if (!drawerOpened) {
        return null;
    }

    return (
        <Portal>
            <Box
                className={classNames(classes, {
                    [styles.StackedDrawer__Closing]: isClosing
                })}
            >
                <div
                    className={styles.StackedDrawer__Overlay}
                    onClick={onCloseHandler}
                />
                {drawerStack.map((page, index) => (
                    <div
                        key={page.pageID}
                        className={styles.StackedDrawer__Content}
                        ref={ref => {
                            if (ref) {
                                pageRefs.current = pageRefs.current || [];
                                pageRefs.current[index] = ref;
                            }
                        }}
                    >
                        <StackedDrawerHeader
                            pageIndex={index}
                            page={page}
                            previousPage={drawerStack[index - 1]}
                            onBack={onBackHandler}
                            onClose={onCloseHandler}
                        />
                        {renderPage(page.pageType, page.pageID)}
                    </div>
                ))}
            </Box>
        </Portal>
    );
};

export default memo(StackedDrawer);
