import React, { memo, useEffect, useMemo, useState } from 'react';
import { Button, Flex, Group, Skeleton, Stack, Text } from '@mantine/core';
import {
    Plan,
    PlanSchema,
    useGetActiveSubscriptionQuery,
    useGetPricingPlansQuery
} from 'entities/Billing';
import { ContactUsModal } from 'features/mail/ContactUs';
import { useGetBeingVerifiedSubscriptionQuery } from 'entities/Billing/api/subscriptionsApi/subscriptionsApi';
import TrialPriceCard from './TrialPriceCard';
import PriceCard from './PriceCard';
import CartCard from './CartCard';
import CustomPriceCard from './CustomPriceCard';

interface SelectedPlan {
    planSchema: PlanSchema;
    priceKey: number;
    priceValue: number;
    description: string;
}

const DEFAULT_MONTHLY_PRICE_KEY = 120;
const DEFAULT_MONTHLY_PRICE_VALUE = 349;
const DEFAULT_YEARLY_PRICE_KEY = 120;
const DEFAULT_YEARLY_PRICE_VALUE = 3490;
const DEFAULT_MONTHLY_PLAN: SelectedPlan = {
    planSchema: PlanSchema.MONTHLY,
    priceKey: DEFAULT_MONTHLY_PRICE_KEY,
    priceValue: DEFAULT_MONTHLY_PRICE_VALUE,
    description: `Scan up to ${DEFAULT_MONTHLY_PRICE_KEY} assets daily`
};
const DEFAULT_YEARLY_PLAN: SelectedPlan = {
    planSchema: PlanSchema.YEARLY,
    priceKey: DEFAULT_YEARLY_PRICE_KEY,
    priceValue: DEFAULT_YEARLY_PRICE_VALUE,
    description: `Scan up to ${DEFAULT_YEARLY_PRICE_KEY} assets daily`
};
const PRICE_NAMES: Record<number, string> = {
    129: 'Starter',
    349: 'Plus',
    949: 'Business',
    1290: 'Starter',
    3490: 'Plus',
    9490: 'Business'
};

const PricingComponent = () => {
    const [activePlanSchema, setActivePlanSchema] = useState<PlanSchema>();
    const [selectedPlan, setSelectedPlan] = useState<SelectedPlan>();
    const { data: plans, isLoading: pricingLoading } =
        useGetPricingPlansQuery();

    const { data: activeSubscription, isLoading: subscriptionLoading } =
        useGetActiveSubscriptionQuery(undefined, {
            pollingInterval: 180 * 1000
        });

    const {
        data: verifyingSubscription,
        isLoading: verifyingSubscriptionLoading
    } = useGetBeingVerifiedSubscriptionQuery(undefined, {
        pollingInterval: 180 * 1000
    });

    const isLoading = useMemo(
        () =>
            subscriptionLoading ||
            pricingLoading ||
            verifyingSubscriptionLoading,
        [subscriptionLoading, pricingLoading, verifyingSubscriptionLoading]
    );

    const isTrial = useMemo(
        () => Boolean(activeSubscription?.trial),
        [activeSubscription]
    );

    const isCustom = useMemo(
        () => Boolean(activeSubscription?.price_desc === 'Custom'),
        [activeSubscription]
    );

    const activePlan = useMemo(
        () => plans?.find(plan => plan.plan_schema === activePlanSchema),
        [plans, activePlanSchema]
    );

    const priceName = (price?: number) =>
        price ? PRICE_NAMES[price] : undefined;

    const planPeriod = (planSchema: PlanSchema) =>
        planSchema === PlanSchema.YEARLY ? 'yr' : 'mo';

    const defineDefaultPlan = () => {
        const prices = activePlan?.price_list;

        if (isTrial || isCustom || !activeSubscription) {
            if (activePlanSchema === PlanSchema.MONTHLY) {
                setSelectedPlan(DEFAULT_MONTHLY_PLAN);
            }
            if (activePlanSchema === PlanSchema.YEARLY) {
                setSelectedPlan(DEFAULT_YEARLY_PLAN);
            }
            return;
        }

        if (prices) {
            const recommendedPrice = [...prices]
                .reverse()
                .find(
                    price =>
                        price.value !== activeSubscription.price &&
                        price.value > activeSubscription.price
                );

            if (!recommendedPrice) {
                return;
            }

            setSelectedPlan({
                planSchema: activePlanSchema ?? PlanSchema.MONTHLY,
                priceKey: recommendedPrice.key,
                priceValue: recommendedPrice.value,
                description: `Scan up to ${recommendedPrice.key} assets daily`
            });
        }
    };

    useEffect(() => {
        if (activeSubscription) {
            setActivePlanSchema(
                activeSubscription.plan_desc === 'yearly'
                    ? PlanSchema.YEARLY
                    : PlanSchema.MONTHLY
            );
        }
    }, [activeSubscription]);

    const onSelectPlan = (
        planSchema: PlanSchema,
        priceKey: number,
        priceValue: number,
        description: string
    ) => {
        setSelectedPlan({ planSchema, priceKey, priceValue, description });
    };

    const onSchemaChange = (schema: PlanSchema) => {
        setActivePlanSchema(schema);
        setSelectedPlan(undefined);
    };

    return (
        <Stack>
            {isLoading ? (
                Array.from({ length: 5 }).map((_, index) => (
                    <Skeleton
                        key={index}
                        height={75}
                        width="100%"
                        radius="md"
                    />
                ))
            ) : (
                <Flex align="flex-start" gap="lg">
                    <Stack gap="lg" style={{ flex: 1 }}>
                        {isTrial && <TrialPriceCard isActive={isTrial} />}
                        {isCustom && (
                            <CustomPriceCard
                                isActive={isCustom}
                                assets={
                                    activeSubscription?.state.credits_available
                                }
                            />
                        )}
                        {activePlan?.price_list.map(price => (
                            <PriceCard
                                key={price.key}
                                schema={activePlan.plan_schema}
                                name={priceName(price.value)}
                                price={price.value}
                                period={planPeriod(activePlan.plan_schema)}
                                assets={price.key}
                                isSelected={
                                    selectedPlan?.priceValue === price.value
                                }
                                isActive={
                                    price.value === activeSubscription?.price
                                }
                                isVerifying={
                                    price.value === verifyingSubscription?.price
                                }
                                isTrial={false}
                                onSelectPlan={onSelectPlan}
                            />
                        ))}

                        <Group>
                            {activePlanSchema === PlanSchema.MONTHLY && (
                                <Button
                                    color="asm"
                                    variant="light"
                                    w={300}
                                    onClick={() =>
                                        onSchemaChange(PlanSchema.YEARLY)
                                    }
                                >
                                    Get 2 months free (switch to yearly)
                                </Button>
                            )}
                            {activePlanSchema === PlanSchema.YEARLY && (
                                <Button
                                    color="asm"
                                    variant="light"
                                    w={300}
                                    onClick={() =>
                                        onSchemaChange(PlanSchema.MONTHLY)
                                    }
                                >
                                    Switch to monthly
                                </Button>
                            )}
                            <ContactUsModal ctaText="Get in touch for custom plan" />
                        </Group>
                    </Stack>
                    <CartCard
                        plan={activePlan}
                        price={selectedPlan?.priceValue}
                        assets={selectedPlan?.priceKey}
                        name={priceName(selectedPlan?.priceValue)}
                        description={selectedPlan?.description}
                    />
                </Flex>
            )}
        </Stack>
    );
};

export default memo(PricingComponent);
