import React, { memo, useCallback, useState } from 'react';
import { Icon, Icons, IconSize } from 'shared/ui/Icon';
import { Button, ButtonProps } from '@mantine/core';

interface DownloadButtonProps extends ButtonProps {
    label?: string;
    type?: string;
    filename?: string;
    content: any;
}

const DownloadButton = (props: DownloadButtonProps) => {
    const {
        label = 'Download',
        type = 'text/plain',
        filename = 'attaxion.txt',
        content = 'Provide content for downloading.',
        ...rest
    } = props;
    const [downloading, setDownloading] = useState(false);

    const onDownload = useCallback(() => {
        setDownloading(true);
        const element = document.createElement('a');
        const file = new Blob([content], { type });
        element.href = URL.createObjectURL(file);
        element.download = filename;
        document.body.appendChild(element);
        element.click();

        // Clean up
        setTimeout(() => {
            setDownloading(false);
            document.body.removeChild(element);
            URL.revokeObjectURL(element.href);
        }, 500);
    }, [content, filename, type]);

    return (
        <Button
            variant="light"
            leftSection={<Icon icon={Icons.DOWNLOAD} size={IconSize.MEDIUM} />}
            loading={downloading}
            onClick={onDownload}
        >
            {label}
        </Button>
    );
};

export default memo(DownloadButton);
