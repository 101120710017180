import { Helmet } from 'react-helmet-async';
import { useMemo } from 'react';

interface SeoProps {
    title?: string;
    description?: string;
    type?: string;
    name?: string;
}

export const Seo = (props: SeoProps) => {
    const {
        title = 'Attaxion',
        description = 'Attaxion – External Attack Surface Management (EASM) Platform',
        type = 'webapp',
        name = 'Attaxion'
    } = props;

    const titleSuffix = ' | Attaxion';
    const _title = useMemo(() => {
        if (title.includes(titleSuffix) || title === 'Attaxion') {
            return title;
        }

        return `${title}${titleSuffix}`;
    }, [title]);

    return (
        <Helmet>
            <title>{_title}</title>
            <meta name="description" content={description} />
            <meta property="og:type" content={type} />
            <meta property="og:title" content={_title} />
            <meta property="og:description" content={description} />
            <meta name="twitter:creator" content={name} />
            <meta name="twitter:card" content={type} />
            <meta name="twitter:title" content={_title} />
            <meta name="twitter:description" content={description} />
        </Helmet>
    );
};
