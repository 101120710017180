import { memo, useMemo } from 'react';
import { SegmentedControl, SegmentedControlProps } from '@mantine/core';
import { ScanMode, ScanModeLabel } from '../../model/types/config';

interface ScannerModeControlProps
    extends Omit<SegmentedControlProps, 'data' | 'onChange'> {
    value: ScanMode;
    onChange: (value: ScanMode) => void;
    hideInheritMode?: boolean;
}

const ScannerModeControl = (props: ScannerModeControlProps) => {
    const { value, onChange, hideInheritMode = true, ...rest } = props;

    const data = useMemo(() => {
        const modes = Object.entries(ScanMode).map(([key, value]) => ({
            value,
            label: ScanModeLabel[key as keyof typeof ScanModeLabel]
        }));

        if (hideInheritMode) {
            return modes.filter(mode => mode.value !== ScanMode.INHERIT);
        }

        return modes;
    }, [hideInheritMode]);

    const color = useMemo(() => {
        switch (value) {
            case ScanMode.ACTIVE:
                return 'green';
            case ScanMode.INHERIT:
                return 'gray.4';
            case ScanMode.DISABLED:
                return 'red';
            default:
                return 'asm';
        }
    }, [value]);

    return (
        <SegmentedControl
            color={color}
            value={value}
            data={data}
            onChange={value => onChange(value as ScanMode)}
            {...rest}
        />
    );
};

export default memo(ScannerModeControl);
