import { Asset, AssetQueryParams, RootAsset } from 'entities/Asset';
import { DataTableColumn } from 'mantine-datatable';
import React from 'react';
import { BaseColumnProps } from 'widgets/Columns/baseColumn';
import { SeverityDistributionByAssetID } from 'widgets/Issue';

type RecordType = Asset;

export default ({
    query,
    setQuery,
    column
}: BaseColumnProps<
    RecordType,
    AssetQueryParams
>): DataTableColumn<RecordType> => ({
    width: 260,
    accessor: 'directVulnerabilitiesCount',
    title: 'Issues',
    sortable: true,
    render: ({ id }) => <SeverityDistributionByAssetID assetId={id} />,
    ...column
});
