export enum MailDepartment {
    SALES = 'sales',
    SERVICE = 'service'
}

export interface MailData {
    userId: number;
    email: string;
}

export interface MailForm extends MailData {
    message: string;
}

export interface MailResponse {
    acknowledge?: boolean;
    error: string | null;
    workflowRunId?: string;
}

export interface MailContactUsBody {
    channel: MailDepartment;
    message: string;
    data: MailData;
}
