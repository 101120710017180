import React, { memo, useCallback } from 'react';
import { Button } from '@mantine/core';
import { RootAsset } from 'entities/Asset';
import { showNotification } from '@mantine/notifications';
import { analyticsActions, AnalyticsEvent } from 'entities/Analytics';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { SERVICE_DESK_EMAIL } from 'shared/const/emails';
import { useStartRootAssetScanningMutation } from 'entities/Scanner';

interface StartRootAssetScanningButtonProps {
    asset: RootAsset;
}

const StartRootAssetScanningButton = (
    props: StartRootAssetScanningButtonProps
) => {
    const { asset } = props;
    const dispatch = useAppDispatch();
    const [start, { isLoading }] = useStartRootAssetScanningMutation();

    const onScanStart = useCallback(() => {
        start(asset.id)
            .then(() => {
                showNotification({
                    title: 'Success',
                    message: 'Scanning started successfully',
                    color: 'green'
                });
                dispatch(
                    analyticsActions.setEvent(
                        AnalyticsEvent.COMPANY_ROOT_ASSET_SCANNING_STARTED
                    )
                );
            })
            .catch(() => {
                showNotification({
                    title: 'Error',
                    message: `Failed to start scanning. Please try again later or contact support: ${SERVICE_DESK_EMAIL}.`,
                    color: 'red'
                });
            });
    }, [asset.id, start]);

    return (
        <Button
            w={200}
            size="md"
            variant="primary-gradient"
            loading={isLoading}
            onClick={onScanStart}
        >
            Start scanning
        </Button>
    );
};

export default memo(StartRootAssetScanningButton);
