import { useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import { InitContext, SubscriptionStatus } from '../InitContext';

function useSubscription() {
    const {
        subscription,
        subscriptionStatus,
        setSubscriptionDaysLeft,
        setSubscriptionExpiredDaysAgo,
        setScanningStoppedAt
    } = useContext(InitContext);
    const isSubscriptionActive = subscriptionStatus?.includes(
        SubscriptionStatus.SUBSCRIPTION_ACTIVE
    );
    const isSubscriptionExpired = subscriptionStatus?.includes(
        SubscriptionStatus.SUBSCRIPTION_EXPIRED
    );

    useEffect(() => {
        if (subscription && isSubscriptionActive) {
            const now = dayjs();
            const subscriptionExpiresAt = dayjs(subscription.charge_at);
            const subscriptionDaysLeft = subscriptionExpiresAt.diff(now, 'day');
            setSubscriptionDaysLeft(subscriptionDaysLeft);
        }
    }, [isSubscriptionActive, setSubscriptionDaysLeft, subscription]);

    useEffect(() => {
        if (subscription && isSubscriptionExpired) {
            const now = dayjs();
            const subscriptionExpiredAt = dayjs(subscription.archived_at);
            const subscriptionExpiredDaysAgo = subscriptionExpiredAt.diff(
                now,
                'day'
            );
            setSubscriptionExpiredDaysAgo(subscriptionExpiredDaysAgo);
        }

        if (subscription && isSubscriptionExpired && subscription.archived_at) {
            setScanningStoppedAt(subscription.archived_at);
        }
    }, [
        isSubscriptionExpired,
        setScanningStoppedAt,
        setSubscriptionExpiredDaysAgo,
        subscription
    ]);

    return null;
}

export default useSubscription;
