import React, { memo, useMemo } from 'react';
import { Issue } from 'entities/Issue';
import { Badge, BadgeProps, Group } from '@mantine/core';

interface EpssPercentileBadgeProps extends BadgeProps {
    issue: Issue;
}

const EpssPercentileBadge = (props: EpssPercentileBadgeProps) => {
    const { issue, ...rest } = props;

    if (!issue.epss) return null;

    return (
        <Badge variant="light" {...rest}>
            EPSS percentile: {issue.epss.percentile}
        </Badge>
    );
};

export default memo(EpssPercentileBadge);
