import React, { memo } from 'react';
import classNames from 'classnames';
import { navigationActions, NavigationPage } from 'entities/Navigation';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import styles from './NavigationLink.module.scss';

interface NavigationLinkProps extends NavigationPage {
    className?: string;
    children?: React.ReactNode;
}

const NavigationLink = (props: NavigationLinkProps) => {
    const dispatch = useAppDispatch();
    const { className, pageLink, pageType, pageID, pageTitle, children } =
        props;
    const classes = classNames(styles.NavigationLink, className);

    const handleClick = () => {
        dispatch(
            navigationActions.addToDrawerStack({
                pageLink,
                pageType,
                pageID,
                pageTitle
            })
        );
    };

    const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        handleClick();
    };

    if (pageLink) {
        return (
            <a className={classes} href={pageLink} onClick={handleLinkClick}>
                {children}
            </a>
        );
    }

    return (
        <div className={classes} onClick={handleClick}>
            {children}
        </div>
    );
};

export default memo(NavigationLink);
