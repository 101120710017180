import React, { memo, Suspense } from 'react';
import { useGetVulnerability } from 'entities/Vulnerability';
import { CreateJiraTicketModal } from 'features/issue/actions/CreateJiraTicket';
import { UpdateIssueStatusModal } from 'features/issue/actions/UpdateIssueStatus';

interface IssueDetailsPageHeaderActionsProps {
    id: string;
}

const IssueDetailsPageHeaderActions = (
    props: IssueDetailsPageHeaderActionsProps
) => {
    const { id } = props;
    const { data: vulnerability } = useGetVulnerability(id);

    return (
        vulnerability && (
            <Suspense fallback={null}>
                <UpdateIssueStatusModal issue={vulnerability} />
                <CreateJiraTicketModal issue={vulnerability} />
            </Suspense>
        )
    );
};

export default memo(IssueDetailsPageHeaderActions);
