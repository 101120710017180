import { memo } from 'react';
import { Page } from 'shared/ui/Page';
import ManagementPageHeader from '../ManagementPageHeader/ManagementPageHeader';

interface ManagementBaseLayoutProps {
    withHeader?: boolean;
    children?: React.ReactNode;
}

const ManagementBaseLayout = (props: ManagementBaseLayoutProps) => {
    const { withHeader = true, children } = props;

    return (
        <Page>
            {withHeader && <ManagementPageHeader />}
            {children}
        </Page>
    );
};

export default memo(ManagementBaseLayout);
