import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootAsset } from 'entities/Asset';
import { VerifyRootAssetSchema } from '../../types/verifyRootAssetSchema';

const initialState: VerifyRootAssetSchema = {
    asset: null
};

export const verifyRootAssetSlice = createSlice({
    name: 'verifyRootAsset',
    initialState,
    reducers: {
        reset: () => initialState,
        setAsset: (state, action: PayloadAction<RootAsset>) => {
            state.asset = action.payload;
        }
    }
});

export const { actions: verifyRootAssetActions } = verifyRootAssetSlice;
export const { reducer: verifyRootAssetReducer } = verifyRootAssetSlice;
