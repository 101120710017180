import React, { memo, useMemo } from 'react';
import { PieChart } from 'shared/ui/Chart';
import { useGetLastStatisticsByVulnerabilityRating } from 'entities/Statistics';
import { EChartsOption } from 'echarts';
import { Rating, severityRatingColor } from 'entities/Issue';
import { useNavigate } from 'react-router-dom';

const DistributionBySeverityPieChart = () => {
    const navigate = useNavigate();
    const { data, isLoading, isFetching } =
        useGetLastStatisticsByVulnerabilityRating({});

    const series: EChartsOption['series'] = useMemo(
        () => ({
            name: 'Distribution by severity',
            type: 'pie',
            radius: ['100%', '150%'],
            center: ['50%', '85%'],
            startAngle: 180 + 6 / 2,
            minAngle: 6,
            label: {
                show: true,
                position: 'inside',
                fontWeight: 'bolder'
            },
            itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
            },
            data: [
                ...Object.entries(data?.distribution ?? {})
                    .filter(([name, value]) => value.count > 0)
                    .map(([name, value]) => ({
                        name: name.toUpperCase(),
                        link: `/issues/all?rating=${name}&lastSeenPreset=all`,
                        value: value.count,
                        itemStyle: {
                            color: severityRatingColor(name as Rating)
                        }
                    })),
                {
                    value: data?.total.count,
                    itemStyle: {
                        color: 'none',
                        decal: {
                            symbol: 'none'
                        }
                    },
                    label: {
                        show: false
                    }
                }
            ]
        }),
        [data]
    );

    return (
        <PieChart
            series={[series]}
            isLoading={isLoading || isFetching}
            onClick={params => {
                if ('link' in params.data && params.data.link) {
                    navigate(params.data.link);
                }
            }}
        />
    );
};

export default memo(DistributionBySeverityPieChart);
