import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { removeCookie, setCookie } from 'shared/lib/cookie/cookie';
import { UserSchema } from '../types/userSchema';
import { User } from '../types/user';
import { USER_AUTH_COOKIE, USER_AUTH_COOKIE_OPTIONS } from '../const';

const initialState: UserSchema = {};
const demoEmail = 'demo@attaxion.com';

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setAuthData: (state, action: PayloadAction<User>) => {
            const user: User = {
                id: action.payload.id,
                email: action.payload.email,
                email_domain: action.payload.email.split('@')[1] || '',
                stripe_key: action.payload.stripe_key,
                api_key: action.payload.api_key,
                is_demo: action.payload.email === demoEmail,
                service_user: action.payload.service_user,
                auth_token: action.payload.auth_token,
                created_at: action.payload.completed_at,
                analytics_enabled: action.payload.analytics_enabled || true
            };
            state.authData = user;
            state.initialized = true;
            setCookie(USER_AUTH_COOKIE, user, USER_AUTH_COOKIE_OPTIONS);
        },
        setAuthDataFromQuery: (
            state,
            action: PayloadAction<{ [k: string]: string }>
        ) => {
            const user: User = {
                api_key: '',
                auth_token: '',
                email: '',
                email_domain: '',
                id: 0,
                service_user: 0,
                stripe_key: ''
            };

            if ('id' in action.payload) {
                user.id = Number(action.payload.id);
            }

            if ('email' in action.payload) {
                user.email = action.payload.email;
                user.email_domain = action.payload.email.split('@')[1] || '';
            }

            if ('stripe_key' in action.payload) {
                user.stripe_key = action.payload.stripe_key;
            }

            if ('api_key' in action.payload) {
                user.api_key = action.payload.api_key;
            }

            if ('service_user' in action.payload) {
                user.service_user = Number(action.payload.service_user) as
                    | 0
                    | 1;
            }

            if ('auth_token' in action.payload) {
                user.auth_token = action.payload.auth_token;
            }

            if ('created_at' in action.payload) {
                user.created_at = action.payload.created_at;
            }

            if ('analytics_enabled' in action.payload) {
                user.analytics_enabled =
                    action.payload.analytics_enabled === 'true';
            }

            state.authData = user;
            state.initialized = true;
            setCookie(USER_AUTH_COOKIE, user, USER_AUTH_COOKIE_OPTIONS);
        },
        updateAuthCookie: state => {
            if (state.authData) {
                setCookie(
                    USER_AUTH_COOKIE,
                    state.authData,
                    USER_AUTH_COOKIE_OPTIONS
                );
            }
        },
        setInitialized: (state, action: PayloadAction<boolean>) => {
            state.initialized = action.payload;
        },
        logout: state => {
            state.authData = undefined;
            removeCookie(USER_AUTH_COOKIE);
        }
    }
});

export const { actions: userActions } = userSlice;
export const { reducer: userReducer } = userSlice;
