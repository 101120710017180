import { Tag } from 'entities/Tag';
import { Icons } from 'shared/ui/Icon';
import { ScanMode } from 'entities/Scanner';
import { Metadata } from './metadata';

export enum AssetType {
    DOMAIN = 'domain',
    EMAIL = 'email',
    SSL = 'ssl',
    PORT = 'port',
    IPV4 = 'ipv4',
    IPV6 = 'ipv6',
    ORGANIZATION = 'organization',
    CLOUD = 'cloud',
    CIDR = 'cidr'
}

export enum AssetTypeLabel {
    DOMAIN = 'Domain',
    EMAIL = 'Email Address',
    IPV4 = 'IPv4 Address',
    IPV6 = 'IPv6 Address',
    IP = 'IP Address',
    SSL = 'SSL Certificate',
    PORT = 'Port',
    ORGANIZATION = 'Organization',
    CLOUD = 'Cloud',
    CIDR = 'CIDR'
}

export enum AssetTypeIcon {
    DOMAIN = Icons.DNS,
    EMAIL = Icons.ALTERNATE_EMAIL,
    IPV4 = Icons.LOCATION_ON,
    IPV6 = Icons.LOCATION_ON,
    IP = Icons.LOCATION_ON,
    SSL = Icons.SHIELD_LOCK,
    CLOUD = Icons.CLOUD,
    CIDR = Icons.HUB,
    ORGANIZATION = Icons.APARTMENT,
    PORT = Icons.SETTINGS_INPUT_SVIDEO
}

export enum RootVerificationStatus {
    FAILED = 'failed',
    ERROR = 'error',
    VERIFIED = 'verified',
    PENDING = 'pending',
    RUNNING = 'running'
}

export interface RootVerification {
    code: string;
    dnsRecord: string;
    file: {
        name: string;
        content: string;
    };
}

export enum RootVerificationStatusLabel {
    FAILED = 'Verification Failed',
    ERROR = 'Error',
    VERIFIED = 'Verified',
    PENDING = 'Pending',
    RUNNING = 'Running'
}

export enum RootVerificationStatusIcon {
    FAILED = Icons.WARNING,
    ERROR = Icons.WARNING,
    VERIFIED = Icons.VERIFIED,
    PENDING = Icons.PENDING,
    RUNNING = Icons.PENDING
}

interface VulnerabilityStats {
    critical: number;
    high: number;
    medium: number;
    low: number;
    info: number;
    total: number;
}
export interface BaseAsset {
    id: string;
    value: string;
    tags: Tag[];
    type: AssetType;
    scanMode: ScanMode;
    isRoot: boolean;
    isRootCandidate: boolean;
    isFalsePositive: boolean;
    comment: string;
    directVulnerabilitiesCount: number;
    foundOnPages: string[];
    createdAt: string;
    createdAtParsed: string | null;
    lastActiveAt: string | null;
    lastScannedAt: string | null;
    lastScannedAtParsed: string | null;
    lastSeenAt: string | null;
    lastSeenAtParsed: string | null;
    metadata?: Metadata;
    relationType?: string;
    totalScans?: number;
    vulnerabilityStats?: VulnerabilityStats;
    rootVulnerabilityStats?: VulnerabilityStats;
}

export interface RootAsset extends BaseAsset {
    rootVerificationStatus: RootVerificationStatus;
    rootVerification: RootVerification;
    directAndDerivedSubdomainsCount: number;
    directAndDerivedVulnerabilitiesCount: number;
    descendantsCount: number;
    shouldBeScanned: boolean;
}

export interface Asset extends BaseAsset {
    rootAsset: RootAsset;
}

export interface RootAssetCandidate extends BaseAsset {
    rootAsset: RootAsset;
    declined?: boolean;
}

export interface RootAssetVerification {
    acknowledged: boolean;
    assetId: string;
    error: string;
    workflowRunId: string;
}

export type RootAssetVerificationMethod = 'dns' | 'file';

export interface RootAssetVerificationQuery {
    id: string;
    method: RootAssetVerificationMethod;
}

export interface AssetSearchParams {
    createdAtGte?: number;
    createdAtLte?: number;
}

export interface DeletedAsset {
    acknowledged: boolean;
    assetId: string;
    error: string | null;
    workflowRunId: string;
}

export interface RootAssetName {
    id: string;
    value: string;
}

export interface AssetName extends RootAssetName {}
