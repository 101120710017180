import React, { memo, Suspense } from 'react';
import { Box, Divider, Stack } from '@mantine/core';
import { PaypalConfirmationModal } from 'features/billing/actions/PaypalConfirmation';
import { Seo } from 'shared/ui/SEO/SEO';
import PricingComponent from 'entities/Billing/ui/PricingComponent/PricingComponent';
import { PaymentsTable } from 'widgets/Billing';
import AccountBaseLayout from '../../../ui/AccountBaseLayout/AccountBaseLayout';

function BillingPage() {
    return (
        <AccountBaseLayout>
            <Seo title="Billing | Account" />
            <Box style={{ maxHeight: 'calc(100% - 120px)' }}>
                <Stack gap="lg" maw={1366}>
                    <PricingComponent />
                    <Divider />
                    <PaymentsTable />
                </Stack>
            </Box>
            <Suspense fallback={null}>
                <PaypalConfirmationModal />
            </Suspense>
        </AccountBaseLayout>
    );
}

export default memo(BillingPage);
