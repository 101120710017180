import { memo, useCallback, useEffect } from 'react';
import { useRemoveRootAsset } from 'entities/Asset';
import { Button, Group, Modal, Space, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { useSelector } from 'react-redux';
import { useInjectReducer } from 'shared/hooks/useInjectReducer';
import { showNotification } from '@mantine/notifications';
import {
    removeRootAssetActions,
    removeRootAssetReducer
} from '../../model/slices/removeRootAssetSlice/removeRootAssetSlice';
import { getAsset } from '../../model/selectors/getAsset/getAsset';

const RemoveRootAssetModal = () => {
    useInjectReducer('removeRootAsset', removeRootAssetReducer);

    const [opened, { open, close }] = useDisclosure(false);

    const dispatch = useAppDispatch();
    const asset = useSelector(getAsset);
    const [removeRootAsset, { isLoading }] = useRemoveRootAsset();

    useEffect(() => {
        if (!asset) close();
        if (asset) open();
    }, [asset, close, open]);

    const onDecline = useCallback(() => {
        if (asset) {
            removeRootAsset(asset.id).then(() => {
                dispatch(removeRootAssetActions.reset());
                showNotification({
                    title: 'Deleting...',
                    message: 'Deleting asset and all its children has started.',
                    color: 'green'
                });
            });
        }
    }, [removeRootAsset, asset, dispatch]);

    const onClose = useCallback(() => {
        dispatch(removeRootAssetActions.reset());
    }, [dispatch]);

    return (
        <Modal
            size="lg"
            opened={opened}
            title="Remove root asset"
            onClose={close}
        >
            <Text size="sm">
                Are you sure you want to remove the selected root asset{' '}
                <b>{asset?.value}</b>? This action cannot be undone. All
                children of this asset will be removed as well. Deleting assets
                will be processed in the background, you will be able to close
                this modal and continue working with the app.
            </Text>
            <Space h="md" />
            <Group justify="flex-end">
                <Button onClick={onClose} variant="outline" color="asm">
                    Cancel
                </Button>
                <Button color="red.9" loading={isLoading} onClick={onDecline}>
                    I&apos;m sure, remove
                </Button>
            </Group>
        </Modal>
    );
};

export default memo(RemoveRootAssetModal);
