import { useEffect } from 'react';
import { useDemo } from './useDemo';

export const useDemoStyleSheet = () => {
    const { isDemo } = useDemo();

    useEffect(() => {
        (async () => {
            if (isDemo) {
                await import('app/styles/demo.scss');
            }
        })();
    }, [isDemo]);

    return null;
};
