import { memo, useMemo } from 'react';
import { useGetLastStatisticsByVulnerabilityScore } from 'entities/Statistics';
import { BarChart } from 'shared/ui/Chart';
import { EChartsOption } from 'echarts';
import { severitySegmentColor } from 'entities/Issue';

const DistributionByScoreBarChart = () => {
    const { data, isLoading, isFetching } =
        useGetLastStatisticsByVulnerabilityScore({});

    const segments = useMemo(
        () =>
            Object.keys(data?.distribution ?? {}).filter(
                segment => segment !== '0'
            ),
        [data]
    );

    const series = useMemo(
        () => [
            {
                name: 'Distribution by CVSS score',
                type: 'bar',
                data: Object.entries(data?.distribution ?? {})
                    .filter(([segment]) => segment !== '0')
                    .map(([segment, value]) => ({
                        name: segment,
                        value: value.count,
                        itemStyle: {
                            color: severitySegmentColor(segment),
                            borderRadius: [4, 4, 0, 0]
                        }
                    }))
            }
        ],
        [data]
    );

    // @ts-ignore
    const options: EChartsOption = useMemo(
        () => ({
            grid: {
                top: 10,
                right: 10,
                bottom: 10,
                left: 10,
                containLabel: true
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                formatter(params: any) {
                    const { name: segment, value, marker } = params[0];
                    return `<b>${segment}</b> CVSS score segment<br /> ${marker} <b>${value}</b> issues found`;
                }
            },
            xAxis: [
                {
                    type: 'category',
                    data: segments
                }
            ],
            yAxis: {
                type: 'value',
                scale: true,
                minInterval: 1,
                axisLabel: {
                    formatter: '{value} issues'
                }
            },
            series
        }),
        [segments, series]
    );

    return (
        <BarChart
            defaultOptions={options}
            isLoading={isLoading || isFetching}
        />
    );
};

export default memo(DistributionByScoreBarChart);
