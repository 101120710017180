import React, { useCallback, useEffect, useMemo } from 'react';
import {
    ActionIcon,
    Autocomplete,
    Badge,
    Button,
    Divider,
    Group,
    Modal,
    Text
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { useSelector } from 'react-redux';
import { useInjectReducer } from 'shared/hooks/useInjectReducer';
import { useUpdateAsset } from 'entities/Asset';
import { useForm } from '@mantine/form';
import { Tag, useGetTags } from 'entities/Tag';
import { Icon, Icons } from 'shared/ui/Icon';
import { useEditableTags } from 'features/asset/actions/AddTag/model/hooks/useEditableTags/useEditableTags';
import {
    entityTagActions,
    entityTagReducer
} from '../../model/slices/entityTagSlice/entityTagSlice';
import { getEntity } from '../../model/selectors/getEntity/getEntity';

const EntityTagModal = () => {
    useInjectReducer('entityTag', entityTagReducer);

    const [opened, { open, close }] = useDisclosure(false);
    const dispatch = useAppDispatch();
    const entity = useSelector(getEntity);
    const { hasEditableTags, editableTags } = useEditableTags(entity?.tags);

    const { data: availableTags } = useGetTags();
    const [addAsset, { isLoading: isAdding }] = useUpdateAsset();
    const [removeAsset] = useUpdateAsset();
    const form = useForm<{ tag: string }>({
        initialValues: {
            tag: ''
        }
    });

    const availableTagNames = useMemo(
        () =>
            availableTags?.data
                .filter(tag => !tag.isSystem)
                .map(tag => tag.name) || [],
        [availableTags?.data]
    );

    useEffect(() => {
        if (!entity) close();
        if (!opened && entity) {
            open();
        }
    }, [entity]);

    useEffect(() => {
        if (!opened) form.reset();
    }, [opened]);

    const onClose = useCallback(() => {
        close();
        setTimeout(() => {
            dispatch(entityTagActions.reset());
        }, 500);
    }, [close, dispatch]);

    const onAddTag = useCallback(
        (values: { tag: string }) => {
            if (entity) {
                addAsset({
                    id: entity?.id,
                    addTags: values.tag ? [values.tag] : []
                }).then(data => {
                    if ('data' in data) {
                        dispatch(entityTagActions.setEntity(data.data));
                    }
                });
            }
        },
        [entity, addAsset, dispatch]
    );

    const onRemoveTag = useCallback(
        (tag: string) => {
            if (entity) {
                removeAsset({
                    id: entity?.id,
                    removeTags: [tag]
                }).then(data => {
                    if ('data' in data) {
                        dispatch(entityTagActions.setEntity(data.data));
                    }
                });
            }
        },
        [dispatch, entity, removeAsset]
    );

    return (
        <Modal
            size="lg"
            title={`${hasEditableTags ? 'Edit tags' : 'Add tags'}`}
            opened={opened}
            onClose={onClose}
        >
            {hasEditableTags && (
                <>
                    <Group gap="xs">
                        {editableTags.map((tag: Tag) => (
                            <Badge
                                key={tag.id}
                                size="md"
                                color="asm"
                                variant="outline"
                                rightSection={
                                    <ActionIcon
                                        size="xs"
                                        color="red"
                                        variant="transparent"
                                        onClick={() => {
                                            onRemoveTag(tag.name);
                                        }}
                                    >
                                        <Icon icon={Icons.CLOSE} />
                                    </ActionIcon>
                                }
                            >
                                {tag.name}
                            </Badge>
                        ))}
                    </Group>
                    <Divider my="lg" />
                </>
            )}
            <form onSubmit={form.onSubmit(onAddTag)}>
                <Group>
                    <Autocomplete
                        required
                        placeholder="Enter new tag name"
                        data={availableTagNames}
                        {...form.getInputProps('tag')}
                        style={{ flex: 1 }}
                    />
                    <Button
                        size="sm"
                        color="asm"
                        type="submit"
                        loading={isAdding}
                    >
                        Add new tag
                    </Button>
                </Group>
            </form>
        </Modal>
    );
};

export default EntityTagModal;
