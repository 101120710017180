import { severityRatingColor } from '../severityRatingColor/severityRatingColor';

export function severityScoreColor(score: number) {
    switch (true) {
        case score >= 0 && score < 4:
            return severityRatingColor('low');
        case score >= 4 && score < 7:
            return severityRatingColor('medium');
        case score >= 7 && score < 9:
            return severityRatingColor('high');
        case score >= 9 && score <= 10:
            return severityRatingColor('critical');
        default:
            return severityRatingColor(undefined);
    }
}
