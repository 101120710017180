import { DataTableColumn } from 'mantine-datatable';
import { Asset, AssetQueryParams, WhoisExpiresDate } from 'entities/Asset';
import { BaseColumnProps } from 'widgets/Columns/baseColumn';
import React from 'react';

type RecordType = Asset;
type QueryParams = AssetQueryParams;

export default ({
    column
}: BaseColumnProps<RecordType, QueryParams>): DataTableColumn<RecordType> => ({
    accessor: 'metadata.whoisRecord.expiresDate',
    title: 'Expires',
    render: ({ metadata }) => (
        <WhoisExpiresDate whoisRecord={metadata?.whoisRecord} />
    ),
    ...column
});
