import React, { memo, useCallback, useId } from 'react';
import {
    Box,
    Button,
    Flex,
    PasswordInput,
    Text,
    TextInput
} from '@mantine/core';
import { SignupByEmailForm } from 'entities/User';
import { useForm } from '@mantine/form';
import { useSignupByEmailMutation } from 'entities/User/api/userApi/userApi';
import { RoutePath } from 'shared/config/routeConfig';
import { showNotification } from '@mantine/notifications';
import { useIpAddress } from 'shared/hooks/useIpAddress/useIpAddress';
import { useNavigate } from 'react-router-dom';
import { useFullUrl } from 'shared/hooks/useFullUrl/useFullUrl';

const SignUpForm = () => {
    const navigate = useNavigate();
    const ipAddress = useIpAddress();
    const redirectUrl = useFullUrl(RoutePath.dashboard);
    const id = useId();

    const [signup, { isLoading, error }] = useSignupByEmailMutation();
    const form = useForm<SignupByEmailForm>({
        initialValues: {
            email: '',
            password: '',
            firstname: '',
            lastname: '',
            phone: '',
            company: '',
            jobtitle: ''
        }
    });

    const onSubmit = useCallback(
        (values: SignupByEmailForm) => {
            signup({
                // ip: ipAddress || '',
                ip: id,
                market_id: __NAF_MARKET_ID__,
                signup_product_id: __NAF_PRODUCT_ID__,
                email: values.email,
                password: values.password,
                password_confirm: values.password,
                login_redirect: redirectUrl,
                user_details: [
                    {
                        field_name: 'First Name',
                        field_value: values.firstname || ''
                    },
                    {
                        field_name: 'Last Name',
                        field_value: values.lastname || ''
                    },
                    {
                        field_name: 'Phone',
                        field_value: values.phone || ''
                    },
                    {
                        field_name: 'Company',
                        field_value: values.company || ''
                    },
                    {
                        field_name: 'Job Title',
                        field_value: values.jobtitle || ''
                    }
                ]
            })
                .unwrap()
                .then(data => {
                    if ('error' in data) {
                        showNotification({
                            title: 'Registration error',
                            message: data.error,
                            color: 'red'
                        });
                        return;
                    }

                    showNotification({
                        title: 'Successful registration',
                        message:
                            'message' in data
                                ? data.message
                                : 'You have successfully registered.',
                        color: 'green',
                        autoClose: 30 * 1000
                    });

                    navigate(RoutePath.login);
                });
        },
        [id, navigate, redirectUrl, signup]
    );

    return (
        <Box w="100%">
            <form
                onSubmit={form.onSubmit(onSubmit)}
                autoComplete="on"
                id="signup_form"
            >
                <Flex gap="lg" direction="column">
                    <TextInput
                        required
                        withAsterisk
                        label="Work email"
                        placeholder="account@attaxion.dev"
                        autoComplete="on"
                        type="email"
                        {...form.getInputProps('email')}
                    />
                    <PasswordInput
                        required
                        withAsterisk
                        type="password"
                        label="Password"
                        placeholder="Your password"
                        autoComplete="on"
                        minLength={8}
                        {...form.getInputProps('password')}
                    />
                    <TextInput
                        autoComplete="on"
                        label="First name"
                        placeholder="John"
                        {...form.getInputProps('firstname')}
                    />
                    <TextInput
                        autoComplete="on"
                        label="Last name"
                        placeholder="Doe"
                        {...form.getInputProps('lastname')}
                    />
                    <TextInput
                        autoComplete="on"
                        label="Company"
                        placeholder="Attaxion"
                        {...form.getInputProps('company')}
                    />
                    <TextInput
                        autoComplete="on"
                        label="Position"
                        placeholder="CTO"
                        {...form.getInputProps('jobtitle')}
                    />
                    {error && (
                        <Text c="red">
                            Something went wrong. Please try again later or
                            contact support.
                        </Text>
                    )}
                    <Button
                        type="submit"
                        variant="primary-gradient"
                        loading={isLoading}
                    >
                        Sign up
                    </Button>
                </Flex>
            </form>
        </Box>
    );
};

export default memo(SignUpForm);
