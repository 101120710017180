import React, { memo, useMemo } from 'react';
import { Icon, Icons } from 'shared/ui/Icon';
import { Badge } from '@mantine/core';
import {
    Asset,
    RootAsset,
    RootAssetCandidate
} from '../../../model/types/asset';

interface AssetHealthBadgeProps {
    asset: RootAsset | Asset | RootAssetCandidate;
}

const AssetHealthBadge = (props: AssetHealthBadgeProps) => {
    const { asset } = props;
    const hasVulnerabilities = asset.directVulnerabilitiesCount > 0;
    const hasScans = !!asset.lastScannedAt;
    const color = useMemo(() => {
        if (!hasScans) {
            return 'yellow';
        }

        if (hasVulnerabilities) {
            return 'red';
        }

        return 'green';
    }, [hasScans, hasVulnerabilities]);
    const icon = useMemo(() => {
        if (!hasScans) {
            return Icons.PENDING;
        }

        if (hasVulnerabilities) {
            return Icons.WARNING;
        }

        return Icons.VERIFIED_USER;
    }, [hasScans, hasVulnerabilities]);
    const label = useMemo(() => {
        if (!hasScans) {
            return 'NOT SCANNED YET';
        }

        if (hasVulnerabilities) {
            return 'VULNERABLE';
        }

        return 'HEALTHY';
    }, [hasScans, hasVulnerabilities]);

    return (
        <Badge color={color} leftSection={<Icon icon={icon} />}>
            {label}
        </Badge>
    );
};

export default memo(AssetHealthBadge);
