import { DataTableColumn } from 'mantine-datatable';
import React from 'react';
import { BaseColumnProps, updateQuery } from 'widgets/Columns/baseColumn';
import { StatusBadge } from 'entities/Issue';
import {
    Vulnerability,
    VulnerabilityQueryParams
} from 'entities/Vulnerability';
import { FilterByStatusSelect } from 'features/issue/filters/FilterByStatus';

type RecordType = Vulnerability;
type QueryParams = VulnerabilityQueryParams;

export default ({
    query = {},
    setQuery,
    column
}: BaseColumnProps<RecordType, QueryParams>): DataTableColumn<RecordType> => {
    const { status = [] } = query;

    return {
        width: 140,
        accessor: 'status',
        title: 'Status',
        filtering: status.length > 0,
        filter: (
            <FilterByStatusSelect
                selectedStatuses={status}
                onChange={status => {
                    updateQuery({ status }, query, setQuery);
                }}
            />
        ),
        render: issue => (
            <StatusBadge
                issue={issue}
                fullWidth
                selectedStatuses={status}
                setSelectedStatuses={status => {
                    updateQuery({ status }, query, setQuery);
                }}
            />
        ),
        ...column
    };
};
