import React, { memo, useMemo, useState } from 'react';
import {
    useGetVulnerabilitiesUnique,
    VulnerabilityQueryParams
} from 'entities/Vulnerability';
import { DataTableColumn } from 'mantine-datatable';
import { GenericTable } from 'shared/ui/DataTable';
import { IssueUnique } from 'entities/Issue';
import vulnerabilityNameColumn from 'widgets/Columns/vulnerabilityNameColumn';
import riskColumn from 'widgets/Columns/riskColumn';
import lastSeenColumn from 'widgets/Columns/lastSeenColumn';
import createdAtColumn from 'widgets/Columns/createdAtColumn';
import countColumn from 'widgets/Columns/countColumn';

interface VulnerabilitiesTableProps {
    defaultVulnerabilityName?: string;
    title?: string;
}

const UniqueVulnerabilitiesTable = (props: VulnerabilitiesTableProps) => {
    const { defaultVulnerabilityName, title } = props;
    const [query, setQuery] = useState<VulnerabilityQueryParams>({
        nameExact: defaultVulnerabilityName
    });

    const columns = useMemo<DataTableColumn<IssueUnique>[]>(
        () => [
            vulnerabilityNameColumn({
                query,
                setQuery,
                column: {
                    hidden: Boolean(defaultVulnerabilityName)
                }
            }),
            riskColumn({
                query,
                setQuery,
                column: {
                    hidden: Boolean(defaultVulnerabilityName)
                }
            }),
            countColumn({}),
            lastSeenColumn({
                query,
                setQuery
            }),
            createdAtColumn({
                query,
                setQuery
            })
        ],
        [defaultVulnerabilityName, query]
    );

    return (
        <GenericTable<IssueUnique>
            // @ts-ignore
            useData={useGetVulnerabilitiesUnique}
            columns={columns}
            queryParams={query}
            defaultSortStatus={{
                columnAccessor: 'count',
                direction: 'desc'
            }}
            title={title}
        />
    );
};

export default memo(UniqueVulnerabilitiesTable);
