import { createContext } from 'react';

export enum Layout {
    Collapsed = 'collapsed',
    NotCollapsed = 'not-collapsed'
}

export interface LayoutContextProps {
    layout: Layout;
    setLayout: (layout: Layout) => void;
    isCollapsed: boolean;
    setIsCollapsed: (isCollapsed: boolean) => void;
}

export const LayoutContext = createContext<LayoutContextProps>({
    layout: Layout.Collapsed,
    isCollapsed: false,
    setLayout: () => {},
    setIsCollapsed: () => {}
});

export const LOCAL_STORAGE_LAYOUT_KEY = 'layout';
