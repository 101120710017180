import { ACCOUNT_API_TAGS, accountApi } from 'shared/api/rtkApi';
import { ApiSuccess, ApiError } from 'entities/NAF';
import {
    ChangePasswordBody,
    ForgotPasswordBody,
    LoginByEmailBody,
    SignupByEmailBody,
    TokenValidationAction,
    User,
    ValidateToken
} from '../../model/types/user';

const userApi = accountApi.injectEndpoints({
    endpoints: builder => ({
        loginByEmail: builder.mutation<User | ApiError, LoginByEmailBody>({
            query: body => ({
                url: '/login',
                method: 'POST',
                body
            })
        }),
        signupByEmail: builder.mutation<
            ApiSuccess | ApiError,
            SignupByEmailBody
        >({
            query: body => ({
                url: '/register',
                method: 'POST',
                body
            })
        }),
        forgotPassword: builder.mutation<
            ApiSuccess | ApiError,
            ForgotPasswordBody
        >({
            query: body => ({
                url: '/reset',
                method: 'POST',
                body
            })
        }),
        changePassword: builder.mutation<
            ApiSuccess | ApiError,
            ChangePasswordBody
        >({
            query: body => ({
                url: '/changeregister',
                method: 'POST',
                body
            })
        }),
        validateToken: builder.mutation<
            (TokenValidationAction & User) | ApiError,
            ValidateToken
        >({
            query: body => ({
                url: '/activate_user',
                method: 'POST',
                body
            }),
            invalidatesTags: () => [ACCOUNT_API_TAGS.USER]
        })
    })
});

export const {
    useLoginByEmailMutation,
    useSignupByEmailMutation,
    useForgotPasswordMutation,
    useValidateTokenMutation,
    useChangePasswordMutation
} = userApi;
