import React, { memo, useEffect } from 'react';
import { Button, Center, Text } from '@mantine/core';
import { Link } from 'react-router-dom';
import { RoutePath } from 'shared/config/routeConfig';
import { getSuccessMessage } from 'features/asset/actions/AddNewRootAsset';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { analyticsActions, AnalyticsEvent } from 'entities/Analytics';

const SuccessfullyAddedAsset = () => {
    const dispatch = useAppDispatch();
    const successMessage = useSelector(getSuccessMessage);

    useEffect(() => {
        dispatch(analyticsActions.setEvent(AnalyticsEvent.ROOT_ASSET_ADDED));
    }, []);

    return (
        <>
            <Text c="dimmed" ta="center" size="lg" mb="xl">
                {React.createElement('span', {
                    dangerouslySetInnerHTML: { __html: successMessage }
                })}
            </Text>
            <Center>
                <Button
                    component={Link}
                    to={`${RoutePath.management}/root-assets`}
                    variant="primary-gradient"
                    size="lg"
                >
                    View root assets
                </Button>
            </Center>
        </>
    );
};

export default memo(SuccessfullyAddedAsset);
