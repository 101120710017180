import { AssetName } from 'entities/Asset';
import {
    useGetVulnerableAssetNamesQuery,
    VulnerableAssetQueryParams
} from 'entities/Dictionary';

interface UseGetVulnerableAssetNames {
    assets?: AssetName[];
    loading: boolean;
}

export function useGetVulnerableAssetNames(
    props: VulnerableAssetQueryParams
): UseGetVulnerableAssetNames {
    const { data, isLoading } = useGetVulnerableAssetNamesQuery({
        size: 1000,
        ...props
    });

    return {
        assets: data?.data,
        loading: isLoading
    };
}
