import { DataTableColumn } from 'mantine-datatable';
import { ASN, Asset, AssetQueryParams } from 'entities/Asset';
import { BaseColumnProps } from 'widgets/Columns/baseColumn';
import React from 'react';

type RecordType = Asset;
type QueryParams = AssetQueryParams;

export default ({
    column
}: BaseColumnProps<RecordType, QueryParams>): DataTableColumn<RecordType> => ({
    width: 'fit-content',
    accessor: 'metadata.subnetwork',
    title: 'ASN',
    render: ({ metadata }) => (
        <ASN geolocation={metadata?.geoip} subnetworks={metadata?.subnetwork} />
    ),
    ...column
});
