import { showNotification } from '@mantine/notifications';

interface ApiError {
    message: string;
    statusCode: number;
}

const API_ERRORS: Record<number, string> = {
    400: 'Bad Request',
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Not Found',
    409: 'Conflict',
    422: 'Unprocessable Entity',
    500: 'Internal Server Error'
};

export function parseApiError(
    error: any,
    apiErrors: Record<number, string> = {}
): ApiError {
    if ('status' in error) {
        if ('data' in error) {
            if ('message' in error.data) {
                return {
                    message: error.data.message,
                    statusCode: error.status
                };
            }

            if ('error' in error.data) {
                return {
                    message: error.data.error,
                    statusCode: error.status
                };
            }
        }

        const errors = {
            ...API_ERRORS,
            ...apiErrors
        };

        return {
            message: errors[error.status] || 'Unknown Error',
            statusCode: error.status
        };
    }

    return {
        message: 'Unknown Error',
        statusCode: 0
    };
}

export function showApiErrorNotification(
    error: ApiError,
    defaultMessage: string,
    customErrors: Record<number, string> = {}
) {
    const message =
        customErrors[error.statusCode] || error.message || defaultMessage;
    showNotification({
        color: 'red',
        title: 'Error',
        message
    });
}
