import React, { useCallback, useEffect } from 'react';
import { Button, Group, Modal, Textarea } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { useSelector } from 'react-redux';
import { useInjectReducer } from 'shared/hooks/useInjectReducer';
import { useUpdateAsset } from 'entities/Asset';
import { useForm } from '@mantine/form';
import {
    entityCommentActions,
    entityCommentReducer
} from '../../model/slices/entityCommentSlice/entityCommentSlice';
import { getEntity } from '../../model/selectors/getEntity/getEntity';

const EntityCommentModal = () => {
    useInjectReducer('entityComment', entityCommentReducer);

    const [opened, { open, close }] = useDisclosure(false);
    const dispatch = useAppDispatch();
    const entity = useSelector(getEntity);
    const [updateAsset, { isLoading: isUpdating }] = useUpdateAsset();
    const [updateAssetRemoveComment, { isLoading: isRemoving }] =
        useUpdateAsset();
    const form = useForm<{ comment: string }>({
        initialValues: {
            comment: ''
        }
    });

    useEffect(() => {
        if (!entity) close();
        if (!opened && entity) {
            open();
        }
    }, [entity]);

    useEffect(() => {
        if (!opened) form.reset();
        else form.setFieldValue('comment', entity?.comment || '');
    }, [opened]);

    const onClose = useCallback(() => {
        close();
        setTimeout(() => {
            dispatch(entityCommentActions.reset());
        }, 500);
    }, [close, dispatch]);

    const removeComment = useCallback(() => {
        if (entity) {
            updateAssetRemoveComment({
                id: entity?.id,
                comment: ''
            }).then(() => {
                onClose();
            });
        }
    }, [entity, updateAssetRemoveComment, onClose]);

    const onSubmit = useCallback(
        (values: { comment: string }) => {
            if (entity) {
                updateAsset({
                    id: entity?.id,
                    comment: values.comment
                }).then(() => {
                    onClose();
                });
            }
        },
        [entity, close, updateAsset]
    );

    return (
        <Modal
            size="lg"
            title={`${entity?.comment ? 'Edit' : 'Add'} comment`}
            opened={opened}
            onClose={onClose}
        >
            <form onSubmit={form.onSubmit(onSubmit)}>
                <Textarea
                    rows={8}
                    required
                    autoFocus
                    data-gramm="false"
                    placeholder="Enter briefly note"
                    {...form.getInputProps('comment')}
                />
                <Group justify="flex-end" mt="md">
                    {entity?.comment && (
                        <Button
                            mr="auto"
                            color="red"
                            variant="outline"
                            loading={isRemoving}
                            onClick={removeComment}
                        >
                            Remove comment
                        </Button>
                    )}
                    <Button color="asm" variant="outline" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button color="asm" type="submit" loading={isUpdating}>
                        Save
                    </Button>
                </Group>
            </form>
        </Modal>
    );
};

export default EntityCommentModal;
