import { RootAssetName } from 'entities/Asset';
import { useMemo } from 'react';
import { useGetRootAssetNames } from 'entities/Dictionary';

interface UseGetAllRootAssetIDs {
    ids?: string[];
    loading: boolean;
}

export function useGetRootAssetIDs(): UseGetAllRootAssetIDs {
    const { rootAssets, loading } = useGetRootAssetNames();
    const ids = useMemo(
        () => rootAssets?.map((asset: RootAssetName) => asset.id) ?? undefined,
        [rootAssets]
    );

    return {
        ids,
        loading
    };
}
