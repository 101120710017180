import React, { memo } from 'react';
import { Page } from 'shared/ui/Page';
import TimeAgo from 'timeago-react';
import { useGetVulnerability } from 'entities/Vulnerability';
import {
    IssueBadgesBar,
    IssueDescription,
    IssueEvidence,
    IssueReferences,
    IssueSolution,
    IssueUrl
} from 'entities/Issue';
import { Text } from 'shared/ui/Text';
import { VulnerabilitiesTable } from 'widgets/Issue';
import { AssetLink, RootAssetLink } from 'entities/Asset';
import { MetadataBar, MetadataItem } from 'shared/ui/Metadata';
import {
    formatDateToUTC,
    getDifferenceBetweenDatesInDays
} from 'shared/lib/date';
import { Box, Stack, Title } from '@mantine/core';
import { Seo } from 'shared/ui/SEO/SEO';
import IssueKev from 'entities/Issue/ui/IssueKev/IssueKev';

interface IssueDetailsPageProps {
    id: string;
}

function IssueDetailsPage(props: IssueDetailsPageProps) {
    const { id } = props;
    const { data: vulnerability, isLoading } = useGetVulnerability(id);

    if (isLoading) {
        return (
            <Page>
                <div>Loading...</div>
            </Page>
        );
    }

    if (!vulnerability) {
        return (
            <Page>
                <div>Vulnerability not found</div>
            </Page>
        );
    }

    const { asset } = vulnerability;
    const rootAssetName = asset.isRoot ? asset.value : asset.rootAsset.value;
    const daysBetweenFirstAndLastSeen = getDifferenceBetweenDatesInDays(
        vulnerability.createdAt,
        vulnerability.lastSeenAt
    );

    return (
        <Page h="unset">
            <Seo title="Details | Vulnerability" />
            <Stack gap="xs">
                <IssueBadgesBar issue={vulnerability} />
                <Title order={2}>{vulnerability.name}</Title>
                <MetadataBar>
                    <MetadataItem
                        label="Last seen"
                        value={
                            <Text
                                tooltipText={formatDateToUTC(
                                    vulnerability.lastSeenAt
                                )}
                            >
                                <TimeAgo datetime={vulnerability.lastSeenAt} />
                            </Text>
                        }
                    />
                    <MetadataItem
                        label="First seen"
                        value={
                            <Text
                                tooltipText={formatDateToUTC(
                                    vulnerability.createdAt
                                )}
                            >
                                <TimeAgo datetime={vulnerability.createdAt} />
                            </Text>
                        }
                    />
                    {daysBetweenFirstAndLastSeen > 1 && (
                        <MetadataItem
                            label="Lifetime"
                            value={`${daysBetweenFirstAndLastSeen} days`}
                        />
                    )}
                    {!asset.isRoot && (
                        <MetadataItem
                            label={`Affected ${asset.type}`}
                            value={
                                <AssetLink asset={asset}>
                                    {asset.value}
                                </AssetLink>
                            }
                        />
                    )}
                    <MetadataItem
                        label="Root Asset"
                        value={
                            <RootAssetLink
                                // @ts-ignore
                                asset={asset.isRoot ? asset : asset.rootAsset}
                            >
                                {rootAssetName}
                            </RootAssetLink>
                        }
                    />
                </MetadataBar>
            </Stack>
            <IssueKev issue={vulnerability} />
            <IssueDescription issue={vulnerability} />
            <IssueEvidence issue={vulnerability} />
            <IssueUrl issue={vulnerability} />
            <IssueSolution issue={vulnerability} />
            <IssueReferences issue={vulnerability} />
            <Box>
                <VulnerabilitiesTable
                    defaultVulnerabilityName={vulnerability.name}
                />
            </Box>
        </Page>
    );
}

export default memo(IssueDetailsPage);
