import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { userActions } from '../../slices/userSlice';

const logoutUser = createAsyncThunk<null, void, ThunkConfig<string>>(
    'user/logoutUser',
    async (_, thunkAPI) => {
        const { rejectWithValue, extra, dispatch } = thunkAPI;

        try {
            dispatch(userActions.logout());

            return null;
        } catch (error) {
            return rejectWithValue("Couldn't logout user.");
        }
    }
);

export default logoutUser;
