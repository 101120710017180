import React, { memo } from 'react';
import { Icon, Icons, IconSize } from 'shared/ui/Icon';
import { ActionIcon } from '@mantine/core';
import { ButtonProps } from '../../ui/Button';

interface CloseButtonProps extends ButtonProps {
    label?: string;
    onClose: () => void;
}

const CloseButton = (props: CloseButtonProps) => {
    const { label, onClose } = props;

    return (
        <ActionIcon variant="subtle" onClick={onClose}>
            <Icon icon={Icons.CLOSE} size={IconSize.MEDIUM} />
            {label}
        </ActionIcon>
    );
};

export default memo(CloseButton);
