import { DataTableColumn } from 'mantine-datatable';
import { BaseColumnProps } from 'widgets/Columns/baseColumn';
import {
    Technology,
    TechnologiesQueryParams,
    parseDiscoveryMethods
} from 'entities/Technology';

export default ({
    query,
    setQuery,
    column
}: BaseColumnProps<
    Technology,
    TechnologiesQueryParams
>): DataTableColumn<Technology> => ({
    accessor: 'discoveryMethod',
    title: 'Discovery Methods',
    render: technology => {
        const { discoveryMethod } = technology;
        if (!discoveryMethod || !discoveryMethod.length) return '-';

        const methods = parseDiscoveryMethods(discoveryMethod);
        return methods.map(method => method.name).join(', ');
    },
    ...column
});
