import React, { memo } from 'react';
import { NavigationLink, NavigationPageType } from 'entities/Navigation';
import { Technology } from 'entities/Technology';
import { technologyDetailsRoute } from 'app/providers/RouteProvider';

interface TechnologyLinkProps {
    technology: Technology;
    children?: React.ReactNode;
}

const TechnologyLink = (props: TechnologyLinkProps) => {
    const { technology, children } = props;

    return (
        <NavigationLink
            pageLink={technologyDetailsRoute(technology.id, 'detected')}
            pageType={NavigationPageType.TECHNOLOGY_DETAILS}
            pageID={technology.id}
            pageTitle={technology.name}
        >
            {children || technology.name}
        </NavigationLink>
    );
};

export default memo(TechnologyLink);
