import { DataTableColumn } from 'mantine-datatable';
import React from 'react';
import { BaseColumnProps, updateQuery } from 'widgets/Columns/baseColumn';
import { Technology, TechnologiesQueryParams } from 'entities/Technology';
import { Button, Group } from '@mantine/core';
import { FilterByTechnologyCategoryForm } from 'features/technology/filters/FilterByTechnologyCategory';

export default ({
    query,
    setQuery,
    column
}: BaseColumnProps<
    Technology,
    TechnologiesQueryParams
>): DataTableColumn<Technology> => {
    const { category = [] } = query || {};
    return {
        accessor: 'categories',
        title: 'Categories',
        filtering: category.length > 0,
        filter: (
            <FilterByTechnologyCategoryForm
                selectedCategories={category}
                onChange={category => {
                    updateQuery({ category }, query, setQuery);
                }}
            />
        ),
        render: ({ categories }) => (
            <Group gap="xs">
                {categories.map(({ id, name }) => (
                    <Button
                        key={id}
                        size="compact-xs"
                        variant="light"
                        onClick={() => {
                            const idString = id.toString();
                            const categorySelected =
                                category.includes(idString);
                            if (!categorySelected)
                                updateQuery(
                                    { category: [...category, idString] },
                                    query,
                                    setQuery
                                );
                        }}
                    >
                        {name}
                    </Button>
                ))}
            </Group>
        ),
        ...column
    };
};
