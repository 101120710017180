import React, { memo } from 'react';
import classNames from 'classnames';
import {
    Asset,
    AssetHealthBadge,
    AssetSslStatusBadge,
    AssetType,
    AssetTypeBadge,
    AssetVerificationBadge,
    RootAsset,
    RootAssetCandidate,
    AssetClassBadge,
    PortOpennessBadge,
    AssetActivityBadge,
    TotalScansBadge,
    tagColor,
    FalsePositiveBadge
} from 'entities/Asset';
import { Tag } from 'entities/Tag';
import { Badge } from '@mantine/core';
import { Icon, Icons } from 'shared/ui/Icon';
import styles from './AssetTagBar.module.scss';

interface AssetTagBarProps {
    className?: string;
    asset: Asset | RootAsset | RootAssetCandidate;
}

const AssetTagBar = (props: AssetTagBarProps) => {
    const { className, asset } = props;
    const classes = classNames(styles.AssetTagBar, className);

    return (
        <div className={classes}>
            <AssetClassBadge asset={asset} />
            <FalsePositiveBadge asset={asset} />
            <AssetTypeBadge asset={asset} />
            {asset.type === AssetType.PORT && asset.metadata?.port && (
                <PortOpennessBadge port={asset.metadata?.port} />
            )}
            {asset.type === AssetType.SSL && asset.metadata?.sslCertificate && (
                <AssetSslStatusBadge
                    sslCertificate={asset.metadata?.sslCertificate}
                />
            )}
            {!asset.isRootCandidate && <AssetHealthBadge asset={asset} />}
            {asset.isRoot && (
                <AssetVerificationBadge asset={asset as RootAsset} />
            )}
            {asset.lastActiveAt && !asset.isFalsePositive && (
                <AssetActivityBadge asset={asset} />
            )}
            {!asset.isFalsePositive && <TotalScansBadge asset={asset} />}
            {asset?.tags.map((tag: Tag) => (
                <Badge
                    key={tag.id}
                    color={tagColor(tag, 'asm')}
                    variant="outline"
                    leftSection={<Icon icon={Icons.TAG} />}
                >
                    {tag.name}
                </Badge>
            ))}
        </div>
    );
};

export default memo(AssetTagBar);
