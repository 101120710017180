import React, { memo } from 'react';
import HeaderedCard from 'shared/ui/HeaderedCard/HeaderedCard';
import { Text } from '@mantine/core';
import { Technology } from '../../model/types/technology';

interface TechnologyDescriptionProps {
    technology: Technology;
}

const TechnologyDescription = (props: TechnologyDescriptionProps) => {
    const { technology } = props;
    const { description } = technology;

    function renderDescription() {
        if (description) {
            return (
                <HeaderedCard header="Description">
                    <Text size="sm">{description}</Text>
                </HeaderedCard>
            );
        }

        return null;
    }

    return renderDescription();
};

export default memo(TechnologyDescription);
