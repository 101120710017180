import { internalApi } from 'shared/api/rtkApi';
import { InternalUser, CreateInternalUserBody } from '../../model/types/user';

const internalUserApi = internalApi.injectEndpoints({
    endpoints: builder => ({
        createUser: builder.mutation<InternalUser, CreateInternalUserBody>({
            query: body => ({
                url: '/users',
                method: 'POST',
                body
            })
        }),
        getUser: builder.query<InternalUser, number>({
            query: id => `/users/${id}`
        })
    })
});

export const { useCreateUserMutation, useGetUserQuery } = internalUserApi;
