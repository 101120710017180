import { API_TAGS, rtkApi } from 'shared/api/rtkApi';
import { ApiResponse } from 'shared/types/pagination';
import {
    NotificationChannel,
    NotificationConfig,
    NotificationConfigUpdateBody,
    NotificationConfigCreateBody
} from '../../model/types/notification';

const notificationsApi = rtkApi.injectEndpoints({
    endpoints: builder => ({
        getNotificationConfigs: builder.query<
            ApiResponse<NotificationConfig>,
            void
        >({
            query: () => ({
                url: '/notify/cfg'
            }),
            providesTags: result =>
                result
                    ? [
                          ...result.data.map(
                              notificationConfig =>
                                  ({
                                      type: API_TAGS.NOTIFICATION_CONFIG,
                                      channel: notificationConfig.channel
                                  } as const)
                          )
                      ]
                    : [{ type: API_TAGS.NOTIFICATION_CONFIG }]
        }),
        getNotificationConfig: builder.query<
            NotificationConfig,
            NotificationChannel
        >({
            query: channel => `/notify/cfg/${channel}`,
            providesTags: (_, __, channel) => [
                { type: API_TAGS.NOTIFICATION_CONFIG, channel }
            ]
        }),
        createNotificationConfig: builder.mutation<
            NotificationConfig,
            NotificationConfigCreateBody
        >({
            query: body => ({
                url: '/notify/cfg',
                method: 'POST',
                body
            }),
            invalidatesTags: [API_TAGS.NOTIFICATION_CONFIG]
        }),
        updateNotificationConfig: builder.mutation<
            NotificationConfig,
            { channel: NotificationChannel } & NotificationConfigUpdateBody
        >({
            query: ({ channel, ...body }) => ({
                url: `/notify/cfg/${channel}`,
                method: 'PATCH',
                body
            }),
            invalidatesTags: [API_TAGS.NOTIFICATION_CONFIG]
        })
    })
});

export const {
    useGetNotificationConfigsQuery,
    useGetNotificationConfigQuery,
    useCreateNotificationConfigMutation,
    useUpdateNotificationConfigMutation
} = notificationsApi;
