import { rtkApi } from 'shared/api/rtkApi';
import { MailContactUsBody, MailResponse } from '../../model/types/mail';

const mailApi = rtkApi.injectEndpoints({
    endpoints: builder => ({
        postContactUsMail: builder.mutation<MailResponse, MailContactUsBody>({
            query: body => ({
                url: '/contact',
                method: 'POST',
                body
            })
        })
    })
});

export const { usePostContactUsMailMutation } = mailApi;
