import { memo } from 'react';
import { PageHeader, PageChildrenLink } from 'widgets/PageHeader';
import { RoutePath } from 'shared/config/routeConfig';

const EventsPageHeader = () => (
    <PageHeader
        title="Events"
        links={
            <>
                <PageChildrenLink
                    title="Error"
                    to={`${RoutePath.events}/error`}
                    hasPreviewNumber={false}
                />
                <PageChildrenLink
                    title="Info"
                    to={`${RoutePath.events}/info`}
                    hasPreviewNumber={false}
                />
            </>
        }
    />
);

export default memo(EventsPageHeader);
