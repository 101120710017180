import { API_TAGS, rtkApi } from 'shared/api/rtkApi';
import { ApiResponse } from 'shared/types/pagination';
import { AssetType, PaginationQueryParams } from 'entities/Asset';
import { IssueUnique, Rating, StatusId } from 'entities/Issue';
import {
    Vulnerability,
    VulnerabilityUpdateBody
} from '../../model/types/vulnerability';

export interface VulnerabilityQueryParams extends PaginationQueryParams {
    assetType?: AssetType[];
    tag?: string[];
    assetTag?: string[];
    assetID?: string[];
    rootAssetID?: string[];
    orderBy?: string;
    nameExact?: string;
    nameStartsWith?: string;
    nameIncludes?: string;
    lastSeenAtGte?: number;
    lastSeenAtLte?: number;
    createdAtGte?: number;
    createdAtLte?: number;
    alertId?: string;
    rating?: Rating[];
    status?: StatusId[];
    isKev?: boolean;
    isAssetFalsePositive?: boolean;
}

const vulnerabilityApi = rtkApi.injectEndpoints({
    endpoints: builder => ({
        getVulnerability: builder.query<Vulnerability, string>({
            query: id => `/vulnerabilities/${id}`,
            providesTags: (_, __, id) => [{ type: API_TAGS.ISSUE, id }]
        }),
        getVulnerabilities: builder.query<
            ApiResponse<Vulnerability>,
            VulnerabilityQueryParams
        >({
            query: params => ({
                url: '/vulnerabilities',
                params
            }),
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({
                              type: API_TAGS.ISSUE,
                              id
                          })),
                          API_TAGS.ISSUE
                      ]
                    : [API_TAGS.ISSUE]
        }),
        getVulnerabilitiesCount: builder.query<
            number,
            VulnerabilityQueryParams
        >({
            query: params => ({
                url: '/vulnerabilities',
                params: {
                    page: 1,
                    size: 1,
                    orderBy: '-lastSeenAt',
                    ...params
                }
            }),
            transformResponse: (response: ApiResponse<number>) =>
                response.pagination?.totalItems || 0
        }),
        getVulnerabilitiesUnique: builder.query<
            ApiResponse<IssueUnique>,
            VulnerabilityQueryParams
        >({
            query: params => ({
                url: '/vulnerabilities-unique',
                params
            })
        }),
        createJiraTicket: builder.mutation<Vulnerability, string>({
            query: id => ({
                url: `/vulnerabilities/${id}/create-jira-issue`,
                method: 'POST'
            }),
            invalidatesTags: (_, __, id) => [{ type: API_TAGS.ISSUE, id }]
        }),
        updateVulnerability: builder.mutation<
            Vulnerability,
            VulnerabilityUpdateBody
        >({
            query: ({ id, ...body }) => ({
                url: `/vulnerabilities/${id}`,
                method: 'PUT',
                body
            }),
            invalidatesTags: (_, __, { id }) => [{ type: API_TAGS.ISSUE, id }]
        })
    })
});

export const useGetVulnerabilities =
    vulnerabilityApi.useGetVulnerabilitiesQuery;
export const useGetVulnerabilitiesCount =
    vulnerabilityApi.useGetVulnerabilitiesCountQuery;
export const useGetVulnerability = vulnerabilityApi.useGetVulnerabilityQuery;
export const useGetVulnerabilitiesUnique =
    vulnerabilityApi.useGetVulnerabilitiesUniqueQuery;
export const useCreateJiraTicket = vulnerabilityApi.useCreateJiraTicketMutation;
export const useUpdateVulnerability =
    vulnerabilityApi.useUpdateVulnerabilityMutation;
