import React, { memo } from 'react';
import { VulnerabilitiesTable } from 'widgets/Issue';
import { numberFormat } from 'shared/lib/formatter/formatter';
import { Technology } from '../../model/types/technology';

interface TechnologyRelatedIssuesProps {
    technology: Technology;
}

const TechnologyRelatedIssues = (props: TechnologyRelatedIssuesProps) => {
    const { technology } = props;

    if (!technology.vulnerabilitiesCount) {
        return null;
    }

    return (
        <VulnerabilitiesTable
            title={`Detected the following ${numberFormat(
                technology.vulnerabilitiesCount || 0
            )} issues`}
            defaultTechnologyID={technology.id}
        />
    );
};

export default memo(TechnologyRelatedIssues);
