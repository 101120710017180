import { memo } from 'react';
import { Alert } from '@mantine/core';
import {
    addNewRootAssetActions,
    getErrorMessage
} from 'features/asset/actions/AddNewRootAsset';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';

const ErrorAddedAsset = () => {
    const dispatch = useAppDispatch();
    const errorMessage = useSelector(getErrorMessage);

    return (
        <Alert
            variant="light"
            color="red"
            title="Adding asset failed"
            withCloseButton
            onClose={() => dispatch(addNewRootAssetActions.reset())}
        >
            {errorMessage}
        </Alert>
    );
};

export default memo(ErrorAddedAsset);
