import React, { memo } from 'react';
import classNames from 'classnames';
import { Tooltip } from '@mantine/core';
import styles from './Text.module.scss';

type TextSize = 'xxs' | 'xs' | 'sm' | 'm' | 'l' | 'xl' | '2xl';
type TextColor =
    | 'default'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info';

interface TextProps {
    className?: string;
    tooltipText?: string | null;
    size?: TextSize;
    color?: TextColor;
    children: React.ReactNode;
}

const Text = (props: TextProps) => {
    const { className, size, color, children, tooltipText } = props;
    const classes = classNames(
        styles.Text,
        {
            [styles[`Text--size-${size}`]]: size,
            [styles[`Text--color-${color}`]]: color
        },
        className
    );
    const hasTooltip = !!tooltipText;
    const TextComponent = <div className={classes}>{children}</div>;

    if (hasTooltip) {
        return (
            <Tooltip label={tooltipText || children} withArrow>
                {TextComponent}
            </Tooltip>
        );
    }

    return TextComponent;
};

export default memo(Text);
