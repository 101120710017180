import { Portal } from '@mantine/core';
import useHubspot from 'shared/hooks/useHubspot/useHubspot';
import { useState } from 'react';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { analyticsActions } from 'entities/Analytics';

export interface InvisibleFormProps {
    id: string;
    children?: React.ReactNode;
}

const InvisibleForm = (props: InvisibleFormProps) => {
    const { id, children } = props;
    const dispatch = useAppDispatch();
    const { refreshPageHandlers } = useHubspot();
    const [submitted, setSubmitted] = useState(false);

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSubmitted(true);

        setTimeout(() => {
            dispatch(analyticsActions.reset());
        }, 1500);
    };

    return (
        !submitted && (
            <Portal>
                <form
                    id={id}
                    style={{
                        position: 'absolute',
                        top: '-1000px',
                        left: '-1000px',
                        width: '1px',
                        height: '1px'
                    }}
                    onSubmit={onSubmit}
                >
                    {children}
                    <input
                        type="submit"
                        value="Submit"
                        ref={input => {
                            console.log('input', id);
                            refreshPageHandlers();
                            setTimeout(() => {
                                input?.click();
                            });
                        }}
                    />
                </form>
            </Portal>
        )
    );
};

export default InvisibleForm;
