import { rtkApi } from 'shared/api/rtkApi';
import { ApiResponse } from 'shared/types/pagination';
import {
    DistributionByRating,
    StatisticsByAssetType,
    StatisticsByAssetTypeQueryParams,
    LastStatisticsByAssetTypeQueryParams,
    StatisticsByVulnerabilityRatingQueryParams,
    DistributionByScore,
    IssueStatistics,
    IssueStatisticsQueryParams,
    BaseLastStatisticsQueryParams,
    TotalStatistics,
    AssetsDiscoveredWeeklyQueryParams,
    AssetsDiscoveredWeekly
} from '../../model/types/statistics';

const assetsApi = rtkApi.injectEndpoints({
    endpoints: builder => ({
        getStatisticsByAssetType: builder.query<
            StatisticsByAssetType[],
            StatisticsByAssetTypeQueryParams
        >({
            query: params => ({
                url: '/stats/by-asset-type',
                params
            }),
            transformResponse: (response: ApiResponse<StatisticsByAssetType>) =>
                response.data
        }),
        getLastStatisticsByAssetType: builder.query<
            StatisticsByAssetType,
            LastStatisticsByAssetTypeQueryParams
        >({
            query: params => ({
                url: '/stats/by-asset-type/last',
                params
            }),
            transformResponse: (response: ApiResponse<StatisticsByAssetType>) =>
                response.data[0]
        }),
        getIssueStatistics: builder.query<
            IssueStatistics[],
            IssueStatisticsQueryParams
        >({
            query: params => ({
                url: '/stats/vulnerabilities',
                params
            }),
            transformResponse: (response: ApiResponse<IssueStatistics>) =>
                response.data
        }),
        getLastIssueStatistics: builder.query<
            IssueStatistics,
            IssueStatisticsQueryParams
        >({
            query: params => ({
                url: '/stats/vulnerabilities/last',
                params
            }),
            transformResponse: (response: ApiResponse<IssueStatistics>) =>
                response.data[0]
        }),
        getStatisticsByVulnerabilityRating: builder.query<
            DistributionByRating[],
            StatisticsByVulnerabilityRatingQueryParams
        >({
            query: params => ({
                url: '/stats/vulnerabilities/by-rating',
                params
            }),
            transformResponse: (response: ApiResponse<DistributionByRating>) =>
                response.data
        }),
        getLastStatisticsByVulnerabilityRating: builder.query<
            DistributionByRating,
            BaseLastStatisticsQueryParams
        >({
            query: params => ({
                url: '/stats/vulnerabilities/by-rating/last',
                params
            }),
            transformResponse: (response: ApiResponse<DistributionByRating>) =>
                response.data[0]
        }),
        getStatisticsByVulnerabilityScore: builder.query<
            DistributionByScore[],
            StatisticsByVulnerabilityRatingQueryParams
        >({
            query: params => ({
                url: '/stats/vulnerabilities/by-score',
                params
            }),
            transformResponse: (response: ApiResponse<DistributionByScore>) =>
                response.data
        }),
        getLastStatisticsByVulnerabilityScore: builder.query<
            DistributionByScore,
            BaseLastStatisticsQueryParams
        >({
            query: params => ({
                url: '/stats/vulnerabilities/by-score/last',
                params
            }),
            transformResponse: (response: ApiResponse<DistributionByScore>) =>
                response.data[0]
        }),
        getTotalStatisticsByVulnerabilityScore: builder.query<
            TotalStatistics[],
            IssueStatisticsQueryParams
        >({
            query: params => ({
                url: '/stats/vulnerabilities/total-score',
                params
            }),
            transformResponse: (response: ApiResponse<TotalStatistics>) =>
                response.data
        }),
        getLastTotalStatisticsByVulnerabilityScore: builder.query<
            TotalStatistics,
            BaseLastStatisticsQueryParams
        >({
            query: params => ({
                url: '/stats/vulnerabilities/total-score/last',
                params
            }),
            transformResponse: (response: ApiResponse<TotalStatistics>) =>
                response.data[0]
        }),
        getAssetsDiscoveredWeekly: builder.query<
            AssetsDiscoveredWeekly[],
            AssetsDiscoveredWeeklyQueryParams
        >({
            query: params => ({
                url: '/stats/assets-discovered-weekly',
                params
            }),
            transformResponse: (
                response: ApiResponse<AssetsDiscoveredWeekly>
            ) => response.data
        })
    })
});

export const useGetStatisticsByAssetType =
    assetsApi.useGetStatisticsByAssetTypeQuery;
export const useGetLastStatisticsByAssetType =
    assetsApi.useGetLastStatisticsByAssetTypeQuery;
export const useGetStatisticsByVulnerabilityRating =
    assetsApi.useGetStatisticsByVulnerabilityRatingQuery;
export const useGetLastStatisticsByVulnerabilityRating =
    assetsApi.useGetLastStatisticsByVulnerabilityRatingQuery;
export const useGetStatisticsByVulnerabilityScore =
    assetsApi.useGetStatisticsByVulnerabilityScoreQuery;
export const useGetLastStatisticsByVulnerabilityScore =
    assetsApi.useGetLastStatisticsByVulnerabilityScoreQuery;
export const useGetIssueStatistics = assetsApi.useGetIssueStatisticsQuery;
export const useGetLastIssueStatistics =
    assetsApi.useGetLastIssueStatisticsQuery;
export const useGetTotalStatisticsByVulnerabilityScore =
    assetsApi.useGetTotalStatisticsByVulnerabilityScoreQuery;
export const useGetLastTotalStatisticsByVulnerabilityScore =
    assetsApi.useGetLastTotalStatisticsByVulnerabilityScoreQuery;
export const useGetAssetsDiscoveredWeekly =
    assetsApi.useGetAssetsDiscoveredWeeklyQuery;
