import React, { memo, useMemo } from 'react';
import { Icon, Icons } from 'shared/ui/Icon';
import { Badge } from '@mantine/core';
import {
    Asset,
    RootAsset,
    RootAssetCandidate
} from '../../../model/types/asset';

interface AssetClassBadgeProps {
    asset: RootAsset | Asset | RootAssetCandidate;
}

const AssetClassBadge = (props: AssetClassBadgeProps) => {
    const { asset } = props;
    const icon = useMemo(() => {
        switch (true) {
            case asset.isRoot:
                return Icons.LANGUAGE;
            case asset.isRootCandidate:
                return Icons.WIFI_FIND;
            default:
                return null;
        }
    }, [asset.isRoot, asset.isRootCandidate]);
    const label = useMemo(() => {
        switch (true) {
            case asset.isRoot:
                return 'Root';
            case asset.isRootCandidate:
                return 'Asset Candidate';
            default:
                return null;
        }
    }, [asset.isRoot, asset.isRootCandidate]);

    if (!icon || !label) return null;
    return <Badge leftSection={icon && <Icon icon={icon} />}>{label}</Badge>;
};

export default memo(AssetClassBadge);
