import React, { memo } from 'react';
import { Icon, Icons, IconSize } from 'shared/ui/Icon';
import { Alert, Stack, Text } from '@mantine/core';
import { useStartRootAssetScanningBanner } from '../../model/hooks/useStartRootAssetScanningBanner';
import StartRootAssetScanningButton from '../StartRootAssetScanningBanner/StartRootAssetScanningButton';

interface StartRootAssetScanningBannerProps {
    className?: string;
}

const StartRootAssetScanningBanner = (
    props: StartRootAssetScanningBannerProps
) => {
    const { asset, show } = useStartRootAssetScanningBanner();

    if (!show || !asset) {
        return null;
    }

    return (
        <Alert
            variant="light"
            color="asm"
            title="Start scanning"
            icon={<Icon icon={Icons.ROCKET_LAUNCH} size={IconSize.MEDIUM} />}
            {...props}
        >
            <Stack gap="sm">
                <Text size="sm">
                    Using your email domain <b>{asset.value}</b> we have
                    automatically added it as a verified root asset. Attaxion is
                    waiting for your confirmation to start monitoring your
                    infrastructure. Click the <b>&quot;Start scanning&quot;</b>{' '}
                    button to start scanning.
                </Text>
                <StartRootAssetScanningButton asset={asset} />
            </Stack>
        </Alert>
    );
};

export default memo(StartRootAssetScanningBanner);
