import { memo } from 'react';
import { Badge, Group, NumberFormatter } from '@mantine/core';
import { Rating, severityRatingColor } from 'entities/Issue';
import { BaseAsset } from 'entities/Asset';

interface VulnerabilityStatsProps {
    stats?:
        | BaseAsset['vulnerabilityStats']
        | BaseAsset['rootVulnerabilityStats'];
}

const VulnerabilityStats = (props: VulnerabilityStatsProps) => {
    const { stats } = props;

    if (!stats) return null;

    return (
        <Group gap="xs" wrap="nowrap" w="100%" grow>
            {Object.entries(stats ?? {})
                .filter(([rating]) => rating !== 'total')
                .map(([rating, value]) => (
                    <Badge
                        key={rating}
                        px="xs"
                        c="#fff"
                        radius="sm"
                        color={
                            value
                                ? severityRatingColor(rating as Rating)
                                : 'gray.4'
                        }
                        styles={{
                            label: {
                                overflow: 'visible'
                            }
                        }}
                    >
                        <NumberFormatter value={value} thousandSeparator />
                    </Badge>
                ))}
        </Group>
    );
};

export default memo(VulnerabilityStats);
