import {
    CombinedState,
    configureStore,
    Reducer,
    ReducersMapObject
} from '@reduxjs/toolkit';
import { userReducer } from 'entities/User';
import { createReducerManager } from 'app/providers/StoreProvider/config/reducerManager';
import { $api } from 'shared/api/api';
import { To } from '@remix-run/router';
import { NavigateOptions } from 'react-router';
import { accountApi, internalApi, rtkApi } from 'shared/api/rtkApi';
import { navigationReducer } from 'entities/Navigation';
import { analyticsReducer } from 'entities/Analytics';
import { StateSchema, ThunkExtraArgument } from './StateSchema';

export function createStore(
    initialState?: StateSchema,
    asyncReducers?: ReducersMapObject<StateSchema>,
    navigate?: (to: To, options?: NavigateOptions) => void
) {
    const rootReducer: ReducersMapObject<StateSchema> = {
        ...asyncReducers,
        user: userReducer,
        navigation: navigationReducer,
        analytics: analyticsReducer,
        [rtkApi.reducerPath]: rtkApi.reducer,
        [accountApi.reducerPath]: accountApi.reducer,
        [internalApi.reducerPath]: internalApi.reducer
    };

    const reducerManager = createReducerManager(rootReducer);
    const extraArgument: ThunkExtraArgument = {
        api: $api,
        navigate
    };
    const store = configureStore({
        reducer: reducerManager.reduce as Reducer<CombinedState<StateSchema>>,
        devTools: __IS_DEV__,
        preloadedState: initialState,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({
                thunk: {
                    extraArgument
                }
            })
                .concat(rtkApi.middleware)
                .concat(accountApi.middleware)
                .concat(internalApi.middleware)
    });

    // @ts-ignore
    store.reducerManager = reducerManager;
    return store;
}

export type AppStore = ReturnType<typeof createStore>;
export type AppDispatch = AppStore['dispatch'];
