import React, { memo } from 'react';
import {
    MetadataItemFirstSeen,
    MetadataItemLastSeen,
    MetadataItemShortestDiscoveryPath,
    DiscoveryMethods,
    Asset,
    RootAsset,
    RootAssetCandidate,
    RootAssetLink
} from 'entities/Asset';
import { MetadataBar, MetadataItem } from 'shared/ui/Metadata';
import { ScanModeLabel } from 'entities/Scanner';
import { AssetScanModePost } from 'entities/KnowledgeBase';
import { Group } from '@mantine/core';
import { useDemo } from 'shared/hooks/useDemo/useDemo';
import { EditScanModeIcon } from 'features/asset/actions/UpdateAssetScannerSettings';

interface AssetMetadataBarProps {
    asset: Asset | RootAsset | RootAssetCandidate;
}

const AssetMetadataBar = (props: AssetMetadataBarProps) => {
    const { asset } = props;
    const { isDemo } = useDemo();

    return (
        <>
            <MetadataBar>
                <MetadataItemLastSeen asset={asset} />
                <MetadataItemFirstSeen asset={asset} />
                {!isDemo &&
                    !asset.isRootCandidate &&
                    !asset.isFalsePositive && (
                        <MetadataItem
                            label="Scan mode"
                            value={
                                <Group gap={2} align="center">
                                    <span>
                                        {
                                            ScanModeLabel[
                                                asset.scanMode.toUpperCase() as keyof typeof ScanModeLabel
                                            ]
                                        }
                                    </span>
                                    <EditScanModeIcon asset={asset} />
                                </Group>
                            }
                        />
                    )}
                {'rootAsset' in asset && asset.rootAsset && (
                    <MetadataItem
                        label="Root Asset"
                        value={<RootAssetLink asset={asset.rootAsset} />}
                    />
                )}
            </MetadataBar>
            <MetadataBar>
                <DiscoveryMethods asset={asset as Asset} />
                <MetadataItemShortestDiscoveryPath asset={asset as Asset} />
            </MetadataBar>
        </>
    );
};

export default memo(AssetMetadataBar);
