import React, { memo } from 'react';
import { Asset, RootAsset, RootAssetCandidate } from 'entities/Asset';
import { Badge } from '@mantine/core';

interface FalsePositiveBadgeProps {
    asset: Asset | RootAsset | RootAssetCandidate;
    fullWidth?: boolean;
}

const FalsePositiveBadge = (props: FalsePositiveBadgeProps) => {
    const { asset, fullWidth } = props;

    if (!asset.isFalsePositive) return null;

    return (
        <Badge fullWidth={fullWidth} color="orange">
            False Positive
        </Badge>
    );
};

export default memo(FalsePositiveBadge);
