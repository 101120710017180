import { useCallback, useEffect } from 'react';

const KEY_NAME_ESC = 'Escape';
const KEY_EVENT_TYPE = 'keyup';

interface UseEscapeKey {
    handleClose: () => void;
    enabled?: boolean;
}

export function useEscapeKey(props: UseEscapeKey) {
    const { handleClose, enabled = true } = props;

    const handleEscape = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === KEY_NAME_ESC) {
                handleClose();
            }
        },
        [handleClose]
    );

    useEffect(() => {
        if (enabled) {
            document.addEventListener(KEY_EVENT_TYPE, handleEscape);
        } else {
            document.removeEventListener(KEY_EVENT_TYPE, handleEscape);
        }

        return () => {
            document.removeEventListener(KEY_EVENT_TYPE, handleEscape);
        };
    }, [enabled, handleEscape]);

    return null;
}
