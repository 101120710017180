import { memo } from 'react';
import { useDemo } from 'shared/hooks/useDemo/useDemo';

interface DemoGuardProps {
    children: React.ReactNode;
}

const DemoGuard = (props: DemoGuardProps) => {
    const { isDemo } = useDemo();
    const { children } = props;

    return isDemo ? null : children;
};

export default memo(DemoGuard);
