import { Graph, useGetAssetsDiscoveryPathForward } from 'entities/Asset';
import { useGetRootAssetIDs } from 'entities/Dictionary';

interface UseGetAllRootAssetGraphs {
    graphs: Graph[] | undefined;
    loading: boolean;
}

export function useGetAllRootAssetGraphs(): UseGetAllRootAssetGraphs {
    const { ids, loading } = useGetRootAssetIDs();
    const { data, isLoading } = useGetAssetsDiscoveryPathForward(ids);

    return {
        graphs: data,
        loading: loading || isLoading
    };
}
