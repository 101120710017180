import React, { memo, useMemo, useState } from 'react';
import { DataTableColumn } from 'mantine-datatable';
import { GenericTable } from 'shared/ui/DataTable';
import {
    TechnologiesQueryParams,
    Technology,
    useGetTechnologies
} from 'entities/Technology';
import technologyNameColumn from 'widgets/Columns/technologyNameColumn';
import versionColumn from 'widgets/Columns/versionColumn';
import discoveryMethodColumn from 'widgets/Columns/discoveryMethodColumn';
import createdAtColumn from 'widgets/Columns/createdAtColumn';

interface TechnologiesFeedTableProps {
    defaultSize?: number;
}

const TechnologiesFeedTable = (props: TechnologiesFeedTableProps) => {
    const { defaultSize = 5 } = props;
    const [query, setQuery] = useState<TechnologiesQueryParams>({});
    const columns = useMemo<DataTableColumn<Technology>[]>(
        () => [
            createdAtColumn({
                query,
                setQuery,
                column: {
                    title: 'Detected'
                }
            }),
            technologyNameColumn({
                query,
                setQuery
            }),
            versionColumn({
                query,
                setQuery
            }),
            discoveryMethodColumn({
                query,
                setQuery
            })
        ],
        [query]
    );

    return (
        <GenericTable<Technology>
            // @ts-ignore
            useData={useGetTechnologies}
            columns={columns}
            queryParams={query}
            defaultSize={defaultSize}
            defaultSortStatus={{
                columnAccessor: 'createdAt',
                direction: 'desc'
            }}
            hasToolbar={false}
            hasDownload={false}
            hasRecordsPerPage={false}
        />
    );
};

export default memo(TechnologiesFeedTable);
