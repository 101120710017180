import React, { memo } from 'react';
import {
    DistributionByScoreBarChart,
    DistributionBySeverityPieChart,
    UniqueVulnerabilitiesTable,
    DistributionBySeverityCard,
    IssueStatisticsChart
} from 'widgets/Issue';
import HeaderedCard from 'shared/ui/HeaderedCard/HeaderedCard';
import { Group } from '@mantine/core';
import { Seo } from 'shared/ui/SEO/SEO';
import IssuesBaseLayout from '../../../ui/IssuesBaseLayout/IssuesBaseLayout';
import styles from './OverviewPage.module.scss';

function OverviewPage() {
    return (
        <IssuesBaseLayout className={styles.Page}>
            <Seo title="Overview | Issues" />
            <Group grow>
                <DistributionBySeverityCard />
            </Group>
            <Group grow>
                <HeaderedCard header="Current distribution by severity">
                    <DistributionBySeverityPieChart />
                </HeaderedCard>
                <HeaderedCard header="Current distribution by CVSS score">
                    <DistributionByScoreBarChart />
                </HeaderedCard>
            </Group>
            <Group grow>
                <HeaderedCard header="Distribution by severity, by date">
                    <IssueStatisticsChart />
                </HeaderedCard>
            </Group>
            <UniqueVulnerabilitiesTable title="Unique vulnerabilities" />
        </IssuesBaseLayout>
    );
}

export default memo(OverviewPage);
