import { useApp } from 'app/providers/InitProvider';
import styles from './SubscriptionDaysLeftNotification.module.scss';

const SubscriptionDaysLeftNotification = () => {
    const { subscriptionDaysLeft } = useApp();

    if (!subscriptionDaysLeft || subscriptionDaysLeft >= 21) {
        return null;
    }

    return (
        <div className={styles['subscription-days-left-notification']}>
            <b>{subscriptionDaysLeft} days left in your trial</b>
        </div>
    );
};

export default SubscriptionDaysLeftNotification;
