import { memo, useCallback, useEffect } from 'react';
import { useSetAsFalsePositive, useUnsetAsFalsePositive } from 'entities/Asset';
import { Button, Group, Modal, Space, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { useSelector } from 'react-redux';
import { useInjectReducer } from 'shared/hooks/useInjectReducer';
import {
    setAssetFalsePositiveActions,
    setAssetFalsePositiveReducer
} from 'features/asset/actions/SetAssetFalsePositive';
import { showNotification } from '@mantine/notifications';
import { getAsset } from '../../model/selectors/getAsset/getAsset';

const SetAssetFalsePositiveModal = () => {
    useInjectReducer('setAssetFalsePositive', setAssetFalsePositiveReducer);

    const [opened, { open, close }] = useDisclosure(false);
    const dispatch = useAppDispatch();
    const asset = useSelector(getAsset);
    const [setAssetFalsePositive, { isLoading: isSetting }] =
        useSetAsFalsePositive();
    const [unsetAssetFalsePositive, { isLoading: isUnsetting }] =
        useUnsetAsFalsePositive();

    useEffect(() => {
        if (asset === undefined) return;
        if (!asset) close();
        if (asset) open();
    }, [asset, close, open]);

    const onClose = useCallback(() => {
        close();
        setTimeout(() => {
            dispatch(setAssetFalsePositiveActions.reset());
        }, 500);
    }, [dispatch]);

    const onSubmit = useCallback(() => {
        if (asset === undefined) return;
        if (!asset.isFalsePositive) {
            setAssetFalsePositive(asset.id).then(() => {
                showNotification({
                    title: 'Success',
                    message:
                        'The asset has been set as false positive successfully.',
                    color: 'green'
                });
                onClose();
            });
        } else {
            unsetAssetFalsePositive(asset.id).then(() => {
                showNotification({
                    title: 'Success',
                    message:
                        'The asset has been unset as false positive successfully.',
                    color: 'green'
                });
                onClose();
            });
        }
    }, [asset, setAssetFalsePositive, dispatch, unsetAssetFalsePositive]);

    return (
        <Modal
            size="lg"
            opened={opened}
            title="Set asset as false positive"
            onClose={close}
        >
            {!asset?.isFalsePositive && (
                <Text size="sm">
                    Are you sure you want to set the asset <b>{asset?.value}</b>{' '}
                    as false positive? All the descendants will be set as false
                    positive as well if they have only this asset as a parent.
                    False positive assets will not be scanned and will not be
                    included in the reports, notifications and statistics. All
                    false positive assets are excluded from the <b>Assets</b>{' '}
                    page and can be viewed in the{' '}
                    <b>&ldquo;Management&ldquo;</b> →{' '}
                    <b>&ldquo;Archived assets&ldquo;</b> section.
                </Text>
            )}
            {asset?.isFalsePositive && (
                <Text size="sm">
                    Are you sure you want to unset the asset{' '}
                    <b>{asset?.value}</b> as false positive?
                </Text>
            )}
            <Space h="md" />
            <Group justify="flex-end">
                <Button onClick={onClose} variant="outline" color="asm">
                    Cancel
                </Button>
                <Button
                    color="asm"
                    loading={isSetting || isUnsetting}
                    onClick={onSubmit}
                >
                    I&apos;m sure, {asset?.isFalsePositive ? 'unset' : 'set'}
                </Button>
            </Group>
        </Modal>
    );
};

export default memo(SetAssetFalsePositiveModal);
