import { API_TAGS, rtkApi } from 'shared/api/rtkApi';
import { ApiResponse } from 'shared/types/pagination';
import { Tag } from '../../model/types/tag';

const tagApi = rtkApi.injectEndpoints({
    endpoints: builder => ({
        getTags: builder.query<ApiResponse<Tag>, void>({
            query: () => ({
                url: '/tags'
            }),
            providesTags: [API_TAGS.TAG]
        })
    })
});

export const useGetTags = tagApi.useGetTagsQuery;
