import { useContext } from 'react';
import { Rating } from 'entities/Issue';
import { AssetDetailsContext } from './AssetDetailsContext';

interface UseIssueFiltersProps {
    selectedIssueRating?: Rating;
    setSelectedIssueRating?: (value: Rating) => void;
}

function useIssueFilters() {
    const { selectedIssueRating, setSelectedIssueRating } =
        useContext(AssetDetailsContext);

    return {
        selectedIssueRating,
        setSelectedIssueRating
    };
}

export default useIssueFilters;
