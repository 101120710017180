import { InvisibleForm } from 'shared/ui/Analytics';
import { useMemo } from 'react';

interface DefaultInvisibleFormProps {
    id: string;
    email: string;
}

const DefaultInvisibleForm = (props: DefaultInvisibleFormProps) => {
    const { id, email } = props;
    const formId = useMemo(() => {
        if (id.endsWith('_form')) {
            return id;
        }

        return `${id}_form`;
    }, [id]);

    if (!id || !email) return null;

    return (
        <InvisibleForm id={formId}>
            <input type="email" name="email" value={email} />
        </InvisibleForm>
    );
};

export default DefaultInvisibleForm;
