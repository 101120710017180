import React, { memo, useMemo } from 'react';
import { Badge } from '@mantine/core';
import { numberFormat } from 'shared/lib/formatter/formatter';
import {
    Asset,
    RootAsset,
    RootAssetCandidate
} from '../../../model/types/asset';

interface AssetHealthBadgeProps {
    asset: RootAsset | Asset | RootAssetCandidate;
}

const TotalScansBadge = (props: AssetHealthBadgeProps) => {
    const { asset } = props;
    const { totalScans } = asset;
    const label = useMemo(() => {
        if (!totalScans) return 'Not scanned yet';
        if (totalScans === 1) return 'Scanned once';
        return `Scanned ${numberFormat(totalScans)} times`;
    }, [totalScans]);
    const color = totalScans ? 'green' : 'yellow';

    return (
        <Badge color={color} variant="dot">
            {label}
        </Badge>
    );
};

export default memo(TotalScansBadge);
