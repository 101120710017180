import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { RoutePath } from 'shared/config/routeConfig';

export function rootAssetCandidateDetailsRoute(assetId: string) {
    return `${RoutePath.management}/root-asset-candidates/${assetId}`;
}

export function useNavigateToRootAssetCandidateDetails() {
    const navigate = useNavigate();

    return useCallback(
        (assetId: string) => {
            navigate(rootAssetCandidateDetailsRoute(assetId));
        },
        [navigate]
    );
}
