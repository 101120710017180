import React, { memo, Suspense } from 'react';
import classNames from 'classnames';
import { Asset, RootAsset, RootAssetCandidate } from 'entities/Asset';
import {
    CommentFormButton,
    EntityCommentModal
} from 'features/asset/actions/AddComment';
import styles from './AssetHeader.module.scss';

interface AssetHeaderProps {
    className?: string;
    asset: Asset | RootAsset | RootAssetCandidate;
}

const AssetHeader = (props: AssetHeaderProps) => {
    const { className, asset } = props;
    const classes = classNames(styles.AssetHeader, className);

    return (
        <div className={classes}>
            <h1 className={styles.AssetHeader__Title}>{asset.value}</h1>
        </div>
    );
};

export default memo(AssetHeader);
