import React, { memo, useCallback } from 'react';
import { Box, Button, Flex, Group, Text, TextInput } from '@mantine/core';
import {
    ForgotPasswordForm as IForgotPasswordForm,
    useForgotPasswordMutation
} from 'entities/User';
import { useForm } from '@mantine/form';
import { NavLink, useNavigate } from 'react-router-dom';
import { RoutePath } from 'shared/config/routeConfig';
import { Icon, Icons, IconSize } from 'shared/ui/Icon';
import { useFullUrl } from 'shared/hooks/useFullUrl/useFullUrl';
import { showNotification } from '@mantine/notifications';

const ForgotPasswordForm = () => {
    const navigate = useNavigate();
    const redirectUrl = useFullUrl(RoutePath.forgotPasswordConfirmation);
    const [forgotPassword, { isLoading, error }] = useForgotPasswordMutation();
    const form = useForm<IForgotPasswordForm>({
        initialValues: {
            email: ''
        }
    });

    const onSubmit = useCallback(
        (values: IForgotPasswordForm) => {
            forgotPassword({
                market_id: __NAF_MARKET_ID__,
                email: values.email,
                confirm_redirect: redirectUrl
            })
                .unwrap()
                .then(data => {
                    if ('error' in data) {
                        showNotification({
                            title: 'Error',
                            message: data.error,
                            color: 'red'
                        });
                        return;
                    }

                    showNotification({
                        title: 'Success',
                        message:
                            'message' in data
                                ? data.message
                                : 'Your password has been reset. Please check your email.',
                        color: 'green',
                        autoClose: 30 * 1000
                    });

                    navigate(RoutePath.login);
                });
        },
        [forgotPassword, navigate, redirectUrl]
    );

    return (
        <Box w="100%">
            <form
                onSubmit={form.onSubmit(onSubmit)}
                autoComplete="on"
                id="forgot_password_form"
            >
                <Flex gap="lg" direction="column">
                    <TextInput
                        required
                        withAsterisk
                        label="Your email"
                        placeholder="account@attaxion.dev"
                        autoComplete="on"
                        type="email"
                        {...form.getInputProps('email')}
                    />
                    {error && (
                        <Text c="red">
                            Something went wrong. Please try again later or
                            contact support.
                        </Text>
                    )}
                    <Group justify="space-between">
                        <NavLink to={RoutePath.login}>
                            <Group gap="xs">
                                <Icon
                                    icon={Icons.CHEVRON_LEFT}
                                    size={IconSize.MEDIUM}
                                />
                                <Text size="sm">Back to login</Text>
                            </Group>
                        </NavLink>
                        <Button
                            type="submit"
                            variant="primary-gradient"
                            loading={isLoading}
                        >
                            Reset password
                        </Button>
                    </Group>
                </Flex>
            </form>
        </Box>
    );
};

export default memo(ForgotPasswordForm);
