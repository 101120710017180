import { Asset } from 'entities/Asset';
import { Icons } from 'shared/ui/Icon';
import { Epss } from 'entities/EPSS';
import { Kev } from 'entities/KEV';

export interface Meta {
    key: string;
    value: string;
}

export type Rating = 'low' | 'medium' | 'high' | 'critical' | 'info';

export interface Severity {
    score: number;
    rating: Rating;
    version: string | null;
}

export interface CVE {
    id: string;
    url: string;
}

export interface CWE {
    id: string;
    url: string;
}

export type StatusId = 'accepted' | 'fixed' | 'fp' | 'open';
export type StatusName = 'Accepted risk' | 'Fixed' | 'False positive' | 'Open';
export enum StatusIcon {
    accepted = Icons.VERIFIED,
    fixed = Icons.VERIFIED_USER,
    fp = Icons.RULE,
    open = Icons.POLICY
}
export interface Status {
    id: StatusId;
    name: StatusName;
}

export interface Issue {
    id: string;
    alertId: string;
    asset: Asset;
    name: string;
    description: string;
    solution: string | null;
    severity: Severity | null;
    type: 'cve' | 'dast';
    references: string[];
    evidence: string[];
    targets: string[];
    createdAt: string;
    lastSeenAt: string;
    meta: Meta[];
    jiraIssueUrl: string | null;
    status: Status;
    cve: CVE | null;
    cwes: CWE[] | null;
    epss: Epss | null;
    kev: Kev | null;
}

export type LastSeenPreset = '7' | '30' | '90' | 'all';

export interface IssueSearchParams {
    nameExact?: string;
    rating?: Rating;
    lastSeenPreset?: LastSeenPreset;
    lastSeenAtGte?: number;
    lastSeenAtLte?: number;
    createdAtGte?: number;
    createdAtLte?: number;
    epssScoreGte?: number;
    epssScoreLte?: number;
    epssPercentileGte?: number;
    epssPercentileLte?: number;
}

export interface IssueUpdateBody {
    id: string;
    status?: Status['id'];
}

export interface IssueUnique
    extends Pick<
        Issue,
        | 'alertId'
        | 'name'
        | 'severity'
        | 'type'
        | 'cve'
        | 'createdAt'
        | 'lastSeenAt'
    > {
    count: number;
}
