export enum AnalyticsEvent {
    SUCCESSFUL_PURCHASE = 'successful_purchase',
    SUCCESSFUL_EMAIL_CONFIRMATION = 'successful_email_confirmation',
    ROOT_ASSET_ADDED = 'root_asset_added',
    ROOT_ASSET_VERIFIED = 'root_asset_verified',
    ROOT_ASSET_DELETED = 'root_asset_deleted',
    BUSINESS_EMAIL_DETECTED = 'business_email_detected',
    COMPANY_ROOT_ASSET_AUTO_VERIFIED = 'company_root_asset_auto_verified',
    COMPANY_ROOT_ASSET_SCANNING_STARTED = 'company_root_asset_scanning_started'
}
