import { memo } from 'react';
import { Box, List, ListItem, Text, Title } from '@mantine/core';

const FeaturesList = () => (
    <Box>
        <Title order={3}>All plans include:</Title>
        <List>
            <ListItem>
                <Text size="lg">Leading coverage across asset types</Text>
            </ListItem>
            <ListItem>
                <Text size="lg">Attack surface overview and scoring</Text>
            </ListItem>
            <ListItem>
                <Text size="lg">Inventory of assets and issues</Text>
            </ListItem>
            <ListItem>
                <Text size="lg">Asset-to-asset mapping</Text>
            </ListItem>
            <ListItem>
                <Text size="lg">Technology discovery and classification</Text>
            </ListItem>
            <ListItem>
                <Text size="lg">Exportable vulnerability reports</Text>
            </ListItem>
            <ListItem>
                <Text size="lg">Cloud provider integrations</Text>
            </ListItem>
            <ListItem>
                <Text size="lg">Alerts via email, Slack, and Jira</Text>
            </ListItem>
        </List>
    </Box>
);

export default memo(FeaturesList);
