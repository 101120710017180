import { memo, useEffect } from 'react';
import { Page } from 'shared/ui/Page';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { useParams } from 'react-router-dom';
import { navigationActions, NavigationPageType } from 'entities/Navigation';
import IssuesPageHeader from '../IssuesPageHeader/IssuesPageHeader';

interface IssuesBaseLayoutProps {
    className?: string;
    children?: React.ReactNode;
}

const IssuesBaseLayout = (props: IssuesBaseLayoutProps) => {
    const { className, children } = props;
    const dispatch = useAppDispatch();
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (id) {
            dispatch(
                navigationActions.addToDrawerStack({
                    pageLink: window.location.href,
                    pageType: NavigationPageType.ISSUE_DETAILS,
                    pageID: id
                })
            );
        }
    }, [dispatch, id]);

    return (
        <Page className={className}>
            <IssuesPageHeader />
            {children}
        </Page>
    );
};

export default memo(IssuesBaseLayout);
