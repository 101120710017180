import React, { useEffect } from 'react';
import { Button, Group, Select, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import {
    JiraSettings,
    JiraSettingsUpdateBody,
    useDeleteJiraSettingsMutation,
    useGetJiraProjectsQuery,
    usePostTestIssueMutation,
    useUpdateJiraSettingsMutation
} from 'entities/Integrations';
import { showNotification } from '@mantine/notifications';

export interface ConnectToJiraProjectFormProps {
    jiraSettings?: JiraSettings;
    onConnected?: () => void;
}

const ConnectToJiraProjectForm = (props: ConnectToJiraProjectFormProps) => {
    const { jiraSettings, onConnected } = props;

    const { data: jiraProjects, isError: jiraProjectsError } =
        useGetJiraProjectsQuery();
    const [updateSettings, { isLoading: isUpdating }] =
        useUpdateJiraSettingsMutation();
    const [deleteSettings, { isLoading: isDeleting }] =
        useDeleteJiraSettingsMutation();
    const [postTestIssue, { isLoading: isPostingTestIssue }] =
        usePostTestIssueMutation();

    const form = useForm<JiraSettingsUpdateBody>({
        initialValues: {
            projectKey: jiraSettings?.projectKey || '',
            issueType: jiraSettings?.issueType || ''
        }
    });

    useEffect(() => {
        if (jiraProjectsError || jiraProjects?.length === 0) {
            form.setFieldError(
                'projectKey',
                'No projects found. Click "I want to connect to another JIRA project" to re-authenticate.'
            );
        }
    }, [jiraProjects, jiraProjectsError]);

    const onResetConnection = () => {
        updateSettings({
            ...form.values,
            projectKey: '',
            issueType: ''
        });
    };

    const onPostTestIssue = () => {
        postTestIssue()
            .unwrap()
            .then(postedIssue => {
                showNotification({
                    title: 'Test issue posted',
                    message: `Test issue (${postedIssue?.key}) has been posted to the selected JIRA project.`,
                    color: 'green'
                });
                onConnected?.();
            })
            .catch(() => {
                showNotification({
                    title: 'Test issue post failed',
                    message:
                        'Failed to post test issue to the selected JIRA project. Select correct project key or check if you have permissions to create issues in the selected project.',
                    color: 'red'
                });
                onResetConnection();
            });
    };

    const onSubmit = (values: JiraSettingsUpdateBody) => {
        updateSettings(values)
            .unwrap()
            .then(() => {
                showNotification({
                    title: 'Settings updated',
                    message: 'JIRA integration settings have been updated.',
                    color: 'green'
                });
                onPostTestIssue();
            })
            .catch(() => {
                showNotification({
                    title: 'Settings update failed',
                    message: 'Failed to update JIRA integration settings.',
                    color: 'red'
                });
            });
    };

    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <Stack gap="lg">
                <Select
                    required
                    searchable
                    withAsterisk
                    label="Project key"
                    data={jiraProjects?.map(project => project.key) || []}
                    error={form.errors.projectKey}
                    {...form.getInputProps('projectKey')}
                />
                {form.values.projectKey && (
                    <Select
                        required
                        searchable
                        withAsterisk
                        label="Issue type"
                        data={
                            jiraProjects?.find(
                                project =>
                                    project.key === form.values.projectKey
                            )?.issueTypes || []
                        }
                        {...form.getInputProps('issueType')}
                    />
                )}
                <Stack gap="sm" align="center" mt="md">
                    <Group>
                        <Button color="asm" type="submit" loading={isUpdating}>
                            Save and post test issue
                        </Button>
                    </Group>
                    <Button
                        w="fit-content"
                        variant="transparent"
                        loading={isDeleting}
                        onClick={() => deleteSettings()}
                    >
                        I want to connect to another JIRA project
                    </Button>
                </Stack>
            </Stack>
        </form>
    );
};

export default ConnectToJiraProjectForm;
