import React, { useEffect, useMemo } from 'react';
import { Alert, Button, Group, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import {
    JiraSettings,
    JiraSettingsCreateBody,
    useCreateJiraSettingsMutation
} from 'entities/Integrations';
import { Icon, Icons, IconSize } from 'shared/ui/Icon';

export interface AuthAppFormProps {
    jiraSettings?: JiraSettings;
    onCancelled?: () => void;
}

const JIRA_URL = 'https://mycompany.atlassian.net';

const AuthAppForm = (props: AuthAppFormProps) => {
    const { jiraSettings, onCancelled } = props;
    const [createSettings, { isLoading: isCreating, isError, isSuccess }] =
        useCreateJiraSettingsMutation();

    const form = useForm<JiraSettingsCreateBody>({
        initialValues: {
            url: '',
            username: '',
            apiToken: ''
        }
    });

    useEffect(() => {
        if (jiraSettings) {
            form.setFieldValue('url', jiraSettings.url);
            form.setFieldValue('username', jiraSettings.username);
            form.setFieldValue('apiToken', jiraSettings.apiToken);
        } else {
            form.setFieldValue('url', JIRA_URL);
            form.setFieldValue('username', '');
            form.setFieldValue('apiToken', '');
        }
    }, [jiraSettings]);

    const isAuthAvailable = useMemo(
        () =>
            !jiraSettings?.url &&
            !jiraSettings?.username &&
            !jiraSettings?.apiToken,
        [jiraSettings]
    );

    const onSubmit = (values: JiraSettingsCreateBody) => {
        createSettings(values);
    };

    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <Stack gap="lg">
                {jiraSettings && isSuccess && (
                    <Alert
                        variant="light"
                        color="blue"
                        title="Authenticated"
                        icon={
                            <Icon
                                icon={Icons.VERIFIED}
                                size={IconSize.MEDIUM}
                            />
                        }
                    >
                        Your JIRA integration is authenticated. You can now
                        connect it to a project by selecting a project key and
                        issue type.
                    </Alert>
                )}
                {isError && (
                    <Alert
                        variant="light"
                        color="red"
                        title="Not authenticated"
                        icon={
                            <Icon icon={Icons.WARNING} size={IconSize.MEDIUM} />
                        }
                    >
                        You need to authenticate your JIRA integration first.
                        Please provide your JIRA URL, username and API token.
                    </Alert>
                )}
                <TextInput
                    required
                    withAsterisk
                    label="URL"
                    type="url"
                    placeholder={JIRA_URL}
                    disabled={Boolean(jiraSettings?.url)}
                    {...form.getInputProps('url')}
                />
                <TextInput
                    required
                    withAsterisk
                    label="Username"
                    disabled={Boolean(jiraSettings?.username)}
                    {...form.getInputProps('username')}
                />
                <TextInput
                    required
                    withAsterisk
                    label="API token"
                    type="password"
                    disabled={Boolean(jiraSettings?.apiToken)}
                    {...form.getInputProps('apiToken')}
                />
                {isAuthAvailable && (
                    <Group justify="flex-end" mt="md">
                        <Button
                            color="asm"
                            variant="outline"
                            onClick={onCancelled}
                        >
                            Cancel
                        </Button>
                        <Button color="asm" type="submit" loading={isCreating}>
                            Authenticate
                        </Button>
                    </Group>
                )}
            </Stack>
        </form>
    );
};

export default AuthAppForm;
