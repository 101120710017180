import { StoreWithReducerManager } from 'app/providers/StoreProvider';
import { useEffect } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { StateSchemaKey } from 'app/providers/StoreProvider/config/StateSchema';
import { Reducer } from '@reduxjs/toolkit';

export type ReducersList = {
    [name in StateSchemaKey]?: Reducer;
};

export function useInjectReducer(
    key: StateSchemaKey,
    reducer: Reducer,
    removeOnUnmount: boolean = true
): void {
    const { reducerManager } = useStore() as StoreWithReducerManager;
    const dispatch = useDispatch();

    useEffect(() => {
        reducerManager.add(key, reducer);
        dispatch({ type: `@INIT REDUCER_INJECTED: ${key}` });

        return () => {
            if (removeOnUnmount) {
                reducerManager.remove(key);
                dispatch({ type: `@DESTROY REDUCER_REMOVED: ${key}` });
            }
        };
    }, [key, reducer, reducerManager, dispatch, removeOnUnmount]);
}
