import React, { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import { Button, Group, Modal, Text } from '@mantine/core';
import { RoutePath } from 'shared/config/routeConfig';
import { useCheckPaymentStatusMutation } from 'entities/Billing';
import { analyticsActions, AnalyticsEvent } from 'entities/Analytics';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';

const PaypalConfirmationModal = () => {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const dispatch = useAppDispatch();

    const [opened, { open, close }] = useDisclosure(false);
    const [isErrorMessage, setIsErrorMessage] = useState<string>();
    const [isSuccessMessage, setIsSuccessMessage] = useState<string>();
    const [checkPaymentStatus, { isLoading: isChecking }] =
        useCheckPaymentStatusMutation();

    useEffect(() => {
        const payload = params.get('payload') ?? '';
        const success = params.get('success') ?? '0';
        const token = params.get('token') ?? '';
        const ba_token = params.get('ba_token') ?? '';

        if (payload && token) {
            open();
            checkPaymentStatus({ payload, success, token, ba_token })
                .unwrap()
                .then(result => {
                    if ('error' in result) {
                        setIsErrorMessage(result.error);
                        return;
                    }

                    setIsSuccessMessage(
                        'Your payment has been processed. Usually PayPal validates the payment a few minutes. In some cases it may takes up to day. You can close this window and check your account later.'
                    );
                    dispatch(
                        analyticsActions.setEvent(
                            AnalyticsEvent.SUCCESSFUL_PURCHASE
                        )
                    );
                })
                .catch(() => {
                    setIsErrorMessage(
                        'An error occurred while processing your payment. Please try again later or contact support.'
                    );
                });
        }
    }, []);

    const onClose = useCallback(() => {
        navigate(`${RoutePath.account}/billing`, { replace: true });
        close();
    }, [close, navigate]);

    return (
        <Modal
            size="lg"
            title="Order confirmation: PayPal"
            opened={opened}
            onClose={onClose}
        >
            {isChecking && <Text>Checking payment status...</Text>}
            {isErrorMessage && <Text c="red">{isErrorMessage}</Text>}
            {isSuccessMessage && <Text c="green">{isSuccessMessage}</Text>}
            <Group justify="flex-end" mt="md">
                <Button color="asm" type="submit" onClick={onClose}>
                    OK
                </Button>
            </Group>
        </Modal>
    );
};

export default memo(PaypalConfirmationModal);
