import React, { memo } from 'react';
import { AssetType, RootAsset, useGetAsset } from 'entities/Asset';
import { Menu, Button } from '@mantine/core';
import { entityTagActions } from 'features/asset/actions/AddTag';
import { entityCommentActions } from 'features/asset/actions/AddComment';
import { updateAssetScannerSettingsActions } from 'features/asset/actions/UpdateAssetScannerSettings';
import { Icon, Icons } from 'shared/ui/Icon';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { removeRootAssetActions } from 'features/asset/actions/RemoveRootAsset';
import { VerifyAssetButton } from 'features/asset/actions/VerifyRootAsset';
import { setAssetFalsePositiveActions } from 'features/asset/actions/SetAssetFalsePositive';

interface AssetDetailsPageHeaderActionsProps {
    id: string;
}

const AssetDetailsPageHeaderActions = (
    props: AssetDetailsPageHeaderActionsProps
) => {
    const { id } = props;
    const { data: asset } = useGetAsset(id);
    const dispatch = useAppDispatch();

    return (
        asset && (
            <Menu shadow="md" width="fit-content" withArrow>
                <Menu.Target>
                    <Button
                        size="xs"
                        color="asm"
                        variant="outline"
                        rightSection={<Icon icon={Icons.MORE_VERT} />}
                    >
                        Actions
                    </Button>
                </Menu.Target>

                <Menu.Dropdown>
                    <Menu.Item
                        leftSection={<Icon icon={Icons.TAG} />}
                        onClick={() => {
                            dispatch(entityTagActions.setEntity(asset));
                        }}
                    >
                        Update tags
                    </Menu.Item>
                    <Menu.Item
                        leftSection={<Icon icon={Icons.COMMENT} />}
                        onClick={() => {
                            dispatch(entityCommentActions.setEntity(asset));
                        }}
                    >
                        Update comment
                    </Menu.Item>
                    {asset.type !== AssetType.PORT &&
                        !asset.isFalsePositive && (
                            <Menu.Item
                                leftSection={<Icon icon={Icons.WIFI_FIND} />}
                                onClick={() => {
                                    dispatch(
                                        updateAssetScannerSettingsActions.setAsset(
                                            asset
                                        )
                                    );
                                }}
                            >
                                Update scanner settings
                            </Menu.Item>
                        )}
                    {!asset.isRoot && !asset.isRootCandidate && (
                        <Menu.Item
                            leftSection={<Icon icon={Icons.SETTINGS} />}
                            onClick={() => {
                                dispatch(
                                    setAssetFalsePositiveActions.setAsset(asset)
                                );
                            }}
                        >
                            {asset.isFalsePositive ? 'Unset' : 'Set'} as false
                            positive
                        </Menu.Item>
                    )}
                    {asset.isRoot && (
                        <>
                            <VerifyAssetButton
                                asset={asset as unknown as RootAsset}
                                isMenuItemButton
                            />
                            <Menu.Label>Danger zone</Menu.Label>
                            <Menu.Item
                                color="red"
                                leftSection={<Icon icon={Icons.DELETE} />}
                                onClick={() =>
                                    dispatch(
                                        removeRootAssetActions.setAsset(
                                            asset as unknown as RootAsset
                                        )
                                    )
                                }
                            >
                                Remove root asset
                            </Menu.Item>
                        </>
                    )}
                </Menu.Dropdown>
            </Menu>
        )
    );
};

export default memo(AssetDetailsPageHeaderActions);
