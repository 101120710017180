import {
    Asset,
    Graph,
    RootAsset,
    useGetAssetDiscoveryPathForward
} from 'entities/Asset';

interface UseGetAssetDependencyGraph {
    graph: Graph | undefined;
    loading: boolean;
}

export function useGetAssetDependencyGraph(
    asset: Asset | RootAsset
): UseGetAssetDependencyGraph {
    const { data, isLoading } = useGetAssetDiscoveryPathForward({
        id: asset.id,
        params: {
            maxDepth: asset.isRoot ? undefined : 1,
            excludeCandidates: 1,
            excludeFalsePositives: 1
        }
    });

    return {
        graph: data,
        loading: isLoading
    };
}
