import React, { memo, Suspense } from 'react';
import { Button, Divider, Group, Stack, Text } from '@mantine/core';
import HeaderedCard from 'shared/ui/HeaderedCard/HeaderedCard';
import { useSelector } from 'react-redux';
import { ChangePasswordModal } from 'features/user/actions/AuthByUsername';
import { Icon, Icons, IconSize } from 'shared/ui/Icon';
import { getUserAuthData } from '../../model/selectors/getUser/getUser';

const AccountInformationCard = () => {
    const user = useSelector(getUserAuthData);

    return (
        <HeaderedCard
            header="Account information"
            headerIcon={<Icon icon={Icons.PERSON} size={IconSize.MEDIUM} />}
        >
            <Stack>
                <Group justify="space-between">
                    <Text>Account status</Text>
                    <Text c="green">Active</Text>
                </Group>
                <Divider />
                <Group justify="space-between">
                    <Text>Email</Text>
                    <Text>{user?.email}</Text>
                </Group>
                <Divider />
                <Group justify="flex-end">
                    <Suspense
                        fallback={<Button loading>Change password</Button>}
                    >
                        <ChangePasswordModal />
                    </Suspense>
                </Group>
            </Stack>
        </HeaderedCard>
    );
};

export default memo(AccountInformationCard);
