import { Asset } from 'entities/Asset';
import { Icons } from 'shared/ui/Icon';

export enum LogLevels {
    INFO = 'info',
    ERROR = 'error'
}

export interface Log {
    messageId: string;
    level: LogLevels;
    action: string;
    asset: Asset;
    userId: string;
    isSystem: boolean;
    timestamp: number;
    timestampMs: number;
    timestampParsed: string;
}
