export enum PlanSchema {
    TRIAL = 16,
    MONTHLY = 7,
    YEARLY = 9
}

export interface PriceRow {
    key: number;
    value: number;
}

export interface Plan {
    id: number;
    name: string;
    plan_schema: PlanSchema;
    can_subscribe: 0 | 1;
    is_trial: 0 | 1;
    price_list: PriceRow[];
}

export interface Product {
    id: number;
    name: string;
    plans: Plan[];
}
