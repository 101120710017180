import React, { memo, useMemo } from 'react';
import { Button, Group, Modal, Space, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useUpdateVulnerability } from 'entities/Vulnerability';
import { Issue, StatusId } from 'entities/Issue';
import { showNotification } from '@mantine/notifications';
import { Icon, Icons } from 'shared/ui/Icon';
import { FilterByStatusControl } from 'features/issue/filters/FilterByStatus';

export interface UpdateIssueStatusModalProps {
    issue: Issue;
}

const UpdateIssueStatusModal = (props: UpdateIssueStatusModalProps) => {
    const { issue } = props;
    const [opened, { open, close }] = useDisclosure(false);
    const [updateIssue, { isLoading }] = useUpdateVulnerability();
    const ctaButtonLabel = useMemo(
        () => (issue.status.id === 'open' ? 'Set status' : 'Update status'),
        [issue.status.id]
    );

    const onUpdate = (status: StatusId) => {
        updateIssue({
            id: issue.id,
            status
        })
            .unwrap()
            .then(() => {
                showNotification({
                    title: 'Updating issue status...',
                    message: 'Issue status has been updated successfully.',
                    color: 'green'
                });
            })
            .catch(() => {
                showNotification({
                    title: 'Updating issue status...',
                    message: 'Failed to update issue status.',
                    color: 'red'
                });
            })
            .finally(() => setTimeout(close, 1000));
    };

    return (
        <>
            <Modal
                size="xl"
                opened={opened}
                title="Update issue status"
                onClose={close}
            >
                <FilterByStatusControl
                    fullWidth
                    status={issue.status}
                    onChangeStatus={onUpdate}
                />
            </Modal>
            <Button
                size="xs"
                variant="outline"
                color="asm"
                leftSection={<Icon icon={Icons.VITAL_SIGNS} />}
                onClick={open}
            >
                {ctaButtonLabel}
            </Button>
        </>
    );
};

export default memo(UpdateIssueStatusModal);
