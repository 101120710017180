import { memo } from 'react';
import { Stack, StackProps } from '@mantine/core';
import { Loader } from 'shared/ui/Loader';
import styles from './Page.module.scss';

interface PageProps extends StackProps {
    hasData?: boolean;
    isLoading?: boolean;
    children?: React.ReactNode;
}

const Page = (props: PageProps) => {
    const { hasData, isLoading = false, children, ...rest } = props;

    return (
        <Stack h="100%" gap="lg" {...rest}>
            {isLoading && <Loader fullContainer />}
            {children}
        </Stack>
    );
};

export default memo(Page);
