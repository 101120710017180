import { SidebarItem as SidebarItemType } from 'widgets/Sidebar/model/types/sidebar-item';
import React, { memo } from 'react';
import { useLayout } from 'app/providers/LayoutProvider';
import classNames from 'classnames';
import { Icon, IconSize } from 'shared/ui/Icon';
import { NavLink } from 'react-router-dom';
import styles from './SidebarItem.module.scss';

interface SidebarItemProps {
    item: SidebarItemType;
}

const SidebarItem = (props: SidebarItemProps) => {
    const { layout } = useLayout();
    const { item } = props;
    const isAuth = true;
    const classes = classNames(
        styles['sidebar-item-text'],
        styles[`sidebar-item-text--${layout}`]
    );

    if (item.authOnly && !isAuth) {
        return null;
    }

    const navLinkClassName = ({ isActive }: { isActive: boolean }): string =>
        classNames(styles['sidebar-item'], {
            [styles['sidebar-item--active']]: isActive
        });

    return (
        <NavLink to={item.path} className={navLinkClassName}>
            {item.icon && <Icon icon={item.icon} size={IconSize.MEDIUM} />}
            <span className={classes}>{item.label}</span>
        </NavLink>
    );
};

export default memo(SidebarItem);
