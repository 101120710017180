import React, { memo, Suspense, useMemo, useState } from 'react';
import { DataTableColumn } from 'mantine-datatable';
import { GenericTable } from 'shared/ui/DataTable';
import {
    RootAsset,
    useGetRootAssets,
    AssetQueryParams,
    RootAssetLink,
    AssetVerificationBadge
} from 'entities/Asset';
import { Group } from '@mantine/core';
import tagsColumn from 'widgets/Columns/tagsColumn';
import assetTypeColumn from 'widgets/Columns/assetTypeColumn';
import descendantsCountColumn from 'widgets/Columns/descendantsCountColumn';
import directAndDerivedSubdomainsCountColumn from 'widgets/Columns/directAndDerivedSubdomainsCountColumn';
import rootAssetNameColumn from 'widgets/Columns/rootAssetNameColumn';
import { VerifyAssetButton } from 'features/asset/actions/VerifyRootAsset';
import createdAtColumn from 'widgets/Columns/createdAtColumn';
import lastSeenColumn from 'widgets/Columns/lastSeenColumn';
import { useDemo } from 'shared/hooks/useDemo/useDemo';
import rootAssetsActions from 'widgets/Columns/rootAssetsActions';
import vulnerabilityStatsColumn from 'widgets/Columns/vulnerabilityStatsColumn';
import assetScanModeColumn from 'widgets/Columns/assetScanModeColumn';

const RootAssetsTable = () => {
    const { isDemo } = useDemo();
    const [query, setQuery] = useState<AssetQueryParams>({});

    const columns = useMemo<DataTableColumn<RootAsset>[]>(
        () => [
            rootAssetNameColumn({
                query,
                setQuery,
                column: {
                    render: asset => (
                        <Group gap="xs" wrap="nowrap">
                            <RootAssetLink asset={asset}>
                                {asset.value}
                            </RootAssetLink>
                            <AssetVerificationBadge asset={asset} />
                            <Suspense fallback="">
                                <VerifyAssetButton asset={asset} />
                            </Suspense>
                        </Group>
                    )
                }
            }),
            assetTypeColumn({
                query,
                setQuery
            }),
            assetScanModeColumn({
                query,
                setQuery,
                column: {
                    hidden: isDemo
                }
            }),
            vulnerabilityStatsColumn({
                query,
                setQuery
            }),
            descendantsCountColumn({
                query,
                setQuery
            }),
            directAndDerivedSubdomainsCountColumn({
                query,
                setQuery
            }),
            tagsColumn({
                query,
                setQuery
            }),
            lastSeenColumn({
                query,
                setQuery,
                column: {
                    title: 'Last seen at'
                }
            }),
            createdAtColumn({
                query,
                setQuery,
                column: {
                    title: 'Created at'
                }
            }),
            rootAssetsActions({
                column: {
                    hidden: isDemo
                }
            })
        ],
        [query, isDemo]
    );

    return (
        <GenericTable<RootAsset>
            // @ts-ignore
            useData={useGetRootAssets}
            columns={columns}
            queryParams={query}
            defaultSortStatus={{
                columnAccessor: 'lastSeenAt',
                direction: 'desc'
            }}
            pinLastColumn
            hasToolbar
            hasDownload
        />
    );
};

export default memo(RootAssetsTable);
