import React, { memo } from 'react';
import { formatDate, formatDateToUTC } from 'shared/lib/date';
import { Tooltip } from '@mantine/core';
import { WhoisRecord } from '../../../model/types/metadata';

interface WhoisCreatedDateProps {
    whoisRecord?: WhoisRecord;
}

const WhoisCreatedDate = (props: WhoisCreatedDateProps) => {
    const { whoisRecord } = props;
    const createdDate =
        whoisRecord?.createdDateNormalized ||
        whoisRecord?.registryData?.createdDateNormalized;

    if (!createdDate) {
        return 'N/A';
    }

    return (
        <Tooltip label={formatDateToUTC(createdDate)} withArrow>
            <span>{formatDate(createdDate)}</span>
        </Tooltip>
    );
};

export default memo(WhoisCreatedDate);
