import { DataTableColumn } from 'mantine-datatable';
import React from 'react';
import { BaseColumnProps } from 'widgets/Columns/baseColumn';
import {
    Technology,
    TechnologiesQueryParams,
    TechnologyLink
} from 'entities/Technology';
import { NumberFormatter } from '@mantine/core';

export default ({
    query,
    setQuery,
    column
}: BaseColumnProps<
    Technology,
    TechnologiesQueryParams
>): DataTableColumn<Technology> => ({
    width: 130,
    accessor: 'assetsCount',
    title: '# of Assets',
    sortable: true,
    render: technology => {
        const { assetsCount } = technology;
        if (!assetsCount) return '-';

        return (
            <TechnologyLink technology={technology}>
                <NumberFormatter value={assetsCount} thousandSeparator />
            </TechnologyLink>
        );
    },
    ...column
});
