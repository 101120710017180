import React, { memo } from 'react';
import { Page } from 'shared/ui/Page';
import {
    AssetsGeolocationMap,
    AssetType,
    AssetTypeIcon,
    AssetTypeLabel
} from 'entities/Asset';
import { Box, Flex, Group, Paper, SimpleGrid, Stack } from '@mantine/core';
import { Icons } from 'shared/ui/Icon';
import {
    IssuesBasedOnSeverityCard,
    RecentIssuesCard,
    TotalIssueStatisticsCard
} from 'widgets/Issue';
import { AssetsFeedCard, StatisticsByAssetTypeCard } from 'widgets/Asset';
import { NewTechnologiesCard } from 'widgets/Technology';
import { Seo } from 'shared/ui/SEO/SEO';
import { RoutePath } from 'shared/config/routeConfig';
import { FindingsLastWeekCard } from 'widgets/Statistics';

function DashboardPage() {
    return (
        <Page h="unset">
            <Seo title="Dashboard" />
            <Flex gap="md">
                <FindingsLastWeekCard />
                <Stack gap="md" w="100%" h="100%">
                    <Group grow>
                        <StatisticsByAssetTypeCard
                            title="Total assets"
                            icon={Icons.LAYERS}
                            link={RoutePath.assets}
                        />
                        <Box miw={500}>
                            <TotalIssueStatisticsCard
                                title="Total vulnerabilities"
                                icon={Icons.WARNING}
                                link={`${RoutePath.issues}/all?lastSeenPreset=all`}
                            />
                        </Box>
                    </Group>
                    <SimpleGrid cols={5}>
                        <StatisticsByAssetTypeCard
                            title="Domains"
                            icon={AssetTypeIcon.DOMAIN}
                            assetType={AssetType.DOMAIN}
                            link={`${RoutePath.assets}/domain`}
                        />
                        <StatisticsByAssetTypeCard
                            title="IPv4s"
                            icon={AssetTypeIcon.IP}
                            assetType={AssetType.IPV4}
                            link={`${RoutePath.assets}/ipv4`}
                        />
                        <StatisticsByAssetTypeCard
                            title="IPv6s"
                            icon={AssetTypeIcon.IP}
                            assetType={AssetType.IPV6}
                            link={`${RoutePath.assets}/ipv6`}
                        />
                        <StatisticsByAssetTypeCard
                            title="SSLs"
                            icon={AssetTypeIcon.SSL}
                            assetType={AssetType.SSL}
                            link={`${RoutePath.assets}/ssl`}
                        />
                        <StatisticsByAssetTypeCard
                            title="Ports"
                            icon={AssetTypeIcon.PORT}
                            assetType={AssetType.PORT}
                            link={`${RoutePath.assets}/port`}
                        />
                    </SimpleGrid>
                    <SimpleGrid cols={4}>
                        <StatisticsByAssetTypeCard
                            title={`${AssetTypeLabel.CIDR}s`}
                            icon={AssetTypeIcon.CIDR}
                            assetType={AssetType.CIDR}
                            link={`${RoutePath.assets}/cidr`}
                        />
                        <StatisticsByAssetTypeCard
                            title={`${AssetTypeLabel.CLOUD}s`}
                            icon={AssetTypeIcon.CLOUD}
                            assetType={AssetType.CLOUD}
                            link={`${RoutePath.assets}/cloud`}
                        />
                        <StatisticsByAssetTypeCard
                            title={`${AssetTypeLabel.ORGANIZATION}s`}
                            icon={AssetTypeIcon.ORGANIZATION}
                            assetType={AssetType.ORGANIZATION}
                            link={`${RoutePath.assets}/org`}
                        />
                        <StatisticsByAssetTypeCard
                            title="Emails"
                            icon={AssetTypeIcon.EMAIL}
                            assetType={AssetType.EMAIL}
                            link={`${RoutePath.assets}/email`}
                        />
                    </SimpleGrid>
                    <Group grow align="stretch">
                        <IssuesBasedOnSeverityCard />
                        <RecentIssuesCard />
                    </Group>
                </Stack>
            </Flex>
            <Group grow align="stretch">
                <AssetsFeedCard />
                <NewTechnologiesCard />
            </Group>
            <Paper radius="md" shadow="md" mih={500} h={500}>
                <AssetsGeolocationMap />
            </Paper>
        </Page>
    );
}

export default memo(DashboardPage);
