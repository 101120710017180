import React, { memo, useEffect, useMemo } from 'react';
import {
    Asset,
    AssetSslCertificate,
    AssetType,
    RootAsset,
    RootAssetCandidate,
    useGetAssets,
    useGetRelatedAssets,
    AssetWhoisRecord,
    AssetSubnetworks,
    AssetDiscoveryGraph,
    AssetDependencyGraph,
    AssetSslConnection,
    AssetScreenshot
} from 'entities/Asset';
import { PageChildrenLink, PageHeader } from 'widgets/PageHeader';
import { useGetVulnerabilities } from 'entities/Vulnerability';
import { LogsTable } from 'widgets/Log';
import { useGetTechnologies } from 'entities/Technology';
import { TechnologiesTable } from 'widgets/Technology';
import { AssetsTable, DnsTable, RelatedAssetsTable } from 'widgets/Asset';
import { VulnerabilitiesTable } from 'widgets/Issue';
import AssetOverview from 'widgets/Asset/AssetTabs/Tabs/AssetOverview/AssetOverview';
import useTabs from 'pages/Assets/children/AssetDetails/lib/useTabs';
import useIssueFilters from 'pages/Assets/children/AssetDetails/lib/useIssueFilters';
import styles from './AssetTabs.module.scss';

interface AssetTabsProps {
    defaultTabIndex?: number;
    asset: Asset | RootAsset | RootAssetCandidate;
}

const AssetTabs = (props: AssetTabsProps) => {
    const { defaultTabIndex = 0, asset } = props;
    const { data: relatedAssets, isLoading: relatedAssetsLoading } =
        useGetAssets({
            rootAssetID: [asset.id],
            size: 1
        });
    const { data: relatedIssues, isLoading: relatedIssuesLoading } =
        useGetVulnerabilities(
            asset.isRoot
                ? { rootAssetID: [asset.id], size: 1 }
                : {
                      assetID: [asset.id],
                      size: 1
                  }
        );
    const { data: relatedTechnologies, isLoading: relatedTechnologiesLoading } =
        useGetTechnologies({
            assetID: [asset.id],
            size: 1
        });
    const { data: relatedSubdomains, isLoading: relatedSubdomainsLoading } =
        useGetRelatedAssets({
            assetID: asset.id,
            relationType: 'subdomain',
            size: 1
        });
    const { data: relatedPorts, isLoading: relatedPortsLoading } =
        useGetRelatedAssets({
            assetID: asset.id,
            relationType: 'port',
            size: 1
        });

    const relatedAssetsCount = useMemo(
        () => relatedAssets?.pagination?.totalItems,
        [relatedAssets?.pagination?.totalItems]
    );
    const relatedIssuesCount = useMemo(
        () => relatedIssues?.pagination?.totalItems,
        [relatedIssues?.pagination?.totalItems]
    );
    const relatedTechnologiesCount = useMemo(
        () => relatedTechnologies?.pagination?.totalItems,
        [relatedTechnologies?.pagination?.totalItems]
    );
    const relatedSubdomainsCount = useMemo(
        () => relatedSubdomains?.pagination?.totalItems,
        [relatedSubdomains?.pagination?.totalItems]
    );
    const relatedPortsCount = useMemo(
        () => relatedPorts?.pagination?.totalItems,
        [relatedPorts?.pagination?.totalItems]
    );
    const relatedDNSCount = useMemo(
        () => asset.metadata?.dns?.length,
        [asset.metadata?.dns]
    );

    const tabs = useMemo(() => {
        const tabs = [];

        if (asset.type !== AssetType.ORGANIZATION) {
            tabs.push({
                title: 'Overview',
                hasPreviewNumber: false
            });
        }

        if (!asset.isRootCandidate && !asset.isFalsePositive) {
            tabs.push({
                title: 'Issues',
                previewNumber: relatedIssuesCount,
                previewNumberLoading: relatedIssuesLoading
            });
        }

        if (asset.isRoot) {
            tabs.push({
                title: 'Assets',
                previewNumber: relatedAssetsCount,
                previewNumberLoading: relatedAssetsLoading
            });
            tabs.push({
                title: 'Subdomains',
                previewNumber: relatedSubdomainsCount,
                previewNumberLoading: relatedSubdomainsLoading
            });
        }

        if ([AssetType.IPV4, AssetType.IPV6].includes(asset.type)) {
            tabs.push({
                title: 'Ports',
                previewNumber: relatedPortsCount,
                previewNumberLoading: relatedPortsLoading
            });
        }

        if (asset.metadata?.dns) {
            tabs.push({
                title: 'DNS',
                previewNumber: relatedDNSCount
            });
        }

        if (!asset.isRootCandidate) {
            tabs.push({
                title: 'Technologies',
                previewNumber: relatedTechnologiesCount,
                previewNumberLoading: relatedTechnologiesLoading
            });
        }

        if (!asset.isRoot) {
            tabs.push({
                title: 'Discovery Graph',
                hasPreviewNumber: false
            });
        }

        if (!asset.isRootCandidate && !asset.isFalsePositive) {
            tabs.push({
                title: 'Dependency Graph',
                hasPreviewNumber: false
            });
        }

        if (asset.type !== AssetType.SSL) {
            if (asset.metadata?.sslCertificate) {
                tabs.push({
                    title: 'SSL Certificate',
                    hasPreviewNumber: false
                });
            }
        }

        if (asset.metadata?.sslConnection) {
            tabs.push({
                title: 'SSL Connection',
                hasPreviewNumber: false
            });
        }

        if (asset.metadata?.subnetwork) {
            tabs.push({
                title: 'Subnetworks',
                hasPreviewNumber: false
            });
        }

        if (asset.metadata?.screenshot) {
            tabs.push({
                title: 'Screenshot',
                hasPreviewNumber: false
            });
        }

        if (asset.metadata?.whoisRecord) {
            tabs.push({
                title: 'WHOIS',
                hasPreviewNumber: false
            });
        }

        tabs.push({
            title: 'Events',
            hasPreviewNumber: false
        });

        /*
        tabs.push({
            title: 'Raw',
            hasPreviewNumber: false
        }); */

        return tabs;
    }, [
        asset.isRoot,
        asset.isRootCandidate,
        asset.metadata?.dns,
        asset.metadata?.screenshot,
        asset.metadata?.sslCertificate,
        asset.metadata?.sslConnection,
        asset.metadata?.subnetwork,
        asset.metadata?.whoisRecord,
        asset.type,
        relatedAssetsCount,
        relatedAssetsLoading,
        relatedDNSCount,
        relatedIssuesCount,
        relatedIssuesLoading,
        relatedPortsCount,
        relatedPortsLoading,
        relatedSubdomainsCount,
        relatedSubdomainsLoading,
        relatedTechnologiesCount,
        relatedTechnologiesLoading
    ]);
    const { activeTab, setActiveTab } = useTabs(tabs[defaultTabIndex].title);
    const { selectedIssueRating, setSelectedIssueRating } = useIssueFilters();

    useEffect(() => {
        if (activeTab !== 'Issues' && setSelectedIssueRating) {
            setSelectedIssueRating();
        }
    }, [activeTab, setSelectedIssueRating]);

    return (
        <>
            <PageHeader
                links={
                    <>
                        {tabs.map(tab => (
                            <PageChildrenLink
                                key={tab.title}
                                title={tab.title}
                                previewNumber={tab.previewNumber}
                                previewNumberLoading={tab.previewNumberLoading}
                                hasPreviewNumber={tab.hasPreviewNumber}
                                active={tab.title === activeTab}
                                onClick={() => setActiveTab(tab.title)}
                            />
                        ))}
                    </>
                }
            />
            {activeTab === 'Overview' && <AssetOverview asset={asset} />}
            {activeTab === 'Issues' && asset.isRoot && (
                <VulnerabilitiesTable
                    defaultRootAssetID={asset.id}
                    defaultRating={selectedIssueRating}
                />
            )}
            {activeTab === 'Issues' && !asset.isRoot && (
                <VulnerabilitiesTable
                    defaultAssetID={asset.id}
                    defaultRating={selectedIssueRating}
                    includeFalsePositiveAssets={asset.isFalsePositive}
                />
            )}
            {activeTab === 'Assets' && (
                <AssetsTable defaultRootAssetID={asset.id} />
            )}
            {activeTab === 'Subdomains' && (
                <RelatedAssetsTable
                    assetID={asset.id}
                    relationType="subdomain"
                />
            )}
            {activeTab === 'Ports' && (
                <RelatedAssetsTable assetID={asset.id} relationType="port" />
            )}
            {activeTab === 'DNS' && asset.metadata?.dns && (
                <DnsTable dns={asset.metadata?.dns} />
            )}
            {activeTab === 'Dependency Graph' && (
                <div className={styles.AssetDependencyGraph}>
                    <AssetDependencyGraph asset={asset} />
                </div>
            )}
            {activeTab === 'Discovery Graph' && (
                <div className={styles.DiscoveryGraph}>
                    <AssetDiscoveryGraph asset={asset as Asset} />
                </div>
            )}
            {activeTab === 'Technologies' && (
                <TechnologiesTable
                    defaultAssetID={asset.id}
                    includeFalsePositiveAssets={asset.isFalsePositive}
                />
            )}
            {activeTab === 'Events' && <LogsTable defaultAssetID={asset.id} />}
            {activeTab === 'Screenshot' && (
                <AssetScreenshot asset={asset as Asset} />
            )}
            {activeTab === 'WHOIS' && (
                <AssetWhoisRecord asset={asset as Asset} />
            )}
            {activeTab === 'Subnetworks' && (
                <AssetSubnetworks asset={asset as Asset} />
            )}
            {activeTab === 'SSL Certificate' && (
                <AssetSslCertificate asset={asset as Asset} />
            )}
            {activeTab === 'SSL Connection' && (
                <AssetSslConnection asset={asset as Asset} />
            )}
            {activeTab === 'Raw' && (
                <pre style={{ overflow: 'auto', maxHeight: '76vh' }}>
                    <code>{JSON.stringify(asset, null, 4)}</code>
                </pre>
            )}
        </>
    );
};

export default memo(AssetTabs);
