import React, { memo, useMemo, useState } from 'react';
import { AssetType } from 'entities/Asset';
import { DataTableColumn } from 'mantine-datatable';
import { GenericTable } from 'shared/ui/DataTable';
import {
    useGetVulnerabilities,
    VulnerabilityQueryParams,
    Vulnerability
} from 'entities/Vulnerability';
import assetValueColumn from 'widgets/Columns/assetValueColumn';
import assetTypeColumn from 'widgets/Columns/assetTypeColumn';
import lastSeenColumn from 'widgets/Columns/lastSeenColumn';
import createdAtColumn from 'widgets/Columns/createdAtColumn';
import riskColumn from 'widgets/Columns/riskColumn';
import rootAssetSelectColumn from 'widgets/Columns/rootAssetSelectColumn';
import vulnerabilityNameColumn from 'widgets/Columns/vulnerabilityNameColumn';
import assetTagsColumn from 'widgets/Columns/assetTagsColumn';
import { SegmentedControl } from '@mantine/core';
import dayjs from 'dayjs';
import { Rating, LastSeenPreset, StatusId } from 'entities/Issue';
import issueStatusColumn from 'widgets/Columns/issueStatusColumn';
import kevCheckColumn from 'widgets/Columns/kevCheckColumn';

interface VulnerabilitiesTableProps {
    defaultAssetID?: string;
    defaultRootAssetID?: string;
    defaultTechnologyID?: string;
    defaultVulnerabilityName?: string;
    defaultVulnerabilityNameIncludes?: string;
    defaultAssetType?: AssetType;
    defaultRating?: Rating;
    defaultStatus?: StatusId;
    defaultLastSeenPreset?: LastSeenPreset;
    defaultCreatedAtGte?: number;
    defaultCreatedAtLte?: number;
    includeFalsePositiveAssets?: boolean;
    title?: string;
}

const VulnerabilitiesTable = (props: VulnerabilitiesTableProps) => {
    const {
        defaultAssetID,
        defaultRootAssetID,
        defaultTechnologyID,
        defaultVulnerabilityName,
        defaultVulnerabilityNameIncludes,
        defaultAssetType,
        defaultRating,
        defaultStatus,
        defaultLastSeenPreset = '7',
        defaultCreatedAtGte,
        defaultCreatedAtLte,
        includeFalsePositiveAssets = false,
        title
    } = props;

    const defaultQuery = useMemo<VulnerabilityQueryParams>(
        () => ({
            assetID: defaultAssetID ? [defaultAssetID] : undefined,
            assetType: defaultAssetType ? [defaultAssetType] : undefined,
            rootAssetID: defaultRootAssetID ? [defaultRootAssetID] : undefined,
            techID: defaultTechnologyID ? [defaultTechnologyID] : undefined,
            nameExact: defaultVulnerabilityName,
            nameIncludes: defaultVulnerabilityNameIncludes,
            lastSeenAtGte:
                defaultLastSeenPreset === 'all'
                    ? undefined
                    : dayjs()
                          .subtract(Number(defaultLastSeenPreset), 'days')
                          .unix(),
            rating: defaultRating ? [defaultRating] : undefined,
            status: defaultStatus ? [defaultStatus] : undefined,
            createdAtGte: defaultCreatedAtGte,
            createdAtLte: defaultCreatedAtLte,
            isAssetFalsePositive: includeFalsePositiveAssets
        }),
        [
            defaultAssetID,
            defaultAssetType,
            defaultRootAssetID,
            defaultTechnologyID,
            defaultVulnerabilityName,
            defaultVulnerabilityNameIncludes,
            defaultLastSeenPreset,
            defaultRating,
            defaultStatus,
            defaultCreatedAtGte,
            defaultCreatedAtLte,
            includeFalsePositiveAssets
        ]
    );

    const [query, setQuery] = useState<VulnerabilityQueryParams>(defaultQuery);

    const columns = useMemo<DataTableColumn<Vulnerability>[]>(
        () => [
            vulnerabilityNameColumn({
                query,
                setQuery,
                column: {
                    hidden: Boolean(defaultVulnerabilityName)
                }
            }),
            riskColumn({
                query,
                setQuery,
                column: {
                    hidden: Boolean(defaultVulnerabilityName)
                }
            }),
            kevCheckColumn({
                query,
                setQuery,
                column: {
                    hidden: Boolean(defaultVulnerabilityName)
                }
            }),
            issueStatusColumn({
                query,
                setQuery,
                column: {
                    hidden:
                        Boolean(defaultVulnerabilityName) ||
                        Boolean(defaultStatus)
                }
            }),
            assetValueColumn({
                query,
                setQuery
            }),
            assetTypeColumn({
                query,
                setQuery,
                column: {
                    accessor: 'asset.type',
                    title: 'Asset Type'
                }
            }),
            assetTagsColumn({
                query,
                setQuery,
                column: {
                    accessor: 'asset.tags',
                    title: 'Asset Tags'
                }
            }),
            rootAssetSelectColumn({
                query,
                setQuery
            }),
            lastSeenColumn({
                query,
                setQuery
            }),
            createdAtColumn({
                query,
                setQuery
            })
        ],
        [defaultStatus, defaultVulnerabilityName, query]
    );

    return (
        <GenericTable<Vulnerability>
            title={title}
            // @ts-ignore
            useData={useGetVulnerabilities}
            columns={columns}
            queryParams={query}
            defaultSortStatus={{
                columnAccessor: 'lastSeenAt',
                direction: 'desc'
            }}
            hasToolbar
            hasDownload
            toolbarActions={
                <SegmentedControl
                    transitionDuration={0}
                    size="md"
                    color="asm"
                    data={[
                        { value: '7', label: 'Last 7 days' },
                        { value: '30', label: 'Last 30 days' },
                        { value: '90', label: 'Last 90 days' },
                        { value: 'all', label: 'All time' }
                    ]}
                    defaultValue={defaultLastSeenPreset}
                    onChange={value => {
                        if (value === 'all') {
                            setQuery({
                                ...defaultQuery,
                                lastSeenAtGte: undefined
                            });
                        } else {
                            setQuery({
                                ...defaultQuery,
                                lastSeenAtGte: dayjs()
                                    .subtract(Number(value), 'days')
                                    .unix()
                            });
                        }
                    }}
                />
            }
        />
    );
};

export default memo(VulnerabilitiesTable);
