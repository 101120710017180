import React, { memo, useMemo, useState } from 'react';
import { DataTableColumn } from 'mantine-datatable';
import { GenericTable } from 'shared/ui/DataTable';
import {
    Asset,
    AssetQueryParams,
    useGetArchivedAssets,
    ArchivedAssetLink
} from 'entities/Asset';
import assetNameColumn from 'widgets/Columns/assetNameColumn';
import tagsColumn from 'widgets/Columns/tagsColumn';
import lastSeenColumn from 'widgets/Columns/lastSeenColumn';
import createdAtColumn from 'widgets/Columns/createdAtColumn';
import assetTypeColumn from 'widgets/Columns/assetTypeColumn';
import rootAssetSelectColumn from 'widgets/Columns/rootAssetSelectColumn';

interface ArchivedAssetsTableProps {
    title?: string;
}

const ArchivedAssetsTable = (props: ArchivedAssetsTableProps) => {
    const { title } = props;

    const [query, setQuery] = useState<AssetQueryParams>({});
    const columns = useMemo<DataTableColumn<Asset>[]>(
        () => [
            assetNameColumn({
                query,
                setQuery,
                column: {
                    render: asset => (
                        <ArchivedAssetLink asset={asset}>
                            {asset.value}
                        </ArchivedAssetLink>
                    )
                }
            }),
            assetTypeColumn({
                query,
                setQuery
            }),
            rootAssetSelectColumn({
                query,
                setQuery
            }),
            tagsColumn({
                query,
                setQuery
            }),
            lastSeenColumn({
                query,
                setQuery
            }),
            createdAtColumn({
                query,
                setQuery
            })
        ],
        [query]
    );

    return (
        <GenericTable<Asset>
            // @ts-ignore
            useData={useGetArchivedAssets}
            columns={columns}
            queryParams={query}
            defaultSortStatus={{
                columnAccessor: 'lastSeenAt',
                direction: 'desc'
            }}
            hasToolbar
            hasDownload
            title={title}
        />
    );
};

export default memo(ArchivedAssetsTable);
