import { memo } from 'react';
import { Page } from 'shared/ui/Page';
import EventsPageHeader from '../EventsPageHeader/EventsPageHeader';

interface EventsBaseLayoutProps {
    children?: React.ReactNode;
}

const EventsBaseLayout = (props: EventsBaseLayoutProps) => {
    const { children } = props;

    return (
        <Page>
            <EventsPageHeader />
            {children}
        </Page>
    );
};

export default memo(EventsBaseLayout);
