import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const DEFAULT_DATE_FORMAT = 'MMMM D, YYYY';
export const UTC_DATE_FORMAT = 'dddd, MMMM D, YYYY [at] HH:mm [UTC]';

export function isDateValid(date: Date) {
    return !Number.isNaN(date.getTime());
}

export function getTimestampInSeconds(timestamp: number) {
    return Math.floor(timestamp / 1000);
}

export function getNowDate() {
    return new Date();
}

export function getYesterdaysDate() {
    const date = getNowDate();
    date.setDate(date.getDate() - 1);

    return date;
}

export function getNowUTCDate() {
    const date = getNowDate();
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
}

export function getYesterdaysUTCDate() {
    const date = getYesterdaysDate();
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
}

export function dateToUTC(date: Date) {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
}

export function getNowUTCTimestamp() {
    return getNowUTCDate().getTime();
}

export function getYesterdaysUTCTimestamp() {
    return getYesterdaysUTCDate().getTime();
}

export function getNowTimestampInSeconds() {
    return getTimestampInSeconds(getNowUTCTimestamp());
}

export function getYesterdaysTimestampInSeconds() {
    return getTimestampInSeconds(getYesterdaysUTCTimestamp());
}

export function getDateAgo(days: number, date: Date = getNowDate()) {
    date.setDate(date.getDate() - days);

    return date;
}

export function startOfDay(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function endOfDay(date: Date) {
    return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        23,
        59,
        59
    );
}

export function getDifferenceBetweenDatesInDays(
    date1: string | Date,
    date2: string | Date = getNowDate()
) {
    if (typeof date1 === 'string') {
        date1 = new Date(date1);
    }

    if (typeof date2 === 'string') {
        date2 = new Date(date2);
    }

    const diffTime = date2.getTime() - date1.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
}

export function formatDate(
    date: Date | string | number,
    format = DEFAULT_DATE_FORMAT
) {
    return dayjs(date).format(format);
}

export function formatDateToUTC(
    date: Date | string | number,
    format: string = UTC_DATE_FORMAT,
    keepLocalTime = false
) {
    return dayjs(date).utc(keepLocalTime).format(format);
}
