import React, { memo } from 'react';
import { AssetsTable } from 'widgets/Asset';
import { numberFormat } from 'shared/lib/formatter/formatter';
import { Technology } from '../../model/types/technology';

interface TechnologyRelatedAssetsProps {
    technology: Technology;
}

const TechnologyRelatedAssets = (props: TechnologyRelatedAssetsProps) => {
    const { technology } = props;

    return (
        <AssetsTable
            title={`Detected on the following ${numberFormat(
                technology.assetsCount || 0
            )} assets`}
            defaultTechnologyID={technology.id}
            hasActiveEntitiesFilter={false}
        />
    );
};

export default memo(TechnologyRelatedAssets);
