import { DataTableColumn } from 'mantine-datatable';
import React from 'react';
import { BaseColumnProps } from 'widgets/Columns/baseColumn';
import { TechnologiesQueryParams, Technology } from 'entities/Technology';
import { IssuesCountBadge } from 'entities/Issue';

type RecordType = Technology;
type QueryParams = TechnologiesQueryParams;

export default ({
    column
}: BaseColumnProps<RecordType, QueryParams>): DataTableColumn<RecordType> => ({
    width: 200,
    accessor: 'vulnerabilitiesCount',
    title: 'Issues',
    sortable: true,
    render: ({ vulnerabilitiesCount }) => (
        <IssuesCountBadge
            vulnerabilitiesCount={vulnerabilitiesCount}
            fullWidth
        />
    ),
    ...column
});
