import { memo, useEffect } from 'react';
import { Page } from 'shared/ui/Page';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { useParams } from 'react-router-dom';
import { navigationActions, NavigationPageType } from 'entities/Navigation';
import { Box } from '@mantine/core';
import AssetsPageHeader from '../AssetsPageHeader/AssetsPageHeader';

interface AssetsBaseLayoutProps {
    children?: React.ReactNode;
}

const AssetsBaseLayout = (props: AssetsBaseLayoutProps) => {
    const { children } = props;
    const dispatch = useAppDispatch();
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (id) {
            dispatch(
                navigationActions.addToDrawerStack({
                    pageLink: window.location.href,
                    pageType: NavigationPageType.ASSET_DETAILS,
                    pageID: id
                })
            );
        }
    }, [dispatch, id]);

    return (
        <Page>
            <AssetsPageHeader />
            <Box style={{ maxHeight: 'calc(100% - 120px)' }}>{children}</Box>
        </Page>
    );
};

export default memo(AssetsBaseLayout);
