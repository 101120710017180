import React, { memo } from 'react';
import classNames from 'classnames';
import {
    Asset,
    MetadataItemFirstSeen,
    MetadataItemLastSeen,
    RootAsset,
    RootAssetCandidate,
    RootAssetLink
} from 'entities/Asset';
import { SeverityDistributionByAssetID } from 'widgets/Issue';
import { MetadataBar, MetadataItem } from 'shared/ui/Metadata';
import styles from './AssetDetailsCard.module.scss';
import AssetTagBar from '../AssetTagBar/AssetTagBar';
import AssetMetadataBar from '../AssetMetadataBar/AssetMetadataBar';
import AssetHeader from '../AssetHeader/AssetHeader';

interface AssetDetailsCardProps {
    className?: string;
    asset: Asset | RootAsset | RootAssetCandidate;
}

const AssetDetailsCard = (props: AssetDetailsCardProps) => {
    const { className, asset } = props;
    const classes = classNames(styles.AssetDetailsCard, className);

    return (
        <div className={classes}>
            <AssetTagBar asset={asset} />
            <AssetHeader asset={asset} />
            <AssetMetadataBar asset={asset} />
        </div>
    );
};

export default memo(AssetDetailsCard);
