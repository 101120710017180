import { memo } from 'react';
import { Container, Flex, Paper, Text, TextInput } from '@mantine/core';
import { AssetCreateBody, AssetType, useAddAsset } from 'entities/Asset';
import { useForm } from '@mantine/form';
import { addNewRootAssetActions } from 'features/asset/actions/AddNewRootAsset';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { isIPv4, isIPv6 } from 'is-ip';
import { contactUsToVerifyAssetMessage } from '../../model/service/contactUsToVerifyAssetMessage';
import ConfirmFormFooter from '../common/ConfirmFormFooter/ConfirmFormFooter';

const IpAddressRootAsset = () => {
    const dispatch = useAppDispatch();
    const [addAsset, { isLoading }] = useAddAsset();
    const form = useForm<AssetCreateBody>({
        initialValues: {
            value: '',
            type: AssetType.IPV4
        },
        validate: {
            value: value => {
                if (!isIPv4(value) && !isIPv6(value)) {
                    return 'Value must be a valid IPv4 or IPv6 address.';
                }

                return null;
            }
        }
    });

    const onSubmit = (values: AssetCreateBody) => {
        if (isIPv6(values.value)) {
            values.type = AssetType.IPV6;
        }

        if (isIPv4(values.value)) {
            values.type = AssetType.IPV4;
        }

        return addAsset(values)
            .unwrap()
            .then(() => {
                dispatch(
                    addNewRootAssetActions.setSuccess(
                        contactUsToVerifyAssetMessage(
                            `${values.value} IP address`
                        )
                    )
                );
            })
            .catch(err => {
                dispatch(addNewRootAssetActions.setError(err));
            });
    };

    return (
        <Container size="xs">
            <Text c="dimmed" ta="center" size="md">
                Enter a IPv4 or IPv6 address you want to keep secure. Manual
                root asset verification is required.
            </Text>
            <Paper withBorder shadow="md" p="xl" radius="md" mt="xl">
                <form onSubmit={form.onSubmit(onSubmit)}>
                    <Flex gap="lg" direction="column">
                        <TextInput
                            label="IPv4/IPv6 address"
                            placeholder="8.8.8.8 or 2001:4860:4860::8844"
                            autoFocus
                            {...form.getInputProps('value')}
                        />
                        <ConfirmFormFooter loading={isLoading} />
                    </Flex>
                </form>
            </Paper>
        </Container>
    );
};

export default memo(IpAddressRootAsset);
