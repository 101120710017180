import React, { memo } from 'react';
import { Card, Stack, Text } from '@mantine/core';
import { SelectPlanButton } from 'features/billing/actions/SelectPlan';
import { useSelector } from 'react-redux';
import { getUserAuthData } from 'entities/User';
import { ContactUsModal } from 'features/mail/ContactUs';
import FeaturesList from './FeaturesList';
import { Plan } from '../../model/types/pricing';

interface CartCardProps {
    plan?: Plan;
    assets?: number;
    price?: number;
    name?: string;
    description?: string;
}

const CartCard = (props: CartCardProps) => {
    const { plan, assets, price, name, description } = props;
    const user = useSelector(getUserAuthData);

    return (
        <Card withBorder radius="md" shadow="sm" w={600} bg="gray.3">
            <Stack>
                <FeaturesList />
                <SelectPlanButton
                    plan={plan}
                    email={user?.email}
                    name={plan?.name}
                    description={`${name} plan. ${description}.`}
                    amount={price}
                    entitiesAmount={assets}
                    disabled={!price}
                />
                <Text ta="center" c="dimmed">
                    All pricing is in USD and renews automatically unless
                    cancelled. If you need scan more than 360 assets,{' '}
                    <ContactUsModal ctaText="get in touch" inlined /> for
                    pricing.
                </Text>
            </Stack>
        </Card>
    );
};

export default memo(CartCard);
