import React, { memo, useMemo } from 'react';
import { SelectProps, Select } from '@mantine/core';
import { Icon, Icons } from 'shared/ui/Icon';
import { useGetVulnerableAssetNames } from 'entities/Dictionary';

export interface FilterByAssetFormProps extends SelectProps {
    selectedAssets?: string[];
}

const FilterByAssetForm = (props: FilterByAssetFormProps) => {
    const { selectedAssets, onChange, ...selectProps } = props;
    const { assets, loading } = useGetVulnerableAssetNames({});

    const options = useMemo(
        () =>
            assets
                ?.map(asset => ({
                    value: asset.id,
                    label: asset.value
                }))
                .filter(
                    (option, index, self) =>
                        index === self.findIndex(t => t.value === option.value)
                ) ?? [],
        [assets]
    );

    return (
        <Select
            clearable
            searchable
            limit={50}
            label="Affected Asset"
            description="Show all issues belonging to the selected affected asset."
            data={options}
            value={!loading ? selectedAssets?.[0] : 'Loading...'}
            disabled={loading}
            leftSection={<Icon icon={Icons.SEARCH} />}
            comboboxProps={{ withinPortal: true }}
            onChange={onChange}
            style={{ maxWidth: 600 }}
            {...selectProps}
        />
    );
};

export default memo(FilterByAssetForm);
