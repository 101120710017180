import React from 'react';

export function uppercaseFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function stringToReactComponent(str: string): JSX.Element {
    return React.createElement('span', {
        dangerouslySetInnerHTML: { __html: str }
    });
}

export function contactEmail(email: string): string {
    return `mailto:${email}`;
}

export function shuffle(str: string): string {
    const a = str.split('');
    const n = a.length;

    for (let i = n - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const tmp = a[i];
        a[i] = a[j];
        a[j] = tmp;
    }
    return a.join('');
}
