import { memo, useEffect } from 'react';
import { PageHeader, PageChildrenLink } from 'widgets/PageHeader';
import { RoutePath } from 'shared/config/routeConfig';
import { useGetTechnologies } from 'entities/Technology';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { useParams } from 'react-router-dom';
import { navigationActions, NavigationPageType } from 'entities/Navigation';

const TechnologiesPageHeader = () => {
    const { data: technologies, isLoading } = useGetTechnologies({});
    const dispatch = useAppDispatch();
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (!id) return;
        dispatch(
            navigationActions.addToDrawerStack({
                pageLink: window.location.href,
                pageType: NavigationPageType.TECHNOLOGY_DETAILS,
                pageID: id
            })
        );
    }, [dispatch, id]);

    return (
        <PageHeader
            title="Technologies"
            links={
                <PageChildrenLink
                    title="Detected"
                    to={`${RoutePath.technologies}/detected`}
                    previewNumber={technologies?.pagination?.totalItems}
                    previewNumberLoading={isLoading}
                />
            }
        />
    );
};

export default memo(TechnologiesPageHeader);
