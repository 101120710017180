import React, { memo, useCallback } from 'react';
import {
    Box,
    Button,
    Flex,
    PasswordInput,
    Text,
    TextInput
} from '@mantine/core';
import { LoginByEmailForm, userActions } from 'entities/User';
import { useForm } from '@mantine/form';
import { useLoginByEmailMutation } from 'entities/User/api/userApi/userApi';
import { NavLink, useNavigate } from 'react-router-dom';
import { RoutePath } from 'shared/config/routeConfig';
import { showNotification } from '@mantine/notifications';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import DemoGuard from 'shared/guards/DemoGuard/DemoGuard';
import { useDemo } from 'shared/hooks/useDemo/useDemo';
import { useReInitUser } from 'app/providers/InitProvider';
import { DEMO_CREDENTIALS } from '../../model/const';

const LoginForm = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { isDemo } = useDemo();
    const { reinitUser } = useReInitUser();

    const [login, { isLoading, error }] = useLoginByEmailMutation();
    const form = useForm<LoginByEmailForm>({
        initialValues: isDemo
            ? DEMO_CREDENTIALS
            : {
                  email: '',
                  password: ''
              }
    });

    const onSubmit = useCallback(
        (values: LoginByEmailForm) => {
            login({
                market_id: __NAF_MARKET_ID__,
                email: values.email,
                password: values.password
            })
                .unwrap()
                .then(data => {
                    if ('error' in data) {
                        showNotification({
                            title: 'Error',
                            message: data.error,
                            color: 'red'
                        });
                        return;
                    }

                    showNotification({
                        title: 'Success',
                        message: 'You have successfully logged in.',
                        color: 'green'
                    });

                    dispatch(userActions.setAuthData(data));
                    navigate(RoutePath.dashboard);
                    reinitUser();
                });
        },
        [login, navigate]
    );

    return (
        <Box w="100%">
            <form
                onSubmit={form.onSubmit(onSubmit)}
                autoComplete="on"
                id="login_form"
            >
                <Flex gap="lg" direction="column">
                    <TextInput
                        required
                        withAsterisk
                        label="Work email"
                        placeholder="account@attaxion.dev"
                        autoComplete="on"
                        type="email"
                        readOnly={isDemo}
                        {...form.getInputProps('email')}
                    />
                    <PasswordInput
                        required
                        withAsterisk
                        type="password"
                        label="Password"
                        placeholder="Your password"
                        autoComplete="on"
                        minLength={8}
                        readOnly={isDemo}
                        {...form.getInputProps('password')}
                    />
                    {error && (
                        <Text c="red">
                            Something went wrong. Please try again later or
                            contact support.
                        </Text>
                    )}
                    <DemoGuard>
                        <Text size="sm" ta="center">
                            <NavLink to={RoutePath.forgotPassword}>
                                Forgot password?
                            </NavLink>
                        </Text>
                    </DemoGuard>
                    <Button
                        type="submit"
                        variant="primary-gradient"
                        loading={isLoading}
                    >
                        Log in
                    </Button>
                </Flex>
            </form>
        </Box>
    );
};

export default memo(LoginForm);
