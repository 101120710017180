import { DataTableColumn } from 'mantine-datatable';
import {
    Asset,
    AssetQueryParams,
    AssetSslStatusBadge,
    AssetType
} from 'entities/Asset';
import { BaseColumnProps } from 'widgets/Columns/baseColumn';
import React from 'react';

type RecordType = Asset;
type QueryParams = AssetQueryParams;

export default ({
    column
}: BaseColumnProps<RecordType, QueryParams>): DataTableColumn<RecordType> => ({
    width: 120,
    accessor: 'metadata.sslCertificate.status',
    title: 'Status',
    render: ({ metadata }) => {
        if (!metadata?.sslCertificate?.status) {
            return '-';
        }

        return (
            <AssetSslStatusBadge
                sslCertificate={metadata?.sslCertificate}
                fullWidth
            />
        );
    },
    ...column
});
