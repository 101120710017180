import React, { Fragment, memo, useMemo } from 'react';
import { MetadataItem } from 'shared/ui/Metadata';
import {
    AssetType,
    useGetAssetDiscoveryPathBackwardShortest
} from 'entities/Asset';
import { Badge } from 'shared/ui/Badge';
import { NavigationLink, NavigationPageType } from 'entities/Navigation';
import {
    assetDetailsRoute,
    rootAssetCandidateDetailsRoute,
    rootAssetDetailsRoute
} from 'app/providers/RouteProvider';
import { Asset, RootAssetCandidate } from '../../../model/types/asset';
import styles from './MetadataItemShortestDiscoveryPath.module.scss';

interface MetadataItemShortestDiscoveryPathProps {
    asset: Asset | RootAssetCandidate;
}

const MetadataItemShortestDiscoveryPath = (
    props: MetadataItemShortestDiscoveryPathProps
) => {
    const { asset } = props;
    const { data } = useGetAssetDiscoveryPathBackwardShortest({ id: asset.id });
    const links = Object.values(data?.edges || {});
    const nodes = Object.values(data?.nodes || {});
    const shortestPath = useMemo(() => {
        const path = [asset];
        const recursiveTraversal = (node: Asset) => {
            const link = links.find(link => link.to === node.id);
            if (link) {
                const node = nodes.find(node => node.id === link.from);
                if (node) {
                    path.push(node);
                    recursiveTraversal(node);
                }
            }
        };

        recursiveTraversal(asset);
        return path.reverse();
    }, [asset, links, nodes]);

    if (!shortestPath.length || shortestPath.length < 2) return null;

    function pageType(asset: MetadataItemShortestDiscoveryPathProps['asset']) {
        if (asset.isRoot) {
            return NavigationPageType.ROOT_ASSET_DETAILS;
        }

        if (asset.isRootCandidate) {
            return NavigationPageType.ROOT_ASSET_CANDIDATE_DETAILS;
        }

        return NavigationPageType.ASSET_DETAILS;
    }

    function pageLink(asset: MetadataItemShortestDiscoveryPathProps['asset']) {
        if (asset.isRoot) {
            return rootAssetDetailsRoute(asset.id);
        }

        if (asset.isRootCandidate) {
            return rootAssetCandidateDetailsRoute(asset.id);
        }

        return assetDetailsRoute(asset.id, asset.type);
    }

    return (
        <MetadataItem
            label="Discovery Path"
            value={
                <div className={styles.MetadataItemShortestDiscoveryPath}>
                    {shortestPath.map((asset, index) => (
                        <Fragment key={asset.id}>
                            <NavigationLink
                                pageID={asset.id}
                                pageTitle={asset.value}
                                pageType={pageType(asset)}
                                pageLink={pageLink(asset)}
                                className={
                                    styles.MetadataItemShortestDiscoveryPath__Link
                                }
                            >
                                <Badge label={`${asset.type.toUpperCase()}:`}>
                                    {asset.value}
                                </Badge>
                            </NavigationLink>
                            {index < shortestPath.length - 1 && <> → </>}
                        </Fragment>
                    ))}
                </div>
            }
        />
    );
};

export default memo(MetadataItemShortestDiscoveryPath);
