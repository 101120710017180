import React, { memo } from 'react';
import { MultiSelect } from '@mantine/core';
import { Icon, Icons } from 'shared/ui/Icon';
import { StatusId } from 'entities/Issue';

export interface FilterByStatusSelectProps {
    selectedStatuses?: StatusId[];
    onChange?: (value: StatusId[]) => void;
}

const FilterByStatusSelect = (props: FilterByStatusSelectProps) => {
    const { selectedStatuses, onChange } = props;

    return (
        <MultiSelect
            clearable
            searchable
            label="Issue Status"
            description="Show all issues belonging to the selected status."
            data={[
                { value: 'open', label: 'Open' },
                { value: 'fixed', label: 'Fixed' },
                { value: 'accepted', label: 'Accepted risk' },
                { value: 'fp', label: 'False positive' }
            ]}
            value={selectedStatuses}
            onChange={(value: string[]) => onChange?.(value as StatusId[])}
            leftSection={<Icon icon={Icons.SEARCH} />}
            comboboxProps={{ withinPortal: false }}
            style={{ maxWidth: 600 }}
        />
    );
};

export default memo(FilterByStatusSelect);
