import { memo } from 'react';
import * as echarts from 'echarts/core';
import {
    TooltipComponent,
    GridComponent,
    LegendComponent
} from 'echarts/components';
import { BarChart as EBarChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { EChartsOption } from 'echarts';
import ReactECharts from 'echarts-for-react';
import { RefetchableComponent } from 'widgets/RefetchableComponent';
import styles from './BarChart.module.scss';

echarts.use([
    TooltipComponent,
    GridComponent,
    LegendComponent,
    EBarChart,
    CanvasRenderer,
    UniversalTransition
]);

type EventParams = {
    componentType: string;
    seriesType: string;
    seriesIndex: number;
    seriesName: string;
    name: string;
    dataIndex: number;
    data: Object;
    dataType: string;
    value: number | Array<any>;
    color: string;
};

interface BarChartProps {
    className?: string;
    categories?: string[];
    series?: any[];
    isLoading?: boolean;
    noData?: boolean;
    defaultOptions?: EChartsOption;
    onClick?: (params: EventParams) => void;
}

function BarChart(props: BarChartProps) {
    const {
        className,
        categories,
        series,
        isLoading,
        noData,
        defaultOptions = {},
        onClick = () => {}
    } = props;

    const options: EChartsOption = defaultOptions || {
        grid: {
            top: '8%',
            right: '30px',
            bottom: '4%',
            left: '30px',
            containLabel: true
        },
        legend: {
            data: series ? [...series.map(s => s.name)].reverse() : [],
            padding: 24
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            formatter(params: any) {
                const { name, seriesName, value } = params[0];
                const vulnerableAssets = params[0];
                const healthyAssets = params[1];
                const totalAssets =
                    vulnerableAssets.value + healthyAssets.value;
                return `${name}<br />
                    <div style="border-top: 1px solid var(--border-color); border-bottom: 1px solid var(--border-color); padding: 8px 0; margin: 8px 0;">
                        <div style="margin-bottom: 4px;">${healthyAssets.marker} ${healthyAssets.seriesName} <b style="float: right; margin-left: 20px;">${healthyAssets.value}</b></div>
                        <div>${vulnerableAssets.marker} ${vulnerableAssets.seriesName} <b style="float: right; margin-left: 20px;">${vulnerableAssets.value}</b></div>
                    </div>
                    <div style="text-align: right"><b>Total: ${totalAssets}</b></div>`;
            }
        },
        xAxis: [
            {
                type: 'category',
                data: categories
            }
        ],
        yAxis: {
            type: 'value',
            scale: true,
            // boundaryGap: [0, 0.01],
            minInterval: 1,
            axisLabel: {
                formatter: '{value} assets'
            }
        },
        // @ts-ignore
        series
    };

    const onEvents = {
        click: (params: EventParams) => onClick(params)
    };

    return (
        <RefetchableComponent isFetching={isLoading} className={className}>
            {noData && <div className={styles['no-data']}>No data</div>}
            {!noData && (
                <ReactECharts
                    className={styles.chart}
                    option={options}
                    onEvents={onEvents}
                />
            )}
        </RefetchableComponent>
    );
}

export default memo(BarChart);
