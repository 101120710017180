import { ACCOUNT_API_TAGS, accountApi } from 'shared/api/rtkApi';
import { Plan, Product } from '../../model/types/pricing';

const pricingApi = accountApi.injectEndpoints({
    endpoints: builder => ({
        getPricingPlans: builder.query<Plan[], void>({
            query: () => ({
                url: `/market_pricing/${__NAF_MARKET_ID__}`,
                method: 'GET',
                params: {
                    product_id: __NAF_PRODUCT_ID__
                }
            }),
            transformResponse: (response: { products: Product[] }) => {
                const product = response.products.find(
                    product => product.id === __NAF_PRODUCT_ID__
                );
                return product?.plans || [];
            },
            providesTags: [ACCOUNT_API_TAGS.PRICING_PLAN]
        })
    })
});

export const { useGetPricingPlansQuery } = pricingApi;
