import {
    Asset,
    AssetGeolocation,
    AssetScreenshot,
    AssetSubnetworks,
    RootAsset,
    RootAssetCandidate
} from 'entities/Asset';
import { Box, rem, SimpleGrid, Stack } from '@mantine/core';
import React from 'react';

interface IpAddressOverviewProps {
    asset: Asset | RootAsset | RootAssetCandidate;
}

const IpAddressOverview = (props: IpAddressOverviewProps) => {
    const { asset } = props;

    return (
        <Box
            style={{
                flex: 1
            }}
        >
            <AssetGeolocation asset={asset} />
        </Box>
    );
};

export default IpAddressOverview;
