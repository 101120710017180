import React, { memo, useCallback } from 'react';
import { Box, Button, Flex, Group, PasswordInput, Text } from '@mantine/core';
import {
    ChangePasswordForm as IChangePasswordForm,
    useChangePasswordMutation
} from 'entities/User';
import { useForm } from '@mantine/form';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'shared/config/routeConfig';
import { showNotification } from '@mantine/notifications';

export interface ChangePasswordFormProps {
    onClose: () => void;
}

const ChangePasswordForm = (props: ChangePasswordFormProps) => {
    const { onClose } = props;
    const navigate = useNavigate();
    const [changePassword, { isLoading, error }] = useChangePasswordMutation();

    const passwordConfirmationRef = React.useRef<HTMLInputElement>(null);
    const form = useForm<IChangePasswordForm>({
        initialValues: {
            password: '',
            password_confirm: ''
        },
        onValuesChange: () => {
            passwordConfirmationRef.current?.setCustomValidity('');
        }
    });

    const isConfirmPasswordValid = useCallback(() => {
        if (form.values.password_confirm !== form.values.password) {
            passwordConfirmationRef.current?.setCustomValidity(
                'Passwords do not match.'
            );

            return false;
        }

        passwordConfirmationRef.current?.setCustomValidity('');
        return true;
    }, [form.values.password, form.values.password_confirm]);

    const onSubmit = useCallback(
        (values: IChangePasswordForm) => {
            if (isConfirmPasswordValid()) {
                changePassword(values)
                    .unwrap()
                    .then(data => {
                        if ('error' in data) {
                            showNotification({
                                title: 'Error',
                                message: data.error,
                                color: 'red'
                            });
                            return;
                        }

                        showNotification({
                            title: 'Success',
                            message:
                                'message' in data
                                    ? data.message
                                    : 'Password changed successfully!',
                            color: 'green',
                            autoClose: 30 * 1000
                        });

                        navigate(RoutePath.logout);
                    });
            }
        },
        [changePassword, navigate, isConfirmPasswordValid]
    );

    return (
        <Box w="100%">
            <form onSubmit={form.onSubmit(onSubmit)} autoComplete="on">
                <Flex gap="lg" direction="column">
                    <Text>
                        Please enter your new password. It should be at least 8
                        characters long. You will be redirected to the login
                        page after the password is changed.
                    </Text>
                    <PasswordInput
                        required
                        withAsterisk
                        type="password"
                        label="Password"
                        placeholder="New password"
                        autoComplete="on"
                        minLength={8}
                        {...form.getInputProps('password')}
                    />
                    <PasswordInput
                        required
                        withAsterisk
                        type="password"
                        label="Password confirmation"
                        placeholder="Confirm new password"
                        autoComplete="on"
                        minLength={8}
                        ref={passwordConfirmationRef}
                        {...form.getInputProps('password_confirm')}
                    />
                    {error && (
                        <Text c="red">
                            Something went wrong. Please try again later or
                            contact support.
                        </Text>
                    )}
                    <Group justify="flex-end">
                        <Button color="asm" variant="outline" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button type="submit" color="asm" loading={isLoading}>
                            Change password
                        </Button>
                    </Group>
                </Flex>
            </form>
        </Box>
    );
};

export default memo(ChangePasswordForm);
