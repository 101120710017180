import React, { memo, useCallback, useEffect } from 'react';
import { Box, Group, LoadingOverlay, Modal, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { useSelector } from 'react-redux';
import { useInjectReducer } from 'shared/hooks/useInjectReducer';
import { showNotification } from '@mantine/notifications';
import {
    ScanMode,
    useUpdateAssetScannerConfigMutation,
    ScannerModeControl
} from 'entities/Scanner';
import { AssetScanModePost } from 'entities/KnowledgeBase';
import {
    updateAssetScannerSettingsActions,
    updateAssetScannerSettingsReducer
} from '../../model/slices/updateAssetScannerSettingsSlice/updateAssetScannerSettingsSlice';
import { getAsset } from '../../model/selectors/getAsset/getAsset';

const UpdateAssetScannerSettingsModal = () => {
    useInjectReducer(
        'updateAssetScannerSettings',
        updateAssetScannerSettingsReducer
    );

    const [opened, { open, close }] = useDisclosure(false);
    const dispatch = useAppDispatch();
    const asset = useSelector(getAsset);
    const [updateAssetScannerSettings, { isLoading }] =
        useUpdateAssetScannerConfigMutation();

    useEffect(() => {
        if (!asset) close();
        if (asset) open();
    }, [asset, close, open]);

    const onClose = useCallback(() => {
        dispatch(updateAssetScannerSettingsActions.reset());
        close();
    }, [dispatch]);

    const onUpdate = useCallback(
        (scanMode: ScanMode) => {
            if (!asset) return;
            updateAssetScannerSettings({
                scanMode,
                id: asset.id
            })
                .then(() => {
                    showNotification({
                        message: 'Scanner settings updated',
                        color: 'green'
                    });
                    close();
                })
                .catch(() => {
                    showNotification({
                        message: 'Failed to update scanner settings',
                        color: 'red'
                    });
                });
        },
        [asset, updateAssetScannerSettings, close]
    );

    return (
        <Modal
            size="lg"
            opened={opened}
            title={
                <Group gap="xs" align="center">
                    <span>Update scanner settings</span>
                    <AssetScanModePost />
                </Group>
            }
            onClose={onClose}
        >
            <Stack>
                <Text size="sm">
                    Update scanner settings for the selected asset{' '}
                    <b>{asset?.value}</b>
                </Text>
                {asset && asset.scanMode && (
                    <Box pos="relative">
                        <LoadingOverlay visible={isLoading} />
                        <ScannerModeControl
                            fullWidth
                            size="md"
                            hideInheritMode={false}
                            value={asset.scanMode}
                            onChange={onUpdate}
                        />
                    </Box>
                )}
            </Stack>
        </Modal>
    );
};

export default memo(UpdateAssetScannerSettingsModal);
