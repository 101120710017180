import React, { memo } from 'react';
import classNames from 'classnames';
import { Card } from 'shared/ui/Card';
import { Text } from 'shared/ui/Text';
import styles from './AssetSslConnection.module.scss';
import { Asset } from '../../model/types/asset';

interface AssetSslConnectionProps {
    className?: string;
    asset?: Asset;
}

const AssetSslConnectionRow = ({
    label,
    value
}: {
    label: string;
    value: string | string[] | number | number[];
}) =>
    value && (
        <div className={styles.AssetSslConnection__Item__Content__Row}>
            <Text color="secondary">{label}</Text>
            <div>{Array.isArray(value) ? value.join('\n') : value}</div>
        </div>
    );

const AssetSslConnection = (props: AssetSslConnectionProps) => {
    const { className, asset } = props;
    const classes = classNames(styles.AssetSslConnection, className);

    if (!asset?.metadata?.sslConnection) return null;
    const { sslConnection } = asset.metadata;

    return (
        <div className={classes}>
            <Card
                contentClassName={styles.AssetSslConnection__Item}
                header="Overview"
            >
                <div className={styles.AssetSslConnection__Item__Column}>
                    <div className={styles.AssetSslConnection__Item__Content}>
                        <AssetSslConnectionRow
                            label="Protocols"
                            value={sslConnection.protocols.join(', ')}
                        />
                        {Object.keys(sslConnection.cipherSuites).map(key => (
                            <AssetSslConnectionRow
                                label={`Cipher suite ${key}`}
                                value={sslConnection.cipherSuites[key].join(
                                    ', '
                                )}
                            />
                        ))}
                        <AssetSslConnectionRow
                            label="Renegotiation supported"
                            value={sslConnection.renegotiationSupport.join(
                                ', '
                            )}
                        />
                        <AssetSslConnectionRow
                            label="Session resumption"
                            value={sslConnection.sessionResumption}
                        />
                        <AssetSslConnectionRow
                            label="Compression methods"
                            value={sslConnection.compression.join(', ')}
                        />
                        <AssetSslConnectionRow
                            label="Named groups"
                            value={sslConnection.namedGroups.join(', ')}
                        />
                        <AssetSslConnectionRow
                            label="Session tickets"
                            value={sslConnection.sessionTickets.join('. ')}
                        />
                        <AssetSslConnectionRow
                            label="OCSP stapling enabled"
                            value={
                                sslConnection.ocspStaplingEnabled ? 'Yes' : 'No'
                            }
                        />
                        <AssetSslConnectionRow
                            label="OCSP stapling revocation status"
                            value={sslConnection.ocspStaplingRevocationStatus}
                        />
                        <AssetSslConnectionRow
                            label="OCSP stapling error message"
                            value={sslConnection.ocspStaplingErrorMessage}
                        />
                        <AssetSslConnectionRow
                            label="NPN protocols"
                            value={sslConnection.npnProtocols.join(', ')}
                        />
                        <AssetSslConnectionRow
                            label="ALPN protocols"
                            value={sslConnection.alpnProtocols.join(', ')}
                        />
                        <AssetSslConnectionRow
                            label="RC4 supported"
                            value={sslConnection.supportsRc4 ? 'Yes' : 'No'}
                        />
                        <AssetSslConnectionRow
                            label="RC4 supported"
                            value={sslConnection.supportsRc4 ? 'Yes' : 'No'}
                        />
                        <AssetSslConnectionRow
                            label="RC4 with modern clients supported"
                            value={
                                sslConnection.supportsRc4WithModernClients
                                    ? 'Yes'
                                    : 'No'
                            }
                        />
                        <AssetSslConnectionRow
                            label="AEAD supported"
                            value={sslConnection.supportsAead ? 'Yes' : 'No'}
                        />
                        <AssetSslConnectionRow
                            label="CBC supported"
                            value={sslConnection.supportsCbc ? 'Yes' : 'No'}
                        />
                        <AssetSslConnectionRow
                            label="SNI required"
                            value={sslConnection.sniRequired ? 'Yes' : 'No'}
                        />
                        <AssetSslConnectionRow
                            label="Zero RTT enabled"
                            value={sslConnection.zeroRTTEnabled ? 'Yes' : 'No'}
                        />
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default memo(AssetSslConnection);
