import { Button } from 'shared/ui/Button';
import classNames from 'classnames';
import { Logo } from 'shared/ui/Logo';
import styles from './PageError.module.scss';

interface PageErrorProps {
    className?: string;
}

const PageError = (props: PageErrorProps) => {
    const { className } = props;
    const classes = classNames(styles['page-error'], className);

    const reloadPage = () => {
        window.location.reload();
    };

    return (
        <div className={classes}>
            <Logo />
            <h1>
                Something went wrong. <br />
                We received a report about this error.
            </h1>
            <Button onClick={reloadPage}>Reload Page</Button>
        </div>
    );
};

export default PageError;
