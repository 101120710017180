import { memo } from 'react';
import { Port } from 'entities/Asset/model/types/metadata';

interface PortExtraInfoProps {
    port?: Port;
}

const PortExtraInfo = (props: PortExtraInfoProps) => {
    const { port } = props;
    return port?.extraInfo?.data || 'N/A';
};

export default memo(PortExtraInfo);
