import React, { memo } from 'react';
import HeaderedCard from 'shared/ui/HeaderedCard/HeaderedCard';
import ViewAllLink from 'shared/ui/Links/ViewAllLink/ViewAllLink';
import { RoutePath } from 'shared/config/routeConfig';
import AssetsFeedTable from '../../Tables/AssetsFeedTable/AssetsFeedTable';

const AssetsFeedCard = () => (
    <HeaderedCard
        header="Assets Feed"
        bodyPadding="0"
        actions={<ViewAllLink to={RoutePath.assets} />}
    >
        <AssetsFeedTable />
    </HeaderedCard>
);

export default memo(AssetsFeedCard);
