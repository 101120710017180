import {
    Asset,
    AssetQueryParams,
    RootAssetCandidate,
    RootAssetLink
} from 'entities/Asset';
import { DataTableColumn } from 'mantine-datatable';
import React from 'react';
import { BaseColumnProps, updateQuery } from 'widgets/Columns/baseColumn';
import { FilterByRootAssetForm } from 'features/asset/filters/FilterByRootAsset';
import {
    Misconfiguration,
    MisconfigurationQueryParams
} from 'entities/Misconfiguration';
import { Vulnerability } from 'entities/Vulnerability';

type AssetType = Asset | RootAssetCandidate;
type RecordType = AssetType | Misconfiguration | Vulnerability;
type QueryParams = AssetQueryParams | MisconfigurationQueryParams;

export default ({
    query,
    setQuery,
    column
}: BaseColumnProps<RecordType, QueryParams>): DataTableColumn<RecordType> => ({
    accessor: 'rootAsset.value',
    title: 'Root Asset',
    filter: query && setQuery && (
        <FilterByRootAssetForm
            selectedAssets={query.rootAssetID}
            onChange={rootAssetID =>
                updateQuery({ rootAssetID }, query, setQuery)
            }
        />
    ),
    filtering: Boolean(query?.rootAssetID),
    render: record => {
        const asset: AssetType = 'asset' in record ? record.asset : record;

        if (!asset.rootAsset) {
            return '-';
        }

        return <RootAssetLink asset={asset.rootAsset} />;
    },
    ...column
});
