import React, { memo } from 'react';
import { useLayout } from 'app/providers/LayoutProvider';
import classNames from 'classnames';
import { Logo } from 'shared/ui/Logo';
import { Box, Space, Stack, Tooltip } from '@mantine/core';
import { Icons } from 'shared/ui/Icon';
import { RoutePath } from 'shared/config/routeConfig';
import { useSelector } from 'react-redux';
import { getUserAuthData } from 'entities/User';
import DemoGuard from 'shared/guards/DemoGuard/DemoGuard';
import { SubscriptionDaysLeftNotification } from 'entities/Billing';
import SidebarItem from '../SidebarItem/SidebarItem';
import styles from './Sidebar.module.scss';
import useSidebarItems from '../../model/hooks/useSidebarItems/useSidebarItems';

interface SidebarProps {
    className?: string;
}

const SidebarItems = memo(() => {
    const sidebarItems = useSidebarItems();

    return (
        <Stack gap="md">
            {sidebarItems.map(item => (
                <SidebarItem key={item.path} item={item} />
            ))}
        </Stack>
    );
});

const SidebarFooter = () => {
    const user = useSelector(getUserAuthData);

    return (
        <Stack gap="md" mt="auto">
            <SidebarItem
                item={{
                    icon: Icons.EXTENSION,
                    label: 'Integrations',
                    path: `${RoutePath.integrations}`
                }}
            />
            <DemoGuard>
                <Tooltip label={`${user?.email}`} position="right" withArrow>
                    <Box>
                        <SidebarItem
                            item={{
                                icon: Icons.PERSON,
                                label: 'Account',
                                path: RoutePath.account
                            }}
                        />
                    </Box>
                </Tooltip>
            </DemoGuard>
        </Stack>
    );
};

function Sidebar(props: SidebarProps) {
    const { className } = props;
    const { layout } = useLayout();
    const classes = classNames(styles.aside, className, layout);

    return (
        <aside data-testid="sidebar" className={classes}>
            <Logo short />
            <Space h="lg" />
            <SubscriptionDaysLeftNotification />
            <Space h="lg" />
            <SidebarItems />
            <Space h="md" />
            <SidebarFooter />
        </aside>
    );
}

export default memo(Sidebar);
