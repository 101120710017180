import React, { memo } from 'react';
import { Issue } from 'entities/Issue';
import { Badge, BadgeProps, Group } from '@mantine/core';
import { KevPost } from 'entities/KnowledgeBase';

interface KevCheckBadgeProps extends BadgeProps {
    issue: Issue;
    failedText?: string;
    passedText?: string;
    hasFaq?: boolean;
}

const KevCheckBadge = (props: KevCheckBadgeProps) => {
    const {
        issue,
        failedText = 'CISA KEV status: detected',
        passedText = 'CISA KEV status: passed',
        hasFaq = true,
        ...rest
    } = props;

    if (!issue.kev) {
        return (
            <Badge variant="light" color="green" {...rest}>
                <Group gap={2} align="center">
                    {passedText}
                    {hasFaq && <KevPost />}
                </Group>
            </Badge>
        );
    }

    return (
        <Badge variant="light" color="red" {...rest}>
            <Group gap={2} align="center">
                {failedText}
                {hasFaq && <KevPost />}
            </Group>
        </Badge>
    );
};

export default memo(KevCheckBadge);
