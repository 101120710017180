import { memo, useMemo } from 'react';
import {
    Box,
    Card,
    CardProps,
    Divider,
    Flex,
    Group,
    Text,
    Title
} from '@mantine/core';

interface HeaderedCardProps extends CardProps {
    headerIcon?: React.ReactNode;
    header: string | React.ReactNode;
    description?: string;
    bodyPadding?: CardProps['p'];
    actions?: React.ReactNode;
    children?: React.ReactNode;
    attentionCard?: boolean;
}

const HeaderedCard = (props: HeaderedCardProps) => {
    const {
        className,
        headerIcon,
        header,
        description,
        bodyPadding = 'lg',
        actions,
        children,
        attentionCard,
        ...rest
    } = props;

    const themeProps = useMemo(() => {
        if (attentionCard) {
            return {
                bg: 'red.1',
                style: {
                    border: '1px solid var(--mantine-color-red-6)'
                }
            };
        }
        return {};
    }, [attentionCard]);

    return (
        <Card
            shadow="md"
            padding={0}
            radius="md"
            withBorder
            {...themeProps}
            {...rest}
        >
            <Card.Section px="lg" py="sm">
                <Group justify="space-between" wrap="wrap" gap="xs">
                    <Box>
                        <Group align="center" gap="xs">
                            {headerIcon}
                            <Title order={4} fw={600}>
                                {header}
                            </Title>
                        </Group>
                        {description && (
                            <Text c="dimmed" size="sm">
                                {description}
                            </Text>
                        )}
                    </Box>
                    <Flex align="center" gap="xs" wrap="nowrap" h={30}>
                        {actions}
                    </Flex>
                </Group>
            </Card.Section>
            <Card.Section>
                <Divider />
            </Card.Section>
            <Card.Section p={bodyPadding} flex={1}>
                {children}
            </Card.Section>
        </Card>
    );
};

export default memo(HeaderedCard);
