import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon, Icons } from 'shared/ui/Icon';
import { Button } from '@mantine/core';

interface ViewAllLinkProps {
    to: string;
}

const ViewAllLink = (props: ViewAllLinkProps) => {
    const { to } = props;

    return (
        <Button
            component={NavLink}
            to={to}
            color="asm"
            variant="outline"
            size="compact-sm"
            rightSection={<Icon icon={Icons.CHEVRON_RIGHT} />}
        >
            View all
        </Button>
    );
};

export default memo(ViewAllLink);
