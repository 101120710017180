import { useSelector } from 'react-redux';
import { getEmail } from 'entities/User';
import { useEffect, useState } from 'react';
import { analyticsActions } from 'entities/Analytics';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import RootAssetAddedInvisibleForm from '../Forms/RootAssetAddedInvisibleForm/RootAssetAddedInvisibleForm';
import RootAssetDeletedInvisibleForm from '../Forms/RootAssetDeletedInvisibleForm/RootAssetDeletedInvisibleForm';
import RootAssetVerifiedInvisibleForm from '../Forms/RootAssetVerifiedInvisibleForm/RootAssetVerifiedInvisibleForm';
import DefaultInvisibleForm from '../Forms/DefaultInvisibleForm/DefaultInvisibleForm';
import { getEvent } from '../../model/selectors/getEvent/getEvent';
import { AnalyticsEvent } from '../../model/types/analytics';

const EventsListener = () => {
    const dispatch = useAppDispatch();
    const email = useSelector(getEmail);
    const events = useSelector(getEvent);
    const [eventPayload, setEventPayload] = useState<
        | {
              event: AnalyticsEvent;
              payload?: object;
          }
        | undefined
    >(undefined);

    useEffect(() => {
        if (events && events.length) {
            setEventPayload({
                event: events[0].event,
                payload: events[0].payload
            });

            setTimeout(() => {
                setEventPayload(undefined);
                setTimeout(() => {
                    dispatch(analyticsActions.shiftEvent());
                });
            }, 3000);
        }
    }, [events]);

    if (email && eventPayload) {
        const { event, payload } = eventPayload;
        switch (event) {
            case AnalyticsEvent.BUSINESS_EMAIL_DETECTED:
            case AnalyticsEvent.COMPANY_ROOT_ASSET_AUTO_VERIFIED:
            case AnalyticsEvent.COMPANY_ROOT_ASSET_SCANNING_STARTED:
            case AnalyticsEvent.SUCCESSFUL_EMAIL_CONFIRMATION:
            case AnalyticsEvent.SUCCESSFUL_PURCHASE:
                return <DefaultInvisibleForm email={email} id={event} />;
            case AnalyticsEvent.ROOT_ASSET_ADDED:
                return (
                    payload && (
                        <RootAssetAddedInvisibleForm
                            email={email}
                            payload={payload}
                        />
                    )
                );
            case AnalyticsEvent.ROOT_ASSET_VERIFIED:
                return (
                    payload && (
                        <RootAssetVerifiedInvisibleForm
                            email={email}
                            payload={payload}
                        />
                    )
                );
            case AnalyticsEvent.ROOT_ASSET_DELETED:
                return (
                    payload && (
                        <RootAssetDeletedInvisibleForm
                            email={email}
                            payload={payload}
                        />
                    )
                );
            default:
                return null;
        }
    }

    return null;
};

export default EventsListener;
