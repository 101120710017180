import React, { memo } from 'react';
import HeaderedCard from 'shared/ui/HeaderedCard/HeaderedCard';
import { Text } from '@mantine/core';
import { Vulnerability } from 'entities/Vulnerability';
import { Misconfiguration } from 'entities/Misconfiguration';
import { Issue } from '../../model/types/issue';

interface IssueUrlProps {
    issue: Issue | Vulnerability | Misconfiguration;
}

const IssueUrl = (props: IssueUrlProps) => {
    const { issue } = props;
    const { targets } = issue;

    if (!targets) return null;

    return (
        <HeaderedCard header="Where it was found">
            <Text size="sm">{targets[0]}</Text>
        </HeaderedCard>
    );
};

export default memo(IssueUrl);
