import React from 'react';
import {
    DataTable as MantineDataTable,
    DataTableProps as MantineDataTableProps
} from 'mantine-datatable';
import { Group } from '@mantine/core';

type DataTableProps<T> = MantineDataTableProps<T> & {
    actions?: React.ReactNode;
};

function DataTable<T>(props: DataTableProps<T>) {
    const {
        records,
        actions,
        shadow = 'lg',
        borderRadius = 'md',
        verticalSpacing = 'sm'
    } = props;

    return (
        <>
            {false && actions && (
                <Group h={42} justify="space-between">
                    {actions}
                </Group>
            )}
            <MantineDataTable
                withTableBorder
                withColumnBorders
                highlightOnHover
                height={
                    records?.length && records.length < 20
                        ? 'fit-content'
                        : '100%'
                }
                shadow={shadow}
                borderRadius={borderRadius}
                verticalSpacing={verticalSpacing}
                minHeight={records?.length ? 0 : 320}
                {...props}
            />
        </>
    );
}

export default DataTable;
