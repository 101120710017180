import HelpModal from 'shared/ui/HelpModal/HelpModal';
import HeaderedCard from 'shared/ui/HeaderedCard/HeaderedCard';
import { Group, Stack } from '@mantine/core';
import { ModalBaseProps } from '@mantine/core/lib/components/ModalBase/ModalBase';
import { IconSize } from 'shared/ui/Icon';

interface KnowledgeBasePostBaseProps {
    title: string;
    content: React.ReactNode;
    footer?: React.ReactNode;
    mode?: 'modal' | 'inline';
    modalSize?: ModalBaseProps['size'];
    actionIconSize?: IconSize;
}

const KnowledgeBasePost = (props: KnowledgeBasePostBaseProps) => {
    const {
        title,
        content,
        footer,
        mode = 'modal',
        modalSize,
        actionIconSize
    } = props;

    if (mode === 'modal') {
        return (
            <HelpModal
                title={title}
                content={content}
                footer={footer}
                modalSize={modalSize}
                actionIconSize={actionIconSize}
            />
        );
    }

    return (
        <HeaderedCard header={title}>
            <Stack>{content}</Stack>
            {footer && <Group>{footer}</Group>}
        </HeaderedCard>
    );
};

export default KnowledgeBasePost;
