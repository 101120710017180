import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import queryString from 'query-string';
import { StateSchema } from 'app/providers/StoreProvider';
import { getUserApiToken, getUserAuthToken } from 'entities/User';

export enum API_TAGS {
    ASSET = 'Asset',
    ROOT_ASSET_NAMES = 'RootAssetNames',
    VULNERABLE_ASSET_NAMES = 'VulnerableAssetNames',
    ISSUE = 'Issue',
    TAG = 'Tag',
    STATISTICS = 'Statistics',
    LOG = 'Log',
    NOTIFICATION_CONFIG = 'NotificationConfig',
    SCANNER_CONFIG = 'ScannerConfig',
    JIRA_SETTINGS = 'JiraSettings',
    JIRA_PROJECTS = 'JiraProjects',
    SLACK_SETTINGS = 'SlackSettings'
}

export enum ACCOUNT_API_TAGS {
    USER = 'User',
    SUBSCRIPTION = 'Subscription',
    PAYMENT = 'Payment',
    PRICING_PLAN = 'PricingPlan'
}

export enum INTERNAL_API_TAGS {
    USER = 'User'
}

const paramsSerializer = (params: Record<any, any>) =>
    queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true
    });

const baseQuery = fetchBaseQuery({
    baseUrl: __API_URL__,
    prepareHeaders: (headers, { getState }) => {
        const token = getUserApiToken(getState() as StateSchema);
        headers.set('Content-Type', 'application/json');
        headers.set('Authorization', `Bearer ${token}`);
        return headers;
    },
    paramsSerializer
});

const baseInternalQuery = fetchBaseQuery({
    baseUrl: __INTERNAL_API_URL__,
    prepareHeaders: (headers, { getState }) => {
        headers.set('Content-Type', 'application/json');
        return headers;
    },
    paramsSerializer
});

const baseAccountQuery = fetchBaseQuery({
    baseUrl: __AUTH_API_URL__,
    prepareHeaders: (headers, { getState }) => {
        const token = getUserAuthToken(getState() as StateSchema);
        headers.set('Content-Type', 'application/json');

        if (token) {
            headers.set('X-CSRF-TOKEN', token);
        }

        return headers;
    },
    paramsSerializer
});

export const rtkApi = createApi({
    reducerPath: 'api',
    baseQuery: async (args, api, extraOptions) => {
        try {
            const baseResult = await baseQuery(args, api, extraOptions);

            if (baseResult.data && baseResult.meta) {
                const { method, url } = baseResult.meta.request;
                const path = url.replace(__API_URL__, '');

                return {
                    ...baseResult,
                    data: {
                        ...baseResult.data,
                        __meta: {
                            method,
                            url,
                            path
                        }
                    } as any
                };
            }

            return baseResult;
        } catch (error) {
            throw error;
        }
    },
    tagTypes: Object.values(API_TAGS),
    endpoints: () => ({})
});

export const internalApi = createApi({
    reducerPath: 'internalApi',
    baseQuery: async (args, api, extraOptions) =>
        baseInternalQuery(args, api, extraOptions),
    tagTypes: Object.values(INTERNAL_API_TAGS),
    endpoints: () => ({})
});

export const accountApi = createApi({
    reducerPath: 'accountApi',
    baseQuery: async (args, api, extraOptions) =>
        baseAccountQuery(args, api, extraOptions),
    tagTypes: Object.values(ACCOUNT_API_TAGS),
    endpoints: () => ({})
});
