import classNames from 'classnames';
import { Icon, Icons } from 'shared/ui/Icon';
import { memo } from 'react';
import styles from './ExternalLink.module.scss';

interface ExternalLinkProps {
    className?: string;
    href: string;
    hasIcon?: boolean;
    children?: React.ReactNode;
}

const ExternalLink = (props: ExternalLinkProps) => {
    const { className, href, hasIcon = true, children } = props;
    const classes = classNames(styles.ExternalLink, className);

    return (
        <a href={href} className={classes} target="_blank" rel="noreferrer">
            {children}
            {hasIcon && <Icon icon={Icons.OPEN_IN_NEW} />}
        </a>
    );
};

export default memo(ExternalLink);
