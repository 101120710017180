import React, { memo } from 'react';
import { Select } from '@mantine/core';

export interface FilterByKevCheckStatusSelectProps {
    isKev?: boolean;
    onChange?: (value?: boolean) => void;
}

const FilterByKevCheckStatusSelect = (
    props: FilterByKevCheckStatusSelectProps
) => {
    const { isKev, onChange } = props;

    const onStatusChange = (value: string | null) => {
        onChange?.(value === 'any' ? undefined : value === 'detected');
    };

    return (
        <Select
            label="CISA KEV status"
            description="Show all issues belonging to the selected CISA KEV check status"
            data={[
                { value: 'any', label: 'Any' },
                { value: 'detected', label: 'Detected' },
                { value: 'passed', label: 'Passed' }
            ]}
            value={isKev === undefined ? 'any' : isKev ? 'detected' : 'passed'}
            onChange={value => onStatusChange(value)}
            comboboxProps={{ withinPortal: false }}
            style={{ maxWidth: 600 }}
        />
    );
};

export default memo(FilterByKevCheckStatusSelect);
