import React, { memo } from 'react';
import { Page } from 'shared/ui/Page';
import classNames from 'classnames';
import { useGetAsset } from 'entities/Asset';
import { AssetDetailsCard, AssetTabs } from 'widgets/Asset';
import { Seo } from 'shared/ui/SEO/SEO';
import AssetDetailsProvider from 'pages/Assets/children/AssetDetails/ui/AssetDetailsProvider';
import styles from './AssetDetailsPage.module.scss';

interface AssetDetailsPageProps {
    className?: string;
    id: string;
}

function AssetDetailsPage(props: AssetDetailsPageProps) {
    const { className, id } = props;
    const classes = classNames(styles.ArticleDetailsPage, className);
    const { data: asset, isLoading } = useGetAsset(id);

    if (isLoading) {
        return (
            <Page className={classes}>
                <div>Loading...</div>
            </Page>
        );
    }

    if (!asset) {
        return (
            <Page className={classes}>
                <div>Asset not found</div>
            </Page>
        );
    }

    return (
        <AssetDetailsProvider>
            <Page className={classes}>
                <Seo title="Details | Asset" />
                <AssetDetailsCard asset={asset} />
                <AssetTabs asset={asset} />
            </Page>
        </AssetDetailsProvider>
    );
}

export default memo(AssetDetailsPage);
