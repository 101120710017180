import {
    RootAsset,
    RootVerificationStatus,
    useRootAssetStatus
} from 'entities/Asset';
import { Button, ButtonProps, Menu } from '@mantine/core';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { verifyRootAssetActions } from 'features/asset/actions/VerifyRootAsset';
import { Icon, Icons } from 'shared/ui/Icon';
import React from 'react';
import {
    isRootAssetVerificationRequired,
    isRootAssetVerificationAvailable
} from '../../model/service/helpers';

export interface VerifyAssetButtonProps extends ButtonProps {
    asset: RootAsset;
    isMenuItemButton?: boolean;
}

const VerifyAssetButton = (props: VerifyAssetButtonProps) => {
    const { asset, isMenuItemButton = false, ...buttonProps } = props;
    const dispatch = useAppDispatch();
    const { deleting, deleted } = useRootAssetStatus(asset);
    const isVerificationRequired = isRootAssetVerificationRequired(asset);
    const isVerificationAvailable = isRootAssetVerificationAvailable(asset);

    if (deleting || deleted) {
        return null;
    }

    const renderButton = () => {
        if (isMenuItemButton) {
            return (
                <Menu.Item
                    leftSection={<Icon icon={Icons.FINGERPRINT} />}
                    onClick={() => {
                        dispatch(verifyRootAssetActions.setAsset(asset));
                    }}
                >
                    Verify asset
                </Menu.Item>
            );
        }

        return (
            <Button
                color="asm"
                size="compact-xs"
                onClick={() => {
                    dispatch(verifyRootAssetActions.setAsset(asset));
                }}
                {...buttonProps}
            >
                Verify asset
            </Button>
        );
    };

    return (
        isVerificationRequired &&
        isVerificationAvailable && <>{renderButton()}</>
    );
};

export default VerifyAssetButton;
