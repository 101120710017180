import React, { memo } from 'react';
import {
    Badge,
    Box,
    Card,
    Flex,
    NumberFormatter,
    Text,
    Title
} from '@mantine/core';
import { Icon, Icons, IconSize } from 'shared/ui/Icon';
import { PlanSchema } from '../../model/types/pricing';

interface PriceCardProps {
    schema: PlanSchema;
    name?: string;
    price: number;
    period?: 'mo' | 'yr';
    assets: number;
    isSelected?: boolean;
    isActive: boolean;
    isVerifying?: boolean;
    isTrial?: boolean;
    isCustom?: boolean;
    onSelectPlan?: (
        planSchema: PlanSchema,
        priceKey: number,
        priceValue: number,
        description: string
    ) => void;
}

const PriceCard = (props: PriceCardProps) => {
    const {
        schema,
        name,
        price,
        period,
        assets,
        isSelected,
        isActive,
        isVerifying,
        isTrial,
        isCustom,
        onSelectPlan
    } = props;

    const isClickable = !isActive && !isSelected && !isVerifying;

    const renderTitle = () => {
        if (isCustom) {
            return 'Custom';
        }

        if (isTrial) {
            return 'Trial';
        }

        return (
            <NumberFormatter
                value={price}
                prefix="$"
                suffix={`/${period}`}
                thousandSeparator
            />
        );
    };

    const renderDescription = () => {
        if (isTrial) {
            return 'Scan unlimited assets during 30-day trial';
        }

        if (isCustom) {
            if (!assets) {
                return 'Scan unlimited assets';
            }
        }

        return `Scan up to ${assets} assets daily`;
    };

    const renderAction = () => {
        if (isActive) {
            return (
                <Badge color="green" variant="filled">
                    Active
                </Badge>
            );
        }

        if (isVerifying) {
            return (
                <Badge color="yellow" variant="filled">
                    Waiting for payment
                </Badge>
            );
        }

        return (
            <Icon
                icon={
                    isSelected ? Icons.CHECK_BOX : Icons.CHECK_BOX_OUTLINE_BLANK
                }
                size={IconSize.LARGE}
                style={{
                    fill: isSelected
                        ? 'var(--primary-color-1)'
                        : 'var(--slate9)'
                }}
            />
        );
    };
    return (
        <Card
            withBorder
            radius="md"
            shadow={isClickable ? 'sm' : ''}
            miw={660}
            bg={isActive || isVerifying ? 'gray.3' : ''}
            pe="none"
            style={{
                borderColor: isSelected ? 'var(--primary-color-1)' : '',
                pointerEvents: isClickable ? 'auto' : 'none',
                cursor: isClickable ? 'pointer' : 'default'
            }}
            onClick={() => {
                if (isClickable && onSelectPlan) {
                    onSelectPlan(
                        schema,
                        assets,
                        price,
                        `Scan up to ${assets} assets daily`
                    );
                }
            }}
        >
            <Card.Section p="lg">
                <Flex justify="flex-start" align="center">
                    <Box w={160}>
                        {name && (
                            <Title order={4} c="asm">
                                {name}
                            </Title>
                        )}
                        <Title order={2} c="asm">
                            {renderTitle()}
                        </Title>
                    </Box>
                    <Text size="md">{renderDescription()}</Text>
                    <Box ml="auto">{renderAction()}</Box>
                </Flex>
            </Card.Section>
        </Card>
    );
};

export default memo(PriceCard);
