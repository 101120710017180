import { DataTableColumn } from 'mantine-datatable';
import { BaseColumnProps } from 'widgets/Columns/baseColumn';
import { Asset, AssetQueryParams } from 'entities/Asset';

export default ({
    column
}: BaseColumnProps<Asset, AssetQueryParams>): DataTableColumn<Asset> => ({
    width: 'fit-content',
    accessor: 'metadata.sslCertificate.issuer',
    title: 'Issuer',
    render: ({ metadata }) => {
        if (!metadata?.sslCertificate?.issuer) {
            return '-';
        }

        return metadata?.sslCertificate.issuer;
    },
    ...column
});
