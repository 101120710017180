import { Tag } from 'entities/Tag';

const RED_COLOR_TAGS = ['critical', 'high', 'medium', 'low'];
const RED_COLOR_SYSTEM_TAGS = ['declined', 'deleting', 'deleted'];

export function tagColor(tag: Tag, defaultColor = 'light') {
    if (RED_COLOR_TAGS.includes(tag.name.toLowerCase())) return 'red';
    if (tag.isSystem && RED_COLOR_SYSTEM_TAGS.includes(tag.name.toLowerCase()))
        return 'red';

    return defaultColor;
}
