import React, { memo } from 'react';
import { Issue, IssueUnique } from 'entities/Issue';
import { Badge } from '@mantine/core';
import dayjs from 'dayjs';
import { Misconfiguration } from 'entities/Misconfiguration';
import { Vulnerability } from 'entities/Vulnerability';

interface NewIssueBadgeProps {
    issue: Issue | IssueUnique | Vulnerability | Misconfiguration;
}

const NewIssueBadge = (props: NewIssueBadgeProps) => {
    const { issue } = props;
    const createdAt = dayjs(issue.createdAt);
    const lastSeenAt = dayjs(issue.lastSeenAt);
    const isNew = createdAt.isSame(lastSeenAt, 'day');

    if (!isNew) {
        return null;
    }

    return (
        <Badge color="blue" variant="light">
            New
        </Badge>
    );
};

export default memo(NewIssueBadge);
