import { theme } from 'shared/config/theme/theme';
import { StatusId } from '../../types/issue';

export function statusColor(id: StatusId | undefined) {
    switch (id) {
        case 'accepted':
            return theme.colors.yellow[7];
        case 'fp':
            return theme.colors.gray[5];
        case 'fixed':
            return theme.colors.green[7];
        case 'open':
            return theme.colors.blue[7];
        default:
            return theme.colors.gray[7];
    }
}
