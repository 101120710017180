import { Stack, Text } from '@mantine/core';
import React from 'react';
import { ExternalLink } from 'shared/ui/ExternalLink';
import { IconSize } from 'shared/ui/Icon';
import KnowledgeBasePost from '../KnowledgeBasePost/KnowledgeBasePost';

const Content = () => (
    <Stack>
        <Text>
            CISA&apos;s Known Exploited Vulnerabilities (KEV) Catalog is a
            compilation of documented security vulnerabilities that have been
            successfully exploited, as well as vulnerabilities associated with
            ransomware campaigns
        </Text>
        <Text>
            We strongly recommend that all organizations review and monitor the
            KEV catalog and prioritize remediation of the listed vulnerabilities
            to reduce the likelihood of compromise by known threat actors.
        </Text>
        <Text>
            Attaxion Uses CISA&apos;s KEV catalog as one of the sources of
            information about exploited and known to be used in ransomware
            campaigns vulnerabilities.
        </Text>
        <Text>
            Reference:{' '}
            <ExternalLink href="https://www.cisa.gov/known-exploited-vulnerabilities-catalog">
                https://www.cisa.gov/known-exploited-vulnerabilities-catalog
            </ExternalLink>
        </Text>
    </Stack>
);

const KevPost = () => (
    <KnowledgeBasePost
        title="Known Exploited Vulnerabilities Catalog (KEV)"
        content={<Content />}
        modalSize="800px"
        actionIconSize={IconSize.SMALL}
    />
);

export default KevPost;
