import React, { memo, Suspense } from 'react';
import { Alert, Box, Button, Group, Modal, Space, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useCreateJiraTicket } from 'entities/Vulnerability';
import { Issue } from 'entities/Issue';
import { showNotification } from '@mantine/notifications';
import { Icon, Icons, IconSize } from 'shared/ui/Icon';
import { ControlJiraSettings } from 'features/integrations/JiraSettings';
import { Link } from 'react-router-dom';
import { useJiraSettings } from 'entities/Integrations';

export interface CreateJiraTicketModalProps {
    issue: Issue;
}

const CreateJiraTicketModal = (props: CreateJiraTicketModalProps) => {
    const { issue } = props;
    const [opened, { open, close }] = useDisclosure(false);
    const [createJiraTicket, { isLoading }] = useCreateJiraTicket();
    const { hasJiraSettings } = useJiraSettings();

    const onConfirm = () => {
        createJiraTicket(issue.id)
            .unwrap()
            .then(() => {
                showNotification({
                    title: 'Creating Jira ticket...',
                    message: 'Jira ticket has been created',
                    color: 'green'
                });
                close();
            })
            .catch(() => {
                showNotification({
                    title: 'Creating Jira ticket...',
                    message: 'Failed to create Jira ticket',
                    color: 'red'
                });
            });
    };

    return (
        <>
            {issue.jiraIssueUrl && (
                <Button
                    to={issue.jiraIssueUrl}
                    component={Link}
                    target="_blank"
                    size="xs"
                    variant="outline"
                    color="asm"
                    leftSection={<Icon icon={Icons.OPEN_IN_NEW} />}
                >
                    View Jira ticket
                </Button>
            )}
            {!issue.jiraIssueUrl && (
                <>
                    <Modal
                        size="lg"
                        opened={opened}
                        title="Create Jira ticket"
                        onClose={close}
                    >
                        {!hasJiraSettings && (
                            <Alert
                                variant="light"
                                color="orange"
                                title="Jira integration"
                                icon={
                                    <Icon
                                        icon={Icons.WARNING}
                                        size={IconSize.MEDIUM}
                                    />
                                }
                            >
                                Jira integration is not configured, please
                                configure it in order to create Jira tickets.
                            </Alert>
                        )}
                        {hasJiraSettings && (
                            <Text>
                                You are about to create a Jira ticket for the
                                following issue: <b>{issue.name}</b>
                            </Text>
                        )}
                        <Space h="lg" />
                        <Group justify="flex-end">
                            <Box mr="auto">
                                <Suspense fallback="">
                                    <ControlJiraSettings />
                                </Suspense>
                            </Box>
                            <Button
                                onClick={close}
                                variant="outline"
                                color="asm"
                            >
                                Cancel
                            </Button>
                            <Button
                                color="asm"
                                loading={isLoading}
                                disabled={!hasJiraSettings}
                                onClick={onConfirm}
                            >
                                I&apos;m sure, create
                            </Button>
                        </Group>
                    </Modal>
                    <Button
                        size="xs"
                        variant="outline"
                        color="asm"
                        leftSection={<Icon icon={Icons.BUILD} />}
                        onClick={open}
                    >
                        Create Jira ticket
                    </Button>
                </>
            )}
        </>
    );
};

export default memo(CreateJiraTicketModal);
