import React, { memo, useMemo } from 'react';
import { Icon, Icons } from 'shared/ui/Icon';
import { SslCertificate } from 'entities/Asset/model/types/metadata';
import { getDifferenceBetweenDatesInDays } from 'shared/lib/date';
import { Badge } from '@mantine/core';

interface AssetHealthBadgeProps {
    sslCertificate?: SslCertificate;
    fullWidth?: boolean;
}

const AssetSslStatusBadge = (props: AssetHealthBadgeProps) => {
    const { sslCertificate, fullWidth = false } = props;
    const { status } = sslCertificate || {};
    const color = useMemo(() => {
        switch (status) {
            case 'VALID':
                return 'green';
            case 'NOT VALID YET':
            case 'EXPIRES':
                return 'yellow';
            case 'EXPIRED':
            case 'REVOKED':
                return 'red';
            default:
                return 'gray';
        }
    }, [status]);
    const icon = useMemo(() => {
        switch (status) {
            case 'VALID':
                return Icons.VERIFIED_USER;
            case 'EXPIRED':
                return Icons.WARNING;
            case 'NOT VALID YET':
                return Icons.PENDING;
            case 'EXPIRES':
                return Icons.WARNING;
            case 'REVOKED':
                return Icons.WARNING;
            default:
                return Icons.WARNING;
        }
    }, [status]);

    if (!sslCertificate) return null;

    return (
        <Badge
            leftSection={<Icon icon={icon} />}
            fullWidth={fullWidth}
            color={color}
        >
            {status}
        </Badge>
    );
};

export default memo(AssetSslStatusBadge);
