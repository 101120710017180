import { RootAsset } from '../types/asset';

interface UseRootAssetStatus {
    deleting: boolean;
    deleted: boolean;
    verifying: boolean;
    verified: boolean;
}

export function useRootAssetStatus(asset: RootAsset): UseRootAssetStatus {
    return {
        deleting: asset.tags.some(
            tag => tag.isSystem && tag.name === 'deleting'
        ),
        deleted: asset.tags.some(tag => tag.isSystem && tag.name === 'deleted'),
        verifying: asset.tags.some(
            tag => tag.isSystem && tag.name === 'verifying'
        ),
        verified: asset.tags.some(
            tag => tag.isSystem && tag.name === 'verified'
        )
    };
}
