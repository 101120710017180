import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Asset } from 'entities/Asset';
import { SetAssetFalsePositiveSchema } from '../../types/setAssetFalsePositiveSchema';

const initialState: SetAssetFalsePositiveSchema = {
    asset: undefined
};

export const setAssetFalsePositiveSlice = createSlice({
    name: 'setAssetFalsePositive',
    initialState,
    reducers: {
        reset: () => initialState,
        setAsset: (state, action: PayloadAction<Asset>) => {
            state.asset = action.payload;
        }
    }
});

export const { actions: setAssetFalsePositiveActions } =
    setAssetFalsePositiveSlice;
export const { reducer: setAssetFalsePositiveReducer } =
    setAssetFalsePositiveSlice;
