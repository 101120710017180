import React, { memo } from 'react';
import classNames from 'classnames';
import styles from './Button.module.scss';

export enum ButtonTheme {
    CLEAR = 'clear',
    PRIMARY = 'primary',
    OUTLINE = 'outline',
    ICON = 'icon'
}

export enum ButtonSize {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large'
}

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    theme?: ButtonTheme;
    size?: ButtonSize;
    className?: string;
    children?: React.ReactNode;
    success?: boolean;
    disabled?: boolean;
    danger?: boolean;
    warning?: boolean;
}

function Button(props: ButtonProps) {
    const {
        theme = ButtonTheme.OUTLINE,
        size = ButtonSize.MEDIUM,
        className,
        success,
        disabled,
        danger = false,
        warning = false,
        children,
        ...rest
    } = props;
    const classes = classNames(
        styles.Button,
        {
            [styles.success]: success,
            [styles.disabled]: disabled,
            [styles.danger]: danger,
            [styles.warning]: warning
        },
        [className, styles[theme]],
        styles[size]
    );

    return (
        <button type="button" className={classes} disabled={disabled} {...rest}>
            {children}
        </button>
    );
}

export default memo(Button);
