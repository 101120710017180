import { memo, useMemo } from 'react';
import classNames from 'classnames';
import { Badge } from 'shared/ui/Badge';
import { NavLink } from 'react-router-dom';
import { numberFormat } from 'shared/lib/formatter/formatter';
import { Icon, Icons, IconSize } from 'shared/ui/Icon';
import styles from './PageChildrenLink.module.scss';

export interface PageChildrenLinkProps {
    className?: string;
    title: string;
    to?: string;
    hasPreviewNumber?: boolean;
    icon?: Icons;
    previewNumber?: number;
    previewNumberLoading?: boolean;
    disabled?: boolean;
    active?: boolean;
    onClick?: () => void;
}

const PageChildrenLink = (props: PageChildrenLinkProps) => {
    const {
        className,
        title,
        to,
        hasPreviewNumber = true,
        icon,
        previewNumber,
        previewNumberLoading,
        disabled = false,
        active = false,
        onClick
    } = props;

    const navLinkClassName = ({ isActive }: { isActive: boolean }): string =>
        classNames(styles.PageChildrenLink, className, {
            [styles['PageChildrenLink--active']]: isActive,
            [styles['PageChildrenLink--disabled']]: disabled
        });

    const LinkBody = () =>
        useMemo(
            () => (
                <>
                    {icon && (
                        <Icon
                            icon={icon}
                            size={IconSize.MEDIUM}
                            className={styles.PageChildrenLink__Icon}
                        />
                    )}
                    <div className={styles.PageChildrenLink__Title}>
                        {title}
                    </div>
                    {hasPreviewNumber && (
                        <Badge
                            className={styles.PageChildrenLink__Badge}
                            label={
                                previewNumberLoading
                                    ? '...'
                                    : previewNumber
                                    ? numberFormat(previewNumber)
                                    : 'N/A'
                            }
                        />
                    )}
                </>
            ),
            []
        );

    if (!to) {
        return (
            <div
                className={navLinkClassName({ isActive: active })}
                onClick={onClick}
            >
                <LinkBody />
            </div>
        );
    }

    return (
        <NavLink to={to} className={navLinkClassName}>
            <LinkBody />
        </NavLink>
    );
};

export default memo(PageChildrenLink);
