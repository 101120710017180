import { memo, useCallback } from 'react';
import { Tooltip } from '@mantine/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { formatDate, formatDateToUTC } from 'shared/lib/date';

dayjs.extend(relativeTime);
dayjs.extend(utc);

interface DateColumnProps {
    date: string | Date | number | null | undefined;
    timeAgo?: boolean;
    diffDays?: number;
}

const DateColumn = (props: DateColumnProps) => {
    const { date, diffDays = 7, timeAgo = false } = props;
    const renderDate = useCallback(() => {
        if (!date) return '-';

        if (timeAgo) {
            const diffInDays = dayjs().diff(dayjs(date), 'day');
            if (diffInDays < diffDays) return dayjs(date).fromNow();
        }

        return formatDate(date);
    }, [timeAgo, date, diffDays]);

    if (!date) return '-';
    return (
        <Tooltip label={formatDateToUTC(date)}>
            <span>{renderDate()}</span>
        </Tooltip>
    );
};

export default memo(DateColumn);
