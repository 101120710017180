import { rtkApi } from 'shared/api/rtkApi';
import dayjs from 'dayjs';

const downloadApi = rtkApi.injectEndpoints({
    endpoints: builder => ({
        downloadQueryAsFile: builder.mutation({
            queryFn: async ({ path, name }, api, extraOptions, baseQuery) => {
                const result = await baseQuery({
                    url: path,
                    headers: {
                        Accept: 'text/csv'
                    },
                    // @ts-ignore
                    responseHandler: async response => {
                        const data = await response.text();
                        const contentDisposition =
                            response.headers.get('content-disposition') || '';
                        const filenameMatch =
                            contentDisposition.match(/filename="(.+)"/);
                        const filename = filenameMatch
                            ? filenameMatch[1]
                            : 'export.csv';
                        const date = dayjs().format('YYYY-MM-DD_HH-mm-ss');
                        return {
                            name: `Attaxion_${date}_${filename}`,
                            content: data
                        };
                    }
                });

                const hiddenElement = document.createElement('a');
                const url = window.URL || window.webkitURL;
                const blob = new Blob([result.data.content], {
                    type: 'text/csv;charset=utf-8'
                });
                const csvUrl = url.createObjectURL(blob);

                hiddenElement.href = csvUrl;
                hiddenElement.target = '_blank';
                hiddenElement.download = result.data.name;
                hiddenElement.click();
                url.revokeObjectURL(csvUrl);

                return { data: null };
            }
        })
    })
});

export const { useDownloadQueryAsFileMutation } = downloadApi;
