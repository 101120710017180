import React from 'react';
import { Button, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import ContactUsForm from '../ContactUsForm/ContactUsForm';

interface ContactUsModalProps {
    ctaText?: string;
    inlined?: boolean;
}

const ContactUsModal = (props: ContactUsModalProps) => {
    const { ctaText = 'Contact us', inlined } = props;
    const [opened, { open, close }] = useDisclosure(false);

    return (
        <>
            <Modal size="lg" title="Contact Us" opened={opened} onClose={close}>
                <ContactUsForm onPost={close} />
            </Modal>

            {inlined ? (
                <span className="link clickable" onClick={open}>
                    {ctaText}
                </span>
            ) : (
                <Button variant="default" onClick={open}>
                    {ctaText}
                </Button>
            )}
        </>
    );
};

export default ContactUsModal;
