import React, { useCallback, useEffect } from 'react';
import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { useSelector } from 'react-redux';
import { useInjectReducer } from 'shared/hooks/useInjectReducer';
import VerifyRootAssetForm from '../VerifyRootAssetForm/VerifyRootAssetForm';
import {
    verifyRootAssetActions,
    verifyRootAssetReducer
} from '../../model/slices/verifyRootAssetSlice/verifyRootAssetSlice';
import { getAsset } from '../../model/selectors/getAsset/getAsset';

const VerifyRootAssetModal = () => {
    useInjectReducer('verifyRootAsset', verifyRootAssetReducer);

    const [opened, { open, close }] = useDisclosure(false);
    const dispatch = useAppDispatch();
    const asset = useSelector(getAsset);

    useEffect(() => {
        if (!asset) close();
        if (asset) {
            open();
        }
    }, [asset, close, open]);

    const onClose = useCallback(() => {
        close();
        setTimeout(() => {
            dispatch(verifyRootAssetActions.reset());
        }, 500);
    }, [close, dispatch]);

    return (
        <Modal
            size="xl"
            title={`Verify root asset: ${asset?.value}`}
            opened={opened}
            onClose={onClose}
        >
            {asset && <VerifyRootAssetForm asset={asset} />}
        </Modal>
    );
};

export default VerifyRootAssetModal;
