import { severityRatingColor } from '../severityRatingColor/severityRatingColor';

export function severitySegmentColor(scoreSegment: string) {
    switch (scoreSegment) {
        case '0':
            return severityRatingColor('info');
        case '0-1':
        case '1-2':
        case '2-3':
        case '3-4':
            return severityRatingColor('low');
        case '4-5':
        case '5-6':
        case '6-7':
            return severityRatingColor('medium');
        case '7-8':
        case '8-9':
            return severityRatingColor('high');
        case '9-10':
        case '10':
            return severityRatingColor('critical');
        default:
            return severityRatingColor(undefined);
    }
}
