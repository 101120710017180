import React, { useCallback } from 'react';
import classNames from 'classnames';
import { BackButton, CopyButton } from 'shared/ui/Button';
import { ActionIcon, Button, Group } from '@mantine/core';
import { Icon, Icons, IconSize } from 'shared/ui/Icon';
import { IssueDetailsPageHeaderActions } from 'pages/Issues';
import { AssetDetailsPageHeaderActions } from 'pages/Assets';
import DemoGuard from 'shared/guards/DemoGuard/DemoGuard';
import styles from '../StackedDrawer/StackedDrawer.module.scss';
import {
    NavigationPage,
    NavigationPageType
} from '../../model/types/navigation';

interface StackedDrawerHeaderProps {
    pageIndex: number;
    page: NavigationPage;
    previousPage: NavigationPage | null;
    onBack: (pageIndex: number) => void;
    onClose: () => void;
}

const StackedDrawerHeader = (props: StackedDrawerHeaderProps) => {
    const { pageIndex, page, previousPage, onBack, onClose } = props;
    const hasBack = pageIndex > 0;

    const renderActions = useCallback(() => {
        switch (page.pageType) {
            case NavigationPageType.ROOT_ASSET_CANDIDATE_DETAILS:
            case NavigationPageType.ROOT_ASSET_DETAILS:
            case NavigationPageType.ASSET_DETAILS:
                return <AssetDetailsPageHeaderActions id={page.pageID} />;
            case NavigationPageType.ISSUE_DETAILS:
                return <IssueDetailsPageHeaderActions id={page.pageID} />;
            case NavigationPageType.TECHNOLOGY_DETAILS:
            default:
                return null;
        }
    }, [page.pageID, page.pageType]);

    return (
        <div
            className={classNames(styles.StackedDrawer__Content__Header, {
                [styles.StackedDrawer__Content__Header__HasBack]: hasBack
            })}
        >
            {pageIndex > 0 && (
                <BackButton
                    className={styles.StackedDrawer__Content__Header__Back}
                    backPageTitle={previousPage?.pageTitle}
                    onBack={() => onBack(pageIndex)}
                />
            )}
            <Group gap="xs">
                <Button.Group>
                    <DemoGuard>{renderActions()}</DemoGuard>
                    <CopyButton
                        label="Copy link"
                        value={page.pageLink}
                        buttonProps={{
                            size: 'xs',
                            variant: 'outline',
                            leftSection: <Icon icon={Icons.CONTENT_COPY} />
                        }}
                    />
                </Button.Group>
                <ActionIcon variant="transparent" size="lg" onClick={onClose}>
                    <Icon icon={Icons.CLOSE} size={IconSize.MEDIUM} />
                </ActionIcon>
            </Group>
        </div>
    );
};

export default StackedDrawerHeader;
