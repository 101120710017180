import React, { memo, useMemo } from 'react';
import { Badge, BadgeProps } from '@mantine/core';
import { Misconfiguration } from 'entities/Misconfiguration';
import { Vulnerability } from 'entities/Vulnerability';
import { Icon, Icons } from 'shared/ui/Icon';
import { Issue, StatusId, StatusIcon } from '../../../model/types/issue';
import { statusColor } from '../../../model/lib/statusColor/statusColor';

interface RiskBadgeProps extends BadgeProps {
    issue: Issue | Vulnerability | Misconfiguration;
    selectedStatuses?: StatusId[];
    setSelectedStatuses?: (status: StatusId[]) => void;
}

const StatusBadge = (props: RiskBadgeProps) => {
    const { issue, selectedStatuses, setSelectedStatuses, ...badgeProps } =
        props;
    const { status } = issue;
    const color = useMemo(() => statusColor(status.id), [status]);
    const icon = StatusIcon[status.id] as unknown as Icons | undefined;

    if (!status) return null;

    return (
        <Badge
            color={color}
            leftSection={icon && <Icon icon={icon} />}
            {...badgeProps}
        >
            {status.name}
        </Badge>
    );
};

export default memo(StatusBadge);
