import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Asset, RootAsset, RootAssetCandidate } from 'entities/Asset';
import { EntityCommentSchema } from '../../types/entityCommentSchema';

const initialState: EntityCommentSchema = {
    entity: null
};

type Entity = Asset | RootAsset | RootAssetCandidate;

export const entityCommentSlice = createSlice({
    name: 'entityComment',
    initialState,
    reducers: {
        reset: () => initialState,
        setEntity: (state, action: PayloadAction<Entity>) => {
            state.entity = action.payload;
        }
    }
});

export const { actions: entityCommentActions } = entityCommentSlice;
export const { reducer: entityCommentReducer } = entityCommentSlice;
