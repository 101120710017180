import { Asset, AssetQueryParams, RootAsset } from 'entities/Asset';
import { DataTableColumn } from 'mantine-datatable';
import React from 'react';
import { BaseColumnProps } from 'widgets/Columns/baseColumn';
import { VulnerabilityStats } from 'widgets/Issue';

type RecordType = Asset | RootAsset;

export default ({
    query,
    setQuery,
    column
}: BaseColumnProps<
    RecordType,
    AssetQueryParams
>): DataTableColumn<RecordType> => ({
    width: 260,
    accessor: 'vulnerabilityStats',
    title: 'Issues',
    sortable: true,
    render: ({ vulnerabilityStats, rootVulnerabilityStats }) => (
        <VulnerabilityStats
            stats={rootVulnerabilityStats || vulnerabilityStats}
        />
    ),
    ...column
});
