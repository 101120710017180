import { memo } from 'react';
import { GeoIP } from 'entities/Asset/model/types/metadata';

interface GeolocationAddressProps {
    geolocation?: GeoIP;
}

const GeolocationAddress = (props: GeolocationAddressProps) => {
    const { geolocation } = props;

    return (
        [geolocation?.city, geolocation?.region, geolocation?.country]
            .filter(Boolean)
            .join(', ') || 'N/A'
    );
};

export default memo(GeolocationAddress);
