import React, { memo } from 'react';
import { rootAssetCandidateDetailsRoute } from 'app/providers/RouteProvider';
import { NavigationLink, NavigationPageType } from 'entities/Navigation';
import { RootAssetCandidate } from '../../../model/types/asset';

interface AssetCandidateLinkProps {
    asset: RootAssetCandidate;
    children: React.ReactNode;
}

const AssetCandidateLink = (props: AssetCandidateLinkProps) => {
    const { asset, children } = props;
    const { id, value } = asset;

    return (
        <NavigationLink
            pageTitle={value}
            pageLink={rootAssetCandidateDetailsRoute(id)}
            pageType={NavigationPageType.ROOT_ASSET_CANDIDATE_DETAILS}
            pageID={id}
        >
            {children}
        </NavigationLink>
    );
};

export default memo(AssetCandidateLink);
