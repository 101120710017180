import React, { memo } from 'react';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { RootAsset, RootAssetLink, useRootAssetStatus } from 'entities/Asset';
import { ActionIcon, Menu } from '@mantine/core';
import { Icon, Icons } from 'shared/ui/Icon';
import { VerifyAssetButton } from 'features/asset/actions/VerifyRootAsset';
import { removeRootAssetActions } from 'features/asset/actions/RemoveRootAsset';

interface RootAssetActionsMenuProps {
    asset: RootAsset;
}

const RootAssetActionsMenu = (props: RootAssetActionsMenuProps) => {
    const dispatch = useAppDispatch();
    const { asset } = props;
    const { deleting, deleted } = useRootAssetStatus(asset);

    if (deleting || deleted) {
        return null;
    }

    return (
        <Menu shadow="md" width={200}>
            <Menu.Target>
                <ActionIcon variant="default">
                    <Icon icon={Icons.MORE_VERT} />
                </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Label>Root Asset</Menu.Label>
                <Menu.Item leftSection={<Icon icon={Icons.SETTINGS} />}>
                    <RootAssetLink asset={asset}>Details</RootAssetLink>
                </Menu.Item>
                <VerifyAssetButton asset={asset} isMenuItemButton />

                <Menu.Divider />

                <Menu.Label>Danger zone</Menu.Label>
                <Menu.Item
                    color="red"
                    leftSection={<Icon icon={Icons.DELETE} />}
                    onClick={() =>
                        dispatch(removeRootAssetActions.setAsset(asset))
                    }
                >
                    Remove root asset
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
};

export default memo(RootAssetActionsMenu);
