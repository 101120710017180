import dayjs, { Dayjs } from 'dayjs';

export function dateFormat(
    date: Date | string | number | null | undefined,
    locale: Intl.LocalesArgument = 'en-GB',
    options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        weekday: 'long'
    },
    utc = true,
    cutUTC = false
): string {
    if (!date) return '';

    if (typeof date === 'number') {
        if (date < 10000000000) {
            date *= 1000;
        }
    }

    if (typeof date === 'number' || typeof date === 'string') {
        date = new Date(date);
    }

    if (utc) {
        options.timeZone = 'UTC';
        options.timeZoneName = 'short';
    }

    date = date.toLocaleDateString(locale, options);

    if (utc && cutUTC) {
        date = date.replace(', UTC', '');
    }

    return date;
}

export function dateFormatLib(
    date: Dayjs | Date | string | number | null | undefined,
    format = 'MMMM D, YYYY'
) {
    if (!date) return '';

    if (typeof date === 'number') {
        if (date < 10000000000) {
            date *= 1000;
        }
    }

    if (typeof date === 'number' || typeof date === 'string') {
        date = new Date(date);
    }

    return dayjs(date).format(format);
}

export function timeFormat(
    date: Date,
    locale: Intl.LocalesArgument = 'en-US',
    options: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    }
): string {
    return date.toLocaleTimeString(locale, options);
}

export function numberFormat(
    number: number,
    locale: Intl.LocalesArgument = 'en-US',
    options: Intl.NumberFormatOptions = {}
): string {
    return number.toLocaleString(locale, options);
}
