import { DataTableColumn } from 'mantine-datatable';
import { Asset, AssetQueryParams, PortProduct } from 'entities/Asset';
import { BaseColumnProps } from 'widgets/Columns/baseColumn';
import React from 'react';

type RecordType = Asset;
type QueryParams = AssetQueryParams;

export default ({
    column
}: BaseColumnProps<RecordType, QueryParams>): DataTableColumn<RecordType> => ({
    accessor: 'metadata.port.product',
    title: 'Server',
    render: ({ metadata }) => <PortProduct port={metadata?.port} />,
    ...column
});
