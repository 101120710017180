import { memo } from 'react';
import { Port } from 'entities/Asset/model/types/metadata';
import { uppercaseFirstLetter } from 'shared/lib/string/string';

interface PortProductProps {
    port?: Port;
}

const PortProduct = (props: PortProductProps) => {
    const { port } = props;
    const server = port?.extraInfo?.server || port?.product;

    if (!server) {
        return 'N/A';
    }

    return uppercaseFirstLetter(
        `${port.product || port.extraInfo?.server} ${
            port.version && `(${port.version})`
        }`
    );
};

export default memo(PortProduct);
