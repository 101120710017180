import React, { memo, useMemo, useState } from 'react';
import { DataTableColumn } from 'mantine-datatable';
import { DataTable, DateColumn } from 'shared/ui/DataTable';
import { Asset, AssetLink, useGetAssets } from 'entities/Asset';

interface AssetsFeedTableProps {
    defaultSize?: number;
}

const AssetsFeedTable = (props: AssetsFeedTableProps) => {
    const { defaultSize = 5 } = props;
    const [page, setPage] = useState(1);
    const { data, isLoading, isFetching } = useGetAssets(
        {
            page,
            orderBy: '-createdAt',
            size: defaultSize
        },
        {
            pollingInterval: 60 * 1000
        }
    );
    const { data: assets, pagination } = data ?? {};
    const columns = useMemo<DataTableColumn<Asset>[]>(
        () => [
            {
                accessor: 'createdAt',
                title: 'Discovered',
                render: ({ createdAt }) => (
                    <DateColumn date={createdAt} timeAgo />
                )
            },
            {
                accessor: 'name',
                title: 'Asset Name',
                render: asset => (
                    <AssetLink asset={asset}>{asset.value}</AssetLink>
                )
            }
        ],
        []
    );

    return (
        <DataTable
            defaultColumnProps={{
                width: 'fit-content',
                noWrap: true
            }}
            records={assets}
            fetching={isLoading || isFetching}
            columns={columns}
            totalRecords={pagination?.totalItems ?? 0}
            recordsPerPage={pagination?.size ?? 0}
            page={page}
            onPageChange={setPage}
        />
    );
};

export default memo(AssetsFeedTable);
