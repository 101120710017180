import React, { memo } from 'react';
import { Badge, BadgeProps } from '@mantine/core';
import { Icon, Icons } from 'shared/ui/Icon';
import { numberFormat } from 'shared/lib/formatter/formatter';

interface IssuesCountBadgeProps extends BadgeProps {
    vulnerabilitiesCount?: number;
    showNoIssuesLabel?: boolean;
}

const IssuesCountBadge = (props: IssuesCountBadgeProps) => {
    const {
        vulnerabilitiesCount,
        showNoIssuesLabel = true,
        ...badgeProps
    } = props;

    if (!vulnerabilitiesCount && showNoIssuesLabel) {
        return (
            <Badge
                color="green"
                leftSection={<Icon icon={Icons.VERIFIED_USER} />}
                {...badgeProps}
            >
                No issues detected
            </Badge>
        );
    }

    if (!vulnerabilitiesCount) {
        return null;
    }

    return (
        <Badge
            color="red"
            leftSection={<Icon icon={Icons.WARNING} />}
            {...badgeProps}
        >
            Issues detected: {numberFormat(vulnerabilitiesCount)}
        </Badge>
    );
};

export default memo(IssuesCountBadge);
