import { useContext, useEffect, useState } from 'react';
import { useInitialEffect } from 'shared/hooks/useInitialEffect/useInitialEffect';
import { initUser, useUser } from 'entities/User';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { InitContext } from '../InitContext';

interface UseInitUser {
    reinitUser: () => void;
}

function useReInitUser(): UseInitUser {
    const { setHasUser } = useContext(InitContext);
    const dispatch = useAppDispatch();

    const reinitUser = () => {
        dispatch(initUser())
            .unwrap()
            .then(result => {
                setHasUser(Boolean(result));
            });
    };

    return {
        reinitUser
    };
}

export default useReInitUser;
