import { memo, useCallback, useEffect } from 'react';
import { useDeclineAssetCandidates } from 'entities/Asset';
import { Button, Group, Modal, Space, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { useSelector } from 'react-redux';
import { useInjectReducer } from 'shared/hooks/useInjectReducer';
import {
    declineAssetCandidateActions,
    declineAssetCandidateReducer
} from 'features/asset/actions/DeclineAssetCandidate';
import { showNotification } from '@mantine/notifications';
import { getAssets } from '../../model/selectors/getAssets/getAssets';

const DeclineAssetCandidateModal = () => {
    useInjectReducer('declineAssetCandidate', declineAssetCandidateReducer);

    const [opened, { open, close }] = useDisclosure(false);

    const dispatch = useAppDispatch();
    const assets = useSelector(getAssets);
    const [declineAssetCandidate, { isLoading }] = useDeclineAssetCandidates();

    useEffect(() => {
        if (assets === undefined) return;
        if (assets.length === 0) close();
        if (assets.length > 0) open();
    }, [assets, close, open]);

    const onDecline = useCallback(() => {
        if (assets === undefined) return;
        const ids = assets.map(asset => asset.id);
        declineAssetCandidate(ids).then(() => {
            dispatch(declineAssetCandidateActions.reset());
            showNotification({
                title: 'Successfully declined',
                message: `${assets.length} asset candidates have been declined.`,
                color: 'green'
            });
        });
    }, [declineAssetCandidate, assets, dispatch]);

    const onClose = useCallback(() => {
        dispatch(declineAssetCandidateActions.reset());
    }, [dispatch]);

    return (
        <Modal
            size="lg"
            opened={opened}
            title="Decline asset candidates"
            onClose={close}
        >
            {assets?.length === 1 && (
                <Text size="sm">
                    Are you sure you want to decline asset candidate{' '}
                    <b>{assets[0].value}</b>? Declining this asset candidate
                    will remove it from the list of candidates. We will ignore
                    this asset in the future.
                </Text>
            )}
            {assets && assets?.length > 1 && (
                <Text size="sm">
                    Are you sure you want to decline selected{' '}
                    <b>{assets.length} asset candidates</b>? Declining these
                    asset candidates will remove them from the list of
                    candidates. We will ignore these assets in the future.
                </Text>
            )}
            <Space h="md" />
            <Group justify="flex-end">
                <Button onClick={onClose} variant="outline" color="asm">
                    Cancel
                </Button>
                <Button color="red.9" loading={isLoading} onClick={onDecline}>
                    I&apos;m sure, decline
                </Button>
            </Group>
        </Modal>
    );
};

export default memo(DeclineAssetCandidateModal);
