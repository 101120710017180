import { DataTableColumn } from 'mantine-datatable';
import { BaseColumnProps, updateQuery } from 'widgets/Columns/baseColumn';
import React from 'react';
import { FilterByAssetTagForm } from 'features/asset/filters/FilterByAssetTag';
import { TagGroup } from 'entities/Tag';
import { Vulnerability } from 'entities/Vulnerability';
import {
    Misconfiguration,
    MisconfigurationQueryParams
} from 'entities/Misconfiguration';

type RecordType = Vulnerability | Misconfiguration;
type QueryParams = MisconfigurationQueryParams;

export default ({
    query = {},
    setQuery,
    column
}: BaseColumnProps<RecordType, QueryParams>): DataTableColumn<RecordType> => {
    const { assetTag = [] } = query;
    return {
        accessor: 'tags',
        title: 'Tags',
        filtering: assetTag.length > 0,
        filter: (
            <FilterByAssetTagForm
                selectedTags={assetTag}
                onChange={assetTag =>
                    updateQuery({ assetTag }, query, setQuery)
                }
            />
        ),
        render: record => (
            <TagGroup
                tags={record.asset.tags}
                selectedTags={assetTag}
                setSelectedTags={assetTag =>
                    updateQuery({ assetTag }, query, setQuery)
                }
            />
        ),
        ...column
    };
};
