import { rtkApi } from 'shared/api/rtkApi';
import { ApiResponse } from 'shared/types/pagination';
import { dateFormat } from 'shared/lib/formatter/formatter';
import { PaginationQueryParams } from 'entities/Asset';
import { Category, Technology } from '../../model/types/technology';

export interface TechnologiesQueryParams extends PaginationQueryParams {
    technologyId?: string;
    orderBy?: string;
    assetID?: string[];
    rootAssetID?: string[];
    nameIncludes?: string;
    category?: string[];
    group?: string[];
    lastSeenAtGte?: number;
    lastSeenAtLte?: number;
    createdAtGte?: number;
    createdAtLte?: number;
    vulnerabilitiesCount?: number;
    isAssetFalsePositive?: boolean;
}

const technologysApi = rtkApi.injectEndpoints({
    endpoints: builder => ({
        getTechnologies: builder.query<
            ApiResponse<Technology>,
            TechnologiesQueryParams
        >({
            query: params => ({
                url: '/techs',
                params: {
                    orderBy: 'name',
                    ...params
                }
            })
        }),
        getTechnology: builder.query<Technology, string>({
            query: id => `/techs/${id}`,
            transformResponse: (technology: Technology) => ({
                ...technology,
                createdAtParsed: dateFormat(technology.createdAt),
                lastSeenAtParsed: dateFormat(technology.lastSeenAt)
            })
        }),
        getTechnologyCategories: builder.query<ApiResponse<Category>, void>({
            query: () => '/dictionaries/tech-categories'
        })
    })
});

export const useGetTechnologies = technologysApi.useGetTechnologiesQuery;
export const useGetTechnology = technologysApi.useGetTechnologyQuery;
export const useGetTechnologyCategories =
    technologysApi.useGetTechnologyCategoriesQuery;
