import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    DataTable as MantineDataTable,
    DataTableColumn as MantineDataTableColumn,
    DataTableProps as MantineDataTableProps,
    DataTableSortStatus as MantineDataTableSortStatus
} from 'mantine-datatable';
import { ApiResponse } from 'shared/types/pagination';
import { Alert, Box, Button, Divider, Group, Stack, Text } from '@mantine/core';
import { DownloadQueryButton } from 'features/common/actions/DownloadQuery';
import HeaderedCard from 'shared/ui/HeaderedCard/HeaderedCard';
import { Icon, Icons, IconSize } from 'shared/ui/Icon';

type QueryParams = Record<string, any>;

export type GenericTableProps<T> = MantineDataTableProps<T> & {
    /* useData: (
        arg?: QueryParams
    ) => TypedUseQueryHookResult<ApiResponse<T>, QueryParams, BaseQueryFn>; */
    useData: (
        arg?: QueryParams,
        options?: any
    ) => {
        data: ApiResponse<T> | undefined;
        isFetching: boolean;
        isLoading: boolean;
        refetch: () => void;
    };
    columns: MantineDataTableColumn<T>[];
    defaultSortStatus?: MantineDataTableSortStatus<T>;
    defaultSize?: number;
    queryParams?: QueryParams;
    hasToolbar?: boolean;
    hasPagination?: boolean;
    hasRecordsPerPage?: boolean;
    hasSorting?: boolean;
    hasSelection?: boolean;
    hasDownload?: boolean;
    title?: string;
    toolbarActions?: React.ReactNode;
    selectionActions?: React.ReactNode;
    onSelectionChange?: (selectedRecords: T[]) => void;
};

function GenericTable<T extends object>(props: GenericTableProps<T>) {
    const {
        useData,
        columns,
        shadow = 'lg',
        borderRadius = 'md',
        verticalSpacing = 'sm',
        queryParams = {},
        defaultSortStatus,
        defaultSize = 25,
        hasToolbar = false,
        hasPagination = true,
        hasRecordsPerPage = true,
        hasSorting = true,
        hasSelection = false,
        hasDownload = false,
        title,
        toolbarActions,
        selectionActions,
        onSelectionChange,
        ...rest
    } = props;

    const [page, setPage] = useState(1);
    const [size, setSize] = useState(defaultSize);
    const [sortStatus, setSortStatus] = useState<
        MantineDataTableSortStatus<T> | undefined
    >(defaultSortStatus);
    const [selectedRecords, setSelectedRecords] = useState<T[]>([]);
    const orderBy = useMemo<string | undefined>(() => {
        if (!sortStatus) return undefined;
        const { direction, columnAccessor } = sortStatus;
        if (direction === 'desc') return `-${String(columnAccessor)}`;
        return String(columnAccessor);
    }, [sortStatus]);

    const allQueryParams = useMemo(
        () => ({
            ...queryParams,
            page,
            size,
            orderBy
        }),
        [queryParams, page, size, orderBy]
    );
    const { data, isFetching, isLoading, refetch } = useData(allQueryParams, {
        pollingInterval: 60 * 1000
    });
    const { data: records, pagination, __meta } = data ?? {};
    const hasLockedRecords = useMemo(
        () => records?.some((record: T) => 'locked' in record && record.locked),
        [records]
    );

    useEffect(() => {
        if (page === 1) return;
        setPage(1);
    }, [queryParams, size, orderBy]);

    useEffect(() => {
        setSelectedRecords([]);
    }, [records]);

    const defaultProps = useMemo(
        () => ({
            columns,
            shadow,
            borderRadius,
            verticalSpacing,
            minHeight: records?.length ? 0 : 200,
            withTableBorder: true,
            withColumnBorders: true,
            highlightOnHover: true,
            defaultColumnProps: {
                ellipsis: true
            },
            rowClassName: (record: T) => {
                if ('locked' in record && record.locked) return 'locked';
                return undefined;
            },
            ...rest
        }),
        [shadow, borderRadius, verticalSpacing, records?.length, columns, rest]
    );

    const paginationProps = useMemo(() => {
        if (!hasPagination) return {};

        return {
            page,
            onPageChange: setPage,
            totalRecords: pagination?.totalItems ?? 0,
            recordsPerPage: pagination?.size ?? 0
        };
    }, [hasPagination, page, pagination?.size, pagination?.totalItems]);

    const recordsPerPageProps = useMemo(() => {
        if (!hasRecordsPerPage) return {};

        return {
            recordsPerPageOptions: [25, 50, 100],
            onRecordsPerPageChange: setSize
        };
    }, [hasRecordsPerPage]);

    const sortingProps = useMemo(() => {
        if (!hasSorting || !sortStatus) return {};

        return {
            sortStatus,
            onSortStatusChange: setSortStatus
        };
    }, [hasSorting, sortStatus]);

    const selectionProps = useMemo(() => {
        if (!hasSelection) return {};

        return {
            selectedRecords,
            onSelectedRecordsChange: (selectedRecords: T[]) => {
                setSelectedRecords(selectedRecords);
                onSelectionChange?.(selectedRecords);
            }
        };
    }, [hasSelection, onSelectionChange, selectedRecords]);

    const renderToolbar = useCallback(
        () =>
            hasToolbar && (
                <Group h={42} justify="space-between" wrap="nowrap">
                    <Group>{toolbarActions}</Group>
                    {/* hasLockedRecords && (
                        <Paper
                            c="red.6"
                            bg="var(--mantine-color-red-light)"
                            p="xs"
                            mt="-xs"
                        >
                            <Group>
                                <Icon
                                    icon={Icons.WARNING}
                                    size={IconSize.LARGE}
                                    style={{
                                        fill: 'var(--mantine-color-red-6)'
                                    }}
                                />
                                <Text size="sm" fw={700}>
                                    Subscription&apos;s limit reached.
                                </Text>
                                <Button variant="primary-gradient">
                                    Upgrade
                                </Button>
                            </Group>
                        </Paper>
                    ) */}
                    <Group>
                        <Button
                            color="asm"
                            variant="outline"
                            onClick={refetch}
                            disabled={isLoading || isFetching}
                        >
                            Refresh
                        </Button>
                        {hasDownload && (
                            <DownloadQueryButton
                                path={__meta?.path}
                                disabled={isFetching || isFetching}
                            />
                        )}
                    </Group>
                </Group>
            ),
        [
            hasToolbar,
            toolbarActions,
            refetch,
            isLoading,
            isFetching,
            hasDownload,
            __meta?.path
        ]
    );

    const renderSelectionActions = useCallback(
        () =>
            hasSelection &&
            selectedRecords &&
            Boolean(selectedRecords.length) &&
            selectionActions && (
                <Group
                    style={{
                        zIndex: 3,
                        height: '46px',
                        position: 'absolute',
                        background: '#fff',
                        width: 'calc(100% - 60px)',
                        marginLeft: '42px',
                        top: '1px',
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: 'var(--mantine-spacing-md)'
                    }}
                >
                    <Text size="sm" fw={700}>
                        {selectedRecords.length} selected
                    </Text>
                    <Button
                        size="compact-sm"
                        color="asm"
                        variant="outline"
                        onClick={() => setSelectedRecords([])}
                    >
                        Clear
                    </Button>
                    <Divider orientation="vertical" />
                    {selectionActions}
                </Group>
            ),
        [hasSelection, selectedRecords, selectionActions]
    );

    const renderTable = useCallback(
        () => (
            <MantineDataTable
                records={records ?? []}
                fetching={isLoading || isFetching}
                {...defaultProps}
                {...paginationProps}
                {...recordsPerPageProps}
                {...sortingProps}
                {...selectionProps}
                style={{ maxHeight: '100%' }}
            />
        ),
        [
            defaultProps,
            isFetching,
            isLoading,
            paginationProps,
            records,
            recordsPerPageProps,
            selectionProps,
            sortingProps
        ]
    );

    const renderLockedRecordsWarning = useCallback(() => {
        if (hasLockedRecords) {
            return (
                <Alert
                    w={500}
                    color="red"
                    variant="filled"
                    title="Subscription's limit reached"
                    icon={<Icon icon={Icons.WARNING} size={IconSize.LARGE} />}
                    style={{
                        position: 'absolute',
                        bottom: '60px',
                        right: '10px'
                    }}
                >
                    You have reached the limit of records you can access with
                    your current subscription. Please contact our sales team
                    (sales@attaxion.com) to upgrade your subscription.
                </Alert>
            );
        }

        return null;
    }, [hasLockedRecords]);

    const render = useCallback(() => {
        if (title) {
            return (
                <HeaderedCard
                    header={title}
                    bodyPadding="0"
                    actions={renderToolbar()}
                >
                    <Box style={{ position: 'relative' }}>
                        {renderSelectionActions()}
                        {renderTable()}
                    </Box>
                </HeaderedCard>
            );
        }

        return (
            <Stack h="100%" gap="sm" justify="stretch">
                {renderToolbar()}
                <Box h="100%" style={{ position: 'relative' }}>
                    {renderSelectionActions()}
                    {renderTable()}
                    {renderLockedRecordsWarning()}
                </Box>
            </Stack>
        );
    }, [
        renderLockedRecordsWarning,
        renderSelectionActions,
        renderTable,
        renderToolbar,
        title
    ]);

    return render();
}

export default GenericTable;
