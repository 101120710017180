import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import { Vulnerability } from '../../model/types/vulnerability';
import { useVulnerabilityQueryLink } from '../../hooks/useVulnerabilityQueryLink/useVulnerabilityQueryLink';

interface VulnerabilityLinkProps {
    vulnerability: Vulnerability;
    children?: React.ReactNode;
}

const VulnerabilityNameLink = (props: VulnerabilityLinkProps) => {
    const { vulnerability, children } = props;
    const link = useVulnerabilityQueryLink({
        nameExact: vulnerability.name,
        lastSeenPreset: 'all'
    });

    return <NavLink to={link}>{children || vulnerability.name}</NavLink>;
};

export default memo(VulnerabilityNameLink);
