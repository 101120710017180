import React, { memo, useMemo } from 'react';
import { DataTable } from 'shared/ui/DataTable';
import { DataTableColumn } from 'mantine-datatable';

interface FoundOnPagesTableProps {
    pages: string[];
}

interface Page {
    url: string;
}

const FoundOnPagesTable = (props: FoundOnPagesTableProps) => {
    const { pages } = props;
    const data = useMemo<Page[]>(
        () =>
            pages?.map(page => ({
                url: page
            })),
        [pages]
    );

    const columns = useMemo<DataTableColumn<Page>[]>(
        () => [
            {
                accessor: 'url',
                header: 'Page'
            }
        ],
        []
    );

    return (
        <DataTable
            defaultColumnProps={{
                ellipsis: true
            }}
            noHeader
            records={data}
            columns={columns}
        />
    );
};

export default memo(FoundOnPagesTable);
