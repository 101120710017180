import { Outlet, RouteObject } from 'react-router-dom';
import { DashboardPage } from 'pages/Dashboard';
import { RootAssetsPage } from 'pages/Management/children/RootAssetsPage';
import { AssetCandidatesPage } from 'pages/Management/children/AssetCandidatesPage';
import { AddNewRootAssetPage } from 'pages/Management/children/AddNewRootAssetPage';
import { Navigate } from 'react-router';
import { ErrorEventsPage, InfoEventsPage } from 'pages/Events';
import {
    OverviewPage,
    OpenIssuesPage,
    FixedIssuesPage,
    AcceptedRiskIssuesPage,
    FalsePositiveIssuesPage,
    AllIssuesPage
} from 'pages/Issues';
import {
    AllAssetsPage,
    DomainAssetsPage,
    EmailAssetsPage,
    IPv4AssetsPage,
    IPv6AssetsPage,
    PortAssetsPage,
    SslAssetsPage,
    CidrAssetsPage,
    CloudAssetsPage,
    OrgAssetsPage
} from 'pages/Assets';
import { GraphPage } from 'pages/Graph';
import { FoundTechnologiesPage } from 'pages/Technologies';
import { AccountGeneralPage, AccountBillingPage } from 'pages/Account';
import { ArchivedAssetsPage } from 'pages/Management';

type AppRouteObject = RouteObject & {
    authRequired?: boolean;
    availableForDemo?: boolean;
};

export type AppRouteProps = AppRouteObject;

export enum AppRoutes {
    DASHBOARD = 'dashboard',
    NOT_FOUND = 'notFound',
    LOGIN = 'login',
    SIGNUP = 'signup',
    FORGOT_PASSWORD = 'forgotPassword',
    FORGOT_PASSWORD_CONFIRMATION = 'forgotPasswordConfirmation',
    LOGOUT = 'logout',

    MANAGEMENT = 'management',
    ASSETS = 'assets',
    ISSUES = 'issues',
    GRAPH = 'graph',
    EVENTS = 'events',
    TECHNOLOGIES = 'technologies',

    USER_SERVICE = 'userService',
    ACCOUNT = 'account',
    INTEGRATIONS = 'integrations',
    REPORTS = 'reports'
}

export const RoutePath: Record<AppRoutes, string> = {
    [AppRoutes.DASHBOARD]: '/',
    [AppRoutes.LOGIN]: '/auth/login',
    [AppRoutes.SIGNUP]: '/auth/signup',
    [AppRoutes.FORGOT_PASSWORD]: '/auth/forgot-password',
    [AppRoutes.FORGOT_PASSWORD_CONFIRMATION]:
        '/auth/forgot-password/confirmation',
    [AppRoutes.LOGOUT]: '/auth/logout',

    [AppRoutes.MANAGEMENT]: '/management',
    [AppRoutes.ASSETS]: '/assets',
    [AppRoutes.ISSUES]: '/issues',
    [AppRoutes.GRAPH]: '/graph',
    [AppRoutes.EVENTS]: '/events',
    [AppRoutes.TECHNOLOGIES]: '/technologies',

    [AppRoutes.USER_SERVICE]: '/user-service',
    [AppRoutes.ACCOUNT]: '/account',
    [AppRoutes.INTEGRATIONS]: '/integrations',
    [AppRoutes.REPORTS]: '/reports',

    [AppRoutes.NOT_FOUND]: '*'
};

export const routeConfig: Record<AppRoutes, AppRouteProps> = {
    [AppRoutes.DASHBOARD]: {
        authRequired: true,
        availableForDemo: true,
        path: RoutePath.dashboard,
        element: <Outlet />,
        children: [
            {
                index: true,
                element: <DashboardPage />
            }
        ]
    },
    [AppRoutes.ACCOUNT]: {
        authRequired: true,
        availableForDemo: false,
        path: RoutePath.account,
        children: [
            {
                index: true,
                element: (
                    <Navigate replace to={`${RoutePath.account}/general`} />
                )
            },
            {
                path: 'general',
                element: <AccountGeneralPage />
            },
            {
                path: 'billing',
                element: <AccountBillingPage />
            }
        ]
    },
    [AppRoutes.INTEGRATIONS]: {
        path: RoutePath.integrations,
        authRequired: true,
        children: [
            {
                index: true,
                lazy: async () =>
                    import('pages/Integrations').then(m => ({
                        Component: m.IntegrationsPage
                    }))
            },
            {
                path: 'slack/oauth/callback',
                lazy: async () =>
                    import('pages/Integrations').then(m => ({
                        Component: m.IntegrationsPage
                    }))
            }
        ]
    },
    [AppRoutes.REPORTS]: {
        path: RoutePath.reports,
        authRequired: true,
        children: [
            {
                index: true,
                lazy: async () =>
                    import('pages/Reports').then(m => ({
                        Component: m.ReportsIndexPage
                    }))
            }
        ]
    },
    [AppRoutes.MANAGEMENT]: {
        path: RoutePath.management,
        authRequired: true,
        element: <Outlet />,
        children: [
            {
                index: true,
                element: (
                    <Navigate
                        replace
                        to={`${RoutePath.management}/root-assets`}
                    />
                )
            },
            {
                path: 'root-assets',
                element: <RootAssetsPage />,
                children: [
                    {
                        path: `:id`,
                        element: <RootAssetsPage />
                    }
                ]
            },
            {
                path: 'root-assets/new',
                element: <AddNewRootAssetPage />,
                children: [
                    {
                        path: `:type`,
                        element: <AddNewRootAssetPage />
                    }
                ]
            },
            {
                path: `root-asset-candidates`,
                element: <AssetCandidatesPage />,
                children: [
                    {
                        path: `:id`,
                        element: <AssetCandidatesPage />
                    }
                ]
            },
            {
                path: `archived-assets`,
                element: <ArchivedAssetsPage />,
                children: [
                    {
                        path: `:id`,
                        element: <ArchivedAssetsPage />
                    }
                ]
            }
        ]
    },
    [AppRoutes.ASSETS]: {
        path: RoutePath.assets,
        authRequired: true,
        element: <Outlet />,
        children: [
            {
                index: true,
                element: <Navigate replace to={`${RoutePath.assets}/all`} />
            },
            {
                path: 'closed-port',
                element: <Navigate replace to={`${RoutePath.assets}/all`} />
            },
            {
                path: 'all',
                element: <AllAssetsPage />,
                children: [
                    {
                        path: `:id`,
                        element: <AllAssetsPage />
                    }
                ]
            },
            {
                path: 'domain',
                element: <DomainAssetsPage />,
                children: [
                    {
                        path: `:id`,
                        element: <DomainAssetsPage />
                    }
                ]
            },
            {
                path: 'ipv4',
                element: <IPv4AssetsPage />,
                children: [
                    {
                        path: `:id`,
                        element: <IPv4AssetsPage />
                    }
                ]
            },
            {
                path: 'ipv6',
                element: <IPv6AssetsPage />,
                children: [
                    {
                        path: `:id`,
                        element: <IPv6AssetsPage />
                    }
                ]
            },
            {
                path: 'ssl',
                element: <SslAssetsPage />,
                children: [
                    {
                        path: `:id`,
                        element: <SslAssetsPage />
                    }
                ]
            },
            {
                path: 'port',
                element: <PortAssetsPage />,
                children: [
                    {
                        path: `:id`,
                        element: <PortAssetsPage />
                    }
                ]
            },
            {
                path: 'cidr',
                element: <CidrAssetsPage />,
                children: [
                    {
                        path: `:id`,
                        element: <CidrAssetsPage />
                    }
                ]
            },
            {
                path: 'email',
                element: <EmailAssetsPage />,
                children: [
                    {
                        path: `:id`,
                        element: <EmailAssetsPage />
                    }
                ]
            },
            {
                path: 'cloud',
                element: <CloudAssetsPage />,
                children: [
                    {
                        path: `:id`,
                        element: <CloudAssetsPage />
                    }
                ]
            },
            {
                path: 'org',
                element: <OrgAssetsPage />,
                children: [
                    {
                        path: `:id`,
                        element: <OrgAssetsPage />
                    }
                ]
            }
        ]
    },
    [AppRoutes.ISSUES]: {
        path: RoutePath.issues,
        authRequired: true,
        element: <Outlet />,
        children: [
            {
                index: true,
                element: (
                    <Navigate replace to={`${RoutePath.issues}/overview`} />
                )
            },
            {
                path: 'overview',
                element: <OverviewPage />
            },
            {
                path: 'vulnerabilities',
                element: <AllIssuesPage />,
                children: [
                    {
                        path: `:id`,
                        element: <AllIssuesPage />
                    }
                ]
            },
            {
                path: 'all',
                element: <AllIssuesPage />,
                children: [
                    {
                        path: `:id`,
                        element: <AllIssuesPage />
                    }
                ]
            },
            {
                path: 'open',
                element: <OpenIssuesPage />,
                children: [
                    {
                        path: `:id`,
                        element: <OpenIssuesPage />
                    }
                ]
            },
            {
                path: 'fixed',
                element: <FixedIssuesPage />,
                children: [
                    {
                        path: `:id`,
                        element: <FixedIssuesPage />
                    }
                ]
            },
            {
                path: 'accepted-risk',
                element: <AcceptedRiskIssuesPage />,
                children: [
                    {
                        path: `:id`,
                        element: <AcceptedRiskIssuesPage />
                    }
                ]
            },
            {
                path: 'false-positive',
                element: <FalsePositiveIssuesPage />,
                children: [
                    {
                        path: `:id`,
                        element: <FalsePositiveIssuesPage />
                    }
                ]
            }
        ]
    },
    [AppRoutes.GRAPH]: {
        path: RoutePath.graph,
        authRequired: true,
        element: <Outlet />,
        children: [
            {
                index: true,
                element: <GraphPage />
            }
        ]
    },
    [AppRoutes.EVENTS]: {
        path: RoutePath.events,
        authRequired: true,
        element: <Outlet />,
        children: [
            {
                index: true,
                element: <Navigate replace to={`${RoutePath.events}/error`} />
            },
            {
                path: 'error',
                element: <ErrorEventsPage />
            },
            {
                path: 'info',
                element: <InfoEventsPage />
            }
        ]
    },
    [AppRoutes.TECHNOLOGIES]: {
        path: RoutePath.technologies,
        authRequired: true,
        element: <Outlet />,
        children: [
            {
                index: true,
                element: (
                    <Navigate
                        replace
                        to={`${RoutePath.technologies}/detected`}
                    />
                )
            },
            {
                path: 'detected',
                element: <FoundTechnologiesPage />,
                children: [
                    {
                        path: `:id`,
                        element: <FoundTechnologiesPage />
                    }
                ]
            }
        ]
    },
    [AppRoutes.LOGIN]: {
        path: RoutePath.login,
        children: [
            {
                index: true,
                lazy: async () =>
                    import('pages/Auth/children/LoginPage').then(m => ({
                        Component: m.LoginPage
                    }))
            }
        ]
    },
    [AppRoutes.SIGNUP]: {
        availableForDemo: false,
        path: RoutePath.signup,
        children: [
            {
                index: true,
                lazy: async () =>
                    import('pages/Auth/children/SignupPage').then(m => ({
                        Component: m.SignupPage
                    }))
            }
        ]
    },
    [AppRoutes.FORGOT_PASSWORD]: {
        availableForDemo: false,
        path: RoutePath.forgotPassword,
        children: [
            {
                index: true,
                lazy: async () =>
                    import('pages/Auth/children/ForgotPasswordPage').then(
                        m => ({
                            Component: m.ForgotPasswordPage
                        })
                    )
            }
        ]
    },
    [AppRoutes.FORGOT_PASSWORD_CONFIRMATION]: {
        availableForDemo: false,
        path: RoutePath.forgotPasswordConfirmation,
        children: [
            {
                index: true,
                lazy: async () =>
                    import(
                        'pages/Auth/children/ForgotPasswordConfirmationPage'
                    ).then(m => ({
                        Component: m.ForgotPasswordConfirmationPage
                    }))
            }
        ]
    },
    [AppRoutes.LOGOUT]: {
        path: RoutePath.logout,
        children: [
            {
                index: true,
                lazy: async () =>
                    import('pages/Auth/children/LogoutPage').then(m => ({
                        Component: m.LogoutPage
                    }))
            }
        ]
    },
    [AppRoutes.USER_SERVICE]: {
        path: RoutePath.userService,
        children: [
            {
                index: true,
                lazy: async () =>
                    import('pages/Service').then(m => ({
                        Component: m.ServicePage
                    }))
            },
            {
                path: 'auto-login',
                lazy: async () =>
                    import('pages/Service').then(m => ({
                        Component: m.AutoLoginPage
                    }))
            }
        ]
    },
    [AppRoutes.NOT_FOUND]: {
        path: RoutePath.notFound,
        children: [
            {
                index: true,
                lazy: async () =>
                    import('pages/NotFoundPage').then(m => ({
                        Component: m.NotFoundPage
                    }))
            }
        ]
    }
};
