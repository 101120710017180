import React, { useCallback } from 'react';
import { Anchor, Box, Button, Flex, Textarea, Text } from '@mantine/core';
import { MailDepartment, usePostContactUsMail, MailForm } from 'entities/Mail';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { contactEmail } from 'shared/lib/string/string';
import { SALES_EMAIL } from 'shared/const/emails';
import { useUser } from 'entities/User';
import { parseApiError, showApiErrorNotification } from 'shared/lib/api';

interface ContactUsFormProps {
    onPost: () => void;
}

const DEFAULT_ERROR_MESSAGE = 'Failed to send message. Please try again later.';
const READABLE_ERRORS = {
    429: 'Too many requests. Please try again later.'
};

const ContactUsForm = (props: ContactUsFormProps) => {
    const { onPost } = props;
    const { user } = useUser();
    const [postMail, { isLoading }] = usePostContactUsMail();
    const form = useForm<MailForm>({
        initialValues: {
            userId: user?.id || 0,
            email: user?.email || '',
            message: ''
        }
    });

    const onSubmit = useCallback(
        (values: MailForm) => {
            postMail({
                channel: MailDepartment.SALES,
                message: values.message,
                data: {
                    userId: values.userId,
                    email: values.email
                }
            })
                .unwrap()
                .then(_ => {
                    showNotification({
                        title: 'Success',
                        message:
                            'Your message has been sent. We will get back to you soon.',
                        color: 'green',
                        autoClose: 30 * 1000
                    });
                    form.reset();
                    onPost();
                })
                .catch(error => {
                    const parsedError = parseApiError(error);
                    showApiErrorNotification(
                        parsedError,
                        DEFAULT_ERROR_MESSAGE,
                        READABLE_ERRORS
                    );
                });
        },
        [form, onPost, postMail]
    );

    return (
        <Box w="100%">
            <form id="contact_us_form" onSubmit={form.onSubmit(onSubmit)}>
                <Flex gap="sm" direction="column">
                    <Text size="sm">
                        To contact us, you can send an email to{' '}
                        <Anchor>
                            <a href={contactEmail(SALES_EMAIL)}>
                                {SALES_EMAIL}
                            </a>
                        </Anchor>{' '}
                        or use the box below.
                    </Text>
                    <Textarea
                        required
                        withAsterisk
                        rows={8}
                        minLength={10}
                        maxLength={500}
                        placeholder="Enter your request here"
                        {...form.getInputProps('message')}
                    />
                    <Button
                        type="submit"
                        variant="primary-gradient"
                        loading={isLoading}
                    >
                        Send
                    </Button>
                </Flex>
            </form>
        </Box>
    );
};

export default ContactUsForm;
