import { API_TAGS, rtkApi } from 'shared/api/rtkApi';
import { ApiResponse } from 'shared/types/pagination';
import { dateFormat } from 'shared/lib/formatter/formatter';
import { analyticsActions, AnalyticsEvent } from 'entities/Analytics';
import { ScanMode } from 'entities/Scanner';
import { Graph } from '../../model/types/graph';
import {
    Asset,
    AssetType,
    DeletedAsset,
    RootAsset,
    RootAssetCandidate,
    RootAssetVerification,
    RootAssetVerificationQuery
} from '../../model/types/asset';

export interface PaginationQueryParams {
    page?: number;
    size?: number;
}

export enum WithMetaType {
    GEOIP = 'geoip',
    WHOIS_RECORD = 'whoisRecord',
    SSL_CERTIFICATE = 'sslCertificate',
    PORT = 'port',
    SUBNETWORK = 'subnetwork',
    DNS = 'dns'
}

export interface AssetQueryParams extends PaginationQueryParams {
    valueIncludes?: string;
    valueStartsWith?: string;
    assetType?: AssetType[];
    scanMode?: ScanMode[];
    excludeScanMode?: ScanMode[];
    rootAssetID?: string[];
    techID?: string[];
    tag?: string[];
    excludeTagBySlug?: string[];
    orderBy?: string;
    withMeta?: WithMetaType[];
    lastScannedAtGte?: number;
    lastScannedAtLte?: number;
    lastSeenAtGte?: number;
    lastSeenAtLte?: number;
    lastActiveAtGte?: number;
    lastActiveAtLte?: number;
    createdAtGte?: number;
    createdAtLte?: number;
    isFalsePositive?: boolean;
}

export interface RelatedAssetsQueryParams extends AssetQueryParams {
    assetID: string;
    relationType?: string | string[];
}

export interface DiscoveryPathQueryParams {
    id: string;
    params?: {
        maxDepth?: number;
        excludeCandidates?: 1 | 0;
        excludeFalsePositives?: 1 | 0;
    };
}

export interface AssetCreateBody {
    value: string;
    type: Extract<
        AssetType,
        | AssetType.DOMAIN
        | AssetType.IPV4
        | AssetType.IPV6
        | AssetType.ORGANIZATION
        | AssetType.CIDR
    >;
    comment?: string;
}

export interface AwsAssetCreateBody {
    roleArn: string;
    externalID: string;
}

export interface AzureAssetCreateBody {
    tenantID: string;
    clientID: string;
    subscriptionID: string;
    clientSecret: string;
}

export interface GcpAssetCreateBody {
    type: string;
    project_id: string;
    private_key_id: string;
    private_key: string;
    client_email: string;
    client_id: string;
    auth_uri: string;
    token_uri: string;
    auth_provider_x509_cert_url: string;
    client_x509_cert_url: string;
    universe_domain: string;
}

export interface DigitalOceanAssetCreateBody {
    apiKey: string;
}

export interface AssetUpdateBody {
    id: string;
    comment?: string;
    addTags?: string[];
    removeTags?: string[];
}

const INVALIDATE_ASSETS = (_ = {}, __ = {}, ___ = {}) => [
    API_TAGS.ASSET,
    API_TAGS.ROOT_ASSET_NAMES
];

const assetsApi = rtkApi.injectEndpoints({
    endpoints: builder => ({
        addAsset: builder.mutation<RootAsset, AssetCreateBody>({
            query: body => ({
                url: '/assets',
                method: 'POST',
                body
            }),
            invalidatesTags: INVALIDATE_ASSETS,
            onQueryStarted(
                _,
                { dispatch, queryFulfilled }
            ): Promise<void> | void {
                queryFulfilled
                    .then(({ data: asset }) => {
                        dispatch(
                            analyticsActions.setEvent({
                                event: AnalyticsEvent.ROOT_ASSET_ADDED,
                                payload: {
                                    id: asset.id,
                                    name: asset.value
                                }
                            })
                        );
                    })
                    .catch(() => {});
            }
        }),
        addAwsAsset: builder.mutation<Asset, AwsAssetCreateBody>({
            query: body => ({
                url: '/aws/credentials',
                method: 'POST',
                body
            }),
            invalidatesTags: INVALIDATE_ASSETS,
            onQueryStarted(
                _,
                { dispatch, queryFulfilled }
            ): Promise<void> | void {
                queryFulfilled
                    .then(({ data: asset }) => {
                        dispatch(
                            analyticsActions.setEvent({
                                event: AnalyticsEvent.ROOT_ASSET_ADDED,
                                payload: {
                                    id: asset.id,
                                    name: asset.value
                                }
                            })
                        );
                    })
                    .catch(() => {});
            }
        }),
        addAzureAsset: builder.mutation<Asset, AzureAssetCreateBody>({
            query: body => ({
                url: '/azure/credentials',
                method: 'POST',
                body
            }),
            invalidatesTags: INVALIDATE_ASSETS,
            onQueryStarted(
                _,
                { dispatch, queryFulfilled }
            ): Promise<void> | void {
                queryFulfilled
                    .then(({ data: asset }) => {
                        dispatch(
                            analyticsActions.setEvent({
                                event: AnalyticsEvent.ROOT_ASSET_ADDED,
                                payload: {
                                    id: asset.id,
                                    name: asset.value
                                }
                            })
                        );
                    })
                    .catch(() => {});
            }
        }),
        addGcpAsset: builder.mutation<Asset, GcpAssetCreateBody>({
            query: body => ({
                url: '/gcp/credentials',
                method: 'POST',
                body: {
                    key: body
                }
            }),
            invalidatesTags: INVALIDATE_ASSETS,
            onQueryStarted(
                _,
                { dispatch, queryFulfilled }
            ): Promise<void> | void {
                queryFulfilled
                    .then(({ data: asset }) => {
                        dispatch(
                            analyticsActions.setEvent({
                                event: AnalyticsEvent.ROOT_ASSET_ADDED,
                                payload: {
                                    id: asset.id,
                                    name: asset.value
                                }
                            })
                        );
                    })
                    .catch(() => {});
            }
        }),
        addDigitalOceanAsset: builder.mutation<
            Asset,
            DigitalOceanAssetCreateBody
        >({
            query: body => ({
                url: '/digitalocean/credentials',
                method: 'POST',
                body
            }),
            invalidatesTags: INVALIDATE_ASSETS,
            onQueryStarted(
                _,
                { dispatch, queryFulfilled }
            ): Promise<void> | void {
                queryFulfilled
                    .then(({ data: asset }) => {
                        dispatch(
                            analyticsActions.setEvent({
                                event: AnalyticsEvent.ROOT_ASSET_ADDED,
                                payload: {
                                    id: asset.id,
                                    name: asset.value
                                }
                            })
                        );
                    })
                    .catch(() => {});
            }
        }),
        getAsset: builder.query<Asset, string>({
            query: id => `/assets/${id}`,
            providesTags: (_, __, id) => [{ type: API_TAGS.ASSET, id }],
            transformResponse: (asset: Asset) => ({
                ...asset,
                createdAtParsed: asset.createdAt
                    ? dateFormat(asset.createdAt)
                    : null,
                lastScannedAtParsed: asset.lastScannedAt
                    ? dateFormat(asset.lastScannedAt)
                    : null,
                lastSeenAtParsed: asset.lastSeenAt
                    ? dateFormat(asset.lastSeenAt)
                    : null
            })
        }),
        getRootAsset: builder.query<RootAsset, string>({
            query: id => `/assets/${id}`,
            providesTags: (_, __, id) => [{ type: API_TAGS.ASSET, id }],
            transformResponse: (asset: RootAsset) => ({
                ...asset,
                createdAtParsed: asset.createdAt
                    ? dateFormat(asset.createdAt)
                    : null,
                lastScannedAtParsed: asset.lastScannedAt
                    ? dateFormat(asset.lastScannedAt)
                    : null,
                lastSeenAtParsed: asset.lastSeenAt
                    ? dateFormat(asset.lastSeenAt)
                    : null
            })
        }),
        getRootAssetCandidate: builder.query<RootAssetCandidate, string>({
            query: id => `/assets/${id}`,
            providesTags: (_, __, id) => [{ type: API_TAGS.ASSET, id }],
            transformResponse: (asset: RootAssetCandidate) => ({
                ...asset,
                declined: asset.tags.some(tag => tag.name === 'declined'),
                createdAtParsed: asset.createdAt
                    ? dateFormat(asset.createdAt)
                    : null,
                lastSeenAtParsed: asset.lastSeenAt
                    ? dateFormat(asset.lastSeenAt)
                    : null
            })
        }),
        getRelatedAssets: builder.query<
            ApiResponse<Asset>,
            RelatedAssetsQueryParams
        >({
            query: ({ assetID, relationType, ...rest }) => ({
                url: `/assets/${assetID}/relatedAssets`,
                params: {
                    ...rest,
                    relationType,
                    isRootCandidate: false,
                    isFalsePositive: false
                }
            }),
            transformResponse: (response: ApiResponse<Asset>) => ({
                ...response,
                data: response.data.map(asset => ({
                    ...asset,
                    createdAtParsed: asset.createdAt
                        ? dateFormat(asset.createdAt)
                        : null,
                    lastScannedAtParsed: asset.lastScannedAt
                        ? dateFormat(asset.lastScannedAt)
                        : null,
                    lastSeenAtParsed: asset.lastSeenAt
                        ? dateFormat(asset.lastSeenAt)
                        : null
                }))
            })
        }),
        updateAsset: builder.mutation<Asset, AssetUpdateBody>({
            query: ({ id, ...body }) => ({
                url: `/assets/${id}`,
                method: 'PUT',
                body
            }),
            invalidatesTags: (_, __, { id }) => [
                { type: API_TAGS.ASSET, id },
                { type: API_TAGS.TAG }
            ]
        }),
        getAssets: builder.query<ApiResponse<Asset>, AssetQueryParams>({
            query: params => ({
                url: '/assets',
                params: {
                    ...params,
                    isRootCandidate: false,
                    isFalsePositive: false
                }
            }),
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({
                              type: API_TAGS.ASSET,
                              id
                          })),
                          API_TAGS.ASSET
                      ]
                    : [API_TAGS.ASSET],
            transformResponse: (response: ApiResponse<Asset>) => ({
                ...response,
                data: response.data.map(asset => ({
                    ...asset,
                    createdAtParsed: asset.createdAt
                        ? dateFormat(asset.createdAt)
                        : null,
                    lastScannedAtParsed: asset.lastScannedAt
                        ? dateFormat(asset.lastScannedAt)
                        : null,
                    lastSeenAtParsed: asset.lastSeenAt
                        ? dateFormat(asset.lastSeenAt)
                        : null
                }))
            })
        }),
        getArchivedAssets: builder.query<ApiResponse<Asset>, AssetQueryParams>({
            query: params => ({
                url: '/assets',
                params: {
                    ...params,
                    isRootCandidate: false,
                    isFalsePositive: true
                }
            }),
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({
                              type: API_TAGS.ASSET,
                              id
                          })),
                          API_TAGS.ASSET
                      ]
                    : [API_TAGS.ASSET],
            transformResponse: (response: ApiResponse<Asset>) => ({
                ...response,
                data: response.data.map(asset => ({
                    ...asset,
                    createdAtParsed: asset.createdAt
                        ? dateFormat(asset.createdAt)
                        : null,
                    lastScannedAtParsed: asset.lastScannedAt
                        ? dateFormat(asset.lastScannedAt)
                        : null,
                    lastSeenAtParsed: asset.lastSeenAt
                        ? dateFormat(asset.lastSeenAt)
                        : null
                }))
            })
        }),
        getGeoMapAssets: builder.query<ApiResponse<Asset | RootAsset>, void>({
            query: _ => ({
                url: '/assets',
                params: {
                    size: 10000,
                    isRootCandidate: false,
                    isFalsePositive: false,
                    assetType: [AssetType.IPV4, AssetType.IPV6],
                    withMeta: WithMetaType.GEOIP
                }
            }),
            transformResponse: (response: ApiResponse<Asset>) => ({
                ...response,
                data: response.data.map(asset => ({
                    ...asset,
                    createdAtParsed: asset.createdAt
                        ? dateFormat(asset.createdAt)
                        : null,
                    lastScannedAtParsed: asset.lastScannedAt
                        ? dateFormat(asset.lastScannedAt)
                        : null,
                    lastSeenAtParsed: asset.lastSeenAt
                        ? dateFormat(asset.lastSeenAt)
                        : null
                }))
            })
        }),
        getRootAssets: builder.query<ApiResponse<RootAsset>, AssetQueryParams>({
            query: params => ({
                url: '/assets',
                params: {
                    ...params,
                    isRoot: true
                }
            }),
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({
                              type: API_TAGS.ASSET,
                              id
                          })),
                          API_TAGS.ASSET
                      ]
                    : [API_TAGS.ASSET],
            transformResponse: (response: ApiResponse<RootAsset>) => ({
                ...response,
                data: response.data.map(asset => ({
                    ...asset,
                    createdAtParsed: asset.createdAt
                        ? dateFormat(asset.createdAt)
                        : null,
                    lastScannedAtParsed: asset.lastScannedAt
                        ? dateFormat(asset.lastScannedAt)
                        : null,
                    lastSeenAtParsed: asset.lastSeenAt
                        ? dateFormat(asset.lastSeenAt)
                        : null
                }))
            })
        }),
        getRootAssetCandidates: builder.query<
            ApiResponse<RootAssetCandidate>,
            AssetQueryParams
        >({
            query: params => ({
                url: '/assets',
                params: {
                    ...params,
                    isRootCandidate: true
                }
            }),
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({
                              type: API_TAGS.ASSET,
                              id
                          })),
                          API_TAGS.ASSET
                      ]
                    : [API_TAGS.ASSET],
            transformResponse: (response: ApiResponse<RootAssetCandidate>) => ({
                ...response,
                data: response.data.map(asset => ({
                    ...asset,
                    declined: asset.tags.some(tag => tag.name === 'declined'),
                    createdAtParsed: asset.createdAt
                        ? dateFormat(asset.createdAt)
                        : null,
                    lastSeenAtParsed: asset.lastSeenAt
                        ? dateFormat(asset.lastSeenAt)
                        : null
                }))
            })
        }),
        getAssetDiscoveryPathBackward: builder.query<
            Graph,
            DiscoveryPathQueryParams
        >({
            query: params => ({
                url: `/assets/${params.id}/trace-discovery-backward`,
                params: params.params
            })
        }),
        getAssetDiscoveryPathBackwardShortest: builder.query<
            Graph,
            DiscoveryPathQueryParams
        >({
            query: params => ({
                url: `/assets/${params.id}/trace-discovery-backward`,
                params: {
                    ...params.params,
                    shortestPath: 1
                }
            })
        }),
        getAssetDiscoveryPathForward: builder.query<
            Graph,
            DiscoveryPathQueryParams
        >({
            query: params => ({
                url: `/assets/${params.id}/trace-discovery-forward`,
                params: params.params
            })
        }),
        getAssetRelations: builder.query<Graph, string>({
            query: id => `/assets/${id}/relations`
        }),
        getAssetsDiscoveryPathForward: builder.query<
            Graph[] | undefined,
            string[] | undefined
        >({
            async queryFn(ids, _queryApi, _extraOptions, fetchWithBQ) {
                if (!ids?.length)
                    return {
                        data: undefined
                    };

                const graphs = await Promise.all(
                    ids.map(async id => {
                        const { data } = await fetchWithBQ(
                            `/assets/${id}/trace-discovery-forward?excludeCandidates=1&excludeFalsePositives=1`
                        );

                        return {
                            clusterId: id,
                            ...data
                        };
                    })
                );

                return {
                    data: graphs
                };
            }
        }),
        approveAssetCandidates: builder.mutation<Asset, string[]>({
            query: ids => ({
                url: '/assets-bulk-actions/approve-candidates',
                method: 'POST',
                body: {
                    ids
                }
            }),
            invalidatesTags: (_, __, ids) => [
                ...ids.map(id => ({ type: API_TAGS.ASSET, id })),
                API_TAGS.ROOT_ASSET_NAMES
            ]
        }),
        declineAssetCandidates: builder.mutation<Asset, string[]>({
            query: ids => ({
                url: '/assets-bulk-actions/decline-candidates',
                method: 'POST',
                body: {
                    ids
                }
            }),
            invalidatesTags: (_, __, ids) => [
                ...ids.map(id => ({ type: API_TAGS.ASSET, id }))
            ]
        }),
        removeRootAsset: builder.mutation<DeletedAsset, string>({
            query: id => ({
                url: `/assets/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: (_, __, id) => [
                { type: API_TAGS.ASSET, id },
                API_TAGS.ASSET,
                API_TAGS.ROOT_ASSET_NAMES
            ],
            onQueryStarted(
                _,
                { dispatch, queryFulfilled }
            ): Promise<void> | void {
                queryFulfilled
                    .then(({ data: asset }) => {
                        dispatch(
                            analyticsActions.setEvent({
                                event: AnalyticsEvent.ROOT_ASSET_DELETED,
                                payload: {
                                    id: asset.assetId
                                }
                            })
                        );
                    })
                    .catch(() => {});
            }
        }),
        verifyRootAsset: builder.mutation<
            RootAssetVerification,
            RootAssetVerificationQuery
        >({
            query: ({ id, method }) => ({
                url: `/assets/${id}/verify?method=${method}`,
                method: 'GET'
            }),
            invalidatesTags: (_, __, { id }) => [
                { type: API_TAGS.ASSET, id },
                API_TAGS.ASSET,
                API_TAGS.ROOT_ASSET_NAMES
            ]
        }),
        setAsFalsePositive: builder.mutation<Asset, string>({
            query: id => ({
                url: `/assets/${id}/set-false-positive`,
                method: 'POST'
            }),
            invalidatesTags: (_, __, id) => [
                { type: API_TAGS.ASSET, id },
                API_TAGS.ASSET
            ]
        }),
        unsetAsFalsePositive: builder.mutation<Asset, string>({
            query: id => ({
                url: `/assets/${id}/unset-false-positive`,
                method: 'POST'
            }),
            invalidatesTags: (_, __, id) => [
                { type: API_TAGS.ASSET, id },
                API_TAGS.ASSET
            ]
        })
    })
});

export const useAddAsset = assetsApi.useAddAssetMutation;
export const useAddAwsAsset = assetsApi.useAddAwsAssetMutation;
export const useAddAzureAsset = assetsApi.useAddAzureAssetMutation;
export const useAddGcpAsset = assetsApi.useAddGcpAssetMutation;
export const useAddDigitalOceanAsset =
    assetsApi.useAddDigitalOceanAssetMutation;

export const useGetAsset = assetsApi.useGetAssetQuery;
export const useGetRelatedAssets = assetsApi.useGetRelatedAssetsQuery;
export const useUpdateAsset = assetsApi.useUpdateAssetMutation;
export const useRemoveRootAsset = assetsApi.useRemoveRootAssetMutation;
export const useVerifyRootAsset = assetsApi.useVerifyRootAssetMutation;
export const useApproveAssetCandidates =
    assetsApi.useApproveAssetCandidatesMutation;
export const useDeclineAssetCandidates =
    assetsApi.useDeclineAssetCandidatesMutation;
export const useGetRootAsset = assetsApi.useGetRootAssetQuery;
export const useGetRootAssetCandidate = assetsApi.useGetRootAssetCandidateQuery;
export const useGetAssets = assetsApi.useGetAssetsQuery;
export const useGetArchivedAssets = assetsApi.useGetArchivedAssetsQuery;
export const useGetGeoMapAssets = assetsApi.useGetGeoMapAssetsQuery;
export const useGetRootAssets = assetsApi.useGetRootAssetsQuery;
export const useGetRootAssetCandidates =
    assetsApi.useGetRootAssetCandidatesQuery;

export const useGetAssetDiscoveryPathBackward =
    assetsApi.useGetAssetDiscoveryPathBackwardQuery;
export const useGetAssetDiscoveryPathBackwardShortest =
    assetsApi.useGetAssetDiscoveryPathBackwardShortestQuery;
export const useGetAssetsDiscoveryPathForward =
    assetsApi.useGetAssetsDiscoveryPathForwardQuery;
export const useGetAssetDiscoveryPathForward =
    assetsApi.useGetAssetDiscoveryPathForwardQuery;
export const useGetAssetRelations = assetsApi.useGetAssetRelationsQuery;
export const useSetAsFalsePositive = assetsApi.useSetAsFalsePositiveMutation;
export const useUnsetAsFalsePositive =
    assetsApi.useUnsetAsFalsePositiveMutation;
