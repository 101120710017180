import React, { memo } from 'react';
import { Issue } from 'entities/Issue';
import { Badge, BadgeProps, Group } from '@mantine/core';
import { EpssPost } from 'entities/KnowledgeBase';

interface EpssScoreBadgeProps extends BadgeProps {
    issue: Issue;
}

const EpssScoreBadge = (props: EpssScoreBadgeProps) => {
    const { issue, ...rest } = props;

    if (!issue.epss) return null;

    return (
        <Badge variant="light" {...rest}>
            <Group gap={2} align="center">
                EPSS score: {issue.epss.score}
                <EpssPost />
            </Group>
        </Badge>
    );
};

export default memo(EpssScoreBadge);
