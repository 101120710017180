import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavigationSchema } from '../types/navigationSchema';
import { NavigationPage } from '../types/navigation';

const initialState: NavigationSchema = {
    scrollToTop: false,
    drawerOpen: false,
    drawerStack: [],
    stackLength: 0
};

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        addToDrawerStack: (state, action: PayloadAction<NavigationPage>) => {
            if (state.stackLength > 0) {
                const lastPage = state.drawerStack[state.stackLength - 1];
                if (lastPage.pageID === action.payload.pageID) {
                    state.scrollToTop = true;
                    return;
                }
            }

            if (action.payload.pageLink) {
                action.payload.pageLink = new URL(
                    action.payload.pageLink,
                    window.location.origin
                ).href;
            } else {
                action.payload.pageLink = window.location.href;
            }
            state.drawerStack.push(action.payload);
            state.stackLength = state.drawerStack.length;
            state.drawerOpen = true;
        },
        removeFromDrawerStack: state => {
            state.drawerStack.pop();
            state.stackLength = state.drawerStack.length;

            if (state.stackLength === 0) {
                state.drawerOpen = false;
            }
        },
        resetDrawerStack: state => {
            state.drawerStack = [];
            state.stackLength = 0;
            state.drawerOpen = false;
        },
        setScrollToTop: (state, action: PayloadAction<boolean>) => {
            state.scrollToTop = action.payload;
        }
    }
});

export const { actions: navigationActions } = navigationSlice;
export const { reducer: navigationReducer } = navigationSlice;
