import { rtkApi } from 'shared/api/rtkApi';
import { ApiResponse, ScrollApiResponse } from 'shared/types/pagination';
import { dateFormat } from 'shared/lib/formatter/formatter';
import { Log, LogLevels } from '../../model/types/log';

export interface LogQueryParams {
    searchAfter?: string;
    size?: number;
    action?: string | string[];
    level?: LogLevels;
    isSystem?: string;
    assetId?: string;
    assetValueIncludes?: string;
    timestampGte?: number;
    timestampLt?: number;
}

const logApi = rtkApi.injectEndpoints({
    endpoints: builder => ({
        getLogs: builder.query<ScrollApiResponse<Log>, LogQueryParams>({
            query: params => ({
                url: '/audit-log',
                params
            }),
            transformResponse: (response: ScrollApiResponse<Log>) => ({
                ...response,
                data: response.data.map(log => ({
                    ...log,
                    timestamp: log.timestamp,
                    timestampMs: log.timestamp * 1000,
                    timestampParsed: dateFormat(log.timestamp)
                }))
            })
        }),
        getLog: builder.query<Log, string>({
            query: id => ({
                url: `/audit-log/${id}`
            })
        }),
        getLogActions: builder.query<string[], void>({
            query: () => ({
                url: '/dictionaries/audit-actions'
            }),
            transformResponse: (response: ApiResponse<string>) => response.data
        })
    })
});

export const useGetLogs = logApi.useGetLogsQuery;
export const useGetLog = logApi.useGetLogQuery;
export const useGetLogActions = logApi.useGetLogActionsQuery;
