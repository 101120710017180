import { memo, Suspense } from 'react';
import { Anchor, Button, ButtonProps, Group, Text } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { Icon, Icons } from 'shared/ui/Icon';
import {
    addNewRootAssetActions,
    ErrorAddedAsset,
    getErrorMessage
} from 'features/asset/actions/AddNewRootAsset';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';

interface ConfirmFormFooterProps extends Pick<ButtonProps, 'disabled'> {
    loading?: boolean;
}

const ConfirmFormFooter = (props: ConfirmFormFooterProps) => {
    const dispatch = useAppDispatch();
    const errorMessage = useSelector(getErrorMessage);
    const { loading, disabled } = props;
    const location = useLocation();
    const { pathname } = location;

    return (
        <>
            <Group justify="space-between">
                <Anchor
                    c="dimmed"
                    size="sm"
                    component={Link}
                    to={pathname.substring(0, pathname.lastIndexOf('/'))}
                >
                    <Group gap="xs">
                        <Icon icon={Icons.CHEVRON_LEFT} />
                        <Text size="sm" component="span">
                            Back
                        </Text>
                    </Group>
                </Anchor>
                <Button
                    type="submit"
                    variant="primary-gradient"
                    loading={loading}
                    disabled={disabled}
                    style={{ width: 140 }}
                    onClick={() => dispatch(addNewRootAssetActions.reset())}
                >
                    Add
                </Button>
            </Group>
            {errorMessage && (
                <Suspense fallback={null}>
                    <ErrorAddedAsset />
                </Suspense>
            )}
        </>
    );
};

export default memo(ConfirmFormFooter);
