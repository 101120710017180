import React, { Fragment, memo, useMemo } from 'react';
import { MetadataItem } from 'shared/ui/Metadata';
import {
    AdjacencyType,
    AdjacencyTypeLabel,
    useGetAssetDiscoveryPathBackward
} from 'entities/Asset';
import { Text } from 'shared/ui/Text';
import { Tooltip } from '@mantine/core';
import { Asset, RootAssetCandidate } from '../../../model/types/asset';
import styles from './DiscoveryMethods.module.scss';

interface DiscoveryMethodsPathProps {
    asset: Asset | RootAssetCandidate;
}

interface DiscoveryMethod {
    type: string;
    label: string;
}

const DiscoveryMethodsPath = (props: DiscoveryMethodsPathProps) => {
    const { asset } = props;
    const { data } = useGetAssetDiscoveryPathBackward({
        id: asset.id,
        params: { maxDepth: 1 }
    });

    const discoveryMethods = useMemo(() => {
        const { edges } = data || {};
        if (!edges) return [];
        return Array.from(
            new Set(
                Object.values(edges).reduce((acc: DiscoveryMethod[], edge) => {
                    if (
                        edge.to === asset.id &&
                        !acc.find(e => e.type === edge.name)
                    ) {
                        const name = edge.name.toUpperCase() as AdjacencyType;

                        // @ts-ignore
                        acc.push({
                            type: edge.name,
                            // @ts-ignore
                            label: AdjacencyTypeLabel[name] || name
                        });
                    }

                    return acc;
                }, [])
            )
        );
    }, [asset.id, data]);

    if (!discoveryMethods.length) return null;
    return (
        <MetadataItem
            label="Discovery Methods"
            value={
                <div className={styles.DiscoveryMethods}>
                    {discoveryMethods.map((method, index) => (
                        <Fragment key={method.type}>
                            {method.type === AdjacencyType.WEB_CRAWLING &&
                            asset.foundOnPages?.length ? (
                                <Tooltip
                                    withArrow
                                    label={
                                        <div>
                                            <Text>Found on pages:</Text>
                                            <ul>
                                                {asset.foundOnPages.map(
                                                    page => (
                                                        <li key={page}>
                                                            {page}
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    }
                                >
                                    <span>
                                        {method.label} (
                                        {asset.foundOnPages.length})
                                    </span>
                                </Tooltip>
                            ) : (
                                <span>{method.label}</span>
                            )}
                            {index < discoveryMethods.length - 1 && ', '}
                        </Fragment>
                    ))}
                </div>
            }
        />
    );
};

export default memo(DiscoveryMethodsPath);
