import React, { memo } from 'react';
import { dateFormatLib } from 'shared/lib/formatter/formatter';
import { Accordion, Box, Card, Grid, Space, Stack, Text } from '@mantine/core';
import { CopyButton } from 'shared/ui/Button';
import HeaderedCard from 'shared/ui/HeaderedCard/HeaderedCard';
import { Icon, Icons, IconSize } from 'shared/ui/Icon';
import { Asset } from '../../model/types/asset';
import { SslCertificate } from '../../model/types/metadata';

interface AssetSslCertificateProps {
    asset?: Asset;
}

const SslCertificateRow = ({
    label,
    value
}: {
    label: string;
    value: string | string[] | number | number[];
}) =>
    value && (
        <Grid>
            <Grid.Col span={3}>
                <Text c="dimmed">{label}</Text>
            </Grid.Col>
            <Grid.Col span={9}>
                <Text>{Array.isArray(value) ? value.join('\n') : value}</Text>
            </Grid.Col>
        </Grid>
    );

const SslCertificateItem = (props: { sslCertificate: SslCertificate }) => {
    const { sslCertificate } = props;

    return (
        <>
            <HeaderedCard
                header="Overview"
                shadow="0"
                padding="0"
                withBorder={false}
            >
                <Stack>
                    <SslCertificateRow
                        label="Valid from"
                        value={dateFormatLib(sslCertificate.notValidBefore)}
                    />
                    <SslCertificateRow
                        label="Valid to"
                        value={dateFormatLib(sslCertificate.notValidAfter)}
                    />
                    <SslCertificateRow
                        label="Revocation status"
                        value={sslCertificate.revocationStatus}
                    />
                    <SslCertificateRow
                        label="CRL revocation status"
                        value={sslCertificate.crlRevocationStatus}
                    />
                    <SslCertificateRow
                        label="OCSP revocation status"
                        value={sslCertificate.ocspRevocationStatus}
                    />
                    <SslCertificateRow
                        label="Serial number"
                        value={sslCertificate.serialNumber}
                    />
                    <SslCertificateRow
                        label="Subject"
                        value={sslCertificate.subject}
                    />
                    <SslCertificateRow
                        label="Issuer"
                        value={sslCertificate.issuer}
                    />
                    <SslCertificateRow
                        label="Signature algorithm"
                        value={sslCertificate.signatureAlgorithm}
                    />
                    <SslCertificateRow
                        label="SHA1 fingerprint"
                        value={sslCertificate.sha1Hash}
                    />
                    <SslCertificateRow
                        label="SHA256 fingerprint"
                        value={sslCertificate.pinSha256}
                    />
                    <SslCertificateRow
                        label="Alternative names"
                        value={sslCertificate.alternativeNames?.join(', ')}
                    />
                    <SslCertificateRow
                        label="Common names"
                        value={sslCertificate.commonNames?.join(', ')}
                    />
                    <SslCertificateRow
                        label="CRL distribution endpoints"
                        value={sslCertificate.crlURIs?.join(', ')}
                    />
                    <SslCertificateRow
                        label="OCSP endpoints"
                        value={sslCertificate.ocspURIs?.join(', ')}
                    />
                </Stack>
            </HeaderedCard>
            <Space h={20} />
            <HeaderedCard
                header="PEM"
                actions={<CopyButton value={sslCertificate.PEM} />}
                shadow="0"
                padding="0"
                withBorder={false}
            >
                <pre>{sslCertificate.PEM}</pre>
            </HeaderedCard>
        </>
    );
};

const AssetSslCertificate = (props: AssetSslCertificateProps) => {
    const { asset } = props;

    if (!asset?.metadata?.sslCertificate) return null;
    const { sslCertificate } = asset.metadata;
    const { chain } = sslCertificate;

    return (
        <Accordion
            multiple
            defaultValue={[sslCertificate.serialNumber]}
            chevron={<Icon icon={Icons.EXPAND_MORE} size={IconSize.LARGE} />}
            chevronSize={32}
        >
            <Stack>
                <Card shadow="md" padding="0" radius="md" withBorder>
                    <Accordion.Item
                        value={sslCertificate.serialNumber}
                        key={sslCertificate.serialNumber}
                    >
                        <Accordion.Control>
                            <Box>
                                <Text size="sm" c="dimmed" fw={700}>
                                    {sslCertificate.subject}
                                </Text>
                                <Text size="xl" fw={700}>
                                    {sslCertificate.serialNumber}
                                </Text>
                            </Box>
                        </Accordion.Control>
                        <Accordion.Panel>
                            <SslCertificateItem
                                sslCertificate={sslCertificate}
                            />
                        </Accordion.Panel>
                    </Accordion.Item>
                </Card>

                {chain?.map(sslCertificate => (
                    <Card
                        key={sslCertificate.serialNumber}
                        shadow="md"
                        padding="0"
                        radius="md"
                        withBorder
                    >
                        <Accordion.Item
                            value={sslCertificate.serialNumber}
                            key={sslCertificate.serialNumber}
                        >
                            <Accordion.Control>
                                <Box>
                                    <Text size="sm" c="dimmed" fw={700}>
                                        {sslCertificate.subject}
                                    </Text>
                                    <Text size="xl" fw={700}>
                                        {sslCertificate.serialNumber}
                                    </Text>
                                </Box>
                            </Accordion.Control>
                            <Accordion.Panel>
                                <SslCertificateItem
                                    sslCertificate={sslCertificate}
                                />
                            </Accordion.Panel>
                        </Accordion.Item>
                    </Card>
                ))}
            </Stack>
        </Accordion>
    );
};

export default memo(AssetSslCertificate);
