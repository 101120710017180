import { DataTableColumn } from 'mantine-datatable';
import {
    Asset,
    AssetQueryParams,
    RootAsset,
    RootAssetCandidate
} from 'entities/Asset';
import { BaseColumnProps, updateQuery } from 'widgets/Columns/baseColumn';
import React from 'react';
import { DateRangeFilterForm } from 'features/common/filters/DateRangeFilter';
import dayjs from 'dayjs';
import { DateColumn } from 'shared/ui/DataTable';
import { TechnologiesQueryParams, Technology } from 'entities/Technology';
import {
    Misconfiguration,
    MisconfigurationQueryParams
} from 'entities/Misconfiguration';
import { Vulnerability } from 'entities/Vulnerability';
import { IssueUnique } from 'entities/Issue';

type AssetType = Asset | RootAsset | RootAssetCandidate;
type RecordType =
    | AssetType
    | Technology
    | Misconfiguration
    | Vulnerability
    | IssueUnique;
type QueryParams =
    | AssetQueryParams
    | MisconfigurationQueryParams
    | TechnologiesQueryParams;

export default ({
    query = {},
    setQuery,
    column
}: BaseColumnProps<RecordType, QueryParams>): DataTableColumn<RecordType> => {
    const { createdAtGte, createdAtLte } = query;

    return {
        width: 190,
        accessor: 'createdAt',
        title: 'First Seen',
        sortable: true,
        filtering: Boolean(createdAtGte || createdAtLte),
        filter: ({ close }) =>
            query && (
                <DateRangeFilterForm
                    dateRangeValue={[
                        createdAtGte ? new Date(createdAtGte * 1000) : null,
                        createdAtLte ? new Date(createdAtLte * 1000) : null
                    ]}
                    setDateRangeValue={range =>
                        updateQuery(
                            {
                                createdAtGte: range?.[0]
                                    ? dayjs(range?.[0]).startOf('D').unix()
                                    : undefined,
                                createdAtLte: range?.[1]
                                    ? dayjs(range?.[1]).endOf('D').unix()
                                    : undefined
                            },
                            query,
                            setQuery
                        )
                    }
                    close={close}
                />
            ),
        render: ({ createdAt }) => <DateColumn date={createdAt} timeAgo />,
        ...column
    };
};
