import { memo } from 'react';
import { Page } from 'shared/ui/Page';
import AccountPageHeader from '../AccountPageHeader/AccountPageHeader';

interface AccountBaseLayoutProps {
    withHeader?: boolean;
    children?: React.ReactNode;
}

const AccountBaseLayout = (props: AccountBaseLayoutProps) => {
    const { withHeader = true, children } = props;

    return (
        <Page>
            {withHeader && <AccountPageHeader />}
            {children}
        </Page>
    );
};

export default memo(AccountBaseLayout);
