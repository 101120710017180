import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsSchema } from '../types/analyticsSchema';
import { AnalyticsEvent } from '../types/analytics';

const initialState: AnalyticsSchema = {
    event: undefined,
    payload: undefined,
    events: undefined
};

export const setEvent = createAsyncThunk(
    'analytics/setEvent',
    async (
        payload: { event: AnalyticsEvent; payload?: object } | AnalyticsEvent
    ) => {
        await new Promise(resolve => setTimeout(resolve, 100));
        return payload;
    }
);

export const analyticsSlice = createSlice({
    name: 'analytics',
    initialState,
    reducers: {
        setEvent: (
            state,
            action: PayloadAction<
                { event: AnalyticsEvent; payload?: object } | AnalyticsEvent
            >
        ) => {
            const { payload } = action;

            if (!state.events) {
                state.events = [];
            }

            state.events.push({
                event: typeof payload === 'string' ? payload : payload.event,
                payload:
                    typeof payload === 'string' ? undefined : payload.payload
            });
        },
        shiftEvent: state => {
            state.events?.shift();
        },
        reset: state => {
            state.event = undefined;
            state.payload = undefined;
        }
    },
    extraReducers: {
        [setEvent.fulfilled.type]: (state, action) => {
            const { payload } = action;

            if (!state.events) {
                state.events = [];
            }

            state.events.push({
                event: typeof payload === 'string' ? payload : payload.event,
                payload:
                    typeof payload === 'string' ? undefined : payload.payload
            });
        }
    }
});

export const { actions: analyticsActions } = analyticsSlice;
export const { reducer: analyticsReducer } = analyticsSlice;
