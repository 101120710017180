import React, { useEffect, useMemo, useState } from 'react';
import { Rating } from 'entities/Issue';
import { AssetDetailsContext } from '../lib/AssetDetailsContext';

interface AssetDetailsProviderProps {
    children: React.ReactNode;
}

const AssetDetailsProvider = (props: AssetDetailsProviderProps) => {
    const { children } = props;
    const [activeTab, setActiveTab] = useState<string>('');
    const [selectedIssueRating, setSelectedIssueRating] = useState<Rating>();

    const defaultProps = useMemo(
        () => ({
            activeTab,
            setActiveTab,
            selectedIssueRating,
            setSelectedIssueRating
        }),
        [activeTab, selectedIssueRating]
    );

    return (
        <AssetDetailsContext.Provider value={defaultProps}>
            {children}
        </AssetDetailsContext.Provider>
    );
};

export default AssetDetailsProvider;
