import React, { memo } from 'react';
import classNames from 'classnames';
import styles from './MetadataItem.module.scss';

interface MetadataItemProps {
    className?: string;
    label?: string;
    value?: number | string | React.ReactNode;
}

const MetadataItem = (props: MetadataItemProps) => {
    const { className, label, value } = props;
    const classes = classNames(styles.MetadataItem, className);

    return (
        <div className={classes}>
            {label && <div className={styles.MetadataItem__Label}>{label}</div>}
            {value && <div className={styles.MetadataItem__Value}>{value}</div>}
        </div>
    );
};

export default memo(MetadataItem);
