import { DataTableColumn } from 'mantine-datatable';

export interface BaseColumnProps<T, Q> {
    query?: Q;
    setQuery?: (query: Q) => void;
    column?: Partial<DataTableColumn<T>>;
}

export function updateQuery<Q>(
    newValue: Q,
    query?: Q,
    setQuery?: (query: Q) => void
) {
    if (setQuery) {
        setQuery({ ...query, ...newValue });
    }
}
