import React, { memo } from 'react';
import { Group } from '@mantine/core';
import { Misconfiguration } from 'entities/Misconfiguration';
import { Vulnerability } from 'entities/Vulnerability';
import { StatusBadge } from 'entities/Issue';
import { EpssPercentileBadge, EpssScoreBadge } from 'entities/EPSS';
import { KevCheckBadge } from 'entities/KEV';
import NewIssueBadge from '../Badges/NewIssueBadge/NewIssueBadge';
import RiskBadge from '../Badges/RiskBadge/RiskBadge';
import CvssBadge from '../Badges/CvssBadge/CvssBadge';
import MitreBadge from '../Badges/MitreBadge/MitreBadge';
import { Issue } from '../../model/types/issue';
import CveBadge from '../Badges/CveBadge/CveBadge';
import styles from './IssueBadgesBar.module.scss';

interface IssueBadgesBarProps {
    issue: Issue | Vulnerability | Misconfiguration;
    children?: React.ReactNode;
}

const IssueBadgesBar = (props: IssueBadgesBarProps) => {
    const { issue, children } = props;

    return (
        <>
            <Group gap="xs" className={styles.IssueBadgesBar}>
                <RiskBadge issue={issue} />
                <CvssBadge issue={issue} />
                <KevCheckBadge issue={issue} />
                <StatusBadge issue={issue} />
            </Group>
            <Group gap="xs" className={styles.IssueBadgesBar}>
                {children}
                <NewIssueBadge issue={issue} />
                <EpssScoreBadge issue={issue} />
                <EpssPercentileBadge issue={issue} />
                <MitreBadge issue={issue} />
                <CveBadge issue={issue} />
            </Group>
        </>
    );
};

export default memo(IssueBadgesBar);
