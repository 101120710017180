import {
    Asset,
    Graph,
    RootAsset,
    useGetAssetDiscoveryPathBackward
} from 'entities/Asset';

interface UseGetAssetBackwardGraph {
    graph: Graph | undefined;
    loading: boolean;
}

export function useGetAssetBackwardGraph(
    asset: Asset | RootAsset
): UseGetAssetBackwardGraph {
    const { data, isLoading } = useGetAssetDiscoveryPathBackward({
        id: asset.id
    });

    return {
        graph: data,
        loading: isLoading
    };
}
