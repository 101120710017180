import { createRoot } from 'react-dom/client';
import React from 'react';
import { StoreProvider } from 'app/providers/StoreProvider';
import { InitProvider } from 'app/providers/InitProvider';
import App from 'app/App';

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';

import 'mantine-datatable/styles.layer.css';
import './app/styles/index.scss';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { theme } from 'shared/config/theme/theme';
import { HelmetProvider } from 'react-helmet-async';
import Head from 'app/ui/Head/Head';

const container = document.getElementById('app');
const root = createRoot(container!);
root.render(
    <StoreProvider>
        <HelmetProvider>
            <InitProvider>
                <MantineProvider theme={theme}>
                    <ModalsProvider>
                        <Notifications
                            position="top-right"
                            autoClose={7500}
                            containerWidth="fit-content"
                        />
                        <Head />
                        <App />
                    </ModalsProvider>
                </MantineProvider>
            </InitProvider>
        </HelmetProvider>
    </StoreProvider>
);
