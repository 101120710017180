import React, { memo } from 'react';
import { AssetType, useAssetSearchParams } from 'entities/Asset';
import { AssetsTable } from 'widgets/Asset';
import AssetsBaseLayout from 'pages/Assets/ui/AssetsBaseLayout/AssetsBaseLayout';
import { Seo } from 'shared/ui/SEO/SEO';

function PortAssetsPage() {
    const { createdAtGte, createdAtLte } = useAssetSearchParams();

    return (
        <AssetsBaseLayout>
            <Seo title="Port | Assets" />
            <AssetsTable
                defaultAssetType={AssetType.PORT}
                defaultCreatedAtGte={createdAtGte}
                defaultCreatedAtLte={createdAtLte}
            />
        </AssetsBaseLayout>
    );
}

export default memo(PortAssetsPage);
