import { memo } from 'react';
import { PageHeader, PageChildrenLink } from 'widgets/PageHeader';
import { RoutePath } from 'shared/config/routeConfig';
import { Icons } from 'shared/ui/Icon';

const IssuesPageHeader = () => (
    <PageHeader
        title="Issues"
        links={
            <>
                <PageChildrenLink
                    title="Overview"
                    to={`${RoutePath.issues}/overview`}
                    hasPreviewNumber={false}
                />
                <span>|</span>
                <PageChildrenLink
                    title="All Issues"
                    to={`${RoutePath.issues}/all`}
                    icon={Icons.WARNING}
                    hasPreviewNumber={false}
                />
                <PageChildrenLink
                    title="Open"
                    to={`${RoutePath.issues}/open`}
                    icon={Icons.POLICY}
                    hasPreviewNumber={false}
                />
                <PageChildrenLink
                    title="Fixed"
                    to={`${RoutePath.issues}/fixed`}
                    icon={Icons.VERIFIED_USER}
                    hasPreviewNumber={false}
                />
                <PageChildrenLink
                    title="Accepted Risk"
                    to={`${RoutePath.issues}/accepted-risk`}
                    icon={Icons.VERIFIED}
                    hasPreviewNumber={false}
                />
                <PageChildrenLink
                    title="False Positive"
                    to={`${RoutePath.issues}/false-positive`}
                    icon={Icons.RULE}
                    hasPreviewNumber={false}
                />
            </>
        }
    />
);

export default memo(IssuesPageHeader);
