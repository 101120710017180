import React, { memo } from 'react';
import HeaderedCard from 'shared/ui/HeaderedCard/HeaderedCard';
import IssueStatisticsChart from '../../Charts/IssueStatisticsChart/IssueStatisticsChart';

const IssuesBasedOnSeverityCard = () => (
    <HeaderedCard header="Issues based on severity" bodyPadding="0">
        <IssueStatisticsChart />
    </HeaderedCard>
);

export default memo(IssuesBasedOnSeverityCard);
