import React, { memo } from 'react';
import { Badge } from '@mantine/core';
import { formatDateToUTC } from 'shared/lib/date';
import dayjs from 'dayjs';
import HelpHoverCard from 'shared/ui/HelpHoverCard/HelpHoverCard';
import {
    Asset,
    RootAsset,
    RootAssetCandidate
} from '../../../model/types/asset';

interface AssetHealthBadgeProps {
    asset: RootAsset | Asset | RootAssetCandidate;
}

const AssetActivityBadge = (props: AssetHealthBadgeProps) => {
    const { asset } = props;
    if (!asset.lastActiveAt) return null;

    const hoursAgo = dayjs().diff(dayjs(asset.lastActiveAt), 'hour');
    const formattedDate = formatDateToUTC(asset.lastActiveAt);
    const label = `Last active at ${formattedDate} (${hoursAgo} hours ago). The term "Active" means that Attaxion was able to successfully take a screenshot of this asset. For you, it means that you can view this asset through your browser.`;

    return (
        <HelpHoverCard
            title="Asset Activity"
            description={label}
            target={
                <Badge color="green" variant="dot">
                    Active
                </Badge>
            }
        />
    );
};

export default memo(AssetActivityBadge);
