import { memo } from 'react';
import { Stack, Button, Group } from '@mantine/core';
import { DatePicker, type DatesRangeValue } from '@mantine/dates';
import dayjs from 'dayjs';

export interface DateRangeFilterFormProps {
    dateRangeValue?: DatesRangeValue;
    setDateRangeValue: (value: DatesRangeValue | undefined) => void;
    close: () => void;
    includeDefaults?: boolean;
}

const DateRangeFilterForm = (props: DateRangeFilterFormProps) => {
    const {
        dateRangeValue,
        setDateRangeValue,
        close,
        includeDefaults = true
    } = props;

    return (
        <Stack>
            <DatePicker
                type="range"
                maxDate={new Date()}
                allowSingleDateInRange
                value={dateRangeValue}
                onChange={setDateRangeValue}
            />
            {includeDefaults && (
                <Group gap="xs" grow>
                    <Button
                        disabled={!dateRangeValue}
                        variant="light"
                        onClick={() => {
                            setDateRangeValue([
                                dayjs().subtract(1, 'week').toDate(),
                                dayjs().toDate()
                            ]);
                        }}
                    >
                        Last 7 days
                    </Button>
                    <Button
                        disabled={!dateRangeValue}
                        variant="light"
                        onClick={() => {
                            setDateRangeValue([
                                dayjs().subtract(1, 'month').toDate(),
                                dayjs().toDate()
                            ]);
                        }}
                    >
                        Last 30 days
                    </Button>
                </Group>
            )}
            <Button
                disabled={!dateRangeValue}
                variant="outline"
                onClick={() => {
                    setDateRangeValue(undefined);
                    close();
                }}
            >
                Clear
            </Button>
        </Stack>
    );
};

export default memo(DateRangeFilterForm);
