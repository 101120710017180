import { memo } from 'react';
import { Page } from 'shared/ui/Page';
import { Box } from '@mantine/core';
import TechnologiesPageHeader from '../TechnologiesPageHeader/TechnologiesPageHeader';

interface TechnologiesBaseLayoutProps {
    children?: React.ReactNode;
}

const TechnologiesBaseLayout = (props: TechnologiesBaseLayoutProps) => {
    const { children } = props;

    return (
        <Page>
            <TechnologiesPageHeader />
            <Box style={{ maxHeight: 'calc(100% - 120px)' }}>{children}</Box>
        </Page>
    );
};

export default memo(TechnologiesBaseLayout);
