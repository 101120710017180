import React, { memo } from 'react';
import HeaderedCard from 'shared/ui/HeaderedCard/HeaderedCard';
import ViewAllLink from 'shared/ui/Links/ViewAllLink/ViewAllLink';
import { RoutePath } from 'shared/config/routeConfig';
import VulnerabilitiesFeedTable from '../../Tables/VulnerabilitiesFeedTable/VulnerabilitiesFeedTable';

const RecentIssuesCard = () => (
    <HeaderedCard
        header="Recent issues"
        bodyPadding="0"
        actions={<ViewAllLink to={`${RoutePath.issues}/all`} />}
    >
        <VulnerabilitiesFeedTable />
    </HeaderedCard>
);

export default memo(RecentIssuesCard);
