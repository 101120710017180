import React, { memo } from 'react';
import { Issue } from 'entities/Issue';
import { ExternalLink } from 'shared/ui/ExternalLink';
import { Badge } from '@mantine/core';

interface CvssBadgeProps {
    issue: Issue;
}

const MitreBadge = (props: CvssBadgeProps) => {
    const { issue } = props;
    const { cwes } = issue;

    if (!cwes) return null;

    return (
        <>
            {cwes.map(cwe => (
                <Badge color="gray" variant="light" key={cwe.id}>
                    <ExternalLink href={cwe.url}>{cwe.id}</ExternalLink>
                </Badge>
            ))}
        </>
    );
};

export default memo(MitreBadge);
