import { memo } from 'react';
import { Badge, Group } from '@mantine/core';

interface BadgeListProps {
    options: {
        id: string | number;
        name: string;
    }[];
    children?: React.ReactNode;
}

const BadgeBar = (props: BadgeListProps) => {
    const { options, children } = props;

    return (
        <Group gap="xs">
            {options.map(option => (
                <Badge key={option.id} variant="light">
                    {option.name}
                </Badge>
            ))}
            {children}
        </Group>
    );
};

export default memo(BadgeBar);
