import { memo } from 'react';
import classNames from 'classnames';
import { Icon, Icons } from 'shared/ui/Icon';
import styles from './Badge.module.scss';

interface BadgeProps {
    className?: string;
    label?: string;
    icon?: Icons;
    children?: React.ReactNode;
    variant?: 'green' | 'orange' | 'yellow' | 'red' | 'gray' | 'blue';
    size?: '1' | '2' | '3' | '4' | '5' | '6';
    removable?: boolean;
    onClick?: () => void;
}

const Badge = (props: BadgeProps) => {
    const {
        className,
        label,
        icon,
        children,
        variant = 'gray',
        size = '1',
        removable,
        onClick
    } = props;
    const classes = classNames(
        styles.Badge,
        styles[`Badge--${variant}`],
        styles[`Badge--size-${size}`],
        {
            [styles['Badge--clickable']]: onClick,
            [styles['Badge--removable']]: removable
        },
        className
    );

    return (
        <div className={classes} onClick={onClick}>
            {icon && <Icon className={styles.Badge__icon} icon={icon} />}
            {label && <span className={styles.Badge__label}>{label}</span>}
            {children && <span className={styles.Badge__text}>{children}</span>}
            {removable && (
                <Icon className={styles.Badge__remove} icon={Icons.CLOSE} />
            )}
        </div>
    );
};

export default memo(Badge);
