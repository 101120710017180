import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { RoutePath } from 'shared/config/routeConfig';

export function technologyDetailsRoute(
    technologyId: string,
    technologyType?: string
) {
    return `${RoutePath.technologies}/${
        technologyType && `${technologyType}/`
    }${technologyId}`;
}

export function useNavigateToTechnologyDetails() {
    const navigate = useNavigate();

    return useCallback(
        (technologyId: string) => {
            navigate(technologyDetailsRoute(technologyId));
        },
        [navigate]
    );
}
