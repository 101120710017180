import React, { memo } from 'react';
import {
    Box,
    Center,
    Divider,
    Group,
    HoverCard,
    SegmentedControl,
    Text,
    Title
} from '@mantine/core';
import { Icon, Icons, IconSize } from 'shared/ui/Icon';
import HelpHoverCard from 'shared/ui/HelpHoverCard/HelpHoverCard';

export interface ActiveEntitiesFilterControlProps {
    entity: 'assets' | 'vulnerabilities' | 'misconfigurations';
    title?: string;
    helpText?: string;
    onActive: () => void;
    onAll: () => void;
}

const ActiveEntitiesFilterControl = (
    props: ActiveEntitiesFilterControlProps
) => {
    const { entity = 'assets', title, helpText, onActive, onAll } = props;

    return (
        <Group wrap="nowrap">
            <SegmentedControl
                transitionDuration={0}
                size="md"
                color="asm"
                data={[
                    {
                        value: 'active',
                        label: `Show active ${entity} only`
                    },
                    { value: 'all', label: `Show all ${entity}` }
                ]}
                defaultValue="active"
                onChange={value => {
                    if (value === 'active') {
                        onActive();
                    } else {
                        onAll();
                    }
                }}
            />
            {helpText && (
                <HelpHoverCard
                    title={title || `What are active ${entity}?`}
                    description={helpText}
                />
            )}
        </Group>
    );
};

export default memo(ActiveEntitiesFilterControl);
