import { DataTableColumn } from 'mantine-datatable';
import {
    Asset,
    AssetQueryParams,
    GeolocationAddress,
} from 'entities/Asset';
import { BaseColumnProps } from 'widgets/Columns/baseColumn';
import React from 'react';

type RecordType = Asset;
type QueryParams = AssetQueryParams;

export default ({
    column
}: BaseColumnProps<RecordType, QueryParams>): DataTableColumn<RecordType> => ({
    width: 'fit-content',
    accessor: 'metadata.geoip.city',
    title: 'Geolocation',
    render: ({ metadata }) => (
        <GeolocationAddress geolocation={metadata?.geoip} />
    ),
    ...column
});
