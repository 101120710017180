import { DataTableColumn } from 'mantine-datatable';
import { SearchFilter } from 'shared/ui/DataTable';
import React from 'react';
import { BaseColumnProps } from 'widgets/Columns/baseColumn';
import {
    Technology,
    TechnologiesQueryParams,
    TechnologyLink
} from 'entities/Technology';

export default ({
    query,
    setQuery,
    column
}: BaseColumnProps<
    Technology,
    TechnologiesQueryParams
>): DataTableColumn<Technology> => ({
    accessor: 'name',
    title: 'Technology',
    sortable: true,
    filter: query && setQuery && (
        <SearchFilter
            label="Technology"
            description="Show technologies whose names contain the specified text."
            defaultQuery={query.nameIncludes}
            onFilter={nameIncludes => {
                if (setQuery) {
                    setQuery({ ...query, nameIncludes });
                }
            }}
        />
    ),
    filtering: Boolean(query?.nameIncludes),
    render: technology => <TechnologyLink technology={technology} />,
    ...column
});
