import { useSearchParams } from 'react-router-dom';
import { IssueSearchParams, LastSeenPreset, Rating } from '../types/issue';

export function useIssueSearchParams(): IssueSearchParams {
    const [params] = useSearchParams();

    return {
        nameExact: params.get('nameExact') || undefined,
        rating: (params.get('rating') as Rating) || undefined,
        lastSeenPreset:
            (params.get('lastSeenPreset') as LastSeenPreset) || undefined,
        lastSeenAtGte: Number(params.get('lastSeenAtGte')) || undefined,
        lastSeenAtLte: Number(params.get('lastSeenAtLte')) || undefined,
        createdAtGte: Number(params.get('createdAtGte')) || undefined,
        createdAtLte: Number(params.get('createdAtLte')) || undefined
    };
}
