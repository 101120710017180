import React, { memo } from 'react';
import { Text } from 'shared/ui/Text';
import TimeAgo from 'timeago-react';
import { MetadataItem } from 'shared/ui/Metadata';
import {
    Asset,
    RootAsset,
    RootAssetCandidate
} from '../../../model/types/asset';

interface MetadataItemFirstSeenProps {
    asset: Asset | RootAsset | RootAssetCandidate;
}

const MetadataItemFirstSeen = (props: MetadataItemFirstSeenProps) => {
    const { asset } = props;

    return (
        <MetadataItem
            label="First Seen"
            value={
                <Text tooltipText={asset.createdAtParsed}>
                    <TimeAgo datetime={asset.createdAt} />
                </Text>
            }
        />
    );
};

export default memo(MetadataItemFirstSeen);
