import { AssetQueryParams, Asset, AssetLink } from 'entities/Asset';
import { DataTableColumn } from 'mantine-datatable';
import { SearchFilter } from 'shared/ui/DataTable';
import React from 'react';
import { BaseColumnProps } from 'widgets/Columns/baseColumn';

export default ({
    query,
    setQuery,
    column
}: BaseColumnProps<Asset, AssetQueryParams>): DataTableColumn<Asset> => ({
    accessor: 'value',
    title: 'Asset Name',
    sortable: true,
    filter: query && setQuery && (
        <SearchFilter
            label="Asset"
            description="Show assets whose names contain the specified text."
            defaultQuery={query.valueIncludes}
            onFilter={valueIncludes => {
                if (setQuery) {
                    setQuery({ ...query, valueIncludes });
                }
            }}
        />
    ),
    filtering: Boolean(query?.valueIncludes),
    render: asset => <AssetLink asset={asset}>{asset.value}</AssetLink>,
    ...column
});
