import React, { memo, useMemo } from 'react';
import { useGetLogActions } from 'entities/Log';
import { Icon, Icons } from 'shared/ui/Icon';
import { MultiSelect } from '@mantine/core';

export interface FilterByLogActionFormProps {
    selectedActions?: string[];
    onChange?: (value: string[]) => void;
}

const FilterByLogActionForm = (props: FilterByLogActionFormProps) => {
    const { selectedActions, onChange } = props;
    const { data: actions, isLoading } = useGetLogActions();

    const options = useMemo(
        () =>
            actions
                ?.map(action => ({
                    value: action,
                    label: action
                }))
                .filter(
                    (option, index, self) =>
                        index === self.findIndex(t => t.value === option.value)
                ),
        [actions]
    );

    return (
        <MultiSelect
            clearable
            searchable
            label="Event Actions"
            description="Show all actions whose names contain the specified text."
            data={options}
            value={!isLoading ? selectedActions : ['Loading...']}
            disabled={isLoading}
            leftSection={<Icon icon={Icons.SEARCH} />}
            comboboxProps={{ withinPortal: !options?.length }}
            onChange={onChange}
            style={{ maxWidth: 600 }}
        />
    );
};

export default memo(FilterByLogActionForm);
