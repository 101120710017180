import { Tag } from 'entities/Tag';
import { useMemo } from 'react';

export function useEditableTags(tags: Tag[] | undefined) {
    const editableTags = useMemo(
        () => tags?.filter(tag => !tag.isSystem),
        [tags]
    );
    const hasEditableTags = useMemo(
        () => editableTags && editableTags.length > 0,
        [editableTags]
    );

    return {
        editableTags: editableTags || [],
        hasEditableTags: hasEditableTags || false
    };
}
