import { ActionIcon, ActionIconProps } from '@mantine/core';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { Icon, Icons, IconSize } from 'shared/ui/Icon';
import { updateAssetScannerSettingsActions } from 'features/asset/actions/UpdateAssetScannerSettings';
import { Asset, RootAsset } from 'entities/Asset';
import React from 'react';

export interface EditScanModeIconProps extends ActionIconProps {
    asset: Asset | RootAsset;
}

const EditScanModeIcon = (props: EditScanModeIconProps) => {
    const { asset, ...actionIconProps } = props;
    const dispatch = useAppDispatch();

    return (
        <ActionIcon
            size="sm"
            variant="subtle"
            onClick={() => {
                dispatch(updateAssetScannerSettingsActions.setAsset(asset));
            }}
            {...actionIconProps}
        >
            <Icon
                icon={Icons.SETTINGS}
                style={{
                    fill: 'black'
                }}
            />
        </ActionIcon>
    );
};

export default EditScanModeIcon;
