import { memo } from 'react';
import * as echarts from 'echarts/core';
import {
    TooltipComponent,
    GridComponent,
    LegendComponent
} from 'echarts/components';
import { PieChart as EPieChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { EChartsOption } from 'echarts';
import ReactECharts from 'echarts-for-react';
import { RefetchableComponent } from 'widgets/RefetchableComponent';
import styles from './PieChart.module.scss';

echarts.use([
    TooltipComponent,
    GridComponent,
    LegendComponent,
    EPieChart,
    CanvasRenderer,
    UniversalTransition
]);

type EventParams = {
    componentType: string;
    seriesType: string;
    seriesIndex: number;
    seriesName: string;
    name: string;
    dataIndex: number;
    data: Object;
    dataType: string;
    value: number | Array<any>;
    color: string;
};

interface PieChartProps {
    series: EChartsOption['series'];
    isLoading?: boolean;
    noData?: boolean;
    onClick?: (params: EventParams) => void;
}

function PieChart(props: PieChartProps) {
    const { series, isLoading, noData, onClick = () => {} } = props;
    const options: EChartsOption = {
        tooltip: {
            trigger: 'item'
        },
        series
    };

    const onEvents = {
        click: (params: EventParams) => onClick(params)
    };

    return (
        <RefetchableComponent isFetching={isLoading}>
            {noData && <div className={styles['no-data']}>No data</div>}
            {!noData && (
                <ReactECharts
                    className={styles.chart}
                    option={options}
                    onEvents={onEvents}
                />
            )}
        </RefetchableComponent>
    );
}

export default memo(PieChart);
