import React, { memo } from 'react';
import { Text } from 'shared/ui/Text';
import TimeAgo from 'timeago-react';
import { MetadataItem } from 'shared/ui/Metadata';
import {
    Asset,
    RootAsset,
    RootAssetCandidate
} from '../../../model/types/asset';

interface MetadataItemLastSeenProps {
    asset: Asset | RootAsset | RootAssetCandidate;
}

const MetadataItemLastSeen = (props: MetadataItemLastSeenProps) => {
    const { asset } = props;

    return (
        <MetadataItem
            label="Last Seen"
            value={
                asset.lastSeenAt ? (
                    <Text tooltipText={asset.lastSeenAtParsed}>
                        <TimeAgo datetime={asset.lastSeenAt} />
                    </Text>
                ) : (
                    'Not defined'
                )
            }
        />
    );
};

export default memo(MetadataItemLastSeen);
