import { Asset, RootAsset, RootAssetCandidate } from 'entities/Asset';
import { Stack } from '@mantine/core';
import React from 'react';
import HeaderedCard from 'shared/ui/HeaderedCard/HeaderedCard';
import { FoundOnPagesTable } from 'widgets/Asset';

interface EmailOverviewProps {
    asset: Asset | RootAsset | RootAssetCandidate;
}

const EmailOverview = (props: EmailOverviewProps) => {
    const { asset } = props;

    return (
        <>
            {asset.foundOnPages && asset.foundOnPages.length > 0 && (
                <HeaderedCard
                    header="Discovered on the following pages"
                    bodyPadding="0"
                >
                    <FoundOnPagesTable pages={asset.foundOnPages} />
                </HeaderedCard>
            )}
        </>
    );
};

export default EmailOverview;
