import React, { memo } from 'react';
import { Badge, Button } from '@mantine/core';
import {
    Asset,
    AssetType,
    AssetTypeIcon,
    AssetTypeLabel,
    RootAsset,
    RootAssetCandidate
} from '../../../model/types/asset';

interface AssetTypeBadgeProps {
    asset: RootAsset | Asset | RootAssetCandidate;
    fullWidth?: boolean;
    selectedAssetTypes?: AssetType[];
    setSelectedAssetTypes?: (assetTypes: AssetType[]) => void;
}

const AssetTypeBadge = (props: AssetTypeBadgeProps) => {
    const {
        asset,
        fullWidth = false,
        selectedAssetTypes = [],
        setSelectedAssetTypes
    } = props;
    const clickable = setSelectedAssetTypes !== undefined;

    if (!asset || !asset.type) return null;

    const icon =
        AssetTypeIcon[asset.type.toUpperCase() as keyof typeof AssetTypeIcon];
    const label =
        AssetTypeLabel[asset.type.toUpperCase() as keyof typeof AssetTypeLabel];
    const labelPrefix = (() => {
        switch (true) {
            case asset.type === AssetType.PORT:
                if (!asset.metadata?.port) return null;
                return asset.metadata.port.protocol.toUpperCase();
            default:
                return null;
        }
    })();

    if (!icon || !label) return null;

    if (clickable) {
        return (
            <Button
                size="compact-xs"
                fullWidth
                onClick={() => {
                    const assetTypeSelected = selectedAssetTypes.includes(
                        asset.type
                    );
                    if (!assetTypeSelected)
                        setSelectedAssetTypes([
                            ...selectedAssetTypes,
                            asset.type
                        ]);
                }}
            >
                {labelPrefix}
                {labelPrefix && ' '}
                {label}
            </Button>
        );
    }

    return (
        <Badge fullWidth={fullWidth}>
            {labelPrefix}
            {labelPrefix && ' '}
            {label}
        </Badge>
    );
};

export default memo(AssetTypeBadge);
