import React, { memo, useMemo, useState } from 'react';
import {
    useGetTechnologies,
    Technology,
    TechnologiesQueryParams
} from 'entities/Technology';
import { DataTableColumn } from 'mantine-datatable';
import { GenericTable } from 'shared/ui/DataTable';
import lastSeenColumn from 'widgets/Columns/lastSeenColumn';
import createdAtColumn from 'widgets/Columns/createdAtColumn';
import technologyNameColumn from 'widgets/Columns/technologyNameColumn';
import assetsCountColumn from 'widgets/Columns/assetsCountColumn';
import discoveryMethodColumn from 'widgets/Columns/discoveryMethodColumn';
import categoriesColumn from 'widgets/Columns/categoriesColumn';
import versionColumn from 'widgets/Columns/versionColumn';
import issuesCountColumn from 'widgets/Columns/issuesCountColumn';

interface TechnologiesTableProps {
    defaultAssetID?: string;
    includeFalsePositiveAssets?: boolean;
}

const TechnologiesTable = (props: TechnologiesTableProps) => {
    const { defaultAssetID, includeFalsePositiveAssets = false } = props;
    const [query, setQuery] = useState<TechnologiesQueryParams>({
        assetID: defaultAssetID ? [defaultAssetID] : undefined,
        isAssetFalsePositive: includeFalsePositiveAssets
    });
    const columns = useMemo<DataTableColumn<Technology>[]>(
        () => [
            technologyNameColumn({
                query,
                setQuery
            }),
            versionColumn({
                query,
                setQuery
            }),
            issuesCountColumn({
                query,
                setQuery
            }),
            assetsCountColumn({
                query,
                setQuery
            }),
            categoriesColumn({
                query,
                setQuery
            }),
            discoveryMethodColumn({
                query,
                setQuery
            }),
            lastSeenColumn({
                query,
                setQuery,
                column: {
                    title: 'Last Detected'
                }
            }),
            createdAtColumn({
                query,
                setQuery,
                column: {
                    title: 'First Detected'
                }
            })
        ],
        [query]
    );

    return (
        <GenericTable<Technology>
            // @ts-ignore
            useData={useGetTechnologies}
            columns={columns}
            queryParams={query}
            defaultSortStatus={{
                columnAccessor: 'name',
                direction: 'asc'
            }}
            hasToolbar
            hasDownload
        />
    );
};

export default memo(TechnologiesTable);
