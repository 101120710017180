import React, {
    memo,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { DataTableColumn, DataTableSortStatus } from 'mantine-datatable';
import { DataTable, DateColumn, SearchFilter } from 'shared/ui/DataTable';
import { AssetLink, AssetTypeBadge } from 'entities/Asset';
import { Log, LogLevels, useGetLogs } from 'entities/Log';
import { DateRangeFilterForm } from 'features/common/filters/DateRangeFilter';
import { DatesRangeValue } from '@mantine/dates';
import dayjs from 'dayjs';
import { FilterByLogActionForm } from 'features/log/filters/FilterByLogAction';
import { Badge, Button, Select } from '@mantine/core';

interface LogsTableProps {
    defaultAssetID?: string;
    predefinedLogLevel?: LogLevels;
}

const LogsTable = (props: LogsTableProps) => {
    const { predefinedLogLevel, defaultAssetID } = props;

    const [sortStatus, setSortStatus] = useState<DataTableSortStatus<Log>>({
        columnAccessor: 'lastSeenAt',
        direction: 'desc'
    });

    const scrollViewportRef = useRef<HTMLDivElement>(null);
    const [logs, setLogs] = useState<Log[]>([]);
    const [query, setQuery] = useState('');
    const [logLevel, setLogLevel] = useState<LogLevels | undefined>(
        predefinedLogLevel
    );
    const [isLogSystem, setIsLogSystem] = useState<string>();
    const [selectedActions, setSelectedActions] = useState<string[]>([]);
    const [createdAtRange, setCreatedAtAtRange] = useState<
        DatesRangeValue | undefined
    >(undefined);
    const [searchAfter, setSearchAfter] = useState<string | undefined>();
    const { data, isLoading, isFetching } = useGetLogs({
        size: 100,
        searchAfter,
        level: logLevel,
        action: selectedActions,
        assetId: defaultAssetID,
        isSystem: isLogSystem,
        assetValueIncludes: query,
        timestampGte: createdAtRange?.[0]
            ? dayjs(createdAtRange?.[0]).startOf('D').unix()
            : undefined,
        timestampLt: createdAtRange?.[1]
            ? dayjs(createdAtRange?.[1]).endOf('D').unix()
            : undefined
    });

    const columns = useMemo<DataTableColumn<Log>[]>(
        () => [
            {
                width: 150,
                accessor: 'isSystem',
                title: 'Event Source',
                filtering: isLogSystem !== undefined,
                filter: (
                    <Select
                        data={[
                            { value: 'all', label: 'All sources' },
                            { value: 'true', label: 'System events only' },
                            { value: 'false', label: 'User events only' }
                        ]}
                        defaultValue="all"
                        value={isLogSystem}
                        onChange={value => {
                            if (value === 'all') {
                                setIsLogSystem(undefined);
                                return;
                            }

                            setIsLogSystem(value === 'true' ? 'true' : 'false');
                        }}
                    />
                ),
                render: log =>
                    log.isSystem ? (
                        <Badge color="blue" fullWidth>
                            System
                        </Badge>
                    ) : (
                        <Badge color="green" fullWidth>
                            User
                        </Badge>
                    )
            },
            {
                width: 'fit-content',
                accessor: 'messageId',
                title: 'Message ID'
            },
            {
                width: 'fit-content',
                accessor: 'action',
                title: 'Action',
                filtering: selectedActions.length > 0,
                filter: (
                    <FilterByLogActionForm
                        selectedActions={selectedActions}
                        onChange={setSelectedActions}
                    />
                ),
                render: ({ action }) => (
                    <Button
                        size="compact-xs"
                        variant="light"
                        onClick={() => {
                            const actionSelected =
                                selectedActions.includes(action);
                            if (!actionSelected) {
                                setSelectedActions([
                                    ...selectedActions,
                                    action
                                ]);
                            }
                        }}
                    >
                        {action}
                    </Button>
                )
            },
            {
                ellipsis: true,
                width: 'fit-content',
                accessor: 'asset.value',
                title: 'Related Asset',
                filter: (
                    <SearchFilter
                        label="Root Asset"
                        description="Show assets whose names contain the specified text."
                        defaultQuery={query}
                        onFilter={setQuery}
                    />
                ),
                filtering: query !== '',
                render: log => (
                    <AssetLink asset={log.asset}>
                        {log.asset?.value || '-'}
                    </AssetLink>
                )
            },
            {
                width: 130,
                accessor: 'type',
                title: 'Type',
                render: log => <AssetTypeBadge asset={log.asset} fullWidth />
            },

            {
                width: 190,
                accessor: 'createdAt',
                title: 'Created At',
                filtering: Boolean(createdAtRange),
                filter: ({ close }) => (
                    <DateRangeFilterForm
                        dateRangeValue={createdAtRange}
                        setDateRangeValue={setCreatedAtAtRange}
                        close={close}
                    />
                ),
                render: ({ timestampMs }) => (
                    <DateColumn date={timestampMs} timeAgo />
                )
            }
        ],
        [createdAtRange, isLogSystem, query, selectedActions]
    );

    useEffect(() => {
        setLogs([]);
    }, [query, createdAtRange, selectedActions, isLogSystem]);

    useEffect(() => {
        if (data) {
            setLogs(prevLogs => [...prevLogs, ...data.data]);
        }
    }, [data]);

    const onNextPage = useCallback(() => {
        if (!data) {
            return;
        }

        setSearchAfter(data.searchAfter);
    }, [data]);

    return (
        <DataTable
            idAccessor="messageId"
            records={logs}
            fetching={isLoading || isFetching}
            columns={columns}
            sortStatus={sortStatus}
            onSortStatusChange={setSortStatus}
            onScrollToBottom={onNextPage}
            scrollViewportRef={scrollViewportRef}
        />
    );
};

export default memo(LogsTable);
