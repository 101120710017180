import React, { memo } from 'react';
import { Issue } from 'entities/Issue';
import { ExternalLink } from 'shared/ui/ExternalLink';
import { Badge } from '@mantine/core';

interface CvssBadgeProps {
    issue: Issue;
}

const CveBadge = (props: CvssBadgeProps) => {
    const { issue } = props;
    const { cve } = issue;

    if (!cve) return null;

    return (
        <Badge color="gray" variant="light" key={cve.id}>
            <ExternalLink href={cve.url}>{cve.id}</ExternalLink>
        </Badge>
    );
};

export default memo(CveBadge);
