import React, { memo, useMemo } from 'react';
import { Icon, Icons } from 'shared/ui/Icon';
import { Port } from 'entities/Asset';
import { Badge } from '@mantine/core';

interface PortOpennessBadgeProps {
    port?: Port;
    fullWidth?: boolean;
}

const PortOpennessBadge = (props: PortOpennessBadgeProps) => {
    const { port, fullWidth } = props;
    const isOpen = port?.isOpen;
    const icon = useMemo(() => {
        switch (true) {
            case typeof isOpen === 'boolean' && isOpen:
                return Icons.LOCK_OPEN;
            case typeof isOpen === 'boolean' && !isOpen:
                return Icons.LOCK;
            default:
                return undefined;
        }
    }, [isOpen]);
    const label = useMemo(() => {
        switch (true) {
            case typeof isOpen === 'boolean' && isOpen:
                return 'Open';
            case typeof isOpen === 'boolean' && !isOpen:
                return 'Closed';
            default:
                return 'N/A';
        }
    }, [isOpen]);

    return (
        <Badge
            leftSection={icon && <Icon icon={icon} />}
            fullWidth={fullWidth}
            color="gray"
        >
            {label.toUpperCase()}
        </Badge>
    );
};

export default memo(PortOpennessBadge);
