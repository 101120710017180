import React, { memo } from 'react';
import {
    useGetIssueStatistics,
    useGetLastIssueStatistics,
    useGetLastStatisticsByVulnerabilityRating
} from 'entities/Statistics';
import { Icons } from 'shared/ui/Icon';
import dayjs from 'dayjs';
import StatisticsCard from 'shared/ui/StatisticsCard/StatisticsCard';
import { Box, Group, NumberFormatter, RingProgress, Text } from '@mantine/core';
import { Rating, severityRatingColor } from 'entities/Issue';
import { NavLink } from 'react-router-dom';

interface TotalIssueStatisticsCardProps {
    title: string;
    icon: Icons;
    link?: string;
}

const TotalIssueDistribution = () => {
    const ringSize = 60;
    const { data } = useGetLastStatisticsByVulnerabilityRating({});

    const textSize = (value: number) => {
        if (value < 100) {
            return 'lg';
        }

        if (value < 1000) {
            return 'md';
        }

        return 'sm';
    };

    return (
        <Group gap={0} style={{ position: 'absolute', right: 10, bottom: 14 }}>
            {Object.entries(data?.distribution ?? {})
                .reverse()
                .map(([severity, value]) => (
                    <NavLink
                        key={severity}
                        to={`/issues/all?rating=${severity}&lastSeenPreset=all`}
                        color="black"
                    >
                        <RingProgress
                            size={ringSize}
                            thickness={ringSize / 10}
                            label={
                                <Text
                                    fw={700}
                                    ta="center"
                                    size={textSize(value.count)}
                                    style={{ whiteSpace: 'nowrap' }}
                                >
                                    <NumberFormatter
                                        value={value.count}
                                        thousandSeparator
                                    />
                                </Text>
                            }
                            sections={[
                                {
                                    value: 100,
                                    color: value.count
                                        ? severityRatingColor(
                                              severity as Rating
                                          )
                                        : 'gray.4'
                                }
                            ]}
                        />
                    </NavLink>
                ))}
        </Group>
    );
};

const TotalIssueStatisticsCard = (props: TotalIssueStatisticsCardProps) => {
    const { title, icon, link } = props;
    const startDate = dayjs().endOf('day').subtract(1, 'week').unix();
    const endDate = startDate + 1;

    const currentStatistics = useGetLastIssueStatistics({});
    const oldStatistics = useGetIssueStatistics({
        startDate,
        endDate
    });

    const isLoading = currentStatistics.isLoading || oldStatistics.isLoading;
    const actualTotal = currentStatistics.data
        ? currentStatistics.data.total.count
        : undefined;
    const oldTotal = oldStatistics.data
        ? oldStatistics.data[0].total.count
        : undefined;
    const diff = actualTotal && oldTotal && actualTotal - oldTotal;

    return (
        <Box style={{ position: 'relative' }}>
            <StatisticsCard
                link={link}
                title={title}
                icon={icon}
                primaryValue={actualTotal}
                secondaryValue={diff}
                isLoading={isLoading}
                note="Compared to previous week"
                additionalContent={<TotalIssueDistribution />}
            />
        </Box>
    );
};

export default memo(TotalIssueStatisticsCard);
