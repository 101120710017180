import React, { memo, useMemo } from 'react';
import { Badge, Button } from '@mantine/core';
import { Asset, RootAsset } from 'entities/Asset';
import { ScanMode, ScanModeLabel } from '../../model/types/config';

interface ScannerModeBadgeProps {
    asset: RootAsset | Asset;
    fullWidth?: boolean;
    selectedScanModes?: ScanMode[];
    setSelectedScanModes?: (scanModes: ScanMode[]) => void;
}

const ScannerModeBadge = (props: ScannerModeBadgeProps) => {
    const {
        asset,
        fullWidth,
        selectedScanModes = [],
        setSelectedScanModes
    } = props;
    const clickable = setSelectedScanModes !== undefined;
    const { scanMode } = asset;

    const label =
        ScanModeLabel[scanMode.toUpperCase() as keyof typeof ScanModeLabel];
    const color = useMemo(() => {
        switch (scanMode) {
            case ScanMode.ACTIVE:
                return 'green';
            case ScanMode.INHERIT:
                return 'gray.4';
            case ScanMode.DISABLED:
                return 'red';
            default:
                return 'asm';
        }
    }, [scanMode]);

    if (!asset || !scanMode) return null;
    if (clickable) {
        return (
            <Button
                size="compact-xs"
                color={color}
                fullWidth
                onClick={() => {
                    const scanModeSelected =
                        selectedScanModes.includes(scanMode);
                    if (!scanModeSelected)
                        setSelectedScanModes([
                            ...selectedScanModes,
                            asset.scanMode
                        ]);
                }}
            >
                {label}
            </Button>
        );
    }

    return (
        <Badge color={color} fullWidth={fullWidth}>
            {label}
        </Badge>
    );
};

export default memo(ScannerModeBadge);
