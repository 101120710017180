import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { RoutePath } from 'shared/config/routeConfig';

export function rootAssetDetailsRoute(assetId: string) {
    return `${RoutePath.management}/root-assets/${assetId}`;
}

export function useNavigateToRootAssetDetails() {
    const navigate = useNavigate();

    return useCallback(
        (assetId: string) => {
            navigate(rootAssetDetailsRoute(assetId));
        },
        [navigate]
    );
}
