import React, { memo, useMemo, useState } from 'react';
import { DataTableColumn } from 'mantine-datatable';
import { GenericTable } from 'shared/ui/DataTable';
import {
    Asset,
    AssetType,
    AssetQueryParams,
    WithMetaType,
    useGetAssets
} from 'entities/Asset';
import assetNameColumn from 'widgets/Columns/assetNameColumn';
import sslCertificateSubjectColumn from 'widgets/Columns/sslCertificateSubjectColumn';
import sslCertificateStatusColumn from 'widgets/Columns/sslCertificateStatusColumn';
import sslCertificateNotValidBeforeColumn from 'widgets/Columns/sslCertificateNotValidBeforeColumn';
import sslCertificateNotValidAfterColumn from 'widgets/Columns/sslCertificateNotValidAfterColumn';
import sslCertificateIssuerColumn from 'widgets/Columns/sslCertificateIssuerColumn';
import tagsColumn from 'widgets/Columns/tagsColumn';
import lastSeenColumn from 'widgets/Columns/lastSeenColumn';
import createdAtColumn from 'widgets/Columns/createdAtColumn';
import assetTypeColumn from 'widgets/Columns/assetTypeColumn';
import rootAssetSelectColumn from 'widgets/Columns/rootAssetSelectColumn';
import whoisRegistrarNameColumn from 'widgets/Columns/whoisRegistrarNameColumn';
import whoisCreatedDateColumn from 'widgets/Columns/whoisCreatedDateColumn';
import whoisExpiresDateColumn from 'widgets/Columns/whoisExpiresDateColumn';
import geolocationColumn from 'widgets/Columns/geolocationColumn';
import asnColumn from 'widgets/Columns/asnColumn';
import portOpennessColumn from 'widgets/Columns/portOpennessColumn';
import portProductColumn from 'widgets/Columns/portProductColumn';
import { ActiveEntitiesFilterControl } from 'features/common/filters/ActiveEntitiesFilter';
import dayjs from 'dayjs';
import vulnerabilityStatsColumn from 'widgets/Columns/vulnerabilityStatsColumn';

interface AssetsTableProps {
    defaultRootAssetID?: string;
    defaultTechnologyID?: string;
    defaultAssetType?: AssetType;
    defaultCreatedAtGte?: number;
    defaultCreatedAtLte?: number;
    title?: string;
    hasActiveEntitiesFilter?: boolean;
    isArchived?: boolean;
}

const AssetsTable = (props: AssetsTableProps) => {
    const {
        defaultRootAssetID,
        defaultTechnologyID,
        defaultAssetType,
        defaultCreatedAtGte,
        defaultCreatedAtLte,
        hasActiveEntitiesFilter = true,
        isArchived,
        title
    } = props;

    const withMetaQuery = useMemo<WithMetaType[]>(() => {
        const query: WithMetaType[] = [];

        if (defaultAssetType === AssetType.DOMAIN) {
            query.push(WithMetaType.WHOIS_RECORD);
        }

        if (
            defaultAssetType === AssetType.IPV4 ||
            defaultAssetType === AssetType.IPV6
        ) {
            query.push(WithMetaType.GEOIP);
            query.push(WithMetaType.SUBNETWORK);
        }

        if (defaultAssetType === AssetType.PORT) {
            query.push(WithMetaType.PORT);
        }

        if (defaultAssetType === AssetType.SSL) {
            query.push(WithMetaType.SSL_CERTIFICATE);
        }

        return query;
    }, [defaultAssetType]);

    const defaultLastSeenQuery = useMemo(() => {
        if (hasActiveEntitiesFilter) {
            return {
                lastSeenAtGte: dayjs().subtract(7, 'days').unix(),
                lastSeenAtLte: dayjs().unix()
            };
        }

        return {};
    }, [hasActiveEntitiesFilter]);

    const defaultQuery = useMemo<AssetQueryParams>(
        () => ({
            rootAssetID: defaultRootAssetID ? [defaultRootAssetID] : undefined,
            techID: defaultTechnologyID ? [defaultTechnologyID] : undefined,
            assetType: defaultAssetType ? [defaultAssetType] : undefined,
            withMeta: withMetaQuery,
            createdAtGte: defaultCreatedAtGte,
            createdAtLte: defaultCreatedAtLte,
            isFalsePositive: isArchived,
            ...defaultLastSeenQuery
        }),
        [
            withMetaQuery,
            defaultAssetType,
            defaultRootAssetID,
            defaultTechnologyID,
            defaultCreatedAtGte,
            defaultCreatedAtLte,
            defaultLastSeenQuery
        ]
    );

    const [query, setQuery] = useState<AssetQueryParams>(defaultQuery);

    const isDomain = defaultAssetType === AssetType.DOMAIN;
    const isSSL = defaultAssetType === AssetType.SSL;
    const isPort = defaultAssetType === AssetType.PORT;
    const isIP =
        defaultAssetType === AssetType.IPV4 ||
        defaultAssetType === AssetType.IPV6;

    const columns = useMemo<DataTableColumn<Asset>[]>(
        () => [
            assetNameColumn({
                query,
                setQuery
            }),
            assetTypeColumn({
                query,
                setQuery,
                column: {
                    hidden: Boolean(defaultAssetType)
                }
            }),
            vulnerabilityStatsColumn({
                query,
                setQuery
            }),
            rootAssetSelectColumn({
                query,
                setQuery,
                column: {
                    hidden: Boolean(defaultRootAssetID)
                }
            }),
            whoisRegistrarNameColumn({
                column: {
                    hidden: !isDomain
                }
            }),
            whoisCreatedDateColumn({
                column: {
                    hidden: !isDomain
                }
            }),
            whoisExpiresDateColumn({
                column: {
                    hidden: !isDomain
                }
            }),
            geolocationColumn({
                column: {
                    hidden: !isIP
                }
            }),
            asnColumn({
                column: {
                    hidden: !isIP
                }
            }),
            sslCertificateSubjectColumn({
                column: {
                    hidden: !isSSL
                }
            }),
            sslCertificateStatusColumn({
                column: {
                    hidden: !isSSL
                }
            }),
            sslCertificateNotValidBeforeColumn({
                column: {
                    hidden: !isSSL
                }
            }),
            sslCertificateNotValidAfterColumn({
                column: {
                    hidden: !isSSL
                }
            }),
            sslCertificateIssuerColumn({
                column: {
                    hidden: !isSSL
                }
            }),
            portOpennessColumn({
                column: {
                    hidden: !isPort
                }
            }),
            portProductColumn({
                column: {
                    hidden: !isPort
                }
            }),
            tagsColumn({
                query,
                setQuery
            }),
            lastSeenColumn({
                query,
                setQuery
            }),
            createdAtColumn({
                query,
                setQuery
            })
        ],
        [
            defaultAssetType,
            defaultRootAssetID,
            isDomain,
            isIP,
            isPort,
            isSSL,
            query
        ]
    );

    return (
        <GenericTable<Asset>
            // @ts-ignore
            useData={useGetAssets}
            columns={columns}
            queryParams={query}
            defaultSortStatus={{
                columnAccessor: 'lastSeenAt',
                direction: 'desc'
            }}
            hasToolbar
            hasDownload
            title={title}
            toolbarActions={
                hasActiveEntitiesFilter && (
                    <ActiveEntitiesFilterControl
                        entity="assets"
                        helpText="Active assets are assets that have been seen within the last 7 days. By default Attaxion shows only active assets to help you focus on the most important assets."
                        onActive={() => {
                            setQuery({
                                ...defaultQuery,
                                lastSeenAtGte: dayjs()
                                    .subtract(7, 'days')
                                    .unix()
                            });
                        }}
                        onAll={() => {
                            setQuery({
                                ...defaultQuery,
                                lastSeenAtGte: undefined,
                                lastSeenAtLte: undefined
                            });
                        }}
                    />
                )
            }
        />
    );
};

export default memo(AssetsTable);
