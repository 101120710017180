import React, { memo } from 'react';
import { LogsTable } from 'widgets/Log';
import { LogLevels } from 'entities/Log';
import { Seo } from 'shared/ui/SEO/SEO';
import EventsBaseLayout from '../../ui/EventsBaseLayout/EventsBaseLayout';

function ErrorEventsPage() {
    return (
        <EventsBaseLayout>
            <Seo title="Error | Events" />
            <LogsTable predefinedLogLevel={LogLevels.ERROR} />
        </EventsBaseLayout>
    );
}

export default memo(ErrorEventsPage);
