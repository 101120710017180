import React, { memo } from 'react';
import { MultiSelect } from '@mantine/core';
import { Icon, Icons } from 'shared/ui/Icon';
import { Rating } from 'entities/Issue';

export interface FilterBySeverityRatingFormProps {
    selectedSeverityRatings?: Rating[];
    onChange?: (value: Rating[]) => void;
}

const FilterBySeverityRatingForm = (props: FilterBySeverityRatingFormProps) => {
    const { selectedSeverityRatings, onChange } = props;

    return (
        <MultiSelect
            clearable
            searchable
            label="Severity Rating"
            description="Show all issues belonging to the selected severity."
            data={[
                { value: 'critical', label: 'Critical' },
                { value: 'high', label: 'High' },
                { value: 'medium', label: 'Medium' },
                { value: 'low', label: 'Low' },
                { value: 'info', label: 'Info' }
            ]}
            value={selectedSeverityRatings}
            onChange={(value: string[]) => onChange?.(value as Rating[])}
            leftSection={<Icon icon={Icons.SEARCH} />}
            comboboxProps={{ withinPortal: false }}
            style={{ maxWidth: 600 }}
        />
    );
};

export default memo(FilterBySeverityRatingForm);
