import { useGetRootAssets } from 'entities/Asset';
import { useUser } from 'entities/User';
import { useCallback } from 'react';
import { ScanMode } from 'entities/Scanner';

export function useStartRootAssetScanningBanner() {
    const { user } = useUser();
    const { data, isLoading } = useGetRootAssets({
        page: 1,
        size: 100,
        valueStartsWith: user?.email_domain
    });

    const define = useCallback(() => {
        if (data) {
            const { data: assets } = data;
            const targetAsset = assets.find(
                asset =>
                    asset.scanMode === ScanMode.DISABLED &&
                    asset.value === user?.email_domain
            );

            return {
                asset: targetAsset,
                show: Boolean(targetAsset)
            };
        }

        return {
            asset: null,
            show: false
        };
    }, [data, user]);

    return define();
}
