import { DataTableColumn } from 'mantine-datatable';
import { BaseColumnProps } from 'widgets/Columns/baseColumn';
import { Technology, TechnologiesQueryParams } from 'entities/Technology';

export default ({
    query,
    setQuery,
    column
}: BaseColumnProps<
    Technology,
    TechnologiesQueryParams
>): DataTableColumn<Technology> => ({
    width: 'fit-content',
    accessor: 'version',
    title: 'Version',
    ...column
});
