import { memo } from 'react';
import { RoutePath } from 'shared/config/routeConfig';
import classNames from 'classnames';
import { useLayout } from 'app/providers/LayoutProvider';
import styles from './Logo.module.scss';

const DEFAULT_WIDTH = 254;
const DEFAULT_HEIGHT = 56;

interface LogoProps {
    className?: string;
    width?: number;
    height?: number;
    short?: boolean;
}

const Logo = (props: LogoProps) => {
    const {
        className,
        width = DEFAULT_WIDTH,
        height = DEFAULT_HEIGHT,
        short = false
    } = props;

    const { layout } = useLayout();
    const classes = classNames(styles.Logo, className, styles[layout]);

    return (
        <a href={RoutePath.dashboard} className={classes}>
            <img
                src={`/assets/${
                    short ? 'lettermark' : 'wordmark'
                }-logo-b.svg?v1.0`}
                alt={process.env.APP_NAME}
                width={width}
                height={height}
            />
        </a>
    );
};

export default memo(Logo);
