import { memo } from 'react';
import classNames from 'classnames';
import { Flex, Group } from '@mantine/core';
import styles from './PageHeader.module.scss';

interface HeaderProps {
    className?: string;
    title?: string;
    links?: React.ReactNode;
    actions?: React.ReactNode;
}

const PageHeader = (props: HeaderProps) => {
    const { className, title, links, actions } = props;
    const classes = classNames(styles.PageHeader, className);

    return (
        <div className={classes}>
            {title && <div className={styles.PageHeader__Title}>{title}</div>}
            {links && (
                <Flex gap={{ lg: 'md', xl: 'lg' }} wrap="nowrap" align="center">
                    {links}
                </Flex>
            )}
            {actions && (
                <div className={styles.PageHeader__Actions}>{actions}</div>
            )}
        </div>
    );
};

export default memo(PageHeader);
