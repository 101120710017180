import { useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import { InitContext } from '../InitContext';

function useGlobalDateRange() {
    const { subscription, scanningStoppedAt, setGlobalDateRange } =
        useContext(InitContext);

    useEffect(() => {
        if (subscription) {
            if (scanningStoppedAt) {
                setGlobalDateRange({
                    from: dayjs(scanningStoppedAt)
                        .startOf('hour')
                        .add(55, 'minutes')
                        .subtract(1, 'week')
                        .unix(),
                    to: dayjs(scanningStoppedAt).unix()
                });
            } else {
                setGlobalDateRange({
                    from: dayjs()
                        .startOf('hour')
                        .add(55, 'minutes')
                        .subtract(1, 'week')
                        .unix(),
                    to: dayjs().startOf('hour').add(55, 'minutes').unix()
                });
            }
        }
    }, [scanningStoppedAt, setGlobalDateRange, subscription]);

    return null;
}

export default useGlobalDateRange;
