import React, { memo, useMemo } from 'react';
import {
    Center,
    Divider,
    Grid,
    NumberFormatter,
    Skeleton,
    Stack,
    Text,
    Title
} from '@mantine/core';
import { severityRatingThemeColorName } from 'entities/Issue';
import { useGetVulnerabilitiesCount } from 'entities/Vulnerability';
import { NavLink } from 'react-router-dom';
import { useApp } from 'app/providers/InitProvider';
import { useGetAssetsDiscoveredWeekly } from 'entities/Statistics';
import { AssetType } from 'entities/Asset';

interface BreakdownRepresentationProps {
    title: string;
    color?: string;
    value?: number;
    isLoading: boolean;
    link?: string;
}

const BreakdownRepresentation = (props: BreakdownRepresentationProps) => {
    const { title, color, value, link, isLoading } = props;
    const valueTitleOrder = useMemo(() => {
        if (value) {
            switch (true) {
                case value > 10000:
                    return 3;
                default:
                    return 2;
            }
        }

        return 2;
    }, [value]);

    return (
        <>
            <Text size="sm" c={color || 'dimmed'} fw={700}>
                {title}
            </Text>
            {isLoading ? (
                <Skeleton height={35} width="100%" />
            ) : (
                <Title order={valueTitleOrder}>
                    {value ? (
                        link ? (
                            <NavLink to={link} color={color || 'black'}>
                                <NumberFormatter
                                    value={value}
                                    thousandSeparator
                                    style={{ whiteSpace: 'nowrap' }}
                                />
                            </NavLink>
                        ) : (
                            <NumberFormatter
                                value={value}
                                thousandSeparator
                                style={{ whiteSpace: 'nowrap' }}
                            />
                        )
                    ) : (
                        '-'
                    )}
                </Title>
            )}
        </>
    );
};

const BreakdownsByAssetType = () => {
    const { globalDateRange } = useApp();
    const { from: startDate, to: endDate } = globalDateRange || {};
    const { data: assetsDiscoveredWeekly, isLoading } =
        useGetAssetsDiscoveredWeekly({});

    const totalAssetsStatistics = useMemo(
        () =>
            assetsDiscoveredWeekly?.reduce((acc, item) => acc + item.added, 0),
        [assetsDiscoveredWeekly]
    );

    const domainStatistics = useMemo(
        () =>
            assetsDiscoveredWeekly?.find(
                item => item.assetType === AssetType.DOMAIN
            )?.added || 0,
        [assetsDiscoveredWeekly]
    );

    const ipv4Statistics = useMemo(
        () =>
            assetsDiscoveredWeekly?.find(
                item => item.assetType === AssetType.IPV4
            )?.added || 0,
        [assetsDiscoveredWeekly]
    );

    const ipv6Statistics = useMemo(
        () =>
            assetsDiscoveredWeekly?.find(
                item => item.assetType === AssetType.IPV6
            )?.added || 0,
        [assetsDiscoveredWeekly]
    );

    const sslStatistics = useMemo(
        () =>
            assetsDiscoveredWeekly?.find(
                item => item.assetType === AssetType.SSL
            )?.added || 0,
        [assetsDiscoveredWeekly]
    );

    const portStatistics = useMemo(
        () =>
            assetsDiscoveredWeekly?.find(
                item => item.assetType === AssetType.PORT
            )?.added || 0,
        [assetsDiscoveredWeekly]
    );

    const emailStatistics = useMemo(
        () =>
            assetsDiscoveredWeekly?.find(
                item => item.assetType === AssetType.EMAIL
            )?.added || 0,
        [assetsDiscoveredWeekly]
    );

    const cidrStatistics = useMemo(
        () =>
            assetsDiscoveredWeekly?.find(
                item => item.assetType === AssetType.CIDR
            )?.added || 0,
        [assetsDiscoveredWeekly]
    );

    const cloudStatistics = useMemo(
        () =>
            assetsDiscoveredWeekly?.find(
                item => item.assetType === AssetType.CLOUD
            )?.added || 0,
        [assetsDiscoveredWeekly]
    );

    return (
        <Stack gap="xs">
            <Title order={4}>Discovered Assets</Title>
            <Stack gap="xs" w="100%">
                <Grid columns={11}>
                    <Grid.Col span={3}>
                        <BreakdownRepresentation
                            title="Total"
                            value={totalAssetsStatistics}
                            isLoading={isLoading}
                            link={`/assets/all?createdAtGte=${startDate}&createdAtLte=${endDate}`}
                        />
                    </Grid.Col>
                    <Grid.Col span={1}>
                        <Center>
                            <Divider orientation="vertical" h={50} />
                        </Center>
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <BreakdownRepresentation
                            title="Domain"
                            value={domainStatistics}
                            isLoading={isLoading}
                            link={`/assets/domain?createdAtGte=${startDate}&createdAtLte=${endDate}`}
                        />
                    </Grid.Col>
                    <Grid.Col span={1}>
                        <Center>
                            <Divider orientation="vertical" h={50} />
                        </Center>
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <BreakdownRepresentation
                            title="IPv4"
                            value={ipv4Statistics}
                            isLoading={isLoading}
                            link={`/assets/ipv4?createdAtGte=${startDate}&createdAtLte=${endDate}`}
                        />
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <BreakdownRepresentation
                            title="IPv6"
                            value={ipv6Statistics}
                            isLoading={isLoading}
                            link={`/assets/ipv6?createdAtGte=${startDate}&createdAtLte=${endDate}`}
                        />
                    </Grid.Col>
                    <Grid.Col span={1}>
                        <Center>
                            <Divider orientation="vertical" h={50} />
                        </Center>
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <BreakdownRepresentation
                            title="SSL"
                            value={sslStatistics}
                            isLoading={isLoading}
                            link={`/assets/ssl?createdAtGte=${startDate}&createdAtLte=${endDate}`}
                        />
                    </Grid.Col>
                    <Grid.Col span={1}>
                        <Center>
                            <Divider orientation="vertical" h={50} />
                        </Center>
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <BreakdownRepresentation
                            title="Port"
                            value={portStatistics}
                            isLoading={isLoading}
                            link={`/assets/port?createdAtGte=${startDate}&createdAtLte=${endDate}`}
                        />
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <BreakdownRepresentation
                            title="Email"
                            value={emailStatistics}
                            isLoading={isLoading}
                            link={`/assets/email?createdAtGte=${startDate}&createdAtLte=${endDate}`}
                        />
                    </Grid.Col>
                    <Grid.Col span={1}>
                        <Center>
                            <Divider orientation="vertical" h={50} />
                        </Center>
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <BreakdownRepresentation
                            title="CIDR"
                            value={cidrStatistics}
                            isLoading={isLoading}
                            link={`/assets/cidr?createdAtGte=${startDate}&createdAtLte=${endDate}`}
                        />
                    </Grid.Col>
                    <Grid.Col span={1}>
                        <Center>
                            <Divider orientation="vertical" h={50} />
                        </Center>
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <BreakdownRepresentation
                            title="Cloud"
                            value={cloudStatistics}
                            isLoading={isLoading}
                            link={`/assets/cloud?createdAtGte=${startDate}&createdAtLte=${endDate}`}
                        />
                    </Grid.Col>
                </Grid>
            </Stack>
        </Stack>
    );
};

const BreakdownsByIssues = () => {
    const { globalDateRange } = useApp();
    const { from: startDate, to: endDate } = globalDateRange || {};

    const totalVulnerabilitiesStatistics = useGetVulnerabilitiesCount({
        lastSeenAtGte: startDate,
        lastSeenAtLte: endDate
    });
    const criticalVulnerabilitiesStatistics = useGetVulnerabilitiesCount({
        lastSeenAtGte: startDate,
        lastSeenAtLte: endDate,
        rating: ['critical']
    });
    const highVulnerabilitiesStatistics = useGetVulnerabilitiesCount({
        lastSeenAtGte: startDate,
        lastSeenAtLte: endDate,
        rating: ['high']
    });
    const mediumVulnerabilitiesStatistics = useGetVulnerabilitiesCount({
        lastSeenAtGte: startDate,
        lastSeenAtLte: endDate,
        rating: ['medium']
    });
    const lowVulnerabilitiesStatistics = useGetVulnerabilitiesCount({
        lastSeenAtGte: startDate,
        lastSeenAtLte: endDate,
        rating: ['low']
    });
    const infoVulnerabilitiesStatistics = useGetVulnerabilitiesCount({
        lastSeenAtGte: startDate,
        lastSeenAtLte: endDate,
        rating: ['info']
    });

    return (
        <Stack gap="xs">
            <Title order={4}>Found Issues</Title>
            <Stack gap="xs" w="100%">
                <Grid columns={11}>
                    <Grid.Col span={3}>
                        <BreakdownRepresentation
                            title="Total"
                            value={totalVulnerabilitiesStatistics?.data}
                            isLoading={totalVulnerabilitiesStatistics.isLoading}
                            link="/issues/all"
                        />
                    </Grid.Col>
                    <Grid.Col span={1}>
                        <Center>
                            <Divider orientation="vertical" h={50} />
                        </Center>
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <BreakdownRepresentation
                            title="Critical"
                            color={severityRatingThemeColorName('critical')}
                            value={criticalVulnerabilitiesStatistics?.data}
                            isLoading={
                                criticalVulnerabilitiesStatistics.isLoading
                            }
                            link="/issues/all?rating=critical"
                        />
                    </Grid.Col>
                    <Grid.Col span={1}>
                        <Center>
                            <Divider orientation="vertical" h={50} />
                        </Center>
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <BreakdownRepresentation
                            title="High"
                            color={severityRatingThemeColorName('high')}
                            value={highVulnerabilitiesStatistics?.data}
                            isLoading={highVulnerabilitiesStatistics.isLoading}
                            link="/issues/all?rating=high"
                        />
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <BreakdownRepresentation
                            title="Medium"
                            color={severityRatingThemeColorName('medium')}
                            value={mediumVulnerabilitiesStatistics?.data}
                            isLoading={
                                mediumVulnerabilitiesStatistics.isLoading
                            }
                            link="/issues/all?rating=medium"
                        />
                    </Grid.Col>
                    <Grid.Col span={1}>
                        <Center>
                            <Divider orientation="vertical" h={50} />
                        </Center>
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <BreakdownRepresentation
                            title="Low"
                            color={severityRatingThemeColorName('low')}
                            value={lowVulnerabilitiesStatistics?.data}
                            isLoading={lowVulnerabilitiesStatistics.isLoading}
                            link="/issues/all?rating=low"
                        />
                    </Grid.Col>
                    <Grid.Col span={1}>
                        <Center>
                            <Divider orientation="vertical" h={50} />
                        </Center>
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <BreakdownRepresentation
                            title="Info"
                            color={severityRatingThemeColorName('info')}
                            value={infoVulnerabilitiesStatistics?.data}
                            isLoading={infoVulnerabilitiesStatistics.isLoading}
                            link="/issues/all?rating=info"
                        />
                    </Grid.Col>
                </Grid>
            </Stack>
        </Stack>
    );
};

const BreakdownsByLastPeriod = () => (
    <Stack gap="lg">
        <Title order={3} ta="center">
            Findings for the last week
        </Title>
        <BreakdownsByIssues />
        <BreakdownsByAssetType />
    </Stack>
);

export default memo(BreakdownsByLastPeriod);
