import {
    AssetCandidateLink,
    AssetQueryParams,
    RootAssetCandidate
} from 'entities/Asset';
import { DataTableColumn } from 'mantine-datatable';
import { SearchFilter } from 'shared/ui/DataTable';
import React from 'react';
import { BaseColumnProps } from 'widgets/Columns/baseColumn';

export default ({
    query,
    setQuery,
    column
}: BaseColumnProps<
    RootAssetCandidate,
    AssetQueryParams
>): DataTableColumn<RootAssetCandidate> => ({
    accessor: 'value',
    title: 'Root Asset Candidate',
    sortable: true,
    filter: query && setQuery && (
        <SearchFilter
            label="Root Asset Candidates"
            description="Show candidates whose names contain the specified text."
            defaultQuery={query.valueIncludes}
            onFilter={valueIncludes => {
                if (setQuery) {
                    setQuery({ ...query, valueIncludes });
                }
            }}
        />
    ),
    filtering: Boolean(query?.valueIncludes),
    render: asset => (
        <AssetCandidateLink asset={asset}>{asset.value}</AssetCandidateLink>
    ),
    ...column
});
