import React, { memo } from 'react';
import HeaderedCard from 'shared/ui/HeaderedCard/HeaderedCard';
import { Vulnerability } from 'entities/Vulnerability';
import { Misconfiguration } from 'entities/Misconfiguration';
import { Text } from '@mantine/core';
import { Issue } from '../../model/types/issue';

interface IssueEvidenceProps {
    issue: Issue | Vulnerability | Misconfiguration;
}

const IssueEvidence = (props: IssueEvidenceProps) => {
    const { issue } = props;
    const { evidence } = issue;

    if (!evidence) return null;

    return (
        <HeaderedCard header="Evidence">
            <Text size="sm">{evidence}</Text>
        </HeaderedCard>
    );
};

export default memo(IssueEvidence);
