import React, { memo } from 'react';
import classNames from 'classnames';
import styles from './AssetScreenshot.module.scss';
import { Asset, RootAsset, RootAssetCandidate } from '../../model/types/asset';

interface AssetScreenshotProps {
    className?: string;
    asset?: Asset | RootAsset | RootAssetCandidate;
}

const AssetScreenshot = (props: AssetScreenshotProps) => {
    const { className, asset } = props;
    const classes = classNames(styles.AssetScreenshot, className);

    if (!asset?.metadata?.screenshot) return null;
    const base64Src = `data:image/png;base64, ${asset.metadata.screenshot}`;
    return (
        <div className={classes}>
            <img src={base64Src} alt={asset.value} />
        </div>
    );
};

export default memo(AssetScreenshot);
