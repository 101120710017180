import { InvisibleForm } from 'shared/ui/Analytics';

interface RootAssetVerifiedInvisibleFormProps {
    email: string;
    payload: object;
}

const RootAssetVerifiedInvisibleForm = (
    props: RootAssetVerifiedInvisibleFormProps
) => {
    const { email, payload } = props;

    return (
        <InvisibleForm id="root_asset_verified">
            <input type="email" name="email" value={email} />
            {'id' in payload && (
                <input name="root_asset_id" value={payload.id as string} />
            )}
            {'name' in payload && (
                <input name="root_asset_name" value={payload.name as string} />
            )}
        </InvisibleForm>
    );
};

export default RootAssetVerifiedInvisibleForm;
