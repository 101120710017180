import { Alert, AlertProps, Button, Group, Stack, Text } from '@mantine/core';
import { SubscriptionStatus, useApp } from 'app/providers/InitProvider';
import { Icon, Icons, IconSize } from 'shared/ui/Icon';
import React from 'react';
import { formatDate } from 'shared/lib/date';
import { SALES_EMAIL } from 'shared/const/emails';
import { contactEmail } from 'shared/lib/string/string';
import { Link } from 'react-router-dom';
import { RoutePath } from 'shared/config/routeConfig';

interface AlertsBarProps extends AlertProps {}

const AlertsBar = (props: AlertsBarProps) => {
    const { subscription, subscriptionStatus, subscriptionExpiredDaysAgo } =
        useApp();

    const renderAlerts = () => {
        if (!subscriptionStatus || !subscription) {
            return null;
        }

        switch (true) {
            case [
                SubscriptionStatus.IS_TRIAL,
                SubscriptionStatus.SUBSCRIPTION_EXPIRED
            ].every(status => subscriptionStatus.includes(status)):
                return (
                    <Alert
                        variant="light"
                        color="red"
                        title="Your trial has expired"
                        icon={
                            <Icon icon={Icons.WARNING} size={IconSize.MEDIUM} />
                        }
                        {...props}
                    >
                        <Stack gap="sm">
                            <Text size="sm">
                                Your trial has expired:{' '}
                                <b>
                                    {subscription.archived_at
                                        ? formatDate(subscription.archived_at)
                                        : 'some time ago'}
                                </b>
                                . Attaxion has stopped scanning your
                                infrastructure since then. Upgrade to a paid
                                plan to continue using Attaxion or contact{' '}
                                <a href={contactEmail(SALES_EMAIL)}>
                                    {SALES_EMAIL}
                                </a>{' '}
                                if you have any questions.
                            </Text>
                            <Group>
                                <Button
                                    component={Link}
                                    to={`${RoutePath.account}/billing?ref=trial-expired`}
                                    variant="primary-gradient"
                                    size="md"
                                >
                                    Upgrade now
                                </Button>
                            </Group>
                        </Stack>
                    </Alert>
                );
            default:
                return null;
        }
    };

    return renderAlerts();
};

export default AlertsBar;
