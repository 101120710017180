import React, { memo } from 'react';
import {
    Box,
    Center,
    Divider,
    Flex,
    FloatingPosition,
    HoverCard,
    Text,
    Title
} from '@mantine/core';
import { Icon, Icons, IconSize } from 'shared/ui/Icon';
import { stringToReactComponent } from 'shared/lib/string/string';

interface HelpHoverCardProps {
    title?: string;
    description?: string | React.ReactNode;
    target?: React.ReactNode;
    footer?: React.ReactNode;
    withIcon?: boolean;
    position?: FloatingPosition;
}

const HelpHoverCard = (props: HelpHoverCardProps) => {
    const {
        title,
        description,
        target,
        footer,
        withIcon = false,
        position
    } = props;

    return (
        <HoverCard
            width={560}
            shadow="md"
            withArrow
            zIndex={100}
            position={position}
        >
            <HoverCard.Target>
                {target || (
                    <Center>
                        <Icon icon={Icons.HELP} size={IconSize.MEDIUM} />
                    </Center>
                )}
            </HoverCard.Target>
            <HoverCard.Dropdown p={0}>
                <Box px="md" my="sm">
                    <Title order={4}>{title}</Title>
                </Box>
                <Divider />
                <Box px="md" my="sm">
                    {typeof description === 'string' ? (
                        <Text>{stringToReactComponent(description)}</Text>
                    ) : (
                        description
                    )}
                </Box>
                {footer && (
                    <>
                        <Divider />
                        <Box px="md" my="sm">
                            <Flex justify="flex-end">{footer}</Flex>
                        </Box>
                    </>
                )}
            </HoverCard.Dropdown>
        </HoverCard>
    );
};

export default memo(HelpHoverCard);
