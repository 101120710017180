import { DataTableColumn } from 'mantine-datatable';
import {
    Asset,
    AssetQueryParams,
    RootAsset,
    RootAssetCandidate
} from 'entities/Asset';
import { BaseColumnProps, updateQuery } from 'widgets/Columns/baseColumn';
import React from 'react';
import { FilterByAssetTagForm } from 'features/asset/filters/FilterByAssetTag';
import { TagGroup } from 'entities/Tag';
import { Vulnerability } from 'entities/Vulnerability';
import {
    Misconfiguration,
    MisconfigurationQueryParams
} from 'entities/Misconfiguration';

type AssetType = Asset | RootAsset | RootAssetCandidate;
type RecordType = AssetType | Vulnerability | Misconfiguration;
type QueryParams = AssetQueryParams | MisconfigurationQueryParams;

export default ({
    query = {},
    setQuery,
    column
}: BaseColumnProps<RecordType, QueryParams>): DataTableColumn<RecordType> => {
    const { tag = [] } = query;
    return {
        accessor: 'tags',
        title: 'Tags',
        filtering: tag.length > 0,
        filter: (
            <FilterByAssetTagForm
                selectedTags={tag}
                onChange={tag => updateQuery({ tag }, query, setQuery)}
            />
        ),
        render: record => {
            const asset: AssetType = 'asset' in record ? record.asset : record;

            return (
                <TagGroup
                    tags={asset.tags}
                    selectedTags={tag}
                    setSelectedTags={tag =>
                        updateQuery({ tag }, query, setQuery)
                    }
                />
            );
        },
        ...column
    };
};
