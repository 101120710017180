import React, { memo, useMemo } from 'react';
import { Icon, Icons } from 'shared/ui/Icon';
import { Badge } from '@mantine/core';
import HelpHoverCard from 'shared/ui/HelpHoverCard/HelpHoverCard';
import {
    isRootAssetVerificationAvailable,
    isRootAssetVerificationRequired
} from 'features/asset/actions/VerifyRootAsset/model/service/helpers';
import { VerifyAssetButton } from 'features/asset/actions/VerifyRootAsset';
import { useRootAssetStatus } from 'entities/Asset';
import { contactEmail } from 'shared/lib/string/string';
import { SERVICE_DESK_EMAIL } from 'shared/const/emails';
import { RootAsset, RootVerificationStatus } from '../../../model/types/asset';

interface AssetVerificationBadgeProps {
    asset: RootAsset;
}

const AssetVerificationBadge = (props: AssetVerificationBadgeProps) => {
    const { asset } = props;
    const { rootVerificationStatus } = asset;
    const { deleting, deleted } = useRootAssetStatus(asset);
    const isVerificationRequired = isRootAssetVerificationRequired(asset);
    const isVerificationAvailable = isRootAssetVerificationAvailable(asset);

    const icon = useMemo(() => {
        switch (rootVerificationStatus) {
            case RootVerificationStatus.VERIFIED:
                return Icons.VERIFIED;
            case RootVerificationStatus.PENDING:
            case RootVerificationStatus.RUNNING:
                return Icons.PENDING;
            case RootVerificationStatus.ERROR:
            case RootVerificationStatus.FAILED:
                return Icons.BLOCK;
            default:
                return undefined;
        }
    }, [rootVerificationStatus]);

    const label = useMemo(() => {
        switch (rootVerificationStatus) {
            case RootVerificationStatus.VERIFIED:
                return 'Verified';
            case RootVerificationStatus.PENDING:
                return 'Verification required';
            case RootVerificationStatus.ERROR:
            case RootVerificationStatus.FAILED:
                return 'Verification failed';
            case RootVerificationStatus.RUNNING:
                return 'Verifying...';
            default:
                return 'UNDEFINED';
        }
    }, [rootVerificationStatus]);

    const color = useMemo(() => {
        switch (rootVerificationStatus) {
            case RootVerificationStatus.VERIFIED:
                return 'green';
            case RootVerificationStatus.PENDING:
            case RootVerificationStatus.RUNNING:
                return 'orange';
            case RootVerificationStatus.ERROR:
            case RootVerificationStatus.FAILED:
                return 'red';
            default:
                return 'gray';
        }
    }, [rootVerificationStatus]);

    const description = useMemo(() => {
        switch (rootVerificationStatus) {
            case RootVerificationStatus.VERIFIED:
                return 'This means that the asset is verified and available for scanning.';
            case RootVerificationStatus.PENDING:
                return `You need to start the verification process by contacting <a href="${contactEmail(
                    SERVICE_DESK_EMAIL
                )}">${SERVICE_DESK_EMAIL}</a> to start the scanning if the asset type is not "Domain".`;
            case RootVerificationStatus.ERROR:
                return 'This means that an internal error occurred during the verification process. Try to start the verification process again or contact our support team.';
            case RootVerificationStatus.FAILED:
                return 'This means that the verification process failed. Attaxion could`t find the required TXT record in your DNS settings or TXT file on your web server. Follow the instructions to fix the problem and start the verification process again. If you have any questions, please contact our support team.';
            case RootVerificationStatus.RUNNING:
                return 'This means that the verification process is running. Please wait until the process is finished.';
            default:
                return '';
        }
    }, [rootVerificationStatus]);

    if (deleting || deleted) {
        return null;
    }

    return (
        <HelpHoverCard
            title={`Verification status: "${label}"`}
            description={description}
            target={
                <Badge color={color} leftSection={icon && <Icon icon={icon} />}>
                    {label}
                </Badge>
            }
            footer={
                isVerificationRequired &&
                isVerificationAvailable && (
                    <VerifyAssetButton asset={asset} size="md" />
                )
            }
        />
    );
};

export default memo(AssetVerificationBadge);
