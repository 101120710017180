import { Asset, AssetLink } from 'entities/Asset';
import { DataTableColumn } from 'mantine-datatable';
import React from 'react';
import { BaseColumnProps, updateQuery } from 'widgets/Columns/baseColumn';
import {
    Vulnerability,
    VulnerabilityQueryParams
} from 'entities/Vulnerability';
import { FilterByAssetForm } from 'features/asset/filters/FilterByAsset';

type RecordType = Vulnerability;
type QueryParams = VulnerabilityQueryParams;

export default ({
    query = {},
    setQuery,
    column
}: BaseColumnProps<RecordType, QueryParams>): DataTableColumn<RecordType> => ({
    accessor: 'asset.value',
    title: 'Affected Asset',
    filtering: Boolean(query?.assetID),
    filter: query && setQuery && (
        <FilterByAssetForm
            selectedAssets={query.assetID}
            onChange={assetID =>
                updateQuery(
                    { assetID: assetID ? [assetID] : undefined },
                    query,
                    setQuery
                )
            }
        />
    ),
    render: ({ asset }) => <AssetLink asset={asset as Asset} />,
    ...column
});
