import React, { memo } from 'react';
import { Badge, Button, Group } from '@mantine/core';
import { tagColor } from 'entities/Asset';
import { Tag } from '../../model/types/tag';

interface TagGroupProps {
    tags: Tag[];
    selectedTags?: string[];
    setSelectedTags: (tags: string[]) => void;
}

const TagGroup = (props: TagGroupProps) => {
    const { tags, selectedTags = [], setSelectedTags } = props;
    const clickable = setSelectedTags !== undefined;

    function renderTag(tag: Tag) {
        if (clickable) {
            return (
                <Button
                    key={tag.id}
                    size="compact-xs"
                    variant="light"
                    color={tagColor(tag)}
                    onClick={() => {
                        const tagSelected = selectedTags.includes(tag.id);
                        if (!tagSelected)
                            setSelectedTags([...selectedTags, tag.id]);
                    }}
                >
                    {tag.name}
                </Button>
            );
        }

        return (
            <Badge key={tag.id} variant="light" color={tagColor(tag)}>
                {tag.name}
            </Badge>
        );
    }

    if (tags.length === 0) return '-';
    return <Group gap="xs">{tags.map(tag => renderTag(tag))}</Group>;
};

export default memo(TagGroup);
