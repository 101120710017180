import React, { memo } from 'react';
import { ActionIcon, Divider, Group, Modal } from '@mantine/core';
import { Icon, Icons, IconSize } from 'shared/ui/Icon';
import { useDisclosure } from '@mantine/hooks';
import { ModalBaseProps } from '@mantine/core/lib/components/ModalBase/ModalBase';

interface HelpHoverCardProps {
    title: string;
    content: React.ReactNode;
    footer?: React.ReactNode;
    modalSize?: ModalBaseProps['size'];
    actionIconSize?: IconSize;
}

const HelpModal = (props: HelpHoverCardProps) => {
    const {
        title,
        content,
        footer,
        modalSize = 'xl',
        actionIconSize = IconSize.MEDIUM
    } = props;
    const [opened, { open, close }] = useDisclosure(false);

    return (
        <>
            <Modal
                size={modalSize}
                title={title}
                opened={opened}
                onClose={close}
            >
                {content}
                {footer && (
                    <>
                        <Divider my="md" />
                        <Group justify="flex-end">{footer}</Group>
                    </>
                )}
            </Modal>
            <ActionIcon
                size="sm"
                variant="transparent"
                aria-label={title}
                onClick={open}
            >
                <Icon icon={Icons.HELP} size={actionIconSize} />
            </ActionIcon>
        </>
    );
};

export default memo(HelpModal);
