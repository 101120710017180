import { memo } from 'react';
import { WhoisRecord } from '../../../model/types/metadata';

interface WhoisRegistrarNameProps {
    whoisRecord?: WhoisRecord;
}

const WhoisRegistrarName = (props: WhoisRegistrarNameProps) => {
    const { whoisRecord } = props;
    return whoisRecord?.registrarName || 'N/A';
};

export default memo(WhoisRegistrarName);
