import React, { memo, useMemo } from 'react';
import { MultiSelect } from '@mantine/core';
import { Icon, Icons } from 'shared/ui/Icon';
import { useGetTechnologyCategories } from 'entities/Technology';

export interface FilterByTechnologyCategoryFormProps {
    selectedCategories?: string[];
    onChange?: (value: string[]) => void;
}

const FilterByTechnologyCategoryForm = (
    props: FilterByTechnologyCategoryFormProps
) => {
    const { selectedCategories, onChange } = props;
    const { data, isLoading } = useGetTechnologyCategories();
    const { data: categories } = data ?? {};

    const options = useMemo(
        () =>
            categories
                ?.map(category => ({
                    value: category.id.toString(),
                    label: category.name
                }))
                .filter(
                    (option, index, self) =>
                        index === self.findIndex(t => t.value === option.value)
                )
                .sort((a, b) => a.label.localeCompare(b.label)),
        [categories]
    );

    return (
        <MultiSelect
            clearable
            searchable
            label="Categories"
            description="Show all categories whose names contain the specified text."
            data={options}
            value={!isLoading ? selectedCategories : ['Loading...']}
            disabled={isLoading}
            leftSection={<Icon icon={Icons.SEARCH} />}
            comboboxProps={{ withinPortal: !options?.length }}
            onChange={onChange}
            style={{ maxWidth: 600 }}
        />
    );
};

export default memo(FilterByTechnologyCategoryForm);
