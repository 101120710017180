import { memo } from 'react';
import { Container, Flex, Paper, Text, TextInput } from '@mantine/core';
import { AssetCreateBody, AssetType, useAddAsset } from 'entities/Asset';
import { useForm } from '@mantine/form';
import { addNewRootAssetActions } from 'features/asset/actions/AddNewRootAsset';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import ConfirmFormFooter from '../common/ConfirmFormFooter/ConfirmFormFooter';
import { contactUsToVerifyAssetMessage } from '../../model/service/contactUsToVerifyAssetMessage';

const OrganizationRootAsset = () => {
    const dispatch = useAppDispatch();
    const [addAsset, { isLoading }] = useAddAsset();
    const form = useForm<AssetCreateBody>({
        initialValues: {
            value: '',
            type: AssetType.ORGANIZATION
        }
    });

    const onSubmit = (values: AssetCreateBody) =>
        addAsset(values)
            .unwrap()
            .then(() => {
                dispatch(
                    addNewRootAssetActions.setSuccess(
                        contactUsToVerifyAssetMessage(
                            `${values.value} organization`
                        )
                    )
                );
            })
            .catch(err => {
                dispatch(addNewRootAssetActions.setError(err));
            });

    return (
        <Container size="xs">
            <Text c="dimmed" ta="center" size="md">
                Enter your organization name you want to keep secure. We&apos;ll
                try to identify and scan all related infrastructure. Manual root
                asset verification is required.
            </Text>
            <Paper withBorder shadow="md" p="xl" radius="md" mt="xl">
                <form onSubmit={form.onSubmit(onSubmit)}>
                    <Flex gap="lg" direction="column">
                        <TextInput
                            label="Organization name"
                            placeholder="My organization name"
                            withAsterisk
                            required
                            autoFocus
                            {...form.getInputProps('value')}
                        />
                        <ConfirmFormFooter loading={isLoading} />
                    </Flex>
                </form>
            </Paper>
        </Container>
    );
};

export default memo(OrganizationRootAsset);
