import React, {
    memo,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import classNames from 'classnames';
import maplibregl, { GeoJSONSource, LngLatLike } from 'maplibre-gl';
import { Icon, Icons, IconSize } from 'shared/ui/Icon';
import { CloseButton } from 'shared/ui/Button';
import { Asset, RootAsset, RootAssetLink } from 'entities/Asset';
import { ActionIcon } from '@mantine/core';
import AssetLink from '../Links/AssetLink/AssetLink';
import { useGetGeoMapAssets } from '../../api/assetsApi/assetsApi';
import styles from './AssetsGeolocationMap.module.scss';

interface AssetsGeolocationMapProps {
    className?: string;
}

const MAX_ZOOM = 10;
const MIN_ZOOM = 0.5;
const DEFAULT_ZOOM = 1;
const DEFAULT_CENTER: LngLatLike = {
    lat: 25,
    lng: 0
};

interface AssetFeature {
    type: 'Feature';
    properties: {
        id: string;
    };
    geometry: {
        type: 'Point';
        coordinates: [number, number];
    };
}

interface AssetFeatureCollection {
    type: 'FeatureCollection';
    features: AssetFeature[] | [];
}

const AssetsGeolocationMap = (props: AssetsGeolocationMapProps) => {
    const { className } = props;
    const classes = classNames(styles.AssetsGeolocationMap, className);

    const selectedFeatureID = useRef<string | number | undefined>();
    const [selectedAssetIDs, setSelectedAssetIDs] = useState<string[] | null>(
        null
    );
    const { data, isLoading, isFetching } = useGetGeoMapAssets();
    const { data: assets, pagination } = data ?? {};

    const mapContainer = useRef(null);
    const map = useRef<maplibregl.Map | null>(null);
    // @ts-ignore
    const geojson = useMemo<AssetFeatureCollection>(() => {
        const features = assets
            ?.filter(
                asset =>
                    asset.metadata?.geoip?.lat && asset.metadata?.geoip?.lng
            )
            .map(asset => ({
                type: 'Feature',
                properties: {
                    id: asset.id
                },
                geometry: {
                    type: 'Point',
                    coordinates: [
                        asset.metadata?.geoip.lng,
                        asset.metadata?.geoip.lat
                    ]
                }
            }));

        return {
            type: 'FeatureCollection',
            features: features ?? []
        };
    }, [assets]);

    const onSelectFeatureID = useCallback(
        (
            source: string,
            featureID: string | number | undefined,
            reset = false
        ) => {
            if (reset) {
                map.current?.setFeatureState(
                    { source, id: selectedFeatureID.current },
                    { hover: false }
                );
            }

            if (featureID === undefined) {
                return;
            }

            if (selectedFeatureID.current === featureID) {
                return;
            }

            if (selectedFeatureID.current !== undefined) {
                map.current?.setFeatureState(
                    { source, id: selectedFeatureID.current },
                    { hover: false }
                );
            }

            if (featureID !== undefined) {
                map.current?.setFeatureState(
                    { source, id: featureID },
                    { hover: true }
                );
            }

            selectedFeatureID.current = featureID;
        },
        []
    );

    const onCloseSelectedAssets = useCallback(() => {
        setSelectedAssetIDs(null);
        onSelectFeatureID('assets', undefined, true);
    }, [onSelectFeatureID]);

    useEffect(() => {
        if (mapContainer.current && !map.current) {
            map.current = new maplibregl.Map({
                container: mapContainer.current,
                style: 'https://api.maptiler.com/maps/basic-v2/style.json?key=gpBPerLiRLjnpXmTKx3P',
                center: DEFAULT_CENTER,
                zoom: DEFAULT_ZOOM,
                maxZoom: MAX_ZOOM,
                minZoom: MIN_ZOOM,
                attributionControl: false
            });

            map.current.addControl(
                new maplibregl.NavigationControl({
                    showCompass: false
                }),
                'top-left'
            );

            map.current.scrollZoom.disable();

            map.current.on('load', () => {
                map.current?.addSource('assets', {
                    type: 'geojson',
                    data: geojson,
                    cluster: true,
                    clusterMaxZoom: 14,
                    clusterRadius: 50,
                    generateId: true
                });

                map.current?.addLayer({
                    id: 'clusters',
                    type: 'circle',
                    source: 'assets',
                    filter: ['has', 'point_count'],
                    paint: {
                        'circle-color': [
                            'step',
                            ['get', 'point_count'],
                            '#7878ff',
                            100,
                            '#7878ff',
                            750,
                            '#7878ff'
                        ],
                        'circle-radius': [
                            'step',
                            ['get', 'point_count'],
                            20,
                            100,
                            30,
                            750,
                            40
                        ],
                        'circle-stroke-width': 4,
                        'circle-stroke-color': [
                            'case',
                            ['boolean', ['feature-state', 'hover'], false],
                            '#fda800',
                            'transparent'
                        ]
                    }
                });

                map.current?.addLayer({
                    id: 'cluster-count',
                    type: 'symbol',
                    source: 'assets',
                    filter: ['has', 'point_count'],
                    layout: {
                        'text-field': '{point_count_abbreviated}',
                        'text-font': ['sans-serif'],
                        'text-size': 12
                    },
                    paint: {
                        'text-color': '#fff'
                    }
                });

                map.current?.addLayer({
                    id: 'unclustered-point',
                    type: 'circle',
                    source: 'assets',
                    filter: ['!', ['has', 'point_count']],
                    paint: {
                        'circle-color': '#7878ff',
                        'circle-radius': 6,
                        'circle-stroke-width': 2,
                        'circle-stroke-color': [
                            'case',
                            ['boolean', ['feature-state', 'hover'], false],
                            '#fda800',
                            '#fff'
                        ]
                    }
                });

                map.current?.on('click', 'clusters', e => {
                    const { features } = e;

                    if (!features) return;
                    const source = 'assets';
                    const cluster = features[0];
                    const clusterID = cluster.properties.cluster_id;
                    const assetsSource: GeoJSONSource = map.current?.getSource(
                        source
                    ) as GeoJSONSource;

                    onSelectFeatureID(source, clusterID);

                    if (assetsSource) {
                        assetsSource.getClusterLeaves(
                            clusterID,
                            100,
                            0,
                            (error, features) => {
                                setSelectedAssetIDs(
                                    features?.map(
                                        feature => feature.properties?.id
                                    ) ?? []
                                );
                            }
                        );

                        assetsSource.getClusterExpansionZoom(
                            clusterID,
                            (error, zoom) => {
                                if (error) return;

                                if (zoom) {
                                    map.current?.easeTo({
                                        // @ts-ignore
                                        center: cluster.geometry.coordinates,
                                        zoom: map.current.getZoom() + 1
                                    });
                                }
                            }
                        );
                    }
                });

                map.current?.on('click', 'unclustered-point', e => {
                    const { features } = e;

                    if (!features) return;
                    const source = 'assets';
                    const point = features[0];
                    const pointID = point.id;
                    const assetsSource: GeoJSONSource = map.current?.getSource(
                        source
                    ) as GeoJSONSource;

                    onSelectFeatureID(source, pointID);

                    if (assetsSource) {
                        setSelectedAssetIDs(
                            features?.map(feature => feature.properties?.id) ??
                                []
                        );

                        map.current?.easeTo({
                            // @ts-ignore
                            center: point.geometry.coordinates,
                            zoom: map.current.getZoom() + 1
                        });
                    }
                });

                map.current?.on('mouseenter', 'clusters', () => {
                    // @ts-ignore
                    map.current?.getCanvas().style.cursor = 'pointer';
                });

                map.current?.on('mouseleave', 'clusters', () => {
                    // @ts-ignore
                    map.current?.getCanvas().style.cursor = '';
                });

                map.current?.on('mouseenter', 'unclustered-point', () => {
                    // @ts-ignore
                    map.current?.getCanvas().style.cursor = 'pointer';
                });

                map.current?.on('mouseleave', 'unclustered-point', () => {
                    // @ts-ignore
                    map.current?.getCanvas().style.cursor = '';
                });
            });
        }
    }, [geojson, onCloseSelectedAssets, onSelectFeatureID]);

    if (!assets?.length) return null;

    return (
        <div className={classes}>
            <div ref={mapContainer} className={styles.Map} />
            {selectedAssetIDs && selectedAssetIDs.length > 0 && (
                <div className={styles.AssetsList}>
                    <div className={styles.AssetsList__Header}>
                        <div className={styles.AssetsList__Title}>
                            Selected Assets: {selectedAssetIDs.length}
                        </div>
                        <CloseButton onClose={onCloseSelectedAssets} />
                    </div>
                    {assets
                        // @ts-ignore
                        .filter(asset => selectedAssetIDs.includes(asset.id))
                        // @ts-ignore
                        .map(asset => (
                            <div
                                key={asset.id}
                                className={classNames(styles.Asset, {
                                    [styles.Selected]:
                                        selectedAssetIDs.includes(asset.id)
                                })}
                            >
                                <div className={styles.Asset__Info}>
                                    <div className={styles.Asset__Root}>
                                        Root Asset
                                    </div>
                                    <div className={styles.Asset__Name}>
                                        {asset.value}
                                    </div>
                                    {asset.metadata?.geoip && (
                                        <div className={styles.Asset__Location}>
                                            {asset.metadata?.geoip.city},{' '}
                                            {asset.metadata?.geoip.country}
                                        </div>
                                    )}
                                </div>
                                <div className={styles.Asset__Actions}>
                                    {asset.isRoot ? (
                                        <RootAssetLink
                                            asset={asset as RootAsset}
                                        >
                                            <ActionIcon variant="subtle">
                                                <Icon
                                                    icon={Icons.CHEVRON_RIGHT}
                                                    size={IconSize.LARGE}
                                                />
                                            </ActionIcon>
                                        </RootAssetLink>
                                    ) : (
                                        <AssetLink asset={asset as Asset}>
                                            <ActionIcon variant="subtle">
                                                <Icon
                                                    icon={Icons.CHEVRON_RIGHT}
                                                    size={IconSize.LARGE}
                                                />
                                            </ActionIcon>
                                        </AssetLink>
                                    )}
                                </div>
                            </div>
                        ))}
                </div>
            )}
        </div>
    );
};

export default memo(AssetsGeolocationMap);
