import React, { memo } from 'react';
import HeaderedCard from 'shared/ui/HeaderedCard/HeaderedCard';
import { Box, Group, Stack, Text } from '@mantine/core';
import { Vulnerability } from 'entities/Vulnerability';
import { formatDate } from 'shared/lib/date';
import { KevPost } from 'entities/KnowledgeBase';
import { Issue } from '../../model/types/issue';

interface IssueKevProps {
    issue: Issue | Vulnerability;
}

const IssueKev = (props: IssueKevProps) => {
    const { issue } = props;
    const { kev } = issue;

    function renderKev() {
        if (kev) {
            return (
                <HeaderedCard
                    attentionCard
                    header={
                        <Group gap="xs">
                            CISA Known Exploited Vulnerability (KEV)
                            <KevPost />
                        </Group>
                    }
                >
                    <Stack>
                        <Box>
                            <Text size="sm" fw={700}>
                                Name
                            </Text>
                            <Text size="sm">{kev.vulnerability}</Text>
                        </Box>
                        <Group gap={56}>
                            <Box>
                                <Text size="sm" fw={700}>
                                    Exploit added
                                </Text>
                                <Text size="sm">{formatDate(kev.added)}</Text>
                            </Box>
                            <Box>
                                <Text size="sm" fw={700}>
                                    Action due
                                </Text>
                                <Text size="sm">
                                    {formatDate(kev.action.due)}
                                </Text>
                            </Box>
                        </Group>
                        <Box>
                            <Text size="sm" fw={700}>
                                Action required
                            </Text>
                            <Text size="sm">{kev.action.name}</Text>
                        </Box>
                    </Stack>
                </HeaderedCard>
            );
        }

        return null;
    }

    return renderKev();
};

export default memo(IssueKev);
