import { memo } from 'react';
import { Container, Flex, Paper, Text, TextInput } from '@mantine/core';
import { AssetCreateBody, AssetType, useAddAsset } from 'entities/Asset';
import { useForm } from '@mantine/form';
import { addNewRootAssetActions } from 'features/asset/actions/AddNewRootAsset';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import cidrRegex from 'cidr-regex';
import { contactUsToVerifyAssetMessage } from '../../model/service/contactUsToVerifyAssetMessage';
import ConfirmFormFooter from '../common/ConfirmFormFooter/ConfirmFormFooter';

const CidrRootAsset = () => {
    const dispatch = useAppDispatch();
    const [addAsset, { isLoading }] = useAddAsset();
    const form = useForm<AssetCreateBody>({
        initialValues: {
            value: '',
            type: AssetType.CIDR
        },
        validate: {
            value: value => {
                if (!value) return 'CIDR is required';
                if (!cidrRegex({ exact: true }).test(value))
                    return 'Invalid CIDR format';
                return null;
            }
        }
    });

    const onSubmit = (values: AssetCreateBody) =>
        addAsset(values)
            .unwrap()
            .then(() => {
                dispatch(
                    addNewRootAssetActions.setSuccess(
                        contactUsToVerifyAssetMessage(`${values.value} CIDR`)
                    )
                );
            })
            .catch(err => {
                dispatch(addNewRootAssetActions.setError(err));
            });

    return (
        <Container size="xs">
            <Text c="dimmed" ta="center" size="md">
                Enter a CIDR you want to keep secure. Manual root asset
                verification is required.
            </Text>
            <Paper
                withBorder
                shadow="md"
                radius="md"
                p="xl"
                mt="xl"
                miw="500px"
            >
                <form onSubmit={form.onSubmit(onSubmit)}>
                    <Flex gap="lg" direction="column">
                        <TextInput
                            label="CIDR"
                            placeholder="8.8.8.0/24"
                            autoFocus
                            {...form.getInputProps('value')}
                        />
                        <ConfirmFormFooter loading={isLoading} />
                    </Flex>
                </form>
            </Paper>
        </Container>
    );
};

export default memo(CidrRootAsset);
