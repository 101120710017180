import React, { memo, useMemo } from 'react';
import { SelectProps, Select } from '@mantine/core';
import { Icon, Icons } from 'shared/ui/Icon';
import { useGetRootAssetNames } from 'entities/Dictionary';

export interface FilterBySingleRootAssetFormProps extends SelectProps {
    selectedAsset?: string;
}

const FilterBySingleRootAssetForm = (
    props: FilterBySingleRootAssetFormProps
) => {
    const { selectedAsset, onChange, ...selectProps } = props;
    const { rootAssets, loading } = useGetRootAssetNames();

    const options = useMemo(
        () =>
            rootAssets
                ?.map(asset => ({
                    value: asset.id,
                    label: asset.value
                }))
                .filter(
                    (option, index, self) =>
                        index === self.findIndex(t => t.value === option.value)
                ) ?? [],
        [rootAssets]
    );

    return (
        <Select
            clearable
            searchable
            label="Root Asset"
            description="Show all assets belonging to the selected root asset."
            data={options}
            value={!loading ? selectedAsset : 'Loading...'}
            disabled={loading}
            leftSection={<Icon icon={Icons.SEARCH} />}
            comboboxProps={{ withinPortal: !options?.length }}
            onChange={onChange}
            style={{ maxWidth: 600 }}
            {...selectProps}
        />
    );
};

export default memo(FilterBySingleRootAssetForm);
