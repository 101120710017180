import React, { useCallback, useEffect } from 'react';
import {
    createBrowserRouter,
    RouterProvider,
    useLocation,
    createRoutesFromChildren,
    useNavigationType,
    matchRoutes
} from 'react-router-dom';
import { AppRouteProps, routeConfig } from 'shared/config/routeConfig';
import { AuthLayout, BaseLayout } from 'widgets/Layout';
import * as Sentry from '@sentry/react';
import { PageError } from 'widgets/PageError';
import AuthGuard from './AuthGuard';
import RootElement from './RootElement';
import DemoGuard from './DemoGuard';

if (!__IS_DEV__) {
    Sentry.init({
        dsn: 'https://38c4cf497aa6f19615c0b7afcb32ddb8@o4505792390168576.ingest.sentry.io/4505792391151616',
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                )
            }),
            new Sentry.Replay()
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0
    });
}

const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);

function AppRouter() {
    const renderRoutes = useCallback((route: AppRouteProps) => {
        const {
            path,
            authRequired,
            availableForDemo,
            element,
            handle,
            children
        } = route;
        const isAuthRequired = authRequired ?? false;
        const elementToRender = isAuthRequired ? (
            <AuthGuard>
                <BaseLayout>{element}</BaseLayout>
            </AuthGuard>
        ) : (
            <AuthLayout>{element}</AuthLayout>
        );

        return {
            path,
            element: (
                <DemoGuard routeAvailableForDemo={availableForDemo}>
                    {elementToRender}
                </DemoGuard>
            ),
            handle,
            children
        };
    }, []);

    const router = sentryCreateBrowserRouter([
        {
            element: <RootElement />,
            children: Object.values(routeConfig).map(renderRoutes),
            errorElement: <PageError />
        }
    ]);

    return <RouterProvider router={router} />;
}

export default AppRouter;
