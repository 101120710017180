import React, { memo, Suspense, useEffect } from 'react';
import { Badge, Container, Group, Title } from '@mantine/core';
import { useInjectReducer } from 'shared/hooks/useInjectReducer';
import {
    addNewRootAssetActions,
    addNewRootAssetReducer,
    AwsRootAsset,
    AzureRootAsset,
    CidrRootAsset,
    DigitalOceanRootAsset,
    DomainRootAsset,
    GcpRootAsset,
    getErrorMessage,
    getSuccessMessage,
    IpAddressRootAsset,
    OrganizationRootAsset,
    RootAssetType,
    SelectRootAssetType,
    SuccessfullyAddedAsset
} from 'features/asset/actions/AddNewRootAsset';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch';
import { Seo } from 'shared/ui/SEO/SEO';
import ManagementBaseLayout from '../../../ui/ManagementBaseLayout/ManagementBaseLayout';

function AddNewRootAssetPage() {
    useInjectReducer('addNewRootAsset', addNewRootAssetReducer);
    const dispatch = useAppDispatch();
    const { type } = useParams<{ type: RootAssetType | 'success' }>();
    const successMessage = useSelector(getSuccessMessage);
    const errorMessage = useSelector(getErrorMessage);

    useEffect(() => {
        dispatch(addNewRootAssetActions.reset());
    }, [dispatch, type]);

    return (
        <ManagementBaseLayout withHeader={false}>
            <Seo title="Add new Root Asset | Management" />
            <Container size="xl" py="xl" fluid>
                <Group justify="center">
                    <Badge variant="primary-gradient" size="lg">
                        Protect your infrastructure
                    </Badge>
                </Group>

                <Title order={1} ta="center" mt="lg" mb="md">
                    Add new Root Asset
                </Title>

                {!successMessage && (
                    <Suspense fallback={null}>
                        {!type && <SelectRootAssetType />}
                        {type === 'success' && <SuccessfullyAddedAsset />}
                        {type === 'domain' && <DomainRootAsset />}
                        {type === 'ipAddress' && <IpAddressRootAsset />}
                        {type === 'cidr' && <CidrRootAsset />}
                        {type === 'organization' && <OrganizationRootAsset />}
                        {type === 'aws' && <AwsRootAsset />}
                        {type === 'gcp' && <GcpRootAsset />}
                        {type === 'azure' && <AzureRootAsset />}
                        {type === 'digitalOcean' && <DigitalOceanRootAsset />}
                    </Suspense>
                )}
                {successMessage && (
                    <Suspense fallback={null}>
                        <SuccessfullyAddedAsset />
                    </Suspense>
                )}
            </Container>
        </ManagementBaseLayout>
    );
}

export default memo(AddNewRootAssetPage);
