import { memo } from 'react';
import TopBarProgress from 'react-topbar-progress-indicator';
import { useRouteProgress } from 'shared/hooks/useRouteProgress/useRouteProgress';

TopBarProgress.config({
    barColors: {
        '0': '#3254b5',
        '0.25': '#94a0fb',
        '0.5': '#5a33ff',
        '0.75': '#fda800',
        '1.0': '#ff0181'
    },
    barThickness: 5
});

const PageBarLoader = () => {
    const { progress } = useRouteProgress();
    if (!progress) return null;
    return <TopBarProgress />;
};

export default memo(PageBarLoader);
