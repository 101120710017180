import React, { memo, useCallback, useMemo } from 'react';
import { useGetLastStatisticsByVulnerabilityRating } from 'entities/Statistics';
import { Rating, severityRatingColor } from 'entities/Issue';
import {
    Card,
    Flex,
    NumberFormatter,
    RingProgress,
    SimpleGrid,
    Text
} from '@mantine/core';
import { NavLink } from 'react-router-dom';
import { Asset, RootAsset } from 'entities/Asset';

interface DistributionBySeverityCardProps {
    asset?: Asset | RootAsset;
    size?: number;
    hasLink?: boolean;
    onClick?: (severity: string) => void;
}

const DistributionBySeverityCard = (props: DistributionBySeverityCardProps) => {
    const { asset, size = 170, hasLink = true, onClick } = props;
    const { data, isLoading, isFetching } =
        useGetLastStatisticsByVulnerabilityRating(
            {},
            {
                skip: Boolean(asset)
            }
        );

    const distribution = useMemo(() => {
        if (asset) {
            return {
                info: asset.vulnerabilityStats?.info,
                low: asset.vulnerabilityStats?.low,
                medium: asset.vulnerabilityStats?.medium,
                high: asset.vulnerabilityStats?.high,
                critical: asset.vulnerabilityStats?.critical
            };
        }

        return data?.distribution;
    }, [asset, data?.distribution]);

    const ringTextSize = useCallback(
        (value: number) => {
            if (value < 100) {
                return size / 4.5;
            }

            if (value < 1000) {
                return size / 5;
            }

            return size / 6;
        },
        [size]
    );

    const renderRingProgress = (severity: string, value: number) => (
        <RingProgress
            size={size}
            thickness={size / 10}
            label={
                <Text fz={ringTextSize(value)} fw="700" ta="center">
                    <NumberFormatter value={value} thousandSeparator />
                </Text>
            }
            sections={[
                {
                    value: 100,
                    color: value
                        ? severityRatingColor(severity as Rating)
                        : 'gray.4'
                }
            ]}
        />
    );

    const renderElement = (severity: string, value: number) => {
        if (hasLink) {
            return (
                <NavLink
                    to={`/issues/all?rating=${severity}&lastSeenPreset=all`}
                    color="black"
                >
                    {renderRingProgress(severity, value)}
                </NavLink>
            );
        }

        if (onClick) {
            return (
                <div className="clickable" onClick={() => onClick(severity)}>
                    {renderRingProgress(severity, value)}
                </div>
            );
        }

        return renderRingProgress(severity, value);
    };

    return (
        <Card shadow="md" pt="0" pl="lg" pb="lg" pr="lg" radius="md" withBorder>
            <SimpleGrid cols={5}>
                {distribution &&
                    Object.entries(distribution).map(([severity, value]) => (
                        <Flex key={severity} direction="column" align="center">
                            {renderElement(severity, value.count ?? value)}
                            <Text size="md" ta="center">
                                <Text
                                    size="md"
                                    fw={700}
                                    c={severityRatingColor(severity as Rating)}
                                >
                                    {severity.toUpperCase()}
                                </Text>
                                severity issues
                            </Text>
                        </Flex>
                    ))}
            </SimpleGrid>
        </Card>
    );
};

export default memo(DistributionBySeverityCard);
