import { createContext } from 'react';
import { Rating } from 'entities/Issue';

export interface AssetDetailsContextProps {
    activeTab: string;
    setActiveTab: (value: string) => void;
    selectedIssueRating?: Rating;
    setSelectedIssueRating?: (value?: Rating) => void;
}

export const AssetDetailsContext = createContext<AssetDetailsContextProps>({
    selectedIssueRating: undefined,
    setSelectedIssueRating: () => {},
    activeTab: '',
    setActiveTab: () => {}
});
