import { useCallback, useEffect } from 'react';

const MIN_VIEWPORT_WIDTH = 1366;
const viewport = document.querySelector(
    'meta[name="viewport"]'
) as HTMLMetaElement;

export function useDynamicViewport() {
    const onResize = useCallback(() => {
        const screenWidth = window.screen.width;
        const viewportRatio = screenWidth / MIN_VIEWPORT_WIDTH;
        if (screenWidth < MIN_VIEWPORT_WIDTH) {
            viewport.setAttribute(
                'content',
                `initial-scale=${viewportRatio}, maximum-scale=${viewportRatio}, minimum-scale=${viewportRatio}, user-scalable=no, width=${MIN_VIEWPORT_WIDTH}`
            );
        } else {
            viewport.setAttribute(
                'content',
                `initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=yes, width=${screenWidth}`
            );
        }
    }, []);

    useEffect(() => {
        document.documentElement.style.minWidth = `${MIN_VIEWPORT_WIDTH}px`;
        document.body.style.minWidth = `${MIN_VIEWPORT_WIDTH}px`;
        onResize();
    }, [onResize]);

    useEffect(() => {
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [onResize]);
}
