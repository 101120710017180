import React, { memo, useMemo } from 'react';
import { DataTableColumn } from 'mantine-datatable';
import { Payment, useGetPaymentsQuery } from 'entities/Billing';
import { DateColumn, GenericTable } from 'shared/ui/DataTable';
import { Box, MantineColor, NumberFormatter, Text } from '@mantine/core';
import { DownloadInvoiceButton } from 'features/billing/actions/DownloadInvoice';

const PaymentsTable = () => {
    const columns = useMemo<DataTableColumn<Payment>[]>(
        () => [
            {
                accessor: 'payment_id',
                title: 'Invoice ID'
            },
            {
                accessor: 'created_at',
                title: 'Invoice Date',
                render: ({ created_at }) => <DateColumn date={created_at} />
            },
            {
                accessor: 'amount',
                title: 'Amount',
                render: ({ amount, payment_method }) => (
                    <Box>
                        <NumberFormatter
                            prefix="$"
                            value={amount}
                            thousandSeparator
                        />
                        <Text size="xs" c="dimmed">
                            By {payment_method}
                        </Text>
                    </Box>
                )
            },
            {
                accessor: 'status',
                title: 'Status',
                render: ({ state }) => (
                    <Box>
                        <Text fw={500} c={state.color as MantineColor}>
                            {state.name}
                        </Text>
                        {state.reason && (
                            <Text size="xs" c="dimmed">
                                {state.reason}
                            </Text>
                        )}
                    </Box>
                )
            },
            {
                width: 200,
                accessor: 'invoice.id',
                title: 'Invoice',
                render: ({ invoice }) =>
                    invoice.id ? (
                        <DownloadInvoiceButton fullWidth id={invoice.id} />
                    ) : (
                        <Text c="dimmed">Coming soon...</Text>
                    )
            }
        ],
        []
    );

    return (
        <GenericTable<Payment>
            // @ts-ignore
            useData={useGetPaymentsQuery}
            columns={columns}
            idAccessor="payment_id"
            hasPagination={false}
            title="Billing History"
        />
    );
};

export default memo(PaymentsTable);
