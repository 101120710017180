import React, { memo } from 'react';
import HeaderedCard from 'shared/ui/HeaderedCard/HeaderedCard';
import { Text } from '@mantine/core';
import { Misconfiguration } from 'entities/Misconfiguration';
import { Vulnerability } from 'entities/Vulnerability';
import { Issue } from '../../model/types/issue';

interface IssueSolutionProps {
    issue: Issue | Vulnerability | Misconfiguration;
}

const IssueSolution = (props: IssueSolutionProps) => {
    const { issue } = props;
    const { solution } = issue;

    if (!solution) return null;

    return (
        <HeaderedCard header="Solution">
            <Text size="sm">{solution}</Text>
        </HeaderedCard>
    );
};

export default memo(IssueSolution);
