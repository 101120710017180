import { DataTableColumn } from 'mantine-datatable';
import {
    Asset,
    AssetQueryParams,
    AssetTypeBadge,
    RootAsset,
    RootAssetCandidate
} from 'entities/Asset';
import { BaseColumnProps, updateQuery } from 'widgets/Columns/baseColumn';
import React from 'react';
import { FilterByAssetTypeForm } from 'features/asset/filters/FilterByAssetType';
import {
    Vulnerability,
    VulnerabilityQueryParams
} from 'entities/Vulnerability';
import {
    Misconfiguration,
    MisconfigurationQueryParams
} from 'entities/Misconfiguration';

type AssetType = Asset | RootAsset | RootAssetCandidate;
type RecordType = AssetType | Vulnerability | Misconfiguration;
type QueryParams =
    | AssetQueryParams
    | MisconfigurationQueryParams
    | VulnerabilityQueryParams;

export default ({
    query = {},
    setQuery,
    column
}: BaseColumnProps<RecordType, QueryParams>): DataTableColumn<RecordType> => {
    const { assetType = [] } = query;
    return {
        width: 130,
        accessor: 'type',
        title: 'Type',
        filtering: assetType.length > 0,
        filter: (
            <FilterByAssetTypeForm
                selectedAssetTypes={assetType}
                onChange={assetType =>
                    updateQuery({ assetType }, query, setQuery)
                }
            />
        ),
        render: record => {
            const asset: AssetType = 'asset' in record ? record.asset : record;

            return (
                <AssetTypeBadge
                    asset={asset}
                    fullWidth
                    selectedAssetTypes={assetType}
                    setSelectedAssetTypes={assetType =>
                        updateQuery({ assetType }, query, setQuery)
                    }
                />
            );
        },
        ...column
    };
};
