import { contactEmail } from 'shared/lib/string/string';
import { SERVICE_DESK_EMAIL } from 'shared/const/emails';

const MailToServiceDesk = () => {
    const mailTo = contactEmail(SERVICE_DESK_EMAIL);

    return <a href={mailTo}>{SERVICE_DESK_EMAIL}</a>;
};

export default MailToServiceDesk;
