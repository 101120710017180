export enum ScanMode {
    DISABLED = 'disabled',
    INHERIT = 'inherit',
    PASSIVE = 'passive',
    ACTIVE = 'active'
}

export enum ScanModeLabel {
    DISABLED = 'Disabled',
    INHERIT = 'Inherit',
    PASSIVE = 'Passive',
    ACTIVE = 'Active'
}

export interface GlobalScannerConfig {
    scanMode: ScanMode;
}

export interface AssetScannerConfig {
    id: string;
    scanMode: ScanMode;
}
